import React, { useEffect } from 'react';
import { Image, Container, Row, Col, Button } from "react-bootstrap";
import CapsyncLogo from "../assets/images/capsync-logo.svg";
import { useNavigate } from "react-router-dom"
import { useSelector } from 'react-redux';

const FallBack = () => {
    const { user } = useSelector((state) => state.auth)
    // useEffect(() => {
    //     if (!user) {
    //         window.location.href = '/';
    //     }
    // }, [])

    // if (!user) {
    //     return
    // }
    return (
        <Container>
            <div className="page404">
                <Row>
                    <Col lg={12}>
                        <div className="error-container">
                            <Image
                                width="132"
                                src={CapsyncLogo}
                                className="capsync-logo"
                                alt="Capsync Logo"
                            // onClick={redirectToSignin}
                            />
                            <div className="error-image">
                                <Image src={require("../assets/images/other/404-image.png")} className="pagenotfound" />
                            </div>
                            <div className="error-content">
                                <h1 className="default-semi-bold-h1 cs-neutral-100">Page Not Found</h1>
                                <p className="default-semi-bold-h3 cs-neutral-80">We're sorry, the page you requested could not be found please go back to the homepage</p>
                                <span className="back-to-sign-in">
                                    <Button
                                        // onClick={redirectToSignin}
                                        className="cs-btn-tertiary lg-btn default-regular-h5"
                                    >
                                        Back to Sign In
                                    </Button>
                                </span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    );
};

export default FallBack;
