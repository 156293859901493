import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import PlanCards from "../../../commonComponent/paymentSubscription/subscription/PlanCards";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";

const SubscribeModal = ({
  isClose = false,
  onHandleClose,
  sharedPfs,
  CapsyncLogoutModal,
}) => {
  const { authUser } = useSelector((state) => state.auth);
  const [showModal, setShowModal] = useState(false);

  const logUser = authUser && authUser.user ? authUser.user : authUser;

  useEffect(() => {
    if (window.localStorage.getItem('last_pfs_access_id') === null && sharedPfs.length != 0 && logUser.is_subscribe === false && logUser.account_id === null) {
      setShowModal(false)
    } else {
      setShowModal(true)
    }
  }, [])

  const handleLogout = () => {
    CapsyncLogoutModal(true);
  }

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="cs-xl-modal choose-plan-box"
        size="xl"
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <span className="cs-modal-close cs-neutral-60" onClick={isClose ? onHandleClose : handleLogout}>
            <CapsyncIcon title="close-outlined" size="22" /></span>
          <PlanCards intentType={1} />
        </Modal.Body >
      </Modal >
    </>
  );
};

export default SubscribeModal;
