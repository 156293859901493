import React, { useEffect } from 'react'
import Avatar from '../../../../commonComponent/Avatar'
import { socket } from '../../../../config/Socket'
import { Dropdown, Image } from 'react-bootstrap'
import { capitalizeName, nameInitials } from '../../../../config/finance'

const AccessPfsImage = ({ itr, handleResponseFromIndividualDecision, i, srcUrl }) => {

    useEffect(() => {
        return () => {
            socket.off('response_from_individual_decision_to_main_user', handleResponseFromIndividualDecision);
            socket.off('response_from_individual_decision_to_other_wealth_managers', handleResponseFromIndividualDecision);
            socket.off('response_from_individual_decision_to_other_individuals', handleResponseFromIndividualDecision);
        }
    }, [])
    return (
        <> <Dropdown>
            <Dropdown.Toggle>
                <div className="profile-image">
                    <div className='multiple-user-avatar'>
                        {(itr.profileImageUrl == "avtar-6" || itr.profileImageUrl == null) ? (<span className="user-text default-regular-h6 cs-neutral-80">
                            {nameInitials(itr.firstName, itr.lastName)}
                        </span>) : itr.profileImageUrl == "avtar-1" ? <Avatar title="avtar-1" size="38" /> : itr.profileImageUrl == "avtar-2" ? <Avatar title="avtar-2" size="38" /> : itr.profileImageUrl == "avtar-3" ? <Avatar title="avtar-3" size="38" /> : itr.profileImageUrl == "avtar-4" ? <Avatar title="avtar-4" size="38" /> : itr.profileImageUrl == "avtar-5" ? <Avatar title="avtar-5" size="38" /> : <Image
                            src={srcUrl[i]}
                            alt="Profile"
                            className="avtar-6"
                            width={40}
                            height={40}
                        />}
                    </div>
                </div>
                <span className="active-dot cs-success"></span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <div className="profile-info">
                    <span className="default-regular-body-text-m cs-neutral-90">
                        {capitalizeName(itr.firstName) + ' ' + capitalizeName(itr.lastName)}
                    </span>
                    <span className="default-regular-body-text-xs cs-neutral-60">{itr.professional_role == 3 ? itr.businessName : itr.professional_role == 2 ? 'Financial Advisor' : "Individual"}</span>
                </div>
            </Dropdown.Menu>
        </Dropdown></>
    )
}

export default AccessPfsImage