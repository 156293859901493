import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import commonNames from "../../../config/commonNames";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";

import { userRequestList } from "../../../slices/requests";
import { fetch_user_from_email } from "../../../slices/commonUsers";
import RequestsPage from "./RequestsPage";
import { fetchSignatureFrom } from "../../../util/request";
import handleDownload from "../../../customHooks/SignaturePdfdownload";

import OffCanvas from "../../individual/request/OffCanvas";
import SkeletonRequest from "./SkeletonRequest";
import "./Requests.css"
import DeclineSignatureModal from "../../individual/request/DeclineSignatureModal";
import { PDFDocument } from "pdf-lib";
import axios from "axios";
import { sendCancelemail, sendpfsemail } from "../../../slices/user";
import { emailSchema } from "../../../util/common";
import { uniValidation } from "../../individual/liabilities/categoryForms/validationSchema";
import Pagination from "../../../commonComponent/Pagination";
import { Buffer } from 'buffer';
const RequestDashboardWel = (props) => {

  const dispatch = useDispatch();
  const CapsyncMessage = props.CapsyncMessage; const { user } = useSelector((state) => state.auth);
  const { signatureRequest } = useSelector((state) => state.notification);

  const user_id = user && user.user.id;


  let wm_email = user.user.email;
  const radios = [
    { name: "Pending", value: "1" },
    { name: "Completed", value: "2" },
  ];
  const [radioValue, setRadioValue] = useState("1");
  const [pendingRequests, setPendingRequests] = useState([]);
  const [completedRequests, setCompletedRequests] = useState([]);
  const [showCanvas, setShowCanvas] = useState(false);
  const [OffcanvasState, setOffcanvasState] = useState({});
  const [SignatureId, setSignatureId] = useState("");
  const [SignatureReqid, setSignatureReqid] = useState("");
  const [sigId, setSigId] = useState("");
  const [sendUser, setSendUser] = useState("");
  const [wm_user_id, setWm_user_id] = useState("");
  const [signatureRequestId, setSignatureRequestId] = useState("");
  const [flag, setFlag] = useState("");
  const [showSignatureRequestDecline, setShowSignatureRequestDecline] = useState(false);
  // -------------------------
  const [showPFSPreview, setShowPFSPreview] = useState(false);
  const [validationMsg, setValidationmsg] = useState("");
  const [validationMsg1, setValidationmsg1] = useState("");
  const [validationMsgLastName, setValidationmsgLastName] = useState("");
  const [linkk, setLinkk] = useState("");
  const [sharePFS, setSharePFS] = useState({
    show: false,
    hide: true,
  });
  const [sentData, setSentData] = useState({});
  const [URL, setUrl] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [lastName, setLastName] = useState("");
  const [notes, setNotes] = useState("");
  const [signature_id, setSignature_id] = useState("");
  const [urlLink, setUrlLink] = useState(" ");
  const [searchQuery, setSearchQuery] = useState("");

  const [rowStartIdxPending, setRowStartIdxPending] = useState(0);
  const [rowStartIdxCompleted, setRowStartIdxCompleted] = useState(0);
  const paginationDropdownItems = [10, 15, 20, 25, 30];
  const [rowsPerPagePending, setRowsPerPagePending] = useState(paginationDropdownItems[0]);
  const [rowsPerPageCompleted, setRowsPerPageCompleted] = useState(paginationDropdownItems[0]);

  useEffect(() => {
    fetchSignatureRequests();
    if (signatureRequest) {
      setPendingRequests(fetchSignatureFrom(signatureRequest.to, 'Pending', false))
      setCompletedRequests(fetchSignatureFrom(signatureRequest.to, 'Completed', false))
    }
  }, []);

  const fetchSignatureRequests = () => {
    dispatch(userRequestList({ wm_id: user_id, wm_email: wm_email })).unwrap()
      .then((response) => {
        // setPendingRequests(fetchSignatureFrom(response.to, 'Pending', false));
        // setCompletedRequests(fetchSignatureFrom(response.to, 'Completed', false));
      })
      .catch((err) => {
        CapsyncMessage(
          "Error",
          "userRequestList :" + err,
          "error"
        );
      });
  }
  useEffect(() => {
    setPendingRequests(fetchSignatureFrom(signatureRequest.to, 'Pending', false))
    setCompletedRequests(fetchSignatureFrom(signatureRequest.to, 'Completed', false))


  }, [signatureRequest]);
  const handleSignatureDetailsClick = (request_send_by_email,
    requestDetails,
    signature_Id,
    signature_req_id) => {
    const { request_sent_to_email, signature_hello_id, request_id } = requestDetails;
    setSignatureId(signature_hello_id);
    setSignatureReqid(request_id);

    dispatch(fetch_user_from_email({ email: request_sent_to_email }))
      .unwrap()
      .then((response) => {
        let offCanvasObject = {
          signature_date: requestDetails.signature_date ? requestDetails.signature_date : "",
          signature_req_id: requestDetails.signature_hello_id,
          request_type: requestDetails.request_type,
          row_id: requestDetails.id,
        }

        if (response.code == "200") {

          let companyData = "";
          if (response && response.res_data.companyName) {
            companyData = response && response.res_data.companyName;
          }
          // if (response && response.res_data.companyLicenceId) {
          //   if (response && response.res_data.companyName) {
          //     companyData += " | ";
          //     companyData += response && response.res_data.companyLicenceId;
          //   } else {
          //     companyData += response && response.res_data.companyLicenceId;
          //   }
          // }

          offCanvasObject = {
            ...offCanvasObject,
            request_send_by: response.res_data.id,
            request_send_by_displayName: response.res_data.displayName,
            request_send_by_phoneNo: response.res_data.phoneNo,
            request_send_by_email: response.res_data.email,
            request_send_by_role: response.role,
            // request_send_by_role: requestDetails.request_sent_by_role,
            request_send_by_photo: response.res_data.profileImageUrl,
            request_send_by_companyData: companyData,
            request_send_to_role: requestDetails.request_send_to_role,
            companyLicenceId: response.res_data.companyLicenceId,
            check_status: requestDetails.request_status || requestDetails.check_status,
            createdAt: requestDetails.createdAt,
            is_deleted: requestDetails.is_deleted,
            is_signed: requestDetails.isSigned,
            SpouseJointEmail: requestDetails.joint_user_email,
            SpouseJointName: requestDetails.jointUserName,
            updatedAt: requestDetails.updatedAt,
            send_user: requestDetails.send_user,
            FirstEmail: requestDetails.email,
            FirstUserName: requestDetails.firstName + " " + requestDetails.lastName,
            signStatus: requestDetails.sign_status,
            signature_req_id: requestDetails.request_id,
            id: requestDetails.id,
            signature_hello_id: requestDetails.signature_hello_id,
            secondUserEmail: requestDetails.request_sent_by_email,
            firstUserEmail: requestDetails.request_sent_to_email,
            username: requestDetails.request_sent_to_firstName + " " + requestDetails.request_sent_to_lastName,
            wealthManagerName: user.user.displayName,
            receiver_email: requestDetails.receiver_email,
            receiver_name: requestDetails.receiver_firstName + " " + requestDetails.receiver_lastName,
            receiver_note: requestDetails.receiver_notes,
            spouse_email: requestDetails.spouse_email,
            spouse_name: requestDetails.spouse_name,
            request_sent_by_role: requestDetails.request_sent_by_role,
            request_sent_to_role: requestDetails.request_sent_to_role,
            signer_count: requestDetails.signer_count

          }

        } else {
          offCanvasObject = {
            ...offCanvasObject,
            request_send_by_email: request_sent_to_email,
            check_status: requestDetails.request_status,
            request_send_by_phoneNo: requestDetails.request_sent_to_phoneNo,
            request_send_by_displayName: `${requestDetails.request_sent_to_firstName} ${requestDetails.request_sent_to_lastName}`,
            // request_send_by_role: requestDetails.request_sent_by_role,
            request_send_by_companyData: "-",
            companyLicenceId: "-",
            createdAt: requestDetails.createdAt,
            request_send_by_role: requestDetails.request_sent_by_role,
            // request_send_by_role: "Individual",
            signStatus: requestDetails.sign_status,
            signature_req_id: requestDetails.request_id,
            id: requestDetails.id,
            signature_hello_id: requestDetails.signature_hello_id,
            request_send_by: requestDetails.request_sent_by,
            secondUserEmail: requestDetails.request_sent_by_email,
            firstUserEmail: requestDetails.request_sent_to_email,
            username: requestDetails.request_sent_to_firstName + " " + requestDetails.request_sent_to_lastName,
            wealthManagerName: user.user.displayName,
            receiver_email: requestDetails.receiver_email,
            receiver_name: requestDetails.receiver_firstName + " " + requestDetails.receiver_lastName,
            receiver_note: requestDetails.receiver_notes,
            spouse_email: requestDetails.spouse_email,
            spouse_name: requestDetails.spouse_name,
            request_sent_by_role: requestDetails.request_sent_by_role,
            request_sent_to_role: requestDetails.request_sent_to_role,
            signer_count: requestDetails.signer_count

          }
        }
        setOffcanvasState(offCanvasObject);
        setShowCanvas(true);
      }).catch((err) => {

      });
  };

  const HandleOpen = (
    id,
    flag,
    sendUser,
    wm_user_id,
    signatureRequestId,
    show,
    hide
  ) => {
    setSigId(id);
    setFlag(flag);
    setSendUser(sendUser);
    setWm_user_id(wm_user_id);
    setSignatureRequestId(signatureRequestId);
    // setModalStateDecline(false);
    setShowSignatureRequestDecline(true)
  };
  const handleSignatureRequestDecline = () => {
    setShowCanvas(false);
    setShowSignatureRequestDecline(false);
    // emailCancellation(signatureRequestId);
    // setTimeout(() => window.location.reload(true), 2000)
    fetchSignatureRequests()


  };
  // -------------------------------

  const handleNameChange = (e) => {
    setName(e.target.value);
    setValidationmsg1("");
  };
  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
    setValidationmsgLastName("");
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setValidationmsg("");
  };
  const handleNotesChange = (e) => {
    setNotes(e.target.value);

  };
  // let regex = /^\S*$/;
  // let regex = /^[A-Za-z\s]+$/;
  let regex = /[0-9]/;

  function inValidateName(name) {
    return regex.test(name);
  }
  function inValidateLastName(name) {
    return regex.test(name);
  }
  const handleSubmitSec = async (e) => {
    e.preventDefault();
    const datas = { name, email, lastName };
    let nameError = !datas.name ? "Enter the name" : inValidateName(datas.name) ? "Enter a valid first name" : "";
    let lastNameError = !datas.lastName ? "Enter the Last Name" : inValidateLastName(datas.lastName) ? "Enter a valid last name" : "";
    let emailError = "";
    try {
      await emailSchema.validate(datas.email);
    } catch (error) {
      emailError = error.message || uniValidation.email.validEmail;
    }
    setValidationmsg1(nameError)
    setValidationmsg(emailError)
    setValidationmsgLastName(lastNameError)

    if (nameError === '' && emailError === '' && lastNameError === "") {
      setLinkk(sharePFS.pfslink);
      setSharePFS(false);
      openSecondModal(sharePFS.pfslink, datas);
    }
  };
  const openSecondModal = (values, data1) => {
    setSentData(data1);
    fetchUrl(values);
    setShowPFSPreview(true, data1);
  };
  const fetchUrl = async (values) => {
    if (values) {
      const username = process.env.REACT_APP_HELLO_SIGN_USER_NAME;
      const password = process.env.REACT_APP_HELLO_SIGN_PASSWORD;

      const token = Buffer.from(`${username}:${password}`, "utf8").toString(
        "base64"
      );

      const headers = { Authorization: `Basic ${token}` };

      const response = await axios.get(values, {
        headers,
        responseType: "arraybuffer",
      });

      const fileData = response.data;
      const pdfDoc = await PDFDocument.create();

      const downloadedPDF = await PDFDocument.load(fileData);

      const pageCount = downloadedPDF.getPageCount();

      for (let i = 0; i < pageCount; i++) {
        const [copiedPage] = await pdfDoc.copyPages(downloadedPDF, [i]);
        pdfDoc.addPage(copiedPage);
      }

      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      setUrl(url);
    }
  };
  const sharePFSModal = (show, hide, pfslink, sign_id) => {
    setSignature_id(sign_id);
    setSharePFS({ show, hide, pfslink });
    setUrlLink(pfslink);
    setEmail("");
    setName("");
    setLastName("")
    setValidationmsg("");
    setValidationmsg1("");
    setValidationmsgLastName("")
    setNotes("")
  };
  const handleClosePFSPreview = () => {
    setShowPFSPreview(false);
    setValidationmsg("");
    setValidationmsg1("");
    setValidationmsgLastName("")
  };

  const handleSubmit = (datas, linkk) => {
    dispatch(
      sendpfsemail({
        user_id: user_id,
        pfsLink: linkk,
        firstUserEmail: datas.email,
        secondUserEmail: "",
        secondUserName: datas.name + " " + datas.lastName,
        notes: datas.notes,
        // user: "user",
        signature_id: signature_id,
      })
    );
    setShowPFSPreview(false);
    props.toastMessage("Your Personal Financial Statement was sent successfully.", "success");
  };


  return (
    <>
      <section className="request-main-section financial-request-section">
        <Container fluid>

          {props.isLoader === false ? (<><section className="cs-section-topbar">
            <Row>
              <Col>
                <div className="cs-title">
                  <h1 className="default-semi-bold-h1 cs-neutral-100">
                    {commonNames.Requests}
                  </h1>
                </div>
                <div className="cs-tabs-flex">
                  <div className="cs-tabs">
                    {radios.map((radios, id) => (
                      <Button
                        className={`cs-stroke-default-tab cs-tabs-height default-regular-h5 ${radioValue === radios.value ? "cs-stroke-active" : ""}`}
                        key={id}
                        id={`radio-${id}`}
                        type="radio"
                        onClick={() => setRadioValue(radios.value)}
                      >
                        {radios.name}
                      </Button>
                    ))}
                  </div>
                  <div className="cs-search-bar">
                    <Form.Group className="cs-form-group">
                      <Form.Control
                        type="text"
                        placeholder="Search..."
                        // value={search}
                        // className={search && "cs-input-field-active"}
                        // onChange={(event) =>
                        //   searchIndividual(event.target.value)
                        // }
                        value={searchQuery}
                        onChange={(event) => setSearchQuery(event.target.value)}

                      />
                      <span className="input-field-icon cs-neutral-70">
                        <CapsyncIcon title="search-outlined" size="18" />
                      </span>
                    </Form.Group>
                  </div>
                </div>
              </Col>
            </Row>
          </section>

            {radioValue === "1" && <><RequestsPage
              CapsyncMessage={CapsyncMessage}

              data={pendingRequests.filter((request) => {
                if (request.wm_users) {
                  const fullName = `${request.wm_users[0].request_sent_to_firstName.toLowerCase()} ${request.wm_users[0].request_sent_to_lastName.toLowerCase()}`;
                  const formattedCreatedAt = moment(request.wm_users[0].createdAt).format("MM/DD/YYYY").replace(/-/g, '/');
                  return fullName.includes(searchQuery.toLowerCase())
                    || formattedCreatedAt.includes(searchQuery.toLowerCase())
                    || request.wm_users[0].request_type.toLowerCase().includes(searchQuery.toLowerCase())
                    || request.wm_users[0].request_sent_to_role.toLowerCase().includes(searchQuery.toLowerCase());
                }
              })}
              status={'Pending'}
              onSignatureDetailsClick={handleSignatureDetailsClick}
              handleDownload={handleDownload}
              sharePFSModal={sharePFSModal}
              radioValue={radioValue}
              searchQuery={searchQuery}
              rowsPerPage={rowsPerPagePending}
              rowStartIdx={rowStartIdxPending}
            />
              {pendingRequests.length > 0 && <Pagination
                rowStartIdx={rowStartIdxPending}
                setRowStartIdx={setRowStartIdxPending}
                rowsPerPage={rowsPerPagePending}
                setRowsPerPage={setRowsPerPagePending}
                paginationDropdownItems={paginationDropdownItems}
                paginationList={pendingRequests}
              />}
            </>
            }


            {radioValue === "2" && <><RequestsPage
              CapsyncMessage={CapsyncMessage}

              data={completedRequests.filter((request) => {
                if (request.wm_users) {
                  const fullName = `${request.wm_users[0].request_sent_to_firstName.toLowerCase()} ${request.wm_users[0].request_sent_to_lastName.toLowerCase()}`;
                  const formattedCreatedAt = moment(request.wm_users[0].createdAt).format("MM/DD/YYYY").replace(/-/g, '/');
                  return fullName.includes(searchQuery.toLowerCase())
                    || formattedCreatedAt.includes(searchQuery.toLowerCase())
                    || request.wm_users[0].request_type.toLowerCase().includes(searchQuery.toLowerCase())
                    || request.wm_users[0].request_sent_to_role.toLowerCase().includes(searchQuery.toLowerCase());
                }
              }
              )}
              status={'Completed'}
              onSignatureDetailsClick={handleSignatureDetailsClick}
              handleDownload={handleDownload}
              sharePFSModal={sharePFSModal}
              radioValue={radioValue}
              searchQuery={searchQuery}
              rowsPerPage={rowsPerPageCompleted}
              rowStartIdx={rowStartIdxCompleted}
            />
              {completedRequests.length > 0 && <Pagination
                rowStartIdx={rowStartIdxCompleted}
                setRowStartIdx={setRowStartIdxCompleted}
                rowsPerPage={rowsPerPageCompleted}
                setRowsPerPage={setRowsPerPageCompleted}
                paginationDropdownItems={paginationDropdownItems}
                paginationList={completedRequests}
              />}
            </>
            }
            <DeclineSignatureModal
              signatureRequestId={signatureRequestId}
              sendUser={sendUser}
              showSignatureRequestDecline={showSignatureRequestDecline}
              // HandleOpen={HandleOpen}
              onSignatureRequestDecline={handleSignatureRequestDecline}
              setShowSignatureRequestDecline={setShowSignatureRequestDecline}
              toastMessage={props.toastMessage}
              OffcanvasState={OffcanvasState}
            />
            <Modal
              dialogClassName="cs-md-modal"
              show={sharePFS.show}
              // show={props.showThirdModal}
              onHide={sharePFS.hide}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header>
                <Modal.Title className="default-semi-bold-h3">
                  Share PFS
                </Modal.Title>
                {/* <CloseButton onClick={() => sharePFSModal(false, true)} /> */}
              </Modal.Header>
              <Modal.Body>
                <div>

                  <Row>
                    <Col md={6}>
                      <Form.Group className="cs-form-group">
                        <Form.Label className="">First name</Form.Label>
                        <Form.Control
                          type="text"
                          id="name"
                          autoComplete="off"
                          onKeyDown={(e) => {
                            if (e.key === " " && e.target.selectionStart === 0) {
                              e.preventDefault();
                            }
                          }}
                          value={name}
                          className={name && "cs-input-field-active"}
                          onChange={handleNameChange}
                          maxLength={40}
                        />
                        <span className="form-error-msg default-light-body-text-s cs-danger">
                          {validationMsg1}
                        </span>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="cs-form-group">
                        <Form.Label>Last name</Form.Label>
                        <Form.Control
                          type="text"
                          id="lastName"
                          autoComplete="off"
                          onKeyDown={(e) => {
                            if (e.key === " " && e.target.selectionStart === 0) {
                              e.preventDefault();
                            }
                          }}
                          value={lastName}
                          className={lastName && "cs-input-field-active"}
                          onChange={handleLastNameChange}
                          maxLength={40}
                        />
                        <span className="form-error-msg default-light-body-text-s cs-danger">
                          {validationMsgLastName}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <Form.Group className="cs-form-group">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          id="email"
                          autoComplete="off"
                          value={email}
                          onKeyDown={(e) => e.code === 'Space' && e.preventDefault()}
                          className={email && "cs-input-field-active"}
                          onChange={handleEmailChange}
                        />

                        <span className="form-error-msg default-light-body-text-s cs-danger">
                          {validationMsg}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form.Group className="cs-form-group last-email-field">
                        <Form.Label>Notes</Form.Label>
                        <textarea
                          className={
                            notes && "cs-textarea"
                              ? "cs-textarea cs-input-field-active"
                              : "cs-textarea"
                          }
                          type="text"
                          autoComplete="off"
                          placeholder="Optional"
                          onChange={handleNotesChange}
                        // className="cs-textarea"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="cs-modal-btn">
                  <Button
                    onClick={() => sharePFSModal(false, true)}
                    className="cs-btn-secondary md-btn default-regular-h5"
                    // className="wm-req-sharepfs-cancelbtn "
                    variant="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="cs-btn-primary md-btn default-regular-h5"
                    type="button"
                    onClick={handleSubmitSec}
                    variant="primary"
                    disabled={!name || !email || !lastName}
                  >
                    Review & Send
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
            <Modal
              show={showPFSPreview}
              sentData={sentData}
              link={linkk}
              centered
              aria-labelledby="contained-modal-title-vcenter"
              className="pfs-pdf-modal"
            >
              <Modal.Header>
                <Modal.Title className="default-semi-bold-h1 cs-neutral-100">
                  PFS Preview
                </Modal.Title>
                {/* <CloseButton onClick={handleClosePFSPreview} /> */}
              </Modal.Header>

              <Modal.Body>
                {/* <iframe src={URL} width="450" height="800"></iframe> */}
                <div className="pfs-pdf-box wm-req-pdf-container">
                  <iframe
                    src={URL}
                    width="100%"
                    height="100%"
                    title="PDF Viewer"
                  ></iframe>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="cs-modal-btn">
                  <Button
                    onClick={handleClosePFSPreview}
                    className="cs-btn-secondary md-btn default-regular-h5"
                    variant="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="cs-btn-primary md-btn default-regular-h5"
                    type="button"
                    onClick={() => handleSubmit(sentData, linkk)}
                    variant="primary"
                  >
                    Send
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>

            <OffCanvas
              OffcanvasState={OffcanvasState}
              setShowCanvas={setShowCanvas}
              showCanvas={showCanvas}
              HandleOpen={HandleOpen}
              users={user}

            /></>) : <SkeletonRequest />}


        </Container>
      </section>
    </>
  );
};

export default RequestDashboardWel;

