import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Image, Button } from "react-bootstrap";
import QRCode from "qrcode";
import { clearMessage } from "../../../slices/message";
import { mfaGoogleAuth, mfaGoogleAuthSetup } from "../../../slices/auth";
import CapsyncLoaderSpin from "../../../../assets/images/capsync-loader.gif"
import GoogleAppPin from "./GoogleAppPin";


const GoogleAppSetup = ({ CapsyncMessage, closeModal }) => {
  const { user } = useSelector((state) => state.auth);
  const [qr, setQr] = useState("");
  const [mFAGoogle, setMFAGoogleFlag] = useState("");
  const dispatch = useDispatch();
  const email = user && user.user.email;
  const userId = user && user.user.id;
  const qrLbl = user && user.user_role.role === 1 ? `CapSync USER-${userId}` : `CapSync FA-${userId}`;

  useEffect(() => {
    dispatch(clearMessage());
    dispatch(mfaGoogleAuth({ email }))
      .then((data) => {
        if (data.payload.code === 200) {
          const srt = data.payload.res_data.secret;
          const qrCompLabel = process.env.REACT_APP_TITLE;
          const otpauth_url =
            "otpauth://totp/" + qrCompLabel + ": " + email + "?secret=" + srt;

          QRCode.toDataURL(otpauth_url, (err, image_data) => {
            setQr(image_data);
          });
          setMFAGoogleFlag(1);
        } else {
          setMFAGoogleFlag("");
        }
      })
      .catch((err) => { });
  }, [dispatch]);

  const mfaGoogleAuthConfirm = () => {
    dispatch(mfaGoogleAuthSetup({ email }))
      .unwrap()
      .then((res) => {
        if (res.code === 200) {
          setMFAGoogleFlag(2);
        }
      })
      .catch(() => { });
  };

  return (
    <>
      {!mFAGoogle && (
        <Row>
          <Col lg={12} sm={12}>
            <div className="text-center initial-modal-loader">
              <Image
                src={CapsyncLoaderSpin}
                alt="CapsyncLoaderSpin"
                className="w-25"
              />
            </div>
          </Col>
        </Row>
      )}

      {mFAGoogle === 1 && (
        <>
          <div className="authenticator-app-verification">
            <h5 className="default-regular-h5 cs-neutral-100">
            Please scan the barcode below using Google Authenticator.
              {/* You will need a Google
              <span className="default-regular-h5 cs-primary">
                &nbsp;Authenticator&nbsp;
              </span>
              to complete this process. */}
            </h5>
            <span className="cs-qr-code">
              {<Image src={`${qr}`} alt="Qr Code" width={193} height={193} />}
            </span>
          </div>
          <div className="cs-modal-btn">
            <Button
              type="button"
              className="cs-btn-secondary md-btn default-regular-h5"
              variant="secondary"
              onClick={() => closeModal()}
            >
              Cancel
            </Button>
            <Button
              type="button"
              onClick={mfaGoogleAuthConfirm}
              className="cs-btn-primary md-btn default-regular-h5"
              variant="primary"
            >
              Continue
            </Button>
          </div>
        </>
      )}

      {mFAGoogle === 2 && (
        <Row>
          <Col lg={12} sm={12}>
            <GoogleAppPin CapsyncMessage={CapsyncMessage} closeModal={closeModal} />
          </Col>
        </Row>
      )}
    </>
  );
};

export default GoogleAppSetup;
