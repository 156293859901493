import React from "react";
import { Button, Modal, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ErrorValidation from "../../../assets/images/other/error-validation.svg";
import "../../../app/commonComponent/component/Component.css";
import { socket } from "../../config/Socket";
import { useDispatch, useSelector } from "react-redux";
import { localStorageClear } from "../../util/common";
import { change_edit_permission } from "../../slices/requests";
import { setRemoveAuthUser, signout } from "../../slices/auth";
import { roleName } from "../../config/commonRoleName";

const LogoutModal = ({ logoutStatus, CapsyncLogoutModal }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, authUser } = useSelector((state) => state.auth);

  const user_role = user && user.user_role.role;
  const auth_user_role = authUser && authUser.role || '';
  const id = user && user.user.id;

  const confirmLogout = () => {
    dispatch(setRemoveAuthUser())
    if (window.localStorage.pfs_access_obj) {
      socket.emit("broadcast_message", {
        user_id: Number(
          JSON.parse(window.localStorage.getItem("pfs_access_obj"))
            .pfs_access_id
        ),
      });
      socket.emit("remove_edit_permission", {
        user_id: Number(
          JSON.parse(window.localStorage.getItem("pfs_access_obj"))
            .pfs_access_id
        ),
        advisor_user_id: window.localStorage.getItem('id'),
        flag: auth_user_role === 1 ? roleName.individual : auth_user_role === 2 ? roleName.financial_advisor : roleName.tax_professional
      })
      dispatch(
        change_edit_permission({
          advisor_user_id: Number(
            JSON.parse(window.localStorage.getItem("id"))
          ),
          user_id: Number(
            JSON.parse(window.localStorage.getItem("pfs_access_obj"))
              .pfs_access_id
          ),
          flag:
            user_role === 1
              ? roleName.individual
              : user_role === 2 ? roleName.financial_advisor : roleName.tax_professional,
        })
      ).unwrap().catch((err) => {
        console.log('change_edit_permission err:- ', err);
      });

    }

    CapsyncLogoutModal(false);
    // let user_id;
    // let user_role;
    // if (window.localStorage.pfs_access_obj) {
    //   user_id = Number(JSON.parse(window.localStorage.getItem("id")));
    //   user_role = Number(
    //     JSON.parse(window.localStorage.getItem("pfs_access_obj"))
    //       .pfs_access_role
    //   );
    // } else {
    //   user_id = id;
    //   user_role = user_role;
    // }

    // socket.emit("fetch_user", {
    //   user_id: user_id,
    //   user_type: user_role,
    //   user_role:
    //     user_role === 1
    //       ? roleName.individual
    //       : user_role === 2
    //         ? roleName.financial_advisor
    //         : roleName.tax_professional,
    //   logged_out: true,
    // });
    socket.disconnect();
    dispatch(signout())
    localStorageClear()
    socket.emit("user_logging_out", true);
    navigate("/", { replace: true });
    window.location.reload();
  };

  const confirmNotLogout = () => {
    CapsyncLogoutModal(false);
  };

  return (
    <>
      <Modal
        show={logoutStatus.show}
        onHide={confirmNotLogout}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="cs-md-modal"
        className="cs-common-modal-overlay"
      >
        <Modal.Header>
          <Modal.Title className="default-semi-bold-h3">
            Confirm log out
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="log-out-popup">
            <span>
              <Image
                src={ErrorValidation}
                alt="Error Validation"
                width={100}
                height={100}
              />
            </span>
            <p className="default-light-body-text-m cs-neutral-90 text-center">
              Are you sure you want to log out?
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="cs-modal-btn cs-center-btn">
            <Button
              onClick={confirmLogout}
              className="cs-btn-secondary md-btn default-regular-h5"
            >
              Log out
            </Button>
            <Button
              onClick={confirmNotLogout}
              className="cs-btn-primary md-btn default-regular-h5"
            >
              Stay on CapSync
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LogoutModal;
