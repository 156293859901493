/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Image,
  Button,
  Modal,
  Container,
  Row,
  Col,
  Dropdown,
  CloseButton,
} from "react-bootstrap";

import CapsyncPFSFilter from "../../../../commonComponent/CapsyncPFSFilter";
import moment from "moment";
import { socket } from "../../../../config/Socket";
import SharePfsCategoryTable from "./sharePfsCategoryTables";
import userDetails from "../../../../customHooks/UseFetchDetails";
import ExternalAttachments from "./ExternalAttachments";
import { useDispatch, useSelector } from "react-redux";
import { Signature_Requests_Add, userRequestList } from "../../../../slices/requests";
import { customLabelLength } from "../../../../customHooks/adjustLength";
import { useNavigate } from "react-router-dom";
import HelloSign from "hellosign-embedded";
import { backAppURl } from "../../../../applicationMode";
import axios from "axios";
import HelloSignProgress from "../../../../commonComponent/component/HelloSignProgress";
import FinanceTable from "../../dashboard/FinanceTable";
import NetWorthDashboard from "../../dashboard/NetWorthDashboard";
import "../../../../pages/individual/dashboard/dashboard.css";
import IndividualJoint from "../../../../commonComponent/pfsComponent/IndividualJoint";
import { externalAttachmentSharePfs } from "../../../../slices/sharePfs";
import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";
import { emailSchema, formatPhoneNumber, setAddressString } from "../../../../util/common";
import { store_pfs_link } from "../../../../slices/verifyInvestor";
import { toast } from "react-toastify";
import { fetch_all_external_attachments, other_attachements_remove } from "../../../../slices/requests";
import { validateDuplicateFileName } from "../../../../config/validateDuplicateFileName";
import { roleName } from "../../../../config/commonRoleName";
import { uniValidation } from "../../liabilities/categoryForms/validationSchema";


const SharePfsSecondMainModal = (props) => {
  const CapsyncMessage = props.CapsyncMessage;
  useEffect(() => {
    socket.on("connection", () => { });
  }, []);
  const { newCategoryData } = useSelector((state) => state.category);
  const [isLoading, setIsLOading] = useState(false);
  const [inputFields, setInpuFields] = useState([{ file: "", index_id: 0 }]);
  const [ext_attachment, setExt_attachment] = useState([]);
  const [previousAttachFiles, setPreviousAttachFiles] = useState([])
  const [documentName, setDocumentName] = useState("");
  const [comments, setComments] = useState('');
  const [fileUploadError, setFileUploadError] = useState('');
  const [checkExtensions, setCheckExtensions] = useState('');
  const inputRef = useRef(null);
  const navigate = useNavigate();

  const handleUploadAttachment = () => {
    try {
      if (previousAttachFiles.length > 0) {
        if (documentName.trim() !== '' && ext_attachment.length > 0) {

          const changedName = {
            files: ext_attachment[0],
            name: documentName,
          };

          // const isDuplicateName = total.some(existingFile => existingFile === ext_attachment[0].name) ||
          //   props.ext_Files.some(existingFile => existingFile.name === documentName) ||
          //   props.ext_Files.some(existingFile => existingFile.files.name === ext_attachment[0].name);

          // // const isDuplicateName = props.ext_Files.some(existingFile => existingFile.name === documentName);
          // if (isDuplicateName) {
          //   // toast.error('This document name already exists.');
          //   setFileUploadError('A file with this name already exists.');
          //   setCheckExtensions("")
          //   return;
          // }
          props.setExt_Files([...props.ext_Files, changedName]);
        } else if (ext_attachment.length > 0) {


          const changedName = {
            files: ext_attachment[0],
            name: ext_attachment[0].name,
          };

          // const isDuplicateFile = total.some(existingFile => existingFile === ext_attachment[0].name) ||
          //   props.ext_Files.some(existingFile => existingFile.files.name === ext_attachment[0].name)
          // // props.ext_Files.some(existingFile => existingFile.name === ext_attachment[0].name);
          // if (isDuplicateFile) {
          //   // toast.error('A file with this name already exists.');
          //   setFileUploadError('A file with this name already exists.');
          //   setCheckExtensions("")
          //   return;
          // }

          props.setExt_Files([...props.ext_Files, changedName]);
        } else {

          const changedName = {
            files: ext_attachment[0],
            name: ext_attachment[0].name,
          };
          // const isDuplicateFile = total.some(existingFile => existingFile === ext_attachment[0].name);
          // if (isDuplicateFile) {
          //   // toast.error('A file with this name already exists.');
          //   setFileUploadError('A file with this name already exists.');
          //   setCheckExtensions("")
          //   return;
          // }

          props.setExt_Files([...props.ext_Files, changedName]);
        }


      } else {

        if (documentName.trim() !== '' && ext_attachment.length > 0) {
          if (ext_attachment.length > 1) {
            // toast.error('Please select one file at a time.');
            setFileUploadError('Please select one file at a time.');
            return;
          }

          const changedName = {
            files: ext_attachment[0],
            name: documentName,
          };

          // const isDuplicateName = props.ext_Files.some(existingFile => existingFile.name === documentName);

          // // const isDuplicateName = props.ext_Files.some(existingFile => existingFile.name === documentName);
          // if (isDuplicateName) {
          //   // toast.error('This document name already exists.');
          //   setFileUploadError('This document name already exists.');
          //   return;
          // }

          const isDuplicateFile = props.ext_Files.some(existingFile => existingFile.files.name === ext_attachment[0].name);
          if (isDuplicateFile) {
            // toast.error('A file with this name already exists.');
            setFileUploadError('A file with this name already exists.');
            return;
          }

          props.setExt_Files([...props.ext_Files, changedName]);
        } else if (ext_attachment.length > 0) {
          if (ext_attachment.length > 1) {
            // toast.error('Please select one file at a time.');
            return;
          }

          const changedName = {
            files: ext_attachment[0],
            name: ext_attachment[0].name,
          };

          // const isDuplicateFile = props.ext_Files.some(existingFile => existingFile.files.name === ext_attachment[0].name);
          // if (isDuplicateFile) {
          //   // toast.error('A file with this name already exists.');
          //   setFileUploadError('A file with this name already exists.');
          //   return;
          // }

          props.setExt_Files([...props.ext_Files, changedName]);
        }
      }

    } catch (error) {
      console.log("error:- ", error);
    } finally {
      inputRef.current.value = '';
      setDocumentName('');
      setInpuFields([]);
      setExt_attachment([]);
    }
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };
  let arr = []
  previousAttachFiles.map((itr) => {
    arr.push(itr.attachments.substring(itr.attachments.indexOf('@') + 1))
  })

  let total = [...arr]

  const dispatch = useDispatch();

  let IsChecked = props.IsChecked.IsChecked;
  let SpouseValue = props.SpouseValue;

  const { user, authUser } = useSelector((state) => state.auth);

  const current_user_token = user && user.token;

  let companyId;
  let comp;
  let id;
  let firstReq;
  let comp_name;
  let pfs_access_id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
    pfs_access_id = window.localStorage.getItem("pfs_access_id");
    comp = window.localStorage.getItem("pfs_access_comp");
    companyId = window.localStorage.getItem("pfs_comp_id");
    firstReq = window.localStorage.getItem("pfs_access_comp_first");
    comp_name = window.localStorage.getItem("pfs_comp_name");
  } else {
    id = user && user.user.id;
    pfs_access_id = null;
  }

  let userRole = window.localStorage.getItem("loginStatus") && JSON.parse(window.localStorage.getItem("loginStatus")).user_role;
  if (window.localStorage.getItem("loginStatus")) {
    userRole = JSON.parse(window.localStorage.getItem("loginStatus")).user_role
  } else {
    userRole = authUser && authUser.user && authUser.user_role.role
  }


  let indi = "";
  const [Responses, setResponse] = useState("");

  const downloadPdf = async (flag, nameValue) => {
    try {
      let response = await axios({
        url: `${backAppURl}/api/pdf/export`,
        method: "post",
        responseType: "blob",

        data: {
          user_id: id,
          sec: flag,
          secondName: nameValue,
        },
        headers: {
          Authorization: `Bearer ${current_user_token}`,
        },
      })
      setResponse(response);
      return response
    } catch (error) {
      console.log(error);
    }

  };
  let userEmail = user && user.user.email;//email of PFS user
  const email = user && user.user.email;
  let user_email = authUser && authUser.user ? authUser.user.email : authUser && authUser.email;

  // let userRole = user && user.user_role.role;

  const user_id = user && user.user.id;//id of PFS user
  const user_displayName = user && user.user.displayName;//display name of PFS user
  // const user_email = user && user.user.email;
  const user_address = user && user.user.address;//address of PFS user
  const user_phoneNo = user && user.user.phoneNo;//address of PFS user

  // const sentByName = authUser && authUser.displayName;
  const sentByName = authUser && authUser.displayName ? authUser.displayName : authUser && authUser.user && authUser.user.displayName && authUser.user.displayName
  // const sentById = authUser && authUser.id;
  const sentById = authUser && authUser.id ? authUser.id : authUser && authUser.user && authUser.user.id && authUser.user.id
  // const sentByIndId=authUser && authUser.user.id;


  const userData = userDetails();

  const radioName = props.text && props.text === "verify_investor" ? [
    { name: "Summary", value: "1" },
    { name: "Worksheet", value: "2" },
  ] : [
    { name: "Summary", value: "1" },
    { name: "Worksheet", value: "2" },
    { name: "Attachments", value: "3" },
  ];

  let emailError = props.spouseEmailValue === email ? (
    <p className="formik-form-error">Spouse email cant be same</p>
  )
    : props.emailError ? (
      <p className="formik-form-error">{props.emailError}</p>
    ) : <p className="formik-form-error">Enter another email address</p>;



  let NameError = props.userNameError ? (
    <p className="formik-form-error">{props.userNameError}</p>
  ) : (
    <p className="formik-form-error">Enter name</p>
  );
  let ValidateMsgDetails = (
    <p className="msgColor">Please enter spouse details to continue</p>
  );
  const [nameValidation, setNameValidation] = useState("");
  const [validateMessage, setValidateMessage] = useState("");
  const handleValidation = () => {
    setValidateMessage(ValidateMsg);
  };
  const checkFetchRadio = (params, name) => {
    props.setRadioValue({ name: name, num: params });
  };

  const closeSecondModal = () => {
    props.setShowSecondModal(false);
  };

  function storePfstab(params) {
    props.setRadioValue({
      num: radioName.filter((x) => x.value === params)[0].value,
      name: radioName.filter((x) => x.value === params)[0].name,
    })

  }
  // const addFields = () => {
  //   let fetchArr = [...inputFields];
  //   fetchArr.push({ statement: "", file: "", index_id: fetchArr.length });
  //   let extFetchArr = [...props.ext_Files];
  //   extFetchArr.push({ files: "", index_id: extFetchArr.length });
  //   setExt_attachment(extFetchArr);
  //   setInpuFields(fetchArr);
  // };

  const removeInputField = (params) => {
    let externalAttach = [...props.ext_Files];
    externalAttach.splice(params, 1);
    // setExt_attachment(externalAttach);
    props.setExt_Files(externalAttach)
    setCheckExtensions('')
  };
  const attachmentUpload = () => {
    const formData = new FormData();
    formData.append("user_id", id);

    props.ext_Files.forEach((itr) => {
      formData.append("attachments", itr);
    });

    dispatch(externalAttachmentSharePfs(formData))
      .unwrap()
      .then((response) => {
        // console.log(response);
      })
      .catch((err) => {
        console.log("err:- ", err)
      });
  };

  function shortName(params) {
    let newContent = "";
    newContent += params;
    if (newContent.length > 20) {
      return newContent.substring(0, 20) + "...";
    } else {
      return newContent;
    }
  }

  const changeDocumentInputVal = (params) => {
    const newFiles = Array.from(params);

    if (validateDuplicateFileName(newFiles, props.total_attachments, setCheckExtensions)) {
      // const supportedExtensions = ['.pdf', '.png', '.jpg', '.jpeg'];
      // const maxFileSize = 10 * 1024 * 1024; // 10MB in bytes
      // // const fileExtension = params && params[0].name ? params[0].name.slice(((params[0].name.lastIndexOf(".") - 1) >>> 0) + 2) : null;
      // const fileExtension = params && params[0].name && typeof params[0].name === 'string'
      //   ? params[0].name.slice(((params[0].name.lastIndexOf(".") - 1) >>> 0) + 2)
      //   : null;
      // if (fileExtension && !supportedExtensions.includes(`.${fileExtension.toLowerCase()}`)) {
      //   setCheckExtensions("Only PDF, PNG, JPG, and JPEG files are supported")
      //   setFileUploadError('')
      //   setExt_attachment([]);
      //   return false;
      // } else if (params && params[0].size > maxFileSize) {
      //   setCheckExtensions("This file exceeds maximum file size limit(10 MB).")
      //   setFileUploadError('')
      //   setExt_attachment([]);
      //   return false;
      // }
      // else {
      let attachmentVal = [];
      for (let index = 0; index < params.length; index++) {
        attachmentVal.push(params[index]);
      }
      let ext_attachmentVal = [...attachmentVal];
      setExt_attachment(ext_attachmentVal);
      setCheckExtensions("")
      // }
    }
  };


  // let email = props.individualUserData.email;
  // let user_email = authUser.user ? authUser.user.email : authUser.email




  let firstName = props.individualUserData.firstName;
  let lastName = props.individualUserData.lastName;


  const handleCloseModal = () => {
    if (props.text === "verify_investor") {
      props.setShowPfsSecondModal(false);
      props.setAccreditationSign(false);

    } else if (props.showString === "fromRequest") {
      props.setShowSecondModalRequest(false);
    } else {
      closeSecondModal();
    }
    props.setExt_Files([])
    setDocumentName("")
    setInpuFields([])
    setExt_attachment("")
    setComments("")
    props.setRadioValue({ name: "PFS Summary", num: "1" });
    if (ext_attachment.length !== 0) {
      inputRef.current.value = "";
    }
    props.setSelectValue(undefined);
    props.setToUserEmail("")
    setCheckExtensions('')
    setFileUploadError('')
  };
  const [Validation, setValidation] = useState("");

  const [openModal, setOpenModal] = useState(false);



  const fetchSignatureFromDetails = () => {
    dispatch(
      userRequestList({
        id: id,
        user_email: email,
        flag: roleName.individual,
      })
    )
      .unwrap()
      .then((response) => {
        // setSignatureFrom(response.from);
        // setSignatureTo(response.to);
      })
      .catch((err) => {
        //
      });
  };

  const handleOpen = async () => {

    if (props.accreditationSign === undefined || props.accreditationSign === false) {
      setValidation("");
      props.setShowSecondModal(false);
      setOpenModal(true);
    }
    else {
      props.toastMessage("Your signature request has been sent successfully.", "success");
      let emailUrl = `${backAppURl}/api/hellosign/send_signature`;

      const response1 = await fetch(emailUrl, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${current_user_token}`,
        },

        body: JSON.stringify({
          email: email,
          userEmail: user_email,
          isSigned: true,
          pfsLnk: props.pfsLnk,
          user_id: id,

          firstName: firstName,
          // seconduserName: requestValues.JointuserName || "",
          unique_id: props.unique_row_id && props.unique_row_id
        }),
      });
      socket.emit("notification", "message")
      navigate(`/individual/verifyinvestor/pending`);
    }

  };

  const handleModal = async (emailValue, nameValue) => {
    setOpenModal(false);
    setIsLOading(true);
    setNameValidation("");
    setValidation("");

    if (props.selectValue == 2) {

      if (!emailValue) {

        setIsLOading(false);
        setOpenModal(true);
        setValidation(emailError);
        setNameValidation("");
      } else if (!nameValue) {
        setIsLOading(false);
        setOpenModal(true);
        setValidation("");
        setNameValidation(NameError);
      }
      else if (props.spouseEmailValue === email || props.spouseEmailValue === user_email) {
        setValidation(emailError);
        setIsLOading(false);
        setOpenModal(true);
      }
      else {

        var nameArray = nameValue.split(" ");
        var firstSecondName = nameArray[0];
        var lastSecondName = nameArray.length > 1 ? nameArray[nameArray.length - 1] : "";


        if (userRole && (userRole == 2 || userRole == 3)) {

          if (emailValue == user_email || emailValue == email) {
            setIsLOading(false);
            setValidation("please enter another email");
            // CapsyncMessage("error", "please enter another email ", "error");
            props.toastMessage("please enter another email", "success");
            return false;
          } else {
            setValidation("");
            let requestValues = {
              request_sent_by: sentById,
              request_sent_by_role: userRole === 2 ? roleName.financial_advisor : roleName.tax_professional,
              request_sent_to_role: roleName.individual,
              module_name: "wealth manager individual",
              request_sent_by_displayName: sentByName,
              request_sent_by_email: user_email,
              request_type: "Signature Request",
              request_sent_to_email: email,
              request_sent_to_firstName: firstName,
              request_sent_to_lastName: lastName,
              is_return_to_me: props.isCheckedWealth,
              receiver_email: props.toUserEmail,
              receiver_notes: props.changeTextNote,
              receiver_firstName: props.changeName,
              receiver_lastName: props.changeLastName,
              request_sent_to_phoneNo: "",
              request_from: comp,
              company_id: companyId || 0,
              firstReq: firstReq,
              company_name: comp_name,
              JointuserName: firstSecondName || "",
              joint_user_email: emailValue,
              JointuserlastName: lastSecondName || ""
            };

            try {
              props.setShowSecondModal(false);
              let flag;
              if (email && requestValues.joint_user_email) {
                flag = "sign2";
              } else {
                flag = "sign";
              }
              downloadPdf(flag, nameValue);

              setTimeout(() => {
                SignatureEmailreq();
              }, 4000);

              async function SignatureEmailreq() {
                const formData = new FormData();
                formData.append("user_id", id);
                props.ext_Files.forEach((itr) => {
                  formData.append("attachments", itr.files);
                });
                formData.append(
                  "signature_data",
                  JSON.stringify(requestValues)
                );
                const response = await dispatch(
                  Signature_Requests_Add(formData)
                );
                socket.emit("notification", "message")
                let signatureCommonId = response && response.payload ? response.payload.id : 0;
                if (response.payload && response.payload.code === 200) {
                  props.setShowThirdModal(false);
                  props.setShowSecondModal(false);

                  let emailUrl = `${backAppURl}/api/hellosign/send_email_sign`;
                  const response1 = await fetch(emailUrl, {
                    method: "post",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${current_user_token}`,
                    },

                    body: JSON.stringify({
                      email: email,
                      userEmail: user_email,
                      isSigned: true,
                      pfsLnk: props.pfsLnk,
                      user_id: id,
                      JointUserEmail: emailValue,
                      signatureCommonId: signatureCommonId,
                      firstName: firstName,
                      seconduserName: requestValues.JointuserName || "",
                    }),
                  });

                  if (response1.ok) {
                    const webhookResponse = await response1.json();
                  } else {
                    console.error(
                      "Error registering webhook:",
                      response1.status
                    );
                  }
                  setIsLOading(false);
                  // CapsyncMessage(
                  //   "Success",
                  //   "Your Personal Financial Statement was sent successfully.",
                  //   "success"
                  // );
                  props.toastMessage("Your Personal Financial Statement was sent successfully.", "success");

                  props.setSecondEmail("");
                  props.setSecondUserName("");
                  props.setCheckJointEmail("");
                  props.setSelectValue(undefined);
                  socket.emit("notification", "CapSync Notifications");
                  fetchSignatureFromDetails()
                  props.setSelectValue(undefined);
                  props.setToUserEmail("")
                  props.setChangeName("");
                  props.setChangeLastName("");
                  props.setChangeText("");
                  props.setRadioValue({ name: "PFS Summary", num: "1" });
                  // setTimeout(() => window.location.reload(true), 2000)
                } else {
                  setIsLOading(false);

                  // CapsyncMessage("error", "Already sent ", "error");
                  props.toastMessage("Your signature request has been sent successfully. ", "error");
                  props.setSelectValue(undefined);
                  props.setToUserEmail("")
                  props.setChangeName("");
                  props.setChangeLastName("");
                  props.setChangeText("");
                  props.setRadioValue({ name: "PFS Summary", num: "1" });
                  // setTimeout(() => window.location.reload(true), 2000)
                }
              }
            } catch (err) {
              props.setShowSecondModal(false);
              setIsLOading(false);

              // CapsyncMessage("error", "Already sent ", "error");
              props.toastMessage("Your signature request has been sent successfully. ", "error");

              props.setShowThirdModal(false);
            }
          }
        } else {

          let flag;
          if (email && emailValue) {
            flag = "sign2";
          } else {
            flag = "sign";
          }
          downloadPdf(flag, nameValue);
          setTimeout(() => {
            sentIndiRequest();
          }, 3000);
          async function sentIndiRequest() {
            if (email != user_email) {
              setIsLOading(true);
              let requestValues = {
                request_sent_by: sentById,
                request_sent_by_role: roleName.individual,
                request_sent_to_role: roleName.individual,

                module_name: roleName.individual,
                request_sent_by_displayName: authUser.user.displayName,
                request_sent_by_email: user_email,
                request_sent_to_email: email,
                request_sent_to_firstName: firstName,
                request_sent_to_lastName: lastName,
                is_return_to_me: props.isCheckedWealth,
                receiver_email: props.toUserEmail,
                receiver_notes: props.changeTextNote,
                receiver_firstName: props.changeName,
                request_type: "Signature Request",

                receiver_lastName: props.changeLastName,
                joint_user_email: emailValue,
                JointuserName: firstSecondName || "",
                request_from: comp,
                request_sent_to_phoneNo: "",
                company_id: companyId || 0,
                firstReq: firstReq,
                indi_flag: true, JointuserlastName: lastSecondName || ""
              };

              let recieverEmail;
              if (props.isCheckedWealth == true) {
                recieverEmail = user_email;
              } else {
                recieverEmail = props.toUserEmail;
              }
              const formData = new FormData();
              formData.append("user_id", id);
              props.ext_Files.forEach((itr) => {
                formData.append("attachments", itr.files);
              });
              formData.append("signature_data", JSON.stringify(requestValues));
              const response = await dispatch(Signature_Requests_Add(formData));

              let signatureCommonId = response && response.payload ? response.payload.id : 0;
              if (response.payload && response.payload.code === 200) {
                props.setShowThirdModal(false);

                // const webhookUrl = `${backAppURl}/api/hellosign/webhook`;
                let emailUrl = `${backAppURl}/api/hellosign/send_email_sign`;//for joint user
                const response1 = await fetch(emailUrl, {
                  method: "post",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${current_user_token}`,
                  },

                  body: JSON.stringify({
                    email: email,
                    JointUserEmail: emailValue,
                    userEmail: recieverEmail,
                    isSigned: true,
                    pfsLnk: "",
                    user_id: id,
                    signatureCommonId: signatureCommonId,
                    firstName: firstName,
                    seconduserName: requestValues.JointuserName || "",
                  }),
                });

                setIsLOading(false);
                // CapsyncMessage(
                //   "Success",
                //   "Your Personal Financial Statement was sent successfully.",
                //   "success"
                // );
                props.setExt_Files([])
                props.toastMessage("Your Personal Financial Statement was sent successfully.", "success");
                props.setSecondEmail("");
                props.setSecondUserName("");
                props.setCheckJointEmail("");
                fetchSignatureFromDetails()
                props.setSelectValue(undefined);
                props.setToUserEmail("")
                props.setChangeName("");
                props.setChangeLastName("");
                props.setChangeText("");
                props.setRadioValue({ name: "PFS Summary", num: "1" });
                socket.emit("notification", "message")
                // setTimeout(() => window.location.reload(true), 2000)
                if (response1.ok) {
                  const webhookResponse = await response1.json();
                } else {
                  // CapsyncMessage("error", "Already sent ", "error");
                  props.toastMessage("Your signature request has been sent successfully. ", "error");
                  setIsLOading(false);
                  // setTimeout(() => window.location.reload(true), 2000)
                }
              } else if (response.payload && response.payload.status === 400) {
                // CapsyncMessage("error", "Already sent ", "error");
                props.toastMessage("Your signature request has been sent successfully. ", "error");
                props.setSelectValue(undefined);
                props.setToUserEmail("")
                setIsLOading(false);
                props.setShowThirdModal(false);
                props.setChangeName("");
                props.setChangeLastName("");
                props.setChangeText("");
                props.setRadioValue({ name: "PFS Summary", num: "1" });
                setTimeout(() => { }, 500);
                // setTimeout(() => window.location.reload(true), 2000)
              } else {
                // CapsyncMessage("error", "Already sent ", "error");
                props.toastMessage("Your signature request has been sent successfully. ", "error");
                props.setSelectValue(undefined);
                props.setToUserEmail("")
                props.setChangeName("");
                props.setChangeLastName("");
                props.setChangeText("");
                props.setRadioValue({ name: "PFS Summary", num: "1" });
                setIsLOading(false);
                props.setShowThirdModal(false);
                setTimeout(() => { }, 500);
                // setTimeout(() => window.location.reload(true), 2000)
              }
            }
          }
        }
      }
    } else {
      setIsLOading(true);

      if (userRole && (userRole == 2 || userRole == 3)) {
        let requestValues;
        if (props.isCheckedWealth == true) {
          requestValues = {
            request_sent_by: sentById,
            request_sent_by_role: userRole === 2 ? roleName.financial_advisor : roleName.tax_professional,
            request_sent_to_role: roleName.individual,

            module_name: "wealth manager individual",
            request_sent_by_displayName: sentByName,
            request_sent_by_email: user_email,

            request_type: "Signature Request",

            request_sent_to_email: email,
            request_sent_to_firstName: firstName,
            request_sent_to_lastName: lastName,
            is_return_to_me: props.isCheckedWealth,
            receiver_email: props.toUserEmail,
            receiver_notes: props.changeTextNote,
            receiver_firstName: props.changeName,

            request_from: comp,

            company_id: companyId || 0,
            firstReq: firstReq,
            company_name: comp_name,
            receiver_lastName: props.changeLastName,
          };
        } else {
          requestValues = {
            request_sent_by: sentById,
            request_sent_by_role: userRole == 2 ? roleName.financial_advisor : roleName.tax_professional,
            request_sent_to_role: roleName.individual,
            access_permission: 1,
            module_name: "wealth manager individual",
            request_sent_by_displayName: sentByName,
            request_sent_by_email: user_email,
            request_type: "Signature Request",

            request_sent_to_email: email,
            request_sent_to_firstName: firstName,
            request_sent_to_lastName: lastName,
            is_return_to_me: props.isCheckedWealth,
            receiver_email: props.toUserEmail,
            receiver_notes: props.changeTextNote,
            receiver_firstName: props.changeName,

            receiver_lastName: props.changeLastName,

            request_from: comp,
            company_id: companyId || 0,
            firstReq: firstReq,
            company_name: comp_name,
            request_sent_to_phoneNo: "",
          };
        }
        try {
          props.setShowSecondModal(false);
          let flag;
          if (email && requestValues.joint_user_email) {
            flag = "sign2";
          } else {
            flag = "sign";
          }

          downloadPdf(flag);
          setTimeout(() => {
            send_signature_request();
          }, 4000);
          async function send_signature_request() {
            const formData = new FormData();
            formData.append("user_id", id);

            props.ext_Files.forEach((itr) => {
              formData.append("attachments", itr.files);
            });
            formData.append("signature_data", JSON.stringify(requestValues));
            const response = await dispatch(Signature_Requests_Add(formData));

            let signatureCommonId = response && response.payload ? response.payload.id : 0;
            if (response.payload && response.payload.code === 200) {
              props.setShowThirdModal(false);
              props.setShowSecondModal(false);

              // CapsyncMessage(
              //   "Success",
              //   "Your Personal Financial Statement was sent successfully.",
              //   "success"
              // );

              const webhookUrl = `${backAppURl}/api/hellosign/webhook`;
              let emailUrl = `${backAppURl}/api/hellosign/send_signature`;
              if (response.payload.status === "Success") {
                const response1 = await fetch(emailUrl, {
                  method: "post",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${current_user_token}`,
                  },

                  body: JSON.stringify({
                    email: email,
                    userEmail: user_email,
                    isSigned: true,
                    pfsLnk: props.pfsLnk,
                    user_id: id,
                    signatureCommonId: signatureCommonId,
                    firstName: firstName,
                    seconduserName: requestValues.JointuserName || "",
                  }),
                });
                props.toastMessage("Your Personal Financial Statement was sent successfully.", "success");
                fetchSignatureFromDetails()
                props.setSecondEmail("");
                props.setSecondUserName("");
                props.setCheckJointEmail("");
                props.setSelectValue(undefined);
                props.setToUserEmail("")
                props.setChangeName("");
                props.setChangeLastName("");
                props.setChangeText("");
                props.setRadioValue({ name: "PFS Summary", num: "1" });
                socket.emit("notification", "message")
                setIsLOading(false);
                props.setExt_Files([])
                // setTimeout(() => window.location.reload(true), 2000)
              }
              else {
                props.toastMessage("Your signature request has been sent successfully. ", "error");
                props.setSelectValue(undefined);
                props.setToUserEmail("")
                props.setChangeName("");
                props.setChangeLastName("");
                props.setChangeText("");
                props.setRadioValue({ name: "PFS Summary", num: "1" });
                // setTimeout(() => window.location.reload(true), 2000)
              }


              socket.emit("notification", "CapSync Notifications");
            } else {
              setIsLOading(false);
              // CapsyncMessage("error", "Already sent ", "error");
              props.toastMessage("Your signature request has been sent successfully. ", "error");
              props.setSelectValue(undefined);
              props.setToUserEmail("")
              setIsLOading(false);
              props.setChangeName("");
              props.setChangeLastName("");
              props.setChangeText("");
              props.setRadioValue({ name: "PFS Summary", num: "1" });
              // setTimeout(() => window.location.reload(true), 2000)
            }
          }
        } catch (err) {
          props.setShowSecondModal(false);
          setIsLOading(false);
          // CapsyncMessage("error", "Already sent ", "error");
          props.toastMessage("Your signature request has been sent successfully. ", "error");
          setIsLOading(false);
          props.setChangeName("");
          props.setChangeLastName("");
          props.setChangeText("");
          props.setRadioValue({ name: "PFS Summary", num: "1" });
          props.setShowThirdModal(false);
          // setTimeout(() => window.location.reload(true), 2000)
        }
      } else if (
        props.secondUserRequests.secondUserRequests == false &&
        props.JointUserEmail.JointUserEmail == null &&
        props.AllIndividuals != undefined &&
        SpouseValue == false &&
        props.ToUserEmail.toUseremail == undefined
      ) {
        props.setShowSecondModalRequest(false);
        setIsLOading(true);
        let reciever;
        if (props.IsCheckedWealth.IsCheckedWealth == false) {
          reciever = props.recieverEmailWealth.recieverEmailWealth;
        } else {
          reciever = props.allIndividuals.request_send_by_email;
        }
        try {
        } catch (error) {
          console.error(error);
        }
      } else if (
        props.IndividualSignature.IndividualSignature != true &&
        props.allIndividuals != undefined &&
        SpouseValue == true
      ) {
        try {
        } catch (error) {
          console.error(error);
        }
      } else if (
        props.secondUserRequests.secondUserRequests === false &&
        props.JointUserEmail.JointUserEmail
      ) {
      } else if (props.secondUserRequests.secondUserRequests === true) {
        try {
        } catch (error) { }
      } else if (props.IndividualSignature.IndividualSignature === true) {
      } else if (props.text == "verify_investor") {
        if (props.AllIndividuals == undefined) {
          props.setShowSecondModal(false);
        } else {
          props.setShowSecondModalRequest(false);
        }
        try {
          let flag = "sign";

          let resp = await downloadPdf(flag);

          // setTimeout(() => {
          if (resp.status === 200) {

            signatureRequestVerify();
          }
          // }, 3000);
          async function signatureRequestVerify() {
            props.setShowPfsSecondModal(false);
            let claimUrl = `${backAppURl}/api/hellosign/send_user_signature`;

            const response = await fetch(claimUrl, {
              method: "post",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${current_user_token}`,
              },

              body: JSON.stringify({
                email: userEmail,
                userEmail: userEmail,

                user_id: user_id,
                firstName: firstName,
              }),
            });

            const responseData = await response.text();
            const signer2 = JSON.parse(responseData);

            const email1 = signer2.email1;

            const signer2Url = signer2.signing_url;
            const email2 = signer2.email2;

            const signatureRequestId = signer2.signature_request_id;

            const accessToken = process.env.REACT_APP_HELLO_SIGN_API_KEY;
            const client = new HelloSign();

            if (process.env.REACT_APP_MODE === "PROD") {
              client.open(signer2Url, {
                clientId: process.env.REACT_APP_HELLO_SIGN_CLIENT_ID,
              });
            } else {
              client.open(signer2Url, {
                clientId: process.env.REACT_APP_HELLO_SIGN_CLIENT_ID,
                skipDomainVerification: true,
              });
            }

            // }
            setIsLOading(false);

            client.on("sign", async (event) => {
              let downloadUrl = `${backAppURl}/api/hellosign/get_user_signature`;

              const response = await fetch(downloadUrl, {
                method: "post",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${current_user_token}`,
                },
                body: JSON.stringify({
                  values: signatureRequestId,
                }),
              });
              if (response) {
                const data = await response.text();

                props.setData(data);

                if (props.openModal === true) {
                  props.setOpenModal(true);
                } else if (props.openModal2 === true) {
                  props.setOpenModal2(true);
                } else {
                  props.setOpenModal3(true);
                }



                // window.localStorage.setItem("pfs_link", data);
                send_pfs_Link(data, props.unique_row_id)
                props.setShowPfsSecondModal(false);
              } else {
                console.log("Error:", response.status);
              }
            });
          }
        } catch (error) {
          console.error(error);
        }
      } else if (email != user_email) {
        if (userRole && userRole === 1) {

          setIsLOading(true);
          let requestValues = {
            request_sent_by: sentById,
            request_sent_by_role: roleName.individual,
            request_sent_to_role: roleName.individual,

            module_name: roleName.individual,
            request_sent_by_displayName: authUser.user.displayName,
            request_sent_by_email: user_email,

            request_type: "Signature Request",

            request_from: comp,
            request_sent_to_email: email,
            request_sent_to_firstName: firstName,
            request_sent_to_lastName: lastName,
            is_return_to_me: props.isCheckedWealth,
            receiver_email: props.toUserEmail,
            receiver_notes: props.changeTextNote,
            receiver_firstName: props.changeName,
            company_id: companyId || 0,
            receiver_lastName: props.changeLastName,
            request_sent_to_phoneNo: "",
            indi_flag: true
          };

          let recieverEmail;
          if (props.isCheckedWealth == true) {
            recieverEmail = user_email;
          } else {
            recieverEmail = props.toUserEmail;
          }
          const formData = new FormData();
          formData.append("user_id", id);
          props.ext_Files.forEach((itr) => {
            formData.append("attachments", itr.files);
          });
          formData.append("signature_data", JSON.stringify(requestValues));
          const response = await dispatch(Signature_Requests_Add(formData));
          let signatureCommonId = response && response.payload ? response.payload.id : 0;

          if (response.payload && response.payload.code === 200) {
            props.setShowThirdModal(false);

            // const webhookUrl = `${backAppURl}/api/hellosign/webhook`;
            let emailUrl = `${backAppURl}/api/hellosign/send_signature`;
            const response1 = await fetch(emailUrl, {
              method: "post",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${current_user_token}`,
              },

              body: JSON.stringify({
                email: email,
                userEmail: recieverEmail,
                isSigned: true,
                pfsLnk: "",
                user_id: id,
                signatureCommonId: signatureCommonId,
                firstName: firstName,
              }),
            });

            setIsLOading(false);
            // CapsyncMessage("Success", "Your Personal Financial Statement was sent successfully.", "success");
            props.toastMessage("Your Personal Financial Statement was sent successfully.", "success");
            fetchSignatureFromDetails()
            props.setSelectValue(undefined);
            props.setSecondEmail("");
            props.setSecondUserName("");
            props.setToUserEmail("")
            props.setCheckJointEmail("");
            props.setChangeName("");
            props.setChangeLastName("");
            props.setChangeText("");
            props.setRadioValue({ name: "PFS Summary", num: "1" });
            socket.emit("notification", "message")
            // setTimeout(() => window.location.reload(true), 2000)
            if (response1.ok) {
              const webhookResponse = await response1.json();
            } else {
              // CapsyncMessage("error", "Already sent ", "error");
              props.toastMessage("Your signature request has been sent successfully. ", "error");
              setIsLOading(false);
              props.setSelectValue(undefined);
              props.setToUserEmail("")
              props.setChangeName("");
              props.setChangeLastName("");
              props.setChangeText("");
              props.setRadioValue({ name: "PFS Summary", num: "1" });
              // setTimeout(() => window.location.reload(true), 2000)
            }
          } else if (response.payload && response.payload.status === 400) {
            // CapsyncMessage("error", "Already sent ", "error");
            props.toastMessage("Your signature request has been sent successfully. ", "error");
            setIsLOading(false);
            props.setSelectValue(undefined);
            props.setToUserEmail("")
            props.setShowThirdModal(false);
            props.setChangeName("");
            props.setChangeLastName("");
            props.setChangeText("");
            props.setRadioValue({ name: "PFS Summary", num: "1" });
            setTimeout(() => { }, 500);
            // setTimeout(() => window.location.reload(true), 2000)
          } else {
            // CapsyncMessage("error", "Already sent ", "error");
            props.toastMessage("Your signature request has been sent successfully. ", "error");
            setIsLOading(false);
            props.setSelectValue(undefined);
            props.setToUserEmail("")
            props.setShowThirdModal(false);
            props.setChangeName("");
            props.setChangeLastName("");
            props.setChangeText("");
            props.setRadioValue({ name: "PFS Summary", num: "1" });
            // setTimeout(() => {
            //   window.location.reload();
            // }, 500);

          }
        }
      } else {
        props.changeAttachmentState(props.ext_Files);
        if (props.AllIndividuals == undefined) {
          props.setShowSecondModal(false);
          props.setShowThirdModal(true);
          setIsLOading(false);
        } else {
          props.setShowSecondModalRequest(false);
          props.setShowThirdModal(true);
          setIsLOading(false);
        }
      }
    }
  };


  const send_pfs_Link = (data, unique_row_id) => {
    dispatch(store_pfs_link({
      "sign_pfs_link": data, "unique_rowId": unique_row_id
    }))
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          props.setCheckPfsLink(true)
          props.toastMessage("Your Personal Financial Statement has been linked successfully.", "success");
          props.deleteFetchedPfs();
        }

      })
      .catch((error) => {
        console.log("error:", error);

      });
  };
  // --------------------------
  const fetchStoredImages = () => {
    dispatch(
      fetch_all_external_attachments({
        id: user_id,
      })
    )
      .unwrap()
      .then((response) => {
        setPreviousAttachFiles(response.data)
      })
      .catch((err) => {
        console.log("errrrrrrrrrr", err);
      });
  };
  useEffect(() => {
    fetchStoredImages()
  }, [])


  const deleteDatabaseFile = (id, signatureId, attachment) => {
    dispatch(
      other_attachements_remove({
        id: id,
        fileName: attachment,
        signature_id: signatureId
      })
    )
      .unwrap()
      .then((response) => {


      })
      .catch((err) => {
        console.log("errrrrrrrrrr", err);
      });
  };
  const removeDatabaseFile = (index, id, signature_id, attachment) => {
    let externalAttach = [...props.previousAttachFiles];
    externalAttach.splice(index, 1);
    props.setPreviousAttachFiles(externalAttach)
    deleteDatabaseFile(id, signature_id, attachment)
    setCheckExtensions('')
  };


  const handleEmailValidation = async (email) => {
    let emailError = "";
    try {
      await emailSchema.validate(email);
    } catch (error) {
      emailError = error.message || uniValidation.email.validEmail;
    }
    if (emailError) {
      setValidation(emailError);
    } else {
      setValidation('');
      props.handleSpouseEmailChange(email);
    }

  }

  // function isValidEmail(email) {
  //   let pattern = /^[a-z0-9._%+-]{1,30}@[a-z0-9.-]+\.[a-z]{2,}$/;
  //   let result = pattern.test(email);
  //   return result;
  // }

  const liabilitiyGrandTotal = Number(newCategoryData && newCategoryData.liabilities && newCategoryData.liabilities.grandTotal);
  const assetGrandTotal = Number(newCategoryData && newCategoryData.assets && newCategoryData.assets.grandTotal);

  return (
    <>
      {isLoading == false ? (
        <>
          <Modal
            show={
              props.text === "verify_investor"
                ? props.openModalFromVi
                : props.showString === "fromRequest"
                  ? props.openSecondModal
                  : props.showSecondModal
            }
            onHide={
              props.text === "verify_investor"
                ? props.setShowPfsSecondModal
                : props.showString === "fromRequest"
                  ? props.setShowSecondModalRequest
                  : closeSecondModal
            }
            centered
            aria-labelledby="contained-modal-title-vcenter"
            className="share-pfs-modal"
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header>

              <div className="pfs-preview-modal">
                <div className="buttons">
                  <div className="for-des">
                    <div className="cs-tabs cs-primary">
                      {radioName.map((radioName, idx) => (
                        <Button
                          className={`cs-stroke-default-tab default-regular-h5 cs-tabs-height ${props.radioValue.num === radioName.value
                            ? "cs-stroke-active"
                            : ""
                            }`}
                          key={idx}
                          id={`radio-${idx}`}
                          type="radio"
                          variant={
                            idx % 2
                              ? "manage-user-individual"
                              : "manage-user-wealth-manager"
                          }
                          name="radioName"
                          value={radioName.value}
                          checked={props.radioValue.num === radioName.value}
                          onClick={(e) => checkFetchRadio(e.currentTarget.value)}
                        >
                          {radioName.name}
                        </Button>
                      ))}
                    </div>
                  </div>
                  <div className="for-mob">
                    <div className="pfs-preview-dropdown cs-common-add-dropdown">
                      <Dropdown>
                        <Dropdown.Toggle className="default-semi-bold-h5">
                          {props.radioValue.name}
                          <CapsyncIcon title="chevron-down-outlined" size="16" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="cs-pfs-menu cs-scroller">
                          {
                            radioName.map((itr, idx) => {
                              return (
                                <Dropdown.Item
                                  key={idx}
                                  className={`default-regular-h5 ${props.radioValue.num === itr.value ? 'selected' : ''}`}
                                  onClick={(e) => storePfstab(itr.value)}>
                                  {itr.name}
                                </Dropdown.Item>
                              )
                            })
                          }

                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                {/* <Modal.Title className="default-semi-bold-h1 cs-neutral-100 for-mob">
                  My PFS
                </Modal.Title> */}
                <div className="cs-pfs-right">
                  {/* <Modal.Title className="default-semi-bold-h1 cs-neutral-100 for-des">
                    My PFS
                  </Modal.Title> */}
                  {/* <div className="cs-view-pfs-filter">
                    <CapsyncPFSFilter
                      assetTotal={props.getCategoryTotal}
                      liabilityTotal={props.getCategoryTotalLib}
                      individualUserData={props.individualUserData}
                    />
                  </div> */}
                </div>
              </div>
            </Modal.Header>

            <div className="pfs-modal-scroll dashboard-main-section cs-scroller">
              <Modal.Body className="pfs-preview-modal">
                <section className="pfs-container">
                  {props.radioValue.num == 1 ? (
                    <>
                      <Container fluid>
                        <div className="pfs-header">
                          <Row>
                            <Col lg={6}>
                              <div className="view-pfs-modal default-regular-sub-heading-m">
                                <p>Name:&nbsp;</p>
                                {user_displayName}
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="view-pfs-modal default-regular-sub-heading-m">
                                <p>Email:</p> &nbsp;
                                {userEmail}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={6}>
                              <div className="view-pfs-modal default-regular-sub-heading-m">
                                <p>Address:&nbsp;</p>
                                {user_address === null ? "-" : customLabelLength(setAddressString(user_address), 45, 48)}
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="view-pfs-modal default-regular-sub-heading-m">
                                <p>Phone:&nbsp;</p>

                                {user_phoneNo && formatPhoneNumber(user_phoneNo)}
                              </div>
                            </Col>
                          </Row>
                        </div>

                        <div className="pfs-body">
                          <Row>
                            <Col lg={6}>
                              <div className="pfs-common-box finance-box">
                                <FinanceTable
                                  type="assets"
                                  // data={props.dataAsset.assets_category_total}
                                  data={newCategoryData && newCategoryData.assets && newCategoryData.assets.catgeorylist}

                                  grandTotal={assetGrandTotal}
                                  paymentInstallAuto={props.paymentInstallAuto}
                                  isSharePFS
                                />
                                <div className="for-des">
                                  <NetWorthDashboard
                                    netWorth={
                                      assetGrandTotal -
                                      liabilitiyGrandTotal
                                    }
                                  />
                                </div>
                              </div>
                            </Col>

                            <Col lg={6}>
                              <div className="pfs-common-box">
                                <FinanceTable
                                  type="liabilities"
                                  // data={
                                  //   props.dataLiability &&
                                  //   props.dataLiability.liability_category_total
                                  // }
                                  data={newCategoryData && newCategoryData.liabilities && newCategoryData.liabilities.catgeorylist}

                                  grandTotal={liabilitiyGrandTotal}
                                  isSharePFS
                                />
                              </div>
                              <div className="for-mob">
                                <NetWorthDashboard
                                  netWorth={
                                    props.assetGrandTotal -
                                    props.liabilitiyGrandTotal
                                  }
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>

                        <div className="pfs-footer default-regular-sub-heading-m cs-neutral-70">
                          <p>
                            I authorize Lender and/or assigns to make inquiries
                            as necessary to verify the accuracy of the
                            statements made and to determine my
                            creditworthiness. I certify the above and the
                            statements contained in the attachments are true and
                            accurate as of the stated date(s). These statements
                            are made for the purpose of either obtaining loan or
                            guaranteeing a loan. I understand FALSE statements
                            may result in forfeiture of benefits and possible
                            prosecution by the U.S. Attorney General (Reference
                            18 U.S.C. 1001).
                          </p>
                          <div className="pfs-signature-container">
                            <Row>
                              <Col lg={6}>
                                <div className="pfs-signature-box pfs-name-block">
                                  <span className="default-regular-sub-heading-m cs-neutral-100">
                                    Name
                                  </span>
                                  <span className="default-light-sub-heading-s cs-neutral-100">
                                    {userData &&
                                      userData.fetchUserData &&
                                      userData.fetchUserData.displayName}
                                  </span>
                                </div>
                                <div className="pfs-signature-bottom">
                                  <div className="pfs-signature-box pfs-signature-block">
                                    <span className="default-regular-sub-heading-m cs-neutral-100">
                                      Signature
                                    </span>
                                    <span className="cs-signature-text cs-neutral-100"></span>
                                  </div>

                                  <div className="pfs-signature-box pfs-date-block">
                                    <span className="default-regular-sub-heading-m cs-neutral-100">
                                      Date
                                    </span>
                                    <span className="default-light-sub-heading-s cs-neutral-100">
                                      {moment(new Date()).format("MM/DD/YYYY")}
                                    </span>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Container>

                      {/* <div className="pfs-preview-footer">
                        <div className="pfs-preview-footer-desc">
                          <div className="sign-disc-text">
                            <p className="cap-paragraph-color">
                              I authorize Lender and/or assigns to make
                              inquiries as necessary to verify the accuracy of
                              the statements made and to determine my
                              creditworthiness. I certify the above and the
                              statements contained in the attachments are true
                              and accurate as of the stated date(s). These
                              statements are made for the purpose of either
                              obtaining loan or guaranteeing a loan. I
                              understand FALSE statements may result in
                              forfeiture of benefits and possible prosecution by
                              the U.S. Attorney General (Reference 18 U.S.C.
                              1001).
                            </p>
                          </div>
                          
                        </div>
                      </div> */}
                    </>
                  ) : props.radioValue.num == 2 ? (
                    <div className="assets-main-section">
                      <SharePfsCategoryTable
                        closeModal={closeSecondModal}
                        toastMessage={props.toastMessage}
                      />
                    </div>
                  ) : (
                    <ExternalAttachments
                      // addFields={addFields}
                      inputFields={inputFields}
                      ext_attachment={ext_attachment}
                      attachmentUpload={attachmentUpload}
                      removeInputField={removeInputField}
                      changeDocumentInputVal={changeDocumentInputVal}
                      setExt_attachment={setExt_attachment}
                      setInpuFields={setInpuFields}
                      handleUploadAttachment={handleUploadAttachment}
                      setDocumentName={setDocumentName}
                      ext_Files={props.ext_Files}
                      documentName={documentName}
                      inputRef={inputRef}
                      setComments={setComments}
                      comments={comments}
                      fileUploadError={fileUploadError}
                      setFileUploadError={setFileUploadError}
                      setCheckExtensions={setCheckExtensions}
                      checkExtensions={checkExtensions}
                      removeDatabaseFile={removeDatabaseFile}
                      previousAttachFiles={props.previousAttachFiles}
                    />
                  )}
                </section>
              </Modal.Body>
            </div>
            <Modal.Footer>
              <div className="cs-modal-btn wm-req-shareicon-viewpdf">
                <Button
                  className="cs-btn-secondary default-regular-h5 lg-btn"

                  type="button"
                  onClick={handleCloseModal}
                >
                  Cancel
                </Button>
                {userRole && userRole === 1 ? (
                  <>
                    {
                      email == user_email && !pfs_access_id ? (
                        <Button
                          className="cs-btn-primary default-regular-h5 lg-btn"

                          type="button"
                          onClick={handleModal} //second modal will be closed & third modal will be open
                        >
                          Sign
                        </Button>
                      ) : email != user_email && pfs_access_id && (
                        <Button
                          className="cs-btn-primary default-regular-h5 lg-btn"

                          type="button"
                          onClick={handleOpen} //second modal will be closed & third modal will be open
                        >
                          Send for Signature
                          {/* {email != user_email ? "Send for Signature444" : "Sign55"} */}
                        </Button>
                      )}
                  </>
                ) :
                  userRole && (userRole === 2 || userRole === 3) && pfs_access_id && (
                    <Button
                      className="cs-btn-primary default-regular-h5 lg-btn"

                      type="button"
                      onClick={handleOpen} //second modal will be closed & third modal will be open
                    >
                      Send for Signature
                    </Button>
                  )}
              </div>
            </Modal.Footer>
          </Modal>

          <Modal
            show={openModal}
            onHide={() => setOpenModal(false)}
            backdrop="static"
            keyboard={false}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="cs-md-modal"
            className="cs-common-modal-overlay"

          >
            <Modal.Header>
              <Modal.Title className="default-semi-bold-h3">Select PFS type </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {userRole && userRole == 1 && IsChecked != undefined ? (
                <>
                  <IndividualJoint
                    props={props}
                    userRole={userRole}
                    IsChecked={IsChecked}
                  />
                </>
              ) : (
                <>
                  <IndividualJoint props={props} userRole={userRole} />
                </>
              )}
              {props.selectValue == 2 && (
                <>
                  <Row>
                    <Col lg={12}>
                      <Form.Group className="cs-form-group">
                        <Form.Label>
                          Enter name of cosigner
                        </Form.Label>
                        <Form.Control
                          type="text"
                          onKeyDown={(e) => {
                            if (e.key === " " && e.target.selectionStart === 0) {
                              e.preventDefault();
                            }
                          }}
                          autoComplete="off"
                          onChange={(e) =>
                            props.HandleSpouseNameChange(e.target.value)
                          }
                          className={props.checkJointName && 'cs-input-field-active'}
                          maxLength={50}
                        />
                        <span className="form-error-msg default-light-body-text-s cs-danger">
                          {props.nameValidation}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <Form.Group className="cs-form-group">
                        <Form.Label >
                          Enter email of cosigner
                        </Form.Label>
                        <Form.Control
                          type="email"
                          autoComplete="off"
                          onChange={(e) =>
                            handleEmailValidation(e.target.value)
                          }
                          onKeyDown={(e) => e.code === 'Space' && e.preventDefault()}
                          className={props.spouseEmailValue && 'cs-input-field-active'}
                        />
                        <span className="form-error-msg default-light-body-text-s cs-danger">
                          {Validation}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              )}

              <div className="cs-modal-btn">
                <>
                  {props.selectValue == undefined ? (
                    <>
                      <Button
                        className="cs-btn-secondary md-btn default-regular-h5"
                        onClick={() => {
                          setOpenModal(false);
                          props.setSelectValue(undefined);
                          props.setToUserEmail("")
                          setCheckExtensions('')
                          setFileUploadError('')
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="cs-btn-primary md-btn default-regular-h5"
                        onClick={handleValidation}
                        disabled
                      >
                        Send
                      </Button>
                      {/* <span className="form-error-msg default-light-body-text-s cs-danger">
                        {validateMessage}
                      </span> */}
                    </>
                  ) : (
                    <>
                      <Button
                        className="cs-btn-secondary md-btn default-regular-h5"
                        onClick={
                          () => {
                            setOpenModal(false);
                            props.setSelectValue(undefined);
                            props.setToUserEmail("")
                            setNameValidation("");
                            setValidation("");
                            setCheckExtensions('')
                            setFileUploadError('')
                          }
                        }
                      >
                        Cancel
                      </Button>
                      <Button
                        className="cs-btn-primary md-btn default-regular-h5"
                        disabled={(props.checkJointEmail === "" && props.selectValue == 2) || Validation !== '' || props.nameValidation !== ""}

                        onClick={() =>
                          handleModal(props.secondEmail, props.secondUserName)
                        }
                      >
                        Send
                      </Button>

                    </>
                  )}
                </>
              </div>
            </Modal.Body>
          </Modal>
        </>
      ) : (
        <HelloSignProgress />
      )
      }
    </>
  );
};

export default SharePfsSecondMainModal;