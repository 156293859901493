/* eslint-disable no-lone-blocks */
/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";

// COMPONENT IMPORTS
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import { getFileIcon } from "../../../util/common";
import { isMobile } from "react-device-detect";

const GridTableView = ({
  data,
  type,
  isSelected,
  selectedRows,
  openDropdownId,
  storage,
  setFileStorageModal,
  setOpenDropdownId,
  CapsyncDeleteMessage,
  viewOnlyPermission,
  handleFileDownload,
  handleRestore,
  handleProtectedFileDownload,
  handleArchive,
  setRenameFolderDetails,
  setRenameFolder,
  setCreateFolderModal,
  setRenameFile,
  handleClick,
  handleFileDelete,
  handleRowDoubleClick,
  handleMove,
}) => {
  // const navDrop = useRef();
  // useOutsideClick(navDrop, () => setOpenDropdownId(null));
  const [clickTimeout, setClickTimeout] = useState(null);
  const toggleDropdown = (id, e) => {
    e.stopPropagation();
    setOpenDropdownId((prevId) => (prevId === id ? null : id));
  };
  const handleSingleClick = (document, event, index) => {
    if (!event.detail || event.detail === 1) {
      handleClick(document, event, index);
    }
  };

  const handleClickEvents = (document, event, index) => {
    if (clickTimeout) {
      clearTimeout(clickTimeout);
      handleSingleClick(document, event, index);
      setClickTimeout(null);
    } else {
      setClickTimeout(
        setTimeout(() => {
          handleRowDoubleClick && handleRowDoubleClick(document);
          setClickTimeout(null);
        }, 300)
      );
    }
  };

  return (
    <React.Fragment>
      {data.length > 0 ? (
        <div className="cs-grid-view">
          {data.map((document, index) => {
            const extension =
              document.name &&
              document.name.split(/\.(?=[^\.]+$)/)[1] &&
              document.name.split(/\.(?=[^\.]+$)/)[1].toLowerCase();
            return (
              <React.Fragment key={index}>
                {(type == "document" ? !document.is_archive : document) && (
                  <div
                    key={index}
                    className={
                      isSelected(document.id) ? "cs-box selected" : "cs-box"
                    }
                    onClick={(event) => {
                      event.stopPropagation();
                      isMobile
                        ? handleClickEvents(document, event, index)
                        : (!event.detail || event.detail === 1) &&
                          handleClick(document, event, index);
                    }}
                    onDoubleClick={() =>
                      !isMobile &&
                      handleRowDoubleClick &&
                      handleRowDoubleClick(document)
                    }
                    id={"grid-" + index}
                  >
                    <div className="file-preview">
                      {document.thumbnail_attachment ? (
                        <LazyLoadImage
                          src={document.thumbnail_attachment}
                          width={600}
                          height={400}
                          alt="Image Alt"
                        />
                      ) : document.attachment ? (
                        <span>{getFileIcon(extension, "72")}</span>
                      ) : (
                        <span className="cs-neutral-60">
                          <CapsyncIcon title="folder-icon-filled" size="72" />
                        </span>
                      )}
                    </div>
                    <div className="file-info file-management-dropdown">
                      <div className="file-title">
                        {document.attachment ? (
                          <span>{getFileIcon(extension, "22")}</span>
                        ) : (
                          <span className="cs-neutral-60">
                            <CapsyncIcon title="folder-icon-filled" size="22" />
                          </span>
                        )}
                        <h4
                          className="default-regular-body-text-l cs-neutral-100"
                          title={document.name}
                        >
                          {document.name}
                        </h4>
                      </div>
                      {selectedRows &&
                        type == "document" &&
                        !selectedRows.length > 0 &&
                        (document.is_downloadable ||
                          document.is_movable ||
                          document.is_archive_allowed ||
                          document.is_delete_allowed) && (
                          <DropdownButton
                            key={index}
                            id={`dropdown-grid-${index}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              toggleDropdown(document.id, e);
                            }}
                            show={openDropdownId === document.id}
                            title={
                              <span className="cs-neutral-80 cursor-pointer">
                                <CapsyncIcon
                                  title="option-horizontal-filled"
                                  size="22"
                                />
                              </span>
                            }
                            className="cs-settings-dropdown"
                          >
                            {document.is_downloadable && (
                              <Dropdown.Item
                                eventKey="1"
                                className=""
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (storage.usedSpace > storage.maxSpace) {
                                    setFileStorageModal(true);
                                  } else {
                                    handleFileDownload([document]);
                                  }
                                }}
                              >
                                <span>
                                  <CapsyncIcon
                                    title="download-outlined"
                                    size="22"
                                  />
                                </span>
                                <span>Download</span>
                              </Dropdown.Item>
                            )}
                            {document.is_downloadable && (
                              <Dropdown.Item
                                eventKey="2"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleProtectedFileDownload([document]);
                                }}
                              >
                                <span>
                                  <CapsyncIcon
                                    title="protect-download-outlined"
                                    size="22"
                                  />
                                </span>
                                <span>Encrypt Download</span>
                              </Dropdown.Item>
                            )}
                            {!viewOnlyPermission && document.is_movable && (
                              <Dropdown.Item
                                eventKey="3"
                                className=""
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setCreateFolderModal(true);
                                  if (document.attachment) {
                                    setRenameFile(true);
                                  } else {
                                    setRenameFolder(true);
                                  }
                                  setRenameFolderDetails(document);
                                }}
                              >
                                <span>
                                  <CapsyncIcon
                                    title="edit-image-outlined"
                                    size="22"
                                  />
                                </span>
                                <span>Rename</span>
                              </Dropdown.Item>
                            )}
                            {!viewOnlyPermission && document.is_movable && (
                              <Dropdown.Item
                                eventKey="4"
                                className=""
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleMove(document);
                                }}
                              >
                                <span>
                                  <CapsyncIcon
                                    title="move-outlined"
                                    size="22"
                                  />
                                </span>
                                <span>Move</span>
                              </Dropdown.Item>
                            )}
                            {!viewOnlyPermission &&
                              document.is_archive_allowed && (
                                <Dropdown.Item
                                  eventKey="5"
                                  className=""
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleArchive(document);
                                  }}
                                >
                                  <span>
                                    <CapsyncIcon
                                      title="archive-outlined"
                                      size="22"
                                    />
                                  </span>
                                  <span>Archive</span>
                                </Dropdown.Item>
                              )}
                            {!viewOnlyPermission &&
                              document.is_delete_allowed && (
                                <Dropdown.Item
                                  eventKey="6"
                                  className=""
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    CapsyncDeleteMessage(
                                      true,
                                      "",
                                      `Are you sure you want to delete this ${
                                        document.attachment ? "File" : "Folder"
                                      }? You can restore it later from the trash. Please confirm if you wish to proceed.`,
                                      () => handleFileDelete(document)
                                    );
                                  }}
                                >
                                  <span>
                                    <CapsyncIcon
                                      title="delete-outlined"
                                      size="22"
                                    />
                                  </span>
                                  <span>Delete</span>
                                </Dropdown.Item>
                              )}
                          </DropdownButton>
                        )}
                      {!viewOnlyPermission &&
                        selectedRows &&
                        !selectedRows.length &&
                        (type == "archive" || type == "trash") > 0 && (
                          <DropdownButton
                            key={index}
                            id={`dropdown-grid-${index}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              toggleDropdown(document.id, e);
                            }}
                            show={openDropdownId === document.id}
                            title={
                              <span className="cs-neutral-80 cursor-pointer">
                                <CapsyncIcon
                                  title="option-horizontal-filled"
                                  size="22"
                                />
                              </span>
                            }
                            className="cs-settings-dropdown"
                          >
                            {" "}
                            <Dropdown.Item
                              eventKey="1"
                              className=""
                              onClick={(e) => {
                                e.stopPropagation();
                                if (storage.usedSpace > storage.maxSpace) {
                                  setFileStorageModal(true);
                                } else {
                                  handleFileDownload([document]);
                                }
                              }}
                            >
                              <span>
                                <CapsyncIcon
                                  title="download-outlined"
                                  size="22"
                                />
                              </span>
                              <span>Download</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="2"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleProtectedFileDownload([document]);
                              }}
                            >
                              <span>
                                <CapsyncIcon
                                  title="protect-download-outlined"
                                  size="22"
                                />
                              </span>
                              <span>Encrypt Download</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="1"
                              className=""
                              onClick={(e) => {
                                e.stopPropagation();
                                handleRestore(document);
                              }}
                            >
                              <span>
                                <CapsyncIcon title="move-outlined" size="22" />
                              </span>
                              <span>Move</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="2"
                              onClick={(e) => {
                                e.stopPropagation();
                                const message =
                                  type === "trash"
                                    ? `Are you sure you want to permanently delete this ${
                                        document.attachment ? "File" : "Folder"
                                      }? This action cannot be undone and the item cannot be restored. Please confirm if you wish to proceed.`
                                    : `Are you sure you want to delete this items? You can restore it later from the trash. Please confirm if you wish to proceed.`;
                                CapsyncDeleteMessage(true, "", message, () =>
                                  handleFileDelete(document)
                                );
                              }}
                            >
                              <span>
                                <CapsyncIcon
                                  title="delete-outlined"
                                  size="22"
                                />
                              </span>
                              <span>Delete</span>
                            </Dropdown.Item>
                          </DropdownButton>
                        )}
                    </div>
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </div>
      ) : (
        <div className="manage-file-container">
          <label className="cs-upload-files default-regular-h4">
            We don't have any active records to display
          </label>
        </div>
      )}
    </React.Fragment>
  );
};

export default GridTableView;
