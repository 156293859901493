/* eslint-disable */
import React, { useState, useEffect, useLayoutEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Dropdown,
  FormGroup,
} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import LeftPart from "./LeftPart";
import { useFormik } from "formik";
import * as yup from "yup";
import { signup, getWmDataByLicenseNumber } from "../../slices/auth";
import { clearMessage } from "../../slices/message";
import { requestsAcceptDeny } from "../../slices/requests";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { wealth_manager_decrypt } from "../../slices/wealthManager.slice";
import { access_pfs_AcceptDeny } from "../../slices/pfs_access";
import CapsyncLoader from "../../commonComponent/CapsyncLoader";
import { EncryptReact } from "../../customHooks/EncryptDecryptReact";
import CapsyncIcon from "../../commonComponent/CapsyncIcon";
import PasswordToolTip from "../../commonComponent/passwordTooltip/PasswordToolTip";
import AuthHead from "./AuthHead";
import { socket } from "../../config/Socket";
import { DecryptReact } from "../../customHooks/EncryptDecryptReact";
import LogoImage from "../logoImage";
import { usAreaCodes } from "../../config/countryAreaCodes";
import { checkDomainAccept } from "../../util/common";
import { uniValidation } from "../individual/liabilities/categoryForms/validationSchema";
import PasswordRequirement from "../../commonComponent/PasswordRequirement";

const SignUp = () => {
  const fetchUrlData = useParams();
  const fetchPathUrl = window.location.pathname.split("/");
  const [getUnknownDetails, setGetUnknownDetails] = useState([]);
  const [storeUserRole, setStoreUserRole] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPwd, setShowPwd] = useState(false);
  const [showPwdRequirements, setShowPwdRequirements] = useState(false);
  const [showConfirmPwd, setShowConfirmPwd] = useState(false);
  const [licenseValidation, setlicenseValidation] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyLicenceId, setCompanyLicenceId] = useState("");
  const [wmFirstName, setWmFirstName] = useState("");
  const [wmLastName, setWmLastName] = useState("");
  const [signupValidMsg, setSignupValidMsg] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [phoneNoError, setPhoneNoError] = useState("");
  const [licenceLoader, setLicenceLoader] = useState(false);
  const [checkphoneError, setCheckphoneError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(clearMessage());

    return () => {
      setSubmitted(false);
    };
  }, [dispatch]);

  const checkPhoneValid = (event) => {
    setSignupValidMsg("");
    if (event) {
      if (
        isValidPhoneNumber(event) === true &&
        usAreaCodes.includes(Number(event.split("+1")[1].substr(0, 3))) &&
        event.split("+1")[1].length == 10
      ) {
        setPhoneNo(event);
        setPhoneNoError("");
        setCheckphoneError(false);
      } else {
        setPhoneNo(event);
        setPhoneNoError(
          !event
            ? uniValidation.phone_number.requrie
            : uniValidation.phone_number.valid
        );
        setCheckphoneError(true);
      }
    } else {
      setPhoneNo("");
      setPhoneNoError("");
      setCheckphoneError(false);
    }
  };

  const initialValues = {
    roleId: storeUserRole,
    firstName: "",
    lastName: "",
    businessName: "",
    email: "",
    password: "",
    confirmPassword: "",
    terms: "",
    licenseId: licenseValidation,
    companyName: "",
    companyLicenceId: "",
  };

  const validationSchema = yup.object().shape({
    roleId: yup.number().required("Select role"),
    firstName: yup
      .string()
      .strict()
      // .matches(/^\S*$/, "No spaces allowed")
      .max(40, uniValidation.first_name.max)
      .required(uniValidation.first_name.require).test("is-not-only-spaces", uniValidation.first_name.require, (value) => {
        return value.trim() !== "";
      }),
    lastName: yup
      .string()
      .strict()
      // .matches(/^\S*$/, "No spaces allowed")
      .max(40, uniValidation.last_name.max)
      .required(uniValidation.last_name.require).test("is-not-only-spaces", uniValidation.last_name.require, (value) => {
        return value.trim() !== "";
      }),
    ...(storeUserRole == 3 && {
      businessName: yup.string().required("Enter Company name").test("is-not-only-spaces", "Enter Company name", (value) => {
        return value.trim() !== "";
      }),
    }),
    email: yup
      .string()
      // .matches(/^\S*$/, uniValidation.email.sapces)
      .required(uniValidation.email.required).test("is-not-only-spaces", uniValidation.email.required, (value) => {
        return value.trim() !== "";
      })
      .email(uniValidation.email.validEmail)
      .test(
        "no-more-than-30-before-at",
        uniValidation.email.validEmail,
        (value) => (value ? value.split("@")[0].length <= 30 : true)
      )
      .test(
        "at-least-1-character-before-at",
        "Enter at least 1 character before @",
        (value) => (value ? value.split("@")[0].length > 0 : true)
      )
      .test("custom-email-format", uniValidation.email.validEmail, (value) => {
        return value ? uniValidation.email.allowedFormat.test(value) : true;
      })
      .test("domain-acceptance", uniValidation.email.validEmail, (value) => {
        return checkDomainAccept(value);
      }),
    password: yup
      .string()
      .required(uniValidation.password.require).test("is-not-only-spaces", uniValidation.password.require, (value) => {
        return value.trim() !== "";
      })
      .matches(/^\S*$/, uniValidation.password.comman)
      .matches(/[0-9]/, uniValidation.password.comman)
      .matches(/[a-z]/, uniValidation.password.comman)
      .matches(/[A-Z]/, uniValidation.password.comman)
      .matches(/[^A-Za-z0-9]/, uniValidation.password.comman)
      .min(8, uniValidation.password.comman)
      .max(20, uniValidation.password.comman),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], uniValidation.confirm_password.match)
      .required(uniValidation.confirm_password.require).test("is-not-only-spaces",uniValidation.confirm_password.require, (value) => {
        return value.trim() !== "";
      })
      .matches(/^\S*$/, uniValidation.confirm_password.comman)
      .max(20, uniValidation.confirm_password.comman),
  });

  const checkEmail = yup.object({
    email: yup
      .string()
      .matches(/^\S*$/, uniValidation.email.sapces)
      .required(uniValidation.email.required).test("is-not-only-spaces", uniValidation.email.required, (value) => {
        return value.trim() !== "";
      })
      .email(uniValidation.email.validEmail)
      .test(
        "no-more-than-30-before-at",
        uniValidation.email.validEmail,
        (value) => (value ? value.split("@")[0].length <= 30 : true)
      )
      .test(
        "at-least-1-character-before-at",
        "Enter at least 1 character before @",
        (value) => (value ? value.split("@")[0].length > 0 : true)
      )
      .test("custom-email-format", uniValidation.email.validEmail, (value) => {
        return value ? uniValidation.email.allowedFormat.test(value) : true;
      })
      .test("domain-acceptance", uniValidation.email.validEmail, (value) => {
        return checkDomainAccept(value);
      }),
  });

  const validatePhone = () => {
    setSubmitted(true);
    if ((!phoneNo || !isValidPhoneNumber(phoneNo)) && !values.email) {
      setPhoneNoError(
        !phoneNo
          ? uniValidation.phone_number.requrie
          : uniValidation.phone_number.valid
      );
      setCheckphoneError(true);
    } else {
      checkEmail
        .validate({ email: values.email })
        .then(() => {
          if (!phoneNo || !isValidPhoneNumber(phoneNo)) {
            setPhoneNoError(
              !phoneNo
                ? uniValidation.phone_number.requrie
                : uniValidation.phone_number.valid
            );
            setCheckphoneError(true);
          }
        })
        .catch((error) => { });
    }
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldError,
    setFieldTouched,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (checkphoneError) {
        return false;
      }
      setCheckphoneError(false);
      setLoading(true);
      values.phoneNo = phoneNo;
      dispatch(signup(values))
        .unwrap()
        .then((response) => {
          const emailVerificationCode =
            response && response.data && response.data.emailVerificationCode;
          const id = response && response.data && response.data.id;
          setPhoneNoError("");
          if (values.roleId === 1) {
            if (response.status === "Error") {
              setSignupValidMsg(response.message);
              setLoading(false);
            } else {
              setSignupValidMsg("");
              if (id) {
                window.localStorage.setItem(
                  "_signup_verify",
                  emailVerificationCode
                );
                navigate(`/verify-email/${id}`, {
                  replace: false,
                });
              }
              setLoading(false);
            }
          } else {
            if (response.licenseissue) {
              setCompanyName("");
              setCompanyLicenceId("");
              setWmFirstName("");
              setWmLastName("");
              setlicenseValidation("Enter a valid CRD number");
              setLoading(false);
            } else {
              if (response.status === "Error") {
                setSignupValidMsg(response.message);
                setLoading(false);
              } else {
                setSignupValidMsg("");
                if (id) {
                  window.localStorage.setItem(
                    "_signup_verify",
                    emailVerificationCode
                  );
                  navigate(`/verify-email/${id}`, {
                    replace: false,
                  });
                }
                setLoading(false);
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    },
  });
  if (fetchPathUrl[1] === "manage") {
    if (Object.keys(fetchUrlData).length === 1) {
      useLayoutEffect(() => {
        dispatch(access_pfs_AcceptDeny({ code: fetchUrlData.status }))
          .unwrap()
          .then((response) => {
            // socket.emit("collaboration_listing", {
            //   id: response.from_id,
            // });
            socket.emit("notification", "message");
            if (response.navigate === 1) {
              let acceptCode = response.msg
                ? response.msg
                : "You have already performed an action to the invitation.";
              let encAccept = EncryptReact(acceptCode);
              window.localStorage.setItem("manage_action", encAccept);
              // navigate(`/manage/performed/${encAccept}`);
              navigate("/manage/performed");
            } else if (response.code === 400) {
              let acceptCode = response.message ? response.message : "";
              let encAccept = EncryptReact(acceptCode);
              window.localStorage.setItem("manage_action", encAccept);
              navigate("/manage/performed");
            } else {
              let storeUnknown = response.decrypted_code.split("^");
              setGetUnknownDetails(storeUnknown);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, []);
      if (getUnknownDetails[4] === "1") {
        navigate("/");
      }
      values.firstName = getUnknownDetails[0];
      values.lastName = getUnknownDetails[1];
      values.email = getUnknownDetails[2];
      values.roleId = Number(getUnknownDetails[3]);
    }

    if (
      fetchUrlData &&
      fetchUrlData.status &&
      fetchUrlData.status.includes("decline") === true
    ) {
      let textCode = "You have declined the invitation";
      let encDecline = EncryptReact(textCode);
      window.localStorage.setItem("manage_action", encDecline);
      // navigate(`/decline/${encDecline}`);
      navigate("/decline");
    }
  }

  if (
    fetchPathUrl[2] === "wealthcompnanies" ||
    fetchPathUrl[2] === "company-sign-up"
  ) {
    if (Object.keys(fetchUrlData).length === 1) {
      useLayoutEffect(() => {
        dispatch(wealth_manager_decrypt({ code: fetchUrlData.status }))
          .unwrap()
          .then((response) => {
            let storeUnknown = response.split("^");
            setGetUnknownDetails(storeUnknown);
          })
          .catch((err) => {
            console.log(err);
          });
      }, []);
      if (getUnknownDetails[4] === "1") {
        navigate("/");
      }
      values.firstName = getUnknownDetails[0];
      values.lastName = getUnknownDetails[1];
      values.email = getUnknownDetails[2];
      values.roleId = Number(1);
    }
    if (
      fetchUrlData &&
      fetchUrlData.status &&
      fetchUrlData.status.includes("decline") === true
    ) {
      if (fetchPathUrl[2] === "wealthcompnanies") {
        navigate("/wmcompanydecline");
      } else {
        navigate("/procompanydecline");
      }
    }
  }

  if (
    fetchPathUrl[2] === "wealthindividual" ||
    fetchPathUrl[2] === "individual-sign-up"
  ) {
    if (Object.keys(fetchUrlData).length === 1) {
      useLayoutEffect(() => {
        dispatch(requestsAcceptDeny({ code: fetchUrlData.status }))
          .unwrap()
          .then((response) => {
            let storeUnknown = response.split("^");
            setGetUnknownDetails(storeUnknown);
          })
          .catch((err) => {
            console.log(err);
          });
      }, []);
      if (getUnknownDetails[4] === "1") {
        navigate("/");
      }
      values.firstName = getUnknownDetails[0];
      values.lastName = getUnknownDetails[1];
      values.email = getUnknownDetails[2];
      values.roleId = 1;
    }
    if (
      fetchUrlData &&
      fetchUrlData.status &&
      fetchUrlData.status.includes("decline") === true
    ) {
      if (fetchPathUrl[2] === "wealthindividual") {
        navigate("/wmindidecline");
      } else {
        navigate("/individual-sign-up");
      }
    }
  }

  const changeUserRole = (params) => {
    resetForm();
    setStoreUserRole(params);
    setFieldValue("roleId", params);
    if (params === 1 && companyName) {
      setFieldValue("firstName", "");
      setFieldValue("lastName", "");
      setFieldValue("licenseId", "");
    }

    setCompanyName("");
    setCompanyLicenceId("");
    setWmFirstName("");
    setWmLastName("");
    setCheckphoneError(false);
    setShowConfirmPwd(false);
    setPhoneNoError("");
    setPhoneNo("");
    setSignupValidMsg("");
    setlicenseValidation("");
  };
  const licenceData = () => {
    setLicenceLoader(true);
    if (values.licenseId) {
      dispatch(getWmDataByLicenseNumber(values))
        .unwrap()
        .then((response) => {
          if (response.licenseissue) {
            setCompanyName("");
            setCompanyLicenceId("");
            setWmFirstName("");
            setWmLastName("");
            setLicenceLoader(false);
            setCheckphoneError(false);
            setShowConfirmPwd(false);
            setPhoneNoError("");
            setSignupValidMsg("");
            setlicenseValidation("Enter a valid CRD number");
          } else {
            setLicenceLoader(false);
            setCompanyName(response.CrntEmps_CrntEmp_orgNm);
            setCompanyLicenceId(response.CrntEmps_CrntEmp_orgPK);
            setWmFirstName(response.Info_firstNm);
            setWmLastName(response.Info_lastNm);
            setlicenseValidation("");
            setFieldError("email", "");
            setFieldError("password", "");
            setFieldError("confirmPassword", "");
            setFieldTouched("email", false, false);
            setFieldTouched("password", false, false);
            setFieldTouched("confirmPassword", false, false);
            setPhoneNoError("");
            setSignupValidMsg("");
            setSubmitted(false);
            setCheckphoneError(false);
            setShowConfirmPwd(false);
          }
        })
        .catch((err) => {
          setLicenceLoader(false);
          setlicenseValidation("Enter a valid CRD number");
          console.log(err);
        });
    } else {
      setLicenceLoader(false);
      // setlicenseValidation("Enter license number");
    }
  };

  const checkWmValidation = () => {
    setSubmitted(true);
    if (!licenseValidation) {
      setlicenseValidation("Enter CRD number");
      return false;
    }
  };
  const handler = () => {
    licenceData();
  };

  const handleLicence = (e) => {
    setlicenseValidation("");
    setSignupValidMsg("");
    setFieldValue("licenseId", e.target.value);
  };

  const handleLicenceKey = (params) => {
    if (params.key === "Enter") {
      licenceData();
    }
  };

  return (
    <section className="auth-section">
      <Container fluid>
        <Row className="align-items-center">
          <Col lg={6}>
            <LeftPart
              page="signup"
              img="auth-image.svg"
              title="Your New Immersive Digital Finance Hub."
              text="Sync your real-time digital Personal Financial Statement with CapSync."
            />
          </Col>
          <Col lg={6}>
            {isNaN(values.roleId) != true && (
              <div className="auth-right-section">
                <AuthHead title="Sign up" />
                <div className="cs-auth-form auth-sign-up-scrollbar">
                  <Form onSubmit={handleSubmit} className="sign-up-form">
                    <Row>
                      <Col lg={12}>
                        <Dropdown className="cs-form-dropdown">
                          <Dropdown.Toggle
                            className="default-regular-sub-heading-s"
                            disabled={
                              (Object.keys(fetchUrlData).length === 1 &&
                                fetchPathUrl[2] === "wealthindividual") ||
                                fetchPathUrl[2] === "wealthcompnanies" ||
                                fetchPathUrl[2] === "company-sign-up" ||
                                fetchPathUrl[2] === "individual-sign-up" ||
                                fetchPathUrl[1] === "manage"
                                ? true
                                : false
                            }>
                            {values.roleId === ""
                              ? "Select role"
                              : values.roleId === 1
                                ? "Individual"
                                : values.roleId === 2
                                  ? "Financial Advisor"
                                  : "Professional"}
                            <span className="cs-neutral-90">
                              <CapsyncIcon
                                title="chevron-down-outlined"
                                size="18"
                              />
                            </span>
                          </Dropdown.Toggle>

                          {Object.keys(fetchUrlData).length !== 1 && (
                            <Dropdown.Menu>
                              <Dropdown.Item
                                className={
                                  storeUserRole === 1
                                    ? "dropdown-item-active default-regular-sub-heading-s"
                                    : "default-regular-sub-heading-s"
                                }
                                onClick={() => changeUserRole(1)}>
                                Individual
                              </Dropdown.Item>
                              {/* <Dropdown.Item
                                className={
                                  storeUserRole === 2
                                    ? "dropdown-item-active default-regular-sub-heading-s"
                                    : "default-regular-sub-heading-s"
                                }
                                onClick={() => changeUserRole(2)}
                              >
                                Financial Advisor
                              </Dropdown.Item> */}
                              <Dropdown.Item
                                className={
                                  storeUserRole === 3
                                    ? "dropdown-item-active default-regular-sub-heading-s"
                                    : "default-regular-sub-heading-s"
                                }
                                onClick={() => changeUserRole(3)}>
                                Professional
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          )}

                          {errors.roleId && touched.roleId ? (
                            <span className="form-error-msg default-light-body-text-s cs-danger">
                              {errors.roleId}
                            </span>
                          ) : null}
                        </Dropdown>
                      </Col>
                    </Row>
                    {/* values.roleId === 2 is for Financial Advisor */}
                    {values.roleId === 2 && (
                      <Row>
                        <Col lg={12}>
                          <Form.Group className="cs-form-group">
                            <Form.Control
                              className={
                                values.licenseId && "cs-input-field-active"
                              }
                              type="text"
                              placeholder="CRD number"
                              autoComplete="off"
                              name="licenseId"
                              value={values.licenseId}
                              // onChange={handleChange}
                              onChange={handleLicence}
                              maxLength={10}
                              minLength={4}
                              onKeyDown={handleLicenceKey}
                              onBlur={handler}
                            />
                            {errors.licenseId && touched.licenseId ? (
                              <span className="form-error-msg default-light-body-text-s cs-danger">
                                {errors.licenseId}
                              </span>
                            ) : licenseValidation !== "" ? (
                              <span className="form-error-msg default-light-body-text-s cs-danger">
                                {licenseValidation}
                              </span>
                            ) : null}
                            {licenceLoader === true && (
                              <div className="input-field-icon">
                                <span className="spinner-border spinner-border-sm" />
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                    )}
                    {values.licenseId !== "" &&
                      values.roleId === 2 &&
                      licenseValidation === "" &&
                      companyName !== "" && (
                        <Fragment>
                          <Row>
                            <Col lg={12}>
                              <Form.Group className="cs-form-group">
                                <Form.Control
                                  className={
                                    values.companyName &&
                                    "cs-input-field-active"
                                  }
                                  type="text"
                                  placeholder="Company name"
                                  autoComplete="off"
                                  name="companyName"
                                  value={(values.companyName = companyName)}
                                  onChange={handleChange}
                                  // onBlur={handleBlur}
                                  // onKeyPress={(e) =>
                                  //   !/[a-zA-z]/.test(e.key) && e.preventDefault()
                                  // }
                                  disabled={true}
                                />
                                {errors.companyName && touched.companyName ? (
                                  <span className="form-error-msg default-light-body-text-s cs-danger">
                                    {errors.companyName}
                                  </span>
                                ) : null}
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={12}>
                              <Form.Group className="cs-form-group">
                                <Form.Control
                                  className={
                                    values.companyLicenceId &&
                                    "cs-input-field-active"
                                  }
                                  type="text"
                                  placeholder="Company license number"
                                  autoComplete="off"
                                  name="companyLicenceId"
                                  value={
                                    (values.companyLicenceId = companyLicenceId)
                                  }
                                  onChange={handleChange}
                                  // onBlur={handleBlur}
                                  disabled={true}
                                />
                                {errors.companyLicenceId &&
                                  touched.companyLicenceId ? (
                                  <span className="form-error-msg default-light-body-text-s cs-danger">
                                    {errors.companyLicenceId}
                                  </span>
                                ) : null}
                              </Form.Group>
                            </Col>
                          </Row>
                        </Fragment>
                      )}
                    {/* roleId 1 i for Individual */}
                    {values.roleId === 3 && (
                      <Row>
                        <Col lg={12}>
                          <Form.Group
                            className={`cs-form-group ${showPwdRequirements && "last-password-feild"
                              }`}>
                            <Form.Control
                              className={
                                values.businessName && "cs-input-field-active"
                              }
                              type="text"
                              placeholder="Name of Company"
                              autoComplete="off"
                              name="businessName"
                              onKeyDown={(e) => {
                                if (
                                  e.key === " " &&
                                  e.target.selectionStart === 0
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              maxLength="20"
                              value={values.businessName}
                              onChange={handleChange}
                            // onBlur={() => setShowPwdRequirements(false)}
                            // onFocus={() => setShowPwdRequirements(true)}
                            />
                            {errors.businessName && touched.businessName ? (
                              <span className="form-error-msg default-light-body-text-s cs-danger">
                                {errors.businessName}
                              </span>
                            ) : null}
                          </Form.Group>
                        </Col>
                      </Row>
                    )}
                    {values.roleId === 1 ||
                      values.roleId === 3 ||
                      values.roleId === "" ||
                      (values.licenseId !== "" &&
                        values.roleId === 2 &&
                        licenseValidation === "" &&
                        companyName !== "") ? (
                      <Fragment>
                        <Row>
                          <Col className="col" lg={6} sm={6}>
                            <Form.Group className="cs-form-group">
                              <Form.Control
                                className={
                                  values.firstName && "cs-input-field-active"
                                }
                                type="text"
                                placeholder="First name"
                                autoComplete="off"
                                name="firstName"
                                onKeyDown={(e) => {
                                  if (
                                    e.key === " " &&
                                    e.target.selectionStart === 0
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                value={
                                  wmFirstName
                                    ? (values.firstName = wmFirstName)
                                    : values.firstName
                                }
                                // value={values.firstName}
                                // defaultValue={(wmFirstName ? wmFirstName : values.firstName)}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                disabled={
                                  (Object.keys(fetchUrlData).length === 1 &&
                                    (fetchPathUrl[2] === "wealthindividual" ||
                                      fetchPathUrl[2] ===
                                      "individual-sign-up")) ||
                                    fetchPathUrl[2] ===
                                    "company-sign-up" ||
                                    fetchPathUrl[2] ===
                                    "company-sign-up" ||
                                    fetchPathUrl[1] === "manage" ||
                                    values.roleId === 2
                                    ? true
                                    : false
                                }
                                maxLength={40}
                              />
                              {!wmFirstName &&
                                errors.firstName &&
                                touched.firstName ? (
                                <span className="form-error-msg default-light-body-text-s cs-danger">
                                  {errors.firstName}
                                </span>
                              ) : null}
                            </Form.Group>
                          </Col>
                          <Col className="col" lg={6} sm={6}>
                            <Form.Group className="cs-form-group">
                              <Form.Control
                                className={
                                  values.lastName && "cs-input-field-active"
                                }
                                type="text"
                                placeholder="Last name"
                                autoComplete="off"
                                name="lastName"
                                onKeyDown={(e) => {
                                  if (
                                    e.key === " " &&
                                    e.target.selectionStart === 0
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                value={
                                  wmLastName
                                    ? (values.lastName = wmLastName)
                                    : values.lastName
                                }
                                // defaultValue={(wmLastName ? wmLastName : values.lastName)}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                disabled={
                                  (Object.keys(fetchUrlData).length === 1 &&
                                    (fetchPathUrl[2] === "wealthindividual" ||
                                      fetchPathUrl[2] ===
                                      "individual-sign-up")) ||
                                    fetchPathUrl[2] === "wealthcompnanies" ||
                                    fetchPathUrl[2] ===
                                    "company-sign-up" ||
                                    fetchPathUrl[1] === "manage" ||
                                    values.roleId === 2
                                    ? true
                                    : false
                                }
                                maxLength={40}
                              />
                              {!wmFirstName &&
                                errors.lastName &&
                                touched.lastName ? (
                                <span className="form-error-msg default-light-body-text-s cs-danger">
                                  {errors.lastName}
                                </span>
                              ) : null}
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} sm={6}>
                            <Form.Group className="cs-form-group">
                              <Form.Control
                                className={
                                  values.email && "cs-input-field-active"
                                }
                                type="email"
                                placeholder="Email"
                                autoComplete="off"
                                name="email"
                                value={values.email}
                                onKeyDown={(e) =>
                                  e.code === "Space" && e.preventDefault()
                                }
                                onChange={(e) => {
                                  handleChange(e);
                                  setSignupValidMsg("");
                                }}
                                // onBlur={handleBlur}
                                disabled={
                                  Object.keys(fetchUrlData).length === 1
                                    ? true
                                    : false
                                }
                              />
                              {errors.email && touched.email ? (
                                <span className="form-error-msg default-light-body-text-s cs-danger">
                                  {errors.email}
                                </span>
                              ) : null}
                            </Form.Group>
                          </Col>
                          <Col lg={6} sm={6}>
                            <Form.Group className="cs-form-group">
                              <PhoneInput
                                // limitMaxLength={15}
                                international
                                countryCallingCodeEditable={false}
                                addInternationalOption={false}
                                placeholder="Phone number"
                                autoComplete="off"
                                defaultCountry="US"
                                withCountryCallingCode={false}
                                value={phoneNo}
                                onChange={checkPhoneValid}
                                countries={["US"]}
                                className="form-control"
                                maxLength={15}
                              />
                              {submitted && phoneNoError !== "" ? (
                                <span className="form-error-msg default-light-body-text-s cs-danger">
                                  {phoneNoError}
                                </span>
                              ) : null}
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <Form.Group
                              className={`cs-form-group ${showPwdRequirements && "last-password-feild"
                                }`}>
                              <Form.Control
                                className={
                                  values.password && "cs-input-field-active"
                                }
                                type={showPwd ? "text" : "password"}
                                placeholder="Password"
                                autoComplete="off"
                                name="password"
                                onKeyDown={(e) => {
                                  if (
                                    e.key === " " &&
                                    e.target.selectionStart === 0
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                maxLength="20"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={() => setShowPwdRequirements(false)}
                                onFocus={() => setShowPwdRequirements(true)}
                              />
                              <span
                                onClick={() => setShowPwd(!showPwd)}
                                onMouseDown={(e) => e.preventDefault()}
                                className="input-field-icon cursor-pointer cs-neutral-80">
                                {showPwd ? (
                                  <CapsyncIcon
                                    title="show-password-outlined"
                                    size="18"
                                  />
                                ) : (
                                  <CapsyncIcon
                                    title="hide-password-outlined"
                                    size="18"
                                  />
                                )}
                              </span>
                              {!showPwdRequirements &&
                                errors.password &&
                                touched.password ? (
                                <span className="form-error-msg default-light-body-text-s cs-danger">
                                  {errors.password}
                                </span>
                              ) : null}
                            </Form.Group>
                            {showPwdRequirements && (
                              <PasswordRequirement password={values.password} />
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <Form.Group className="cs-form-group">
                              <Form.Control
                                className={
                                  values.confirmPassword &&
                                  "cs-input-field-active"
                                }
                                type={showConfirmPwd ? "text" : "password"}
                                placeholder="Confirm password"
                                autoComplete="off"
                                maxLength="20"
                                name="confirmPassword"
                                onKeyDown={(e) => {
                                  if (
                                    e.key === " " &&
                                    e.target.selectionStart === 0
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                value={values.confirmPassword}
                                onChange={handleChange}
                              // onBlur={handleBlur}
                              />
                              <span
                                onClick={() =>
                                  setShowConfirmPwd(!showConfirmPwd)
                                }
                                className="input-field-icon cursor-pointer cs-neutral-80">
                                {showConfirmPwd ? (
                                  <CapsyncIcon
                                    title="show-password-outlined"
                                    size="18"
                                  />
                                ) : (
                                  <CapsyncIcon
                                    title="hide-password-outlined"
                                    size="18"
                                  />
                                )}
                              </span>
                              {errors.confirmPassword &&
                                touched.confirmPassword ? (
                                <span className="form-error-msg default-light-body-text-s cs-danger">
                                  {errors.confirmPassword}
                                </span>
                              ) : null}
                            </Form.Group>
                          </Col>
                        </Row>
                      </Fragment>
                    ) : (
                      <Fragment></Fragment>
                    )}

                    <Row>
                      <Col lg={12}>
                        <FormGroup className="cs-form-group">
                          {signupValidMsg !== "" && (
                            <div className="cs-msg default-regular-body-text-s cs-danger">
                              <span className="icon">
                                <CapsyncIcon title="info-filled" />
                              </span>
                              <span className="txt">{signupValidMsg}</span>
                            </div>
                          )}

                          {values.licenseId === "" && values.roleId === 2 ? (
                            <Button
                              type="button"
                              onClick={checkWmValidation}
                              className="cs-btn-primary lg-btn default-regular-h5">
                              Continue
                            </Button>
                          ) : (
                            <Button
                              type="submit"
                              onClick={validatePhone}
                              className="cs-btn-primary lg-btn default-regular-h5">
                              Continue
                            </Button>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={12}>
                        <FormGroup className="">
                          <div className="cs-msg default-regular-body-text-s cs-danger">
                            <span
                              htmlFor="termsCheckbox"
                              className="default-light-body-text-m cs-neutral-90">
                              By selecting Continue, you agree to our&nbsp;
                              <Link
                                to="https://capsync.com/terms-of-service/"
                                className="cs-primary default-regular-body-text-s cs-link"
                                target="_Blank">
                                Terms of Service
                                {/* &nbsp;Terms of ytytyty Service&nbsp; */}
                              </Link>
                              &nbsp;and acknowledge our&nbsp;
                              <Link
                                to="https://capsync.com/privacy-policy/"
                                className="cs-primary default-regular-body-text-s cs-link"
                                target="_Blank">
                                Privacy Policy.
                              </Link>
                            </span>
                            {/* <span className="txt">{signupValidMsg}</span> */}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                  <span className="auth-main-link default-regular-body-text-m">
                    Already have a CapSync account?{" "}
                    <Link className="cs-primary" to="/">
                      Sign in
                    </Link>
                  </span>
                </div>
              </div>
            )}
            {fetchUrlData &&
              fetchUrlData.status &&
              fetchUrlData.status.includes("decline") === true && (
                <Row>
                  <Col>
                    <div className="auth-right-section">
                      <LogoImage />
                      <div className="cs-auth-form">
                        <h2 className="default-semi-bold-h2 cs-neutral-100">
                          {DecryptReact(
                            window.localStorage.getItem("manage_action")
                          )}
                        </h2>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
          </Col>
        </Row>
        {loading && <CapsyncLoader />}
      </Container>
    </section>
  );
};

export default SignUp;
