import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { wealth_managers_findone } from "../slices/auth";

const UseWealthManagerDetails = () => {
  const { user } = useSelector((state) => state.auth);

  let id = user && user.user.id;

  const dispatch = useDispatch();

  const [wmFetchUserData, setFetchUserData] = useState({});

  function findRecord() {
    dispatch(wealth_managers_findone({ wm_id: id }))
      .unwrap()
      .then((response) => {
        setFetchUserData(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (id) {
      findRecord();
    }
  }, [id]);
  return { wmFetchUserData };
};

export default UseWealthManagerDetails;
