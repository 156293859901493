/* eslint-disable */
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Table, Row, Col, Container, Form, Dropdown } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useParams } from "react-router-dom";

import commonNames from "../../../config/commonNames";
import CapsyncToolTip from "../../../commonComponent/capsyncTooltip/CapsyncToolTip";
import ManageFilesUpload from "../../../commonComponent/ManageFilesUpload";
import {
  categoryLineChartCalculation,
  totalBalance,
} from "../../../config/financeCalculation";
import {
  amortizationCategory,
  portfolioCategory,
  transactionCategory,
  tableHeader,
  manageColumns,
} from "../../../config/categoryTable";
import { columnFindRecord, selectColumn } from "../../../slices/columnFilter";
import CategoryLineChart from "../CategoryLineChart";
import {
  categoryFilesRemove,
  categoryGetAll,
  categoryDeleteRecord,
  categoryFindRecord,
  categoryFilesUpdate,
  categoryFileListing,
  categoryUnlinkRecord,
  getAllTransactionsLoaderData,
  categoryTotalNew,
} from "../../../slices/category.slice";
import CapsyncSectionHeader from "../../../commonComponent/CapsyncSectionHeader";
import {
  categoryId,
  institutionColumn,
  liabilitiesCategory,
} from "../../../config/finance";
import CategoryTableData from "../../../commonComponent/CategoryTableData";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import SkeletonCategoryDashboard from "../assets/category/SkeletonCategoryDashboard";
import { loadStripe } from "@stripe/stripe-js";
import InitialSetup3 from "../dashboard/InitialSetup/InitialSetup3";
import { totalBalanceColumn } from "../../../util/finance";
import LinkModal from "../../../commonComponent/modalPopup/LinkModal";
import Unlink from "../../../commonComponent/modalPopup/UnlinkPopup";
import { isValidFileName, setAddressString } from "../../../util/common";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { socket } from "../../../config/Socket";
import { Elements } from "@stripe/react-stripe-js";
import UpgradeStorageModal from "../fileManagement/UpgradeStorageModal";
import {
  get_user_storage,
  useProgressUploadDetails,
  useUploadStorage,
} from "../../../slices/fileManagement.slice";
import DownloadConfirmationModal from "../fileManagement/DownloadConfirmationModal";

const CategoryDashboard = ({
  categoryData,
  CapsyncDeleteMessage,
  individualUserData,
  toastMessage,
  is_loader,
  editAccessUser,
  errorAccounts,
  dataAsset,
  dataLiability,
  plaidErrorAccounts,
}) => {
  // const storePlaidMask = PlaidMask();
  const { category } = useParams();
  const categoryListDataTest = useSelector(
    (state) => state.category.categoryDashboardListing
  );

  const categoryItemsID = useRef(null);

  const [isLoader, setIsloader] = useState({
    data: true,
    chart: true,
  });

  const liabilitiesData =
    (categoryListDataTest[category] && categoryListDataTest[category].data) ||
    [];
  const lineChartData =
    (categoryListDataTest[category] &&
      categoryListDataTest[category].chart_data) ||
    [];
  const arrlength = liabilitiesData && liabilitiesData.length;
  const [modalShow, setModalShow] = useState(false);
  const [storeFiles, listFiles] = useState([]);
  const [storeAllFiles, setAllFiles] = useState("");
  const [fileModal, setFileModal] = useState(false);
  const [displayFile, setDisplayFile] = useState([]);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [is_deleted, setIs_deleted] = useState(false);
  const [record, setRecord] = useState({});
  const [unpaidYear, setUnpaidYear] = useState(0);
  const [msg, setMsg] = useState("");
  // const [categoryColumns, setCategoryColumns] = useState([]);
  const [originalFiles, setOriginalFiles] = useState([]);
  const [categoryItemId, setCategoryItemId] = useState(null);
  const [isBigFile, setIsBigFile] = useState(false);
  const [downloadConfirmationModalOpen, setDownloadConfirmationModalOpen] =
    useState(false);
  const [downloadItem, setDownloadItem] = useState(null);
  const [categoryItem, setCategoryItem] = useState(null);
  const [deletedFileDisable, setDeleteFileDisable] = useState([]);

  const [categoryColumnsFilters, setCategoryColumnsFilters] = useState(
    tableHeader.liabilities[category]
  );

  const [liabilitiesColumnsList, setLiabilitiesColumnsList] = useState([]); //column data from config with filter

  const [storedColumns, setStoredColumns] = useState([]);

  const { user } = useSelector((state) => state.auth);
  let id;

  let pfs_access_id = 0;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
    pfs_access_id = id;
  } else {
    id = user && user.user.id;
  }

  const [fetchTypeCategory, setFetchTypeCategory] = useState({
    type: 0,
    category: "Liabilities",
  });
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [showUnlinkModal, setShowUnlinkModal] = useState(false);
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const [isUpdateLinkedListData, setIsUpdateLinkedListData] = useState(true);
  const [duplicateFileError, setDuplicateFileError] = useState("");
  const progressUploadDetails = useProgressUploadDetails();
  const storageData = useUploadStorage();
  // -----------------------------------------------------
  const [isOpen, setIsOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const dropdownRef = useRef(null);
  const actionColumnData = {
    title: "Actions",
    class: "default-medium-sub-heading-m",
    width: "300",
    tooltip: false,
    filter: false,
    fieldName: "action",
    draggable: false,
    fieldType: "string",
  };
  const [sequence, setSequence] = useState([]);

  useEffect(() => {
    categoryItemsID.current = categoryItemId;
  }, [categoryItemId]);

  const getUserStorageFunction = async () => {
    await dispatch(get_user_storage({ id })).unwrap();
  };

  useEffect(() => {
    getUserStorageFunction();
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );

  useEffect(() => {
    let selectedColumnsArray = sequence
      .filter((item) => item.isChecked)
      .map((item) => item.name);
    setSelectedColumns([...selectedColumnsArray]);
    const sortedCategoryColumnFilters = sortDataBySequence(
      categoryColumnsFilters,
      sequence
    );
    setCategoryColumnsFilters(sortedCategoryColumnFilters);
  }, [sequence]);
  /////////////////////////////////////////////////////////////////
  const [linkId, setLinkId] = useState({
    address: "",
    id: "",
  });

  const dispatch = useDispatch();

  const modelData = (title, id) => {
    setModalShow(true);
    setRecord({});
    dispatch(
      categoryFindRecord({
        id: id,
        category: category,
        main_category: "liabilities",
      })
    )
      .unwrap()
      .then((response) => {
        setRecord(response);
        setUnpaidYear(response.year);
      })
      .catch((err) => {
        toastMessage(err.message, "error");
      });

    setFetchTypeCategory({
      type: fetchCatData.id,
      category: "liabilities",
    });
  };
  const getTableList = () => {
    dispatch(
      categoryGetAll({
        id: id,
        category: category,
        main_category: "liabilities",
      })
    )
      .unwrap()
      .then((response) => {
        // setLiabilitiesData(response.entries);
        // let totalShareValManual = response.entries.reduce(
        //   (prev, curr) => prev + curr.balance_due,
        //   0
        // );
        // setPaymentTotal(totalShareValManual);
        setIsloader((prev) => ({ ...prev, chart: false, data: false }));
      })
      .catch((err) => {
        toastMessage(err.message, "error");
      });
  };

  useEffect(() => {
    getTableList();
  }, []);

  useEffect(() => {
    if (fileModal) {
      setDuplicateFileError("");
    }
  }, [fileModal]);
  const getUpdatedTransactionsLoaderData = () => {
    const ins_ids = [];
    for (let i = 0; i < liabilitiesData.length; i++) {
      if (liabilitiesData[i].institution_id) {
        ins_ids.push(liabilitiesData[i].institution_id);
      }
    }
    dispatch(
      getAllTransactionsLoaderData({ user_id: id, ins_ids, category })
    ).unwrap();
  };

  useEffect(() => {
    const iraLoaderHandler = (msg) => {
      getTableList();
    };
    let timeout = null;
    const transactionLoaderHandler = (msg) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        getUpdatedTransactionsLoaderData();
      }, 2000);
    };
    socket.on("ira_loader_updated", iraLoaderHandler);
    socket.on("transaction_loader_updated", transactionLoaderHandler);

    return () => {
      socket.off("ira_loader_updated", iraLoaderHandler);
      socket.off("transaction_loader_updated", transactionLoaderHandler);
    };
  }, []);

  useEffect(() => {
    const columnArr = tableHeader.liabilities[category];
    setLiabilitiesColumnsList(columnArr);
    fetchColumns();
  }, []);

  const getTotalCount = (category, index, selectedColumns) => {
    switch (true) {
      case selectedColumns === "balance_due" &&
        [
          "notespayabletobanksandothers",
          "installmentsauto",
          "installmentsaccount",
          "unpaidtaxes",
          "otherliabilities",
          "accountpayable",
          "mortgagesonrealestate",
        ].includes(category):
        return totalBalance(liabilitiesData, "balance_due");

      case selectedColumns === "loan_amount" &&
        ["lifeinsurance"].includes(category):
        return totalBalance(liabilitiesData, "loan_amount");

      case index && [].includes(category):
        return totalBalance(liabilitiesData, "payment");

      case selectedColumns === "original_balance" &&
        ["mortgagesonrealestate"].includes(category):
        return totalBalance(liabilitiesData, "original_balance");

      default:
        return null;
    }
  };

  const dashboardAPi = () => {
    dispatch(categoryTotalNew({ id: id }))
      .unwrap()
      .then((response) => {})
      .catch((err) => {
        console.log(err);
      });
  };
  const deleteRecord = (id) => {
    CapsyncDeleteMessage(true, "Delete", "", () => deleteFunc(id));
    dashboardAPi();
  };

  function deleteFunc(cid) {
    let payload = {
      id: cid,
      pfs_access_id: id,
      category: category,
      main_category: "liabilities",
    };
    dispatch(categoryDeleteRecord(payload))
      .unwrap()
      .then(() => {
        getTableList();
        setTimeout(() => {
          errorAccounts();
          toastMessage(
            "Your record has been deleted successfully. ",
            "success"
          );
        }, 3000);
      })
      .catch((err) => {
        toastMessage(err.message, "error");
      });
  }

  const manageFilesList = (showModal, itemId) => {
    setFileModal(showModal);
    if (!showModal) return;
    const inProgressData = progressUploadDetails.length
      ? progressUploadDetails.reduce((acc, item) => acc + item.size, 0)
      : 0;
    const used_space = storageData.usedSpace + inProgressData;
    dispatch(
      categoryFileListing({
        formdata: {
          category_id: categoryId.liability[category],
          row_id: itemId,
          user_id: id,
        },
        category: category,
        main_category: "liabilities",
      })
    )
      .unwrap()
      .then((response) => {
        categoryItemsID.current = itemId;
        setOriginalFiles(response.map((x) => x.original_file_name));
        let storeDetails = response.map((x) => ({
          url: x.attachments,
          name: x.original_file_name,
          uploadDate: x.createdAt,
          id: x.id,
          is_scan_file: x.is_scan_file,
          file_size: x.file_size,
        }));
        const allFiles = response.map((x) => x.original_file_name).join(",");
        setAllFiles(allFiles);
        listFiles(storeDetails);
      })
      .catch((err) => {
        console.log("err:- ", err);
      });
    setDeleteFileDisable([]);
  };

  function fileAttachment(params) {
    let storeDetails = params.map((x) => ({
      name: x.original_file_name,
      url: x.attachments,
      uploadDate: x.createdAt,
      id: x.id,
      is_scan_file: x.is_scan_file,
      file_size: x.file_size,
    }));
    const allFiles = params.map((x) => x.original_file_name).join(",");
    setAllFiles(allFiles);
    listFiles(storeDetails);
  }

  useEffect(() => {
    getTableList();
  }, [categoryData]);

  function checkFieldNameExist(category, sequence) {
    const categoryFieldName = category.map((item) => item.fieldName);
    const sequenceFieldName = sequence.map((item) => item.name);
    return categoryFieldName.every((title) =>
      sequenceFieldName.includes(title)
    );
  }

  const fetchColumns = () => {
    dispatch(
      columnFindRecord({
        id: id,
        categoryNames: category,
        category_type: "liabilities",
      })
    )
      .unwrap()
      .then((response) => {
        if (
          response &&
          response.columns_name &&
          checkFieldNameExist(categoryColumnsFilters, response.columns_name)
        ) {
          setSequence(response.columns_name);
        } else {
          changeManageColumnsFilter({
            firstTimeUserSequence: categoryColumnsFilters,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      setDuplicateFileError("");
      setDisplayFile(acceptedFiles);
      if (!validateDuplicateFileName(acceptedFiles, originalFiles)) {
        acceptedFiles.length = 0;
        return;
      }
      setIs_deleted(true);
      const emptyfileArr = [];
      acceptedFiles.forEach((file) => {
        emptyfileArr.push(file);
      });
      const inProgressData = progressUploadDetails.length
        ? progressUploadDetails.reduce((acc, item) => acc + item.size, 0)
        : 0;
      const used_space = storageData.usedSpace + inProgressData;
      const formdata = new FormData();

      dispatch(
        categoryFindRecord({
          id: categoryItemsID.current,
          category: category,
          main_category: "liabilities",
        })
      )
        .unwrap()
        .then((res) => {
          formdata.append(
            "institution",
            res[institutionColumn.liability[category]]
          );
          formdata.append("user_id", res.user_id);
          formdata.append("used_space", used_space);
          formdata.append("row_id", categoryItemsID.current);
          for (let i = 0; i < emptyfileArr.length; i++) {
            formdata.append("attachments", emptyfileArr[i]);
          }
          let newData = {
            formdata: formdata,
            category: category,
            main_category: "liabilities",
          };

          dispatch(categoryFilesUpdate(newData))
            .unwrap()
            .then(async (response) => {
              // await dispatch(get_user_storage({ id })).unwrap();
              getUserStorageFunction();
              if (response.code == 500) {
                setDisplayFile([]);
                setDuplicateFileError(`${response.message}`);
                setShowUpgradeModal(true);
                setFileModal(false);
              }
              fileAttachment(response.data);
              setOriginalFiles(response.data.map((x) => x.original_file_name));
              getTableList();
              setIs_deleted(false);
              acceptedFiles.length = 0;
              toastMessage(
                "Your file has been uploaded successfully.",
                "success"
              );
            })
            .catch((err) => {
              setIs_deleted(false);
              // toastMessage(err.message, "error");
            });
        })
        .catch((err) => {
          console.log("err:- ", err);
        });
    },
    [originalFiles, storageData]
  );

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      "application/pdf": [".pdf"],
    },
    disabled: is_deleted,
    onDropAccepted,
  });

  const removeFile = (params, row_id) => {
    setIs_deleted(true);
    setDeleteFileDisable((pre) => [...pre, row_id]);
    dispatch(
      categoryFilesRemove({
        catRowId: categoryItemId,
        row_id: row_id,
        user_id: id,
        fileName: params,
        category: category,
        main_category: "liabilities",
      })
    )
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          getUserStorageFunction();
          fileAttachment(response.data);
          setOriginalFiles(response.data.map((x) => x.original_file_name));
          setIs_deleted(false);
          getTableList();
          toastMessage("Your file has been deleted successfully.", "success");
          setDuplicateFileError("");
        } else {
          setIs_deleted(false);
        }
      })
      .catch((err) => {
        toastMessage(err.message, "error");
      });
  };

  const validateDuplicateFileName = (newFiles, originalFiles) => {
    const maxFileSize =
      process.env.REACT_APP_FILE_UPLOAD_SIZE_ALLOWED_FILE_MANAGEMENT;
    const supportedExtensions = [".pdf", ".png", ".jpg", ".jpeg"];
    let totalNewFileSize = 0;

    for (const newFile of newFiles) {
      if (!isValidFileName(newFile.name)) {
        setDuplicateFileError(
          "Special characters ([, <. , {, ^, }, %, `, ], ', \", >, ~, #, |, Non-printable ASCII characters) are not allowed in file name."
        );
        return false;
      }
      if (originalFiles.includes(newFile.name)) {
        setDuplicateFileError("A file with this name already exists");
        return false;
      }
      // Check for maximum file size
      if (newFile.size && newFile.size > maxFileSize) {
        setDuplicateFileError(
          `This file exceeds maximum file size limit (${
            maxFileSize / 1048576
          } MB)`
        );
        return false;
      }
      //check file extension
      const fileExtension = newFile.name.slice(
        ((newFile.name.lastIndexOf(".") - 1) >>> 0) + 2
      );
      const notAllowedExtensionHandling =
        fileExtension === "" ||
        fileExtension === null ||
        fileExtension === undefined;
      if (
        notAllowedExtensionHandling ||
        !supportedExtensions.includes(`.${fileExtension.toLowerCase()}`)
      ) {
        setDuplicateFileError("Format not supported");
        return false;
      }
      totalNewFileSize += newFile.size;
    }
    if (totalNewFileSize > maxFileSize) {
      setDuplicateFileError(
        `This file exceeds maximum file size limit (${
          maxFileSize / 1048576
        } MB)`
      );
      return false;
    }
    const uploadedData = storageData && storageData.usedSpace;
    const maxLimitData = storageData && storageData.maxSpace;
    if (uploadedData + totalNewFileSize > maxLimitData) {
      setShowUpgradeModal(true);
      setFileModal(false);
      setDisplayFile([]);
      setIs_deleted(false);
      return;
    }
    setDuplicateFileError("");
    return true;
  };

  const changeManageColumnsFilter = ({
    items,
    columnName,
    firstTimeUserSequence,
  }) => {
    const sequenceMap = {};
    sequence.forEach((item, index) => {
      sequenceMap[item.name] = item.isChecked;
    });
    let newSequence = [];
    if (items) {
      items.map((categoryColumn, index) => {
        newSequence.push({
          name: categoryColumn.fieldName,
          isChecked: sequenceMap[categoryColumn.fieldName],
        });
      });
    } else if (firstTimeUserSequence) {
      firstTimeUserSequence.map((categoryColumn, index) => {
        newSequence.push({ name: categoryColumn.fieldName, isChecked: true });
      });
    } else {
      newSequence = [...sequence];
    }
    if (columnName) {
      newSequence.map((categoryColumn, index) => {
        if (categoryColumn.name === columnName) {
          newSequence[index].isChecked = !newSequence[index].isChecked;
        }
      });
    }

    setSequence(newSequence);
    dispatch(
      selectColumn({
        user_id: id,
        category_name: category,
        columns_name: newSequence, //modifyColumns.length === 0 ? '' : modifyColumns.join(','),
        category_type: "liabilities",
      })
    )
      .unwrap()
      .then((response) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const OnLinkButtonClick = (id) => {
    const addressObj = liabilitiesData.find((addr) => addr.id === id);
    setLinkId({
      address:
        (addressObj.property_address &&
          JSON.parse(addressObj.property_address)) ||
        null,
      id,
    });
    setShowLinkModal(true);
  };

  const linkFunc = () => {
    getTableList();
    toastMessage("You have linked this liability successfully.", "success");
    setShowLinkModal(false);
    setLinkId({
      address: "",
      id: "",
    });
  };

  const OnUnlinkButtonClick = (id) => {
    setLinkId({
      address: "",
      id,
    });
    setShowUnlinkModal(true);
  };

  function UnlinkFunc() {
    setShowUnlinkModal(false);
    let payload = {
      id: linkId.id,
      category: category,
      main_category: "liabilities",
    };

    dispatch(categoryUnlinkRecord(payload))
      .unwrap()
      .then(() => {
        getTableList();
        toastMessage(
          "You have unlinked this liability successfully.",
          "success"
        );
        setLinkId({
          address: "",
          id: "",
        });
      })
      .catch((err) => {
        setModalShow(false);
        toastMessage(err.message, "error");
      });
  }

  const onHandleAddCategory = () => {
    setShowAddCategoryModal(true);
  };

  const onHandleClose = (key = "link", isUpdate = false) => {
    if (key === "link") {
      setShowLinkModal(false);
      setLinkId({
        address: "",
        id: "",
      });
    } else if (key === "unlink") {
      setShowUnlinkModal(false);
      setLinkId({
        address: "",
        id: "",
      });
    } else if (key === "addCategory") {
      setShowAddCategoryModal(false);
      isUpdate === true && setIsUpdateLinkedListData(!isUpdateLinkedListData);
    }
  };

  const lineData = categoryLineChartCalculation(lineChartData, "Liabilities");

  let fetchCatData = liabilitiesCategory.find((x) => x.url === category);

  // ---------------------------------------------------------------------------------------

  let columnCount = selectedColumns.length;
  const isAmortization =
    amortizationCategory.find((item, index) => item.type === category) !==
    undefined
      ? true
      : false;
  const isTransaction =
    transactionCategory.find((item, index) => item.type === category) !==
    undefined
      ? true
      : false;
  const isPortfolio =
    portfolioCategory.find((item, index) => item.type === category) !==
    undefined
      ? true
      : false;
  const isManageColumns =
    manageColumns.find(
      (item, index) => item.type === category && item.category === "liabilities"
    ) !== undefined
      ? true
      : false;
  columnCount = columnCount + 1;
  const tableFooterRow = totalBalanceColumn(columnCount);

  // -----------------------------------------------
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(categoryColumnsFilters);
    if (
      result.destination.index === items.length ||
      !items[result.destination.index].draggable
    )
      return;
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    changeManageColumnsFilter({ items: items });
    setCategoryColumnsFilters(items);
  }

  function sortDataBySequence(data, sequence) {
    const sequenceMap = {};
    sequence.forEach((item, index) => {
      sequenceMap[item.name] = index;
    });

    data.sort((a, b) => {
      const indexA = sequenceMap[a.fieldName];
      const indexB = sequenceMap[b.fieldName];
      return indexA - indexB;
    });

    return data;
  }

  return (
    <React.Fragment>
      <ManageFilesUpload
        changeStatus={manageFilesList}
        fileModal={fileModal}
        storeFiles={storeFiles}
        deletedFileDisable={deletedFileDisable}
        storeAllFiles={storeAllFiles}
        getTableList={getTableList}
        toastMessage={toastMessage}
        fileAttachment={fileAttachment}
        user_id={id}
        acceptedFiles={displayFile}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        removeFile={removeFile}
        is_deleted={is_deleted}
        category={category}
        main_category={"liabilities"}
        categoryItemId={categoryItemId}
        duplicateFileError={duplicateFileError}
        setDownloadConfirmationModalOpen={setDownloadConfirmationModalOpen}
        setIsBigFile={setIsBigFile}
        setDownloadItem={setDownloadItem}
        setCategoryItem={setCategoryItem}
        setCategoryItemId={setCategoryItemId}
      />

      {downloadConfirmationModalOpen && (
        <DownloadConfirmationModal
          downloadConfirmationModalOpen={downloadConfirmationModalOpen}
          downloadItem={downloadItem}
          categoryItem={categoryItem}
          isBigFile={isBigFile}
          closeModal={() => {
            setDownloadConfirmationModalOpen(false);
            setCategoryItem(null);
            setDownloadItem(null);
            setIsBigFile(false);
          }}
        />
      )}

      {is_loader.assets === true ||
      is_loader.liabilities === true ||
      (dataAsset &&
        dataAsset.grandTotal == "0.00" &&
        dataLiability &&
        dataLiability.grandTotal == "0.00") ? (
        <SkeletonCategoryDashboard />
      ) : (
        <section className="cs-category-main-section">
          <Container fluid>
            <>
              <div className="cs-section-topbar">
                <CapsyncSectionHeader
                  mainTitle="Liabilities"
                  subTitle={fetchCatData.title}
                  mainUrl="/individual/liabilities"
                  pagetitle={fetchCatData.title}
                  individualUserData={individualUserData}
                  getTableList={getTableList}
                />
              </div>

              <div className="category-line-chart-container">
                <Row>
                  <Col>
                    {
                      <CategoryLineChart
                        lineData={lineData}
                        categoryName="Liabilities"
                        datas={liabilitiesData}
                        isLoading={isLoader.chart}
                      />
                    }
                  </Col>
                </Row>
              </div>
              {isManageColumns && (
                <Row>
                  <Col>
                    <div className="category-manage-row">
                      <div className="cs-common-add-dropdown">
                        <div className="custom-dropdown" ref={dropdownRef}>
                          <button
                            className="default-regular-h5 xl-btn cs-btn-icon-primary"
                            onClick={() => setIsOpen(!isOpen)}
                          >
                            <span className="cs-neutral-10">
                              <CapsyncIcon title="filter-filled" size="18" />
                            </span>
                            Manage Columns
                          </button>
                          {isOpen && (
                            <div className="custom-dropdown-menu">
                              <DragDropContext onDragEnd={handleOnDragEnd}>
                                <Droppable droppableId="characters">
                                  {(provided) => (
                                    <div
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                      className="manage-columns-items"
                                    >
                                      {[
                                        ...categoryColumnsFilters,
                                        actionColumnData,
                                      ].map((filterColumn, index) => (
                                        <Draggable
                                          key={filterColumn.fieldName}
                                          draggableId={filterColumn.fieldName}
                                          index={index}
                                          isDragDisabled={
                                            !filterColumn.draggable
                                          }
                                        >
                                          {(provided) => (
                                            <li
                                              {...provided.draggableProps}
                                              ref={provided.innerRef}
                                              key={index}
                                              className="manage-column-item"
                                            >
                                              <div className="cs-form-check-box">
                                                <span
                                                  className={`${
                                                    !filterColumn.draggable
                                                      ? "cs-disabled"
                                                      : "cs-neutral-100"
                                                  }`}
                                                  {...provided.dragHandleProps}
                                                >
                                                  <CapsyncIcon
                                                    title="order-selector-filled"
                                                    size="18"
                                                  />
                                                </span>
                                                <Form.Check
                                                  inline
                                                  className={`default-light-body-text-m cs-neutral-100 ${
                                                    !filterColumn.filter &&
                                                    "cs-disabled"
                                                  }`}
                                                  label={filterColumn.title}
                                                  type="checkbox"
                                                  id={`inline-checkbox-${index}`}
                                                  disabled={
                                                    !filterColumn.filter
                                                  }
                                                  onChange={() =>
                                                    changeManageColumnsFilter({
                                                      columnName:
                                                        filterColumn.fieldName,
                                                    })
                                                  }
                                                  checked={
                                                    selectedColumns.includes(
                                                      filterColumn.fieldName
                                                    ) ||
                                                    filterColumn.fieldName ===
                                                      "action"
                                                  }
                                                />
                                              </div>
                                            </li>
                                          )}
                                        </Draggable>
                                      ))}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
              <div className="category-cs-table-container">
                <Row>
                  <Col>
                    {selectedColumns &&
                    selectedColumns.length !== 0 &&
                    liabilitiesData &&
                    liabilitiesData.length > 0 ? (
                      <Table className={`cs-table ${category}-table`}>
                        <thead className="cs-thead">
                          <tr>
                            {categoryColumnsFilters.map((itr, i) => {
                              return (
                                selectedColumns.includes(itr.fieldName) && (
                                  <th
                                    className={itr.class}
                                    key={i}
                                    width={itr.width}
                                  >
                                    {itr.title}
                                    {itr.tooltip && (
                                      <CapsyncToolTip
                                        Child={"info-outlined"}
                                        placement={"top"}
                                        message={"Adjust % as required."}
                                        type="icon"
                                        size={22}
                                      />
                                    )}
                                  </th>
                                )
                              );
                            })}

                            <th
                              className="default-medium-sub-heading-m"
                              width={284}
                            >
                              {editAccessUser && commonNames.Actions}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="cs-tbody default-regular-sub-heading-m">
                          {liabilitiesData.map(
                            (liabilitiesDisplay, indexDisplay) => {
                              return (
                                <tr key={indexDisplay}>
                                  {categoryColumnsFilters.map(
                                    (liabilitiesRowField, index) => {
                                      const { fieldType, fieldName } =
                                        liabilitiesRowField;
                                      let displayValue =
                                        liabilitiesDisplay[fieldName] === ""
                                          ? "-"
                                          : liabilitiesDisplay[fieldName];
                                      displayValue =
                                        fieldName === "apr_percentage"
                                          ? displayValue + " %"
                                          : displayValue;
                                      let linkedTooltip = "";

                                      if (
                                        liabilitiesRowField &&
                                        liabilitiesRowField.isLinkedTooltip
                                      ) {
                                        if (
                                          category ===
                                            "mortgagesonrealestate" &&
                                          liabilitiesDisplay &&
                                          liabilitiesDisplay.asset_real_estate &&
                                          liabilitiesDisplay.asset_real_estate
                                            .address
                                        ) {
                                          linkedTooltip = setAddressString(
                                            JSON.parse(
                                              liabilitiesDisplay
                                                .asset_real_estate.address
                                            )
                                          );
                                        } else if (
                                          category === "installmentsauto" &&
                                          liabilitiesDisplay &&
                                          liabilitiesDisplay.asset_automobile &&
                                          liabilitiesDisplay.asset_automobile
                                            .description
                                        ) {
                                          linkedTooltip =
                                            liabilitiesDisplay.asset_automobile
                                              .description;
                                        }
                                      }
                                      if (fieldName === "ownership") {
                                        displayValue = displayValue + "  %";
                                      }

                                      return (
                                        // <td className={fieldName === "apr_percentage" ? "text-end" : ""} width={liabilitiesRowField.width}>
                                        selectedColumns.includes(
                                          liabilitiesRowField.fieldName
                                        ) && (
                                          <td
                                            key={index}
                                            className={
                                              [
                                                "apr_percentage",
                                                "ownership",
                                              ].includes(fieldName)
                                                ? "text-end"
                                                : ""
                                            }
                                            width={liabilitiesRowField.width}
                                          >
                                            <CategoryTableData
                                              component={displayValue}
                                              type={fieldType}
                                              displayValue={displayValue}
                                              classValue="text-end"
                                              is_display_tooltip={
                                                liabilitiesDisplay.is_display_tooltip
                                              }
                                              linkedTooltip={linkedTooltip}
                                              mainCategory={"liabilities"}
                                              rowFieldIndex={index}
                                              item={liabilitiesDisplay}
                                              fieldName={fieldName}
                                              plaidErrorAccounts={
                                                plaidErrorAccounts
                                              }
                                            />
                                          </td>
                                        )
                                      );
                                    }
                                  )}
                                  <td>
                                    <div className="other-action-td">
                                      {editAccessUser && (
                                        <CategoryTableData
                                          type="action"
                                          changeStatus={(e, id) => {
                                            manageFilesList(e, id);
                                            setCategoryItemId(
                                              liabilitiesDisplay.id
                                            );
                                          }}
                                          modelData={modelData}
                                          deleteRecord={deleteRecord}
                                          item={liabilitiesDisplay}
                                          isLinkToggleVIsible={[
                                            "mortgagesonrealestate",
                                            "installmentsauto",
                                          ].includes(category)}
                                          linkType={
                                            category === "mortgagesonrealestate"
                                              ? "asset_real_estate"
                                              : "asset_automobile"
                                          }
                                          OnLinkButtonClick={OnLinkButtonClick}
                                          OnUnlinkButtonClick={
                                            OnUnlinkButtonClick
                                          }
                                          plaidErrorAccounts={
                                            plaidErrorAccounts
                                          }
                                        />
                                      )}
                                      <CategoryTableData
                                        type={"other_actions"}
                                        item={liabilitiesDisplay}
                                        main_category="liabilities"
                                        category={category}
                                        isAmortization={isAmortization}
                                        isPortfolio={isPortfolio}
                                        isTransaction={isTransaction}
                                        isTransactionEnable={true}
                                        transactionLoader={
                                          liabilitiesDisplay.is_transaction_loader
                                        }
                                        arrlength={arrlength}
                                        plaidErrorAccounts={plaidErrorAccounts}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              );
                            }
                          )}

                          <tr className="cs-tfoot-liability default-medium-sub-heading-m">
                            {tableFooterRow.map((column, index) => {
                              const setFromLast = 3;
                              const totalColumn = tableFooterRow;
                              const lastColumnIndx =
                                totalColumn.length - setFromLast === index;

                              return (
                                <td
                                  key={index}
                                  className={category ? "share-value-th" : ""}
                                >
                                  {index === 0 && commonNames.Total}
                                  {getTotalCount(
                                    category,
                                    lastColumnIndx,
                                    selectedColumns[column - 1]
                                  ) && (
                                    <CategoryTableData
                                      type="number"
                                      component={getTotalCount(
                                        category,
                                        lastColumnIndx,
                                        selectedColumns[column - 1]
                                      )}
                                    />
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        </tbody>
                      </Table>
                    ) : (
                      <span className="cs-common-no-record">
                        There are no records to display
                      </span>
                    )}

                    {/* {liabilitiesData && liabilitiesData.length == 0 && <span className="cs-common-no-record">
                      There are no records to display
                    </span>} */}
                  </Col>
                </Row>
              </div>
            </>
          </Container>
        </section>
      )}

      {showAddCategoryModal && (
        <InitialSetup3
          fetchTypeCategory={
            category === "mortgagesonrealestate"
              ? {
                  type: 17,
                  category: "Assets",
                }
              : {
                  type: 15,
                  category: "Assets",
                }
          }
          setShowEditModal={setShowAddCategoryModal}
          showModal={showAddCategoryModal}
          onHnadleClose={(type) => onHandleClose("addCategory", type)}
          linkAddressObj={linkId.address}
        />
      )}

      {showLinkModal && (
        <LinkModal
          modalShow={showLinkModal}
          usrId={id}
          category={category}
          updateCategory={
            category === "mortgagesonrealestate" ? "realestate" : "automobile"
          }
          endPointAPI={"external_manual"}
          mainCategory={"liabilities"}
          updateMainCategory={"assets"}
          displayCategory={"Liabilities"}
          toastMessag={toastMessage}
          descType={
            category === "mortgagesonrealestate" ? "address" : "description"
          }
          descDisplayType={
            category === "mortgagesonrealestate" ? "Address" : "Description"
          }
          numType={
            category === "mortgagesonrealestate"
              ? "share_market_value"
              : "market_value"
          }
          numDisplayType={
            category === "mortgagesonrealestate"
              ? "Share Market Value"
              : "Market Value"
          }
          id={linkId.id}
          idType={"id"}
          linkIdType={"real_estate_id"}
          closeModal={() => onHandleClose("link")}
          onHandleAddCategory={onHandleAddCategory}
          addLinkSuccess={linkFunc}
          isUpdateData={isUpdateLinkedListData}
        />
      )}

      <Elements stripe={stripePromise}>
        <UpgradeStorageModal
          setShowUpgradeModal={setShowUpgradeModal}
          setFileModal={setFileModal}
          showUpgradeModal={showUpgradeModal}
          showLimitModal={true}
          // onSuccess={fetchMainList}
          storage={storageData}
        />
      </Elements>

      {showUnlinkModal && (
        <Unlink
          modalShow={showUnlinkModal}
          modalFunc={() => onHandleClose("unlink")}
          removeLink={UnlinkFunc}
          tableHeader={
            categoryColumnsFilters && categoryColumnsFilters[0].title
          }
        />
      )}

      {modalShow && (
        <InitialSetup3
          fetchTypeCategory={fetchTypeCategory}
          record={record}
          // setModalShow={setModalShow}
          setShowEditModal={setModalShow}
          showModal={modalShow}
        />
      )}
    </React.Fragment>
  );
};

export default CategoryDashboard;
