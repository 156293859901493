import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { BsFillInfoCircleFill } from "react-icons/bs";

import { clearMessage } from "../../../slices/message";
import { mfaEmailAuthPin, mfaEmailAuth } from "../../../slices/auth";
import { userFindRecord } from "../../../slices/user";
import { Themeprovider } from "./File_Encryption/FileEncryption";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import "../settings/File_Encryption/file_encryption.css";
import { toast } from 'react-toastify';
import { uniValidation } from "../liabilities/categoryForms/validationSchema";

const CommonMfEmailPin = (props) => {
  const [loading, setLoading] = useState(false);
  const [resendMsg, setResendMsg] = useState();
  const [valid, setValid] = useState("");
  const [msg, setMsg] = useState("");
  const [otp, setOtp] = useState();

  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }
  const email = user && user.user.email;

  const fetchProp = useContext(Themeprovider);

  useEffect(() => {
    dispatch(clearMessage());
    setResendMsg(false);

    return () => dispatch(clearMessage());
  }, [dispatch]);

  const handleChange = (otp) => {
    setMsg("");
    setResendMsg(false);
    setOtp(otp);
    setValid("");
    dispatch(clearMessage());
  };

  const resendMFAEmail = () => {
    setOtp("");
    setValid("");
    setMsg("");
    dispatch(clearMessage());
    setLoading(true);
    dispatch(mfaEmailAuth({ email }))
      .unwrap()
      .then((data) => {
        setResendMsg(data.message);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleSubmit = () => {
    setLoading(true);
    setResendMsg(false);
    const MFAEmailOtp = otp
    if (MFAEmailOtp) {
      dispatch(mfaEmailAuthPin({ email, id, MFAEmailOtp }))
        .unwrap()
        .then((response) => {
          try {
            if (response.code !== 200) {
              setMsg("Enter a valid code");
              setLoading(false);
              return
            }

            setLoading(false);
            props.setModalShow && (props.setModalShow(false));
            props.save && (props.save());
            props.saveData && (props.saveData());
            props.setshowpass && (props.setshowpass(true));
            // if (props.forFileEncryption && props.forFileEncryption === true) {
            //   toast.success('File encryption password can be viewed');
            // }
            // setModalShow(false);
            // fetchProp.closeModal();
          } catch (error) {
            console.log(error)
          }

        })
        .catch((err) => {
          setValid("Enter a valid code");
          setLoading(false);
        });
    } else {
      setLoading(false);
      setMsg(uniValidation.otp.require);
    }
  };

  return (
    <>
      <div className="email-otp-verfication">
        {!props.notitle && <h3 className="default-semi-bold-h3">Email verification </h3>}
        <div className="text-boxes-content">
          <div className="otp-content">
            <h5 className="default-regular-h5 cs-neutral-80">
              We have sent a code to
              <span className="default-regular-h5 cs-primary">
                &nbsp;{email}
              </span>
            </h5>
            <Form className="otp-form-boxes">
              <OtpInput
                value={otp}
                onChange={handleChange}
                numInputs={6}
                renderInput={(props) => <input {...props} />}
                inputStyle="otp-txt"
                containerStyle="otp-layout"
                // isInputNum={true}
                inputType="number"
                shouldAutoFocus={true}
              />
              {msg ? (
                <span className="default-light-body-text-s cs-danger">
                  {msg}
                </span>
              ) : null}
            </Form>
          </div>

          <p className="default-regular-h5 cs-neutral-90">
            Didn't receive the code?
            <span
              onClick={resendMFAEmail}
              className="cs-primary cursor-pointer"
            >
              &nbsp;Resend
            </span>
          </p>
        </div>

        {resendMsg && (
          <div className="cs-msg default-regular-body-text-s cs-success">
            <span className="icon cs-success">
              <CapsyncIcon title="verification-outlined" size="18" />
            </span>
            <span className="txt">{resendMsg}</span>
          </div>
        )}

        {valid && (
          <div className="cs-msg default-regular-body-text-s cs-danger">
            <span className="icon">
              <CapsyncIcon title="info-filled" size="18" />
            </span>
            <span className="txt"> {valid}</span>
          </div>
        )}

        <div className="email-verification-buttons">
          <Button
            type="button"
            className="cs-btn-secondary md-btn default-regular-h5"
            onClick={() => props.setModalShow(false)}
          >
            Cancel
          </Button>
          <Button
            type="button"
            className="cs-btn-primary md-btn default-regular-h5"
            disabled={loading}
            onClick={handleSubmit}
          >
            {/* <span onClick={fetchProp.closeModal}>Verify account</span> */}
            Verify account
          </Button>
        </div>
      </div>
    </>
  );
};

export default CommonMfEmailPin;
