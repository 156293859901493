/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Container, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

// import { requestFetchCount } from "../../../slices/requests";
import Individual from "./DashboardIndvidual";
import CardComponent from "./CardComponent";
import SubscribeModal from "./SubscribeModal";
import CapsyncLoader from "../../../commonComponent/CapsyncLoader";
import CapsyncSectionHeader from "../../../commonComponent/CapsyncSectionHeader";

import IndividualTable from "../IndividualTable";
import CompanyTable from "../CompanyTable";

import {
  wealth_companies_list,
  wealth_companies_partners,
  wealth_company_remove,
  company_user_delete,
} from "../../../slices/wealthManager.slice";

import {
  recently_viewed,
  access_indiPFS_fromWealth_without_pfsid,
  add_recently,
  company_recently
} from "../../../slices/pfs_access";

import {
  add_advisors,
  requestEmailLink, user_access_permission
} from "../../../slices/requests";
import CompleteProfile from './InitialModals/CompanyProfile';
import SharePFSModal from "../Modals/SharePfsModal";
import PfsPreviewModal from "../Modals/PfsPreviewModal";
import ExportPdfExcelModal from "../Modals/ExportPdfExcelModal";
import { useNavigate } from "react-router-dom";
import SkeletonDashboard from "./SkeletonDashboard";
import AddIndividualModals from './InitialModals/AddIndividualModals'
import { access_invitation_Pfs } from "../../../slices/pfs_access";
import MicroDepositeVerificationModal from "../../../commonComponent/paymentSubscription/subscription/MicroDepositeVerificationModal";
import { socket } from "../../../config/Socket";
import { roleName } from "../../../config/commonRoleName";
import { formatFullName } from "../../../config/finance";

const Dashboard = ({ CapsyncMessage, toastMessage, changeSrcStatus, accessPFS,
  changeProfileState,
  modal,
  customImgSrc,
  wealthManagerData,
  profileState, isLoader, liftGetIndividuals, liftState }) => {
  const { user } = useSelector((state) => state.auth);
  const role = user.user_role.role

  const dispatch = useDispatch();
  const radios = [
    { name: "Individuals", value: "1" },
    { name: "Companies", value: "2" },
  ];

  const user_id = user && user.user.id;
  const user_displayName = user && formatFullName(user.user.firstName, user.user.lastName);
  const displayName = "Welcome Back," + " " + user_displayName + "!";

  const [loading, setLoading] = useState(true);
  // const [smLoader, setsmLoader] = useState(false);
  // const [showSharePFSModal, setShowSharePFSModal] = useState(false);
  // const [previewModal, setPreviewModal] = useState(false);
  // const [exportPdfExcelModal, setExportPdfExcelModal] = useState(false);
  const [radioValue, setRadioValue] = useState("1");
  // const [requestsNum, setRequestsNum] = useState(0);
  const [recentlyViewedCompanies, setRecentlyViewedCompanies] = useState([]);
  // const [companyPartners, setCompanyPartners] = useState([]);
  const [recentlyViewedIndividual, setRecentlyViewedIndividual] = useState([]);

  // const [shareEmail, setShareEmail] = useState({ email: "", sharelink: "", firstName: "", lastName: "", displayName: "", id: "" });
  // const [row_user_id, setRow_user_id] = useState(1);// doubt
  // const [useParams, setUseParams] = useState("");
  // const [compName, setCompName] = useState("");
  // const [isLoader, setIsLoader] = useState(false);
  const [filteredIndividuals, setFiltered] = useState([]);
  const [companyIndividual, setCompanyIndividual] = useState([]);

  const navigate = useNavigate()
  useEffect(() => {
    fetchRecentlyViewedIndividuals();
    fetchRecentlyViewedCompanies();
    getIndividuals()

    socket.on("get_updated_list", () => {
      fetchRecentlyViewedIndividuals();
      fetchRecentlyViewedCompanies();
    });

    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => {
      socket.off("get_updated_list", () => {
        fetchRecentlyViewedIndividuals();
        fetchRecentlyViewedCompanies();
      });
      clearTimeout(timer)
    };
  }, []);

  const fetchRecentlyViewedIndividuals = () => {

    dispatch(recently_viewed({ wm_id: user && user.user.id, module: roleName.individual }))
      .unwrap()
      .then((response) => {
        if (response.data) {
          setRecentlyViewedIndividual(response.data);

        } else {
          setRecentlyViewedIndividual("");

        }
      })
      .catch((err) => {
        toastMessage("recentlyViewed :" + err.message, "error");
      });
  };

  const getIndividuals = () => {

    dispatch(access_invitation_Pfs({
      email: user && user.user.email,
    }))
      .unwrap()
      .then((response) => {

        setCompanyIndividual(response);
        setFiltered(response);
      })
      .catch((err) => {
        toastMessage("access_invitation_Pfs :" + err.message, "error");
      });
  };



  const fetchRecentlyViewedCompanies = () => {
    // setLoader(true);
    dispatch(company_recently({ wm_id: user && user.user.id, module: "company" }))
      .unwrap()
      .then((response) => {
        setRecentlyViewedCompanies(response ? response : []);
        // setFiltered(response.fetchwealth_manager_companies);
        // setLoader(false);
      })
      .catch((err) => {
        // setLoader(false);
        toastMessage(err.message, "error");
      });
  };

  const fetchAllCompanies = () => {
    setLoading(true);
    dispatch(wealth_companies_list({ user_id: user_id }))
      .unwrap()
      .then((response) => {
        setFetchRecords(response.fetchwealth_manager_companies);
        setFiltered(response.fetchwealth_manager_companies);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        props.toastMessage(err.message, "error");
      });
  };

  useEffect(() => {
    const broadcastChannel = new BroadcastChannel("switch_account_channel");
    // broadcastChannel.onmessage = (event) => {
    //   // if (event.data === "switch_account") {
    //   //   setTimeout(() => {
    //   //     const pfs_access_comp = localStorage.getItem("pfs_access_comp")
    //   //     if (pfs_access_comp === "Individual") {
    //   //       window.location.href = '/individual'
    //   //     } else {
    //   //       window.location.href = '/financial-advisor'
    //   //     }
    //   //   }, 500);
    //   // }
    // };
    return () => {
      broadcastChannel.close();
    };
  }, []);
  const handleSwitchAccount = () => {
    const broadcastChannel = new BroadcastChannel("switch_account_channel");
    broadcastChannel.postMessage("switch_account");
  };

  const accessIndividualPFS = (params) => {

    dispatch(
      add_recently({
        recent_ids: Number(params),
        wm_id: Number(window.localStorage.getItem('id')),
        module: roleName.individual,
      })
    ).unwrap().then((response) => {
      console.log('response success:- ', response);
    }).catch((err) => {
      console.log('err:- ', err);
    })

    dispatch(user_access_permission({
      user_id: Number(params),
      advisor_id: Number(window.localStorage.getItem('id')),
      user_role: JSON.parse(window.localStorage.getItem('loginStatus')).user_role === 1 ? roleName.individual : JSON.parse(window.localStorage.getItem('loginStatus')).user_role === 2 ? roleName.financial_advisor : roleName.tax_professional
    })).unwrap()
      .then((response) => {

        if (response.access_status === 2) {
          dispatch(add_advisors({
            advisor_user_id: Number(window.localStorage.getItem('id')),
            user_id: Number(params),
            flag: role == 2 ? roleName.financial_advisor : roleName.tax_professional
          })).unwrap().then(() => {
            socket.emit('broadcast_message', { user_id: Number(params) })
            accessPFS(params, Number(window.localStorage.getItem('id')))
          }).catch((err) => {
            console.log(err);
          });
        } else {
          accessPFS(params, Number(window.localStorage.getItem('id')))
        }

      }).catch((err) => {
        console.log('err:- ', err);
      })
    handleSwitchAccount()
  };

  const handleUserChange = (event) => {
    setRadioValue(event.currentTarget.value)
  }


  return (
    <>
      <section className="dashboard-main-section">
        <Container fluid>
          {isLoader === true || (user.user.is_subscribe === false && user.user.is_beta_user === false) ? <SkeletonDashboard /> : (<>

            <div className="cs-section-topbar">
              <CapsyncSectionHeader pagetitle={displayName} />
            </div>

            <CardComponent toastMessage={toastMessage} />


            <div className="wm-tab-content">
              <div className="cs-tabs cs-primary">
                {radios.map((radio, idx) => (
                  <Button
                    className={`cs-stroke-default-tab cs-tabs-height default-regular-h5 ${radioValue === radio.value ? "cs-stroke-active" : ""
                      }`}
                    key={idx}
                    id={`radio-${idx}`}
                    type="radio"
                    variant={idx % 2 ? "manage-user-individual" : "manage-user-wealth-manager"}
                    name="radio"
                    value={radio.value}
                    checked={radioValue === radio.value}
                    onClick={(e) => handleUserChange(e)}
                  >
                    {radio.name}
                  </Button>
                ))}
              </div>
              <h6 className="default-regular-h6 cs-neutral-70">
                * Recently viewed
              </h6>
            </div>
            {radioValue === "1" ? (
              <IndividualTable
                data={recentlyViewedIndividual}
                accessIndividualPFS={accessIndividualPFS}
                CapsyncMessage={CapsyncMessage}
                toastMessage={toastMessage}
              />
            ) : (
              <CompanyTable
                forDashboard={true}
                data={recentlyViewedCompanies}
                toastMessage={toastMessage}
                accessPFS={accessPFS}
                rowStartIdx={0}
                rowsPerPage={5}
              />
            )}</>)
          }

        </Container>
      </section>

    </>
  );
};

export default Dashboard;