import React, { useEffect, useState } from "react";

import AvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import faceIcon from "../../../assets/images/other/face.png";
import {
  wm_image_update,
  wealth_managers_avtar_update,
} from "../../slices/wealthManager.slice";
import {
  Modal,
  Button,
  ModalBody,
  Image,
  Row,
  Col,
  FormLabel,
  ModalFooter,
} from "react-bootstrap";
import { userUpdateImage } from "../../slices/user";
import { backAppURl } from "../../applicationMode";
import CapsyncLoader from "../../commonComponent/CapsyncLoader";
import "../profileImage/profile_image.css";
import CapsyncIcon from "../../commonComponent/CapsyncIcon";
import Avatar from "../../commonComponent/Avatar";
import { useSelector, useDispatch } from "react-redux";
import { setAvatarState } from "./../../slices/avatar";
import SuccessBlue from "../../../assets/images/other/success-blue.svg";
import { avatarTitles } from "../../config/avatar";
import { userUpdateRecord, authUserUpdated } from "../../slices/auth";
import { toast } from "react-toastify";

const ProfileImageComponent = (props) => {
  let pfs_access_id = window.localStorage.getItem("pfs_access_id");

  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isSelectPopupVisible, setSelectPopupVisibility] = useState(false);
  const [isCropImagePopupVisible, setCropImagePopupVisibility] =
    useState(false);
  const [validationMessage, setValidationMessage] = useState(null);
  const editorRef = React.useRef(null);
  const [pencilState, setPencilState] = useState(false);
  const [initials, setInitials] = useState("");
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [isAvatarChanged, setIsAvatarChanged] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [disableCurrent, setDisableCurrent] = useState(false);

  const validateFile = (file) => {
    const maxSize = 5 * 1024 * 1024; // 5 MB
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];

    if (file.size > maxSize) {
      setValidationMessage("This file exceeds maximum file size limit (5MB)");
      return false;
    }

    if (!allowedTypes.includes(file.type)) {
      setValidationMessage("Format not supported");
      return false;
    }

    setValidationMessage(null);
    return true;
  };

  const handleDrop = (acceptedFiles) => {
    if (acceptedFiles.length !== 1) {
      setValidationMessage("Only single image upload allowed.");
      return;
    }

    const file = acceptedFiles[0];

    // Validate the file before setting it as selectedFile
    const isValidFile = validateFile(file);
    if (isValidFile) {
      setSelectedFile(file);
      setValidationMessage(null);
      setCropImagePopupVisibility(false);
      setSelectPopupVisibility(true);
    } else {
      setSelectedFile(null);
      setCropImagePopupVisibility(false);
      setSelectPopupVisibility(true);
    }
    isValidFile != false && handleSave();
  };

  const onCropSave = (acceptedFiles) => {
    dispatch(setAvatarState(null));
    setSelectedAvatar(null);
    setSelectedFile(acceptedFiles);
    const canvas = editorRef.current.getImageScaledToCanvas();
    const croppedImage = canvas.toDataURL();

    if (editorRef.current) {
      // Save the croppedImage to state or local storage as needed.
      setSelectedFile(croppedImage);
      setCropImagePopupVisibility(false);
      setSelectPopupVisibility(false);
    }
    if (user.user_role.role === 1 || pfs_access_id) {
      setPencilState(true);
      dispatch(
        userUpdateImage({
          imageData: croppedImage,
          pfs_access_id: pfs_access_id,
        })
      )
        .unwrap()
        .then((response) => {
          if (response.code === 200) {
            props.changeSrcStatus(croppedImage);
            dispatch(
              authUserUpdated({
                profileImageUrl: response.profileImageUrl
                  ? response.profileImageUrl
                  : null,
              })
            );
            props.changeProfileState(!props.profileState);
            toast.success("Your profile image has been updated successfully.");
            props.fetchRecord && props.fetchRecord();
            setPencilState(false);
          } else {
            setLoading(false);
            toast.error(response.message);
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.message);
        });
    } else if (user.user_role.role == 2 || user.user_role.role == 3) {
      setPencilState(true);
      dispatch(wm_image_update({ imageData: croppedImage }))
        .unwrap()
        .then((response) => {
          if (response.code === 200) {
            props.changeSrcStatus(croppedImage);
            dispatch(
              authUserUpdated({
                profileImageUrl: response.profileImageUrl
                  ? response.profileImageUrl
                  : null,
              })
            );
            props.changeProfileState(!props.profileState);
            toast.success("Your profile image has been updated successfully.");
            setPencilState(false);
            props.fetchRecord && props.fetchRecord();
          } else {
            setLoading(false);
            toast.error(response.message);
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.message);
        });
    }
  };

  const handleCancel = () => {
    setSelectedAvatar(null);
    setSelectPopupVisibility(false);
    setSelectedFile(null);
    setValidationMessage(null);
    setCropImagePopupVisibility(false);
  };

  const handleSave = () => {
    setCropImagePopupVisibility(true);
    setSelectPopupVisibility(false);
  };

  function changeModalState(params) {
    setValidationMessage(null);
    setIsAvatarChanged(true);
    setSelectedAvatar(user.user.profileImageUrl);
    setSelectPopupVisibility(params);
  }

  // ---------------------------------------------------------------
  useEffect(() => {
    const name = user.user.displayName;
    if (name) {
      const nameArray = name.split(" ");
      const firstName = nameArray[0];
      const lastName = nameArray[nameArray.length - 1];
      const initials = firstName.charAt(0) + lastName.charAt(0);
      setInitials(initials.toUpperCase());
    }
  }, [user.user.displayName]);

  const handleSelected = (title) => {
    setIsAvatarChanged(false);
    setSelectedAvatar(title);
    if (user.user.profileImageUrl === title) {
      setDisableCurrent(true);
    } else {
      setDisableCurrent(false);
    }
  };

  const handleSaveAvatar = (avatar) => {
    setSelectPopupVisibility(false);
    let updatedUserDetails = {
      id: user.user.id,
      profileImageUrl: avatar,
    };

    if (user.user_role.role === 1) {
      dispatch(userUpdateRecord(updatedUserDetails))
        .unwrap()
        .then((response) => {
          dispatch(setAvatarState(avatar));
          dispatch(authUserUpdated({ profileImageUrl: avatar }));
          props.fetchRecord && props.fetchRecord();
          toast.success("Your profile image has been updated successfully.");
        })
        .catch((err) => {
          toast.error(err.message);
        });
    } else {
      dispatch(wealth_managers_avtar_update(updatedUserDetails))
        .unwrap()
        .then((response) => {
          dispatch(setAvatarState(avatar));
          dispatch(authUserUpdated({ profileImageUrl: avatar }));
          props.fetchRecord && props.fetchRecord();
          toast.success("Your profile image has been updated successfully.");
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
  };

  useEffect(() => {
    if (user.user.profileImageUrl !== undefined) {
      setIsLoading(false);
    }
  }, [user.user.profileImageUrl]);

  return (
    <>
      {loading && <CapsyncLoader />}
      <div className="cs-avatar-md">
        {isLoading ? (
          <div className="setting-image-loader">
            <span className="spinner-border spinner-border-sm"></span>
          </div>
        ) : !!user.user.profileImageUrl &&
          user.user.profileImageUrl.includes("avtar") ? (
          <div
            className={` ${
              user.user.profileImageUrl === "avtar-6" ? "avatar-initials" : ""
            } `}
          >
            <Avatar
              title={user.user.profileImageUrl}
              size={user.user.initial_setup_done === true ? 96 : 160}
            />
            {user.user.profileImageUrl === "avtar-6" && (
              <div className="initials-overlay default-regular-h1 cs-neutral-80">
                {initials}{" "}
              </div>
            )}
          </div>
        ) : user.user.profileImageUrl === null ? (
          <>
            <Avatar
              title="avtar-6"
              size={user.user.initial_setup_done === true ? 96 : 160}
            />
            <div className="initials-overlay default-regular-h1 cs-neutral-80">
              {initials}
            </div>
          </>
        ) : (
          <Image
            src={
              props.customImgSrc !== ""
                ? props.customImgSrc
                : user.user.profileImageUrl
                ? `${backAppURl}/api/${
                    user.user_role.role === 1 ? "user" : "wealthmanager"
                  }/profile/${user.user.id}`
                : faceIcon
            }
            alt="Profile"
          />
        )}
        {pencilState === false && pfs_access_id === null && (
          <>
            <span
              onClick={() => changeModalState(true)}
              id="edit-image"
              className="cs-icon-badge-primary cs-neutral-10 cursor-pointer"
            >
              <CapsyncIcon title="edit-image-filled" size="18" />
            </span>
          </>
        )}
      </div>

      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="cs-md-modal"
        show={isSelectPopupVisible}
        onHide={!isSelectPopupVisible}
        className="cs-common-modal-overlay"
      >
        <Modal.Header>
          <Modal.Title className="default-semi-bold-h3">
            Edit profile picture
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <FormLabel className="default-regular-sub-heading-xs cs-neutral-100">
                Upload file
              </FormLabel>
            </Col>
          </Row>
          <div className="edit-profile-upload">
            <Row>
              <Col>
                <Dropzone onDrop={handleDrop} accept="image/jpeg, image/png">
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()} className="cs-upload-files">
                      <input {...getInputProps()} name="attachments" />
                      <span className="cs-primary">
                        <CapsyncIcon title="upload-files-filled" size="28" />
                      </span>
                      <p className="default-regular-sub-heading-s">
                        <span>Browse</span>&nbsp;or drag file here
                      </p>
                    </div>
                  )}
                </Dropzone>
              </Col>
            </Row>
            {validationMessage && (
              <span className="default-light-body-text-xs cs-danger">
                {validationMessage}
              </span>
            )}
            <p className="default-regular-body-text-s cs-neutral-60">
              Supports PNG, JPG and JPEG format, allowing images up to 5 MB in
              size.
            </p>
            <h3 className="avatar-label default-regular-body-text-m cs-neutral-100">
              Select avatar
            </h3>
            <div className="avtar-container">
              {avatarTitles.map((title, index) => (
                <div
                  className={`cs-avatar-sm avatar-circle ${
                    title === "avtar-6" ? "avatar-initials" : ""
                  } `}
                  key={title}
                  onClick={() => handleSelected(title)}
                  id={"avatars"}
                >
                  <Avatar title={title} isSelected={title === selectedAvatar} />
                  {title === "avtar-6" && (
                    <div className="initials-overlay default-regular-h5 cs-neutral-80">
                      {initials}
                    </div>
                  )}
                  {title === selectedAvatar && (
                    <Image
                      src={SuccessBlue}
                      alt="avatar success"
                      className="avatar-success-badge"
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="cs-modal-btn">
            <Button
              onClick={handleCancel}
              id="cancel-edit-image"
              variant="secondary"
              className="cs-btn-secondary md-btn default-regular-h5"
            >
              Cancel
            </Button>
            <Button
              id="save-edit-image"
              onClick={() => handleSaveAvatar(selectedAvatar)}
              disabled={isAvatarChanged || disableCurrent}
              variant="primary"
              className="cs-btn-primary md-btn default-regular-h5"
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={isCropImagePopupVisible}
        onHide={!isCropImagePopupVisible}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="cs-md-modal"
        className="cs-common-modal-overlay"
      >
        <Modal.Header>
          <Modal.Title className="default-semi-bold-h3">
            Crop Profile Picture
          </Modal.Title>
        </Modal.Header>
        <ModalBody>
          <div className="image-crop-area">
            {selectedFile && (
              <AvatarEditor
                ref={editorRef}
                image={selectedFile}
                width={300}
                height={300}
                borderRadius={300} // Set borderRadius to half of width or height for a circular shape
                color={[255, 255, 255, 0.6]} // RGBA
                scale={1.2}
                rotate={0}
              />
            )}
            {validationMessage && (
              <span className="default-light-body-text-s cs-danger">
                {validationMessage}
              </span>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="crop-profile-buttons">
            <Button
              onClick={handleCancel}
              variant="secondary"
              className="cs-btn-secondary md-btn default-regular-h6"
            >
              Cancel
            </Button>
            <Button
              disabled={!!validationMessage ? true : false}
              onClick={onCropSave}
              variant="primary"
              className="cs-btn-primary md-btn default-regular-h6"
            >
              Save
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ProfileImageComponent;
