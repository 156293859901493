import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Col, Row, Container, FormLabel } from "react-bootstrap";
import MethodIdOne from "../AllSubMethods/IndividualSubMethods/MethodIdOne";
import MethodIdTwo from "../AllSubMethods/IndividualSubMethods/MethodIdTwo";
import MethodIdThree from "../AllSubMethods/IndividualSubMethods/MethodIdThree";
import MethodIdThirtySix from "../AllSubMethods/IndividualSubMethods/MethodIdThirtySix";
import MethodIdThirteen from "../AllSubMethods/IndividualSubMethods/MethodIdThirteen";
import MethodIdFour from "../AllSubMethods/EntitySubMethods/MethodIdFour";
import MethodIdFive from "../AllSubMethods/EntitySubMethods/MethodIdFive";
import MethodIdSix from "../AllSubMethods/EntitySubMethods/MethodIdSix";
import MethodIdSeven from "../AllSubMethods/EntitySubMethods/MethodIdSeven";
import MethodIdEight from "../AllSubMethods/EntitySubMethods/MethodIdEight";
import MethodIdNine from "../AllSubMethods/EntitySubMethods/MethodIdNine";
import MethodIdTen from "../AllSubMethods/EntitySubMethods/MethodIdTen";
import MethodIdThirtySeven from "../AllSubMethods/EntitySubMethods/MethodIdThirtySeven";
import MethodIdThirtyEight from "../AllSubMethods/EntitySubMethods/MethodIdThirtyEight";
import MethodIdFourteen from "../AllSubMethods/EntitySubMethods/MethodIdFourteen";
import MethodIdTwentyThree from "../AllSubMethods/TrustSubMethods/MethodIdTwentyThree";
import MethodIdTwenty from "../AllSubMethods/TrustSubMethods/MethodIdTwenty";
import MethodIdTwentyTwo from "../AllSubMethods/TrustSubMethods/MethodIdTwentyTwo";
import MethodIdTwentyFive from "../AllSubMethods/QualifiedClientSubMethods/MethodIdTwentyFive";
import MethodIdTwentySix from "../AllSubMethods/QualifiedClientSubMethods/MethodIdTwentySix";
import MethodIdTwentySeven from "../AllSubMethods/QualifiedClientSubMethods/MethodIdTwentySeven";
import MethodIdTwentyEight from "../AllSubMethods/QualifiedClientSubMethods/MethodIdTwentyEight";
import MethodIdTwentyNine from "../AllSubMethods/QualifiedClientSubMethods/MethodIdTwentyNine";
import MethodIdThirty from "../AllSubMethods/QualifiedPurchaserSubMethods.jsx/MethodIdThirty";
import MethodIdThirtyOne from "../AllSubMethods/QualifiedPurchaserSubMethods.jsx/MethodIdThirtyOne";
import MethodIdThirtyTwo from "../AllSubMethods/QualifiedPurchaserSubMethods.jsx/MethodIdThirtyTwo";
import MethodIdThirtyThree from "../AllSubMethods/QualifiedPurchaserSubMethods.jsx/MethodIdThirtyThree";
import MethodIdThirtyFour from "../AllSubMethods/QualifiedPurchaserSubMethods.jsx/MethodIdThirtyFour";
import {
  upload_media,
  vi_findUser_by_UniqueID,
  find_legal_name,
  store_notes_value,
  vi_fetch_media,
  vi_remove_attachment,
  submit_investors_api,
  add_update_verification_methodId,
} from "../../../../../slices/verifyInvestor";
import fetchUser_byUniqueCode from "../../../../../customHooks/vi_fetchUser_byUnique";
import CommmonFileForModals from "../../../sharePfsModals/CommmonFileForModals";
import UseFetchPermission from "../../../../../customHooks/UseFetchPermission";
import PermissionPrevent from "../../../../PermissionPrevent";
// import MainDefault from "../../../../default_payments/MainDefault";
import {
  get_billing_and_subscription_by_user,
  remove_user_subscription_method,
} from "../../../../../slices/user_subscription";
import UseFetchDetails from "../../../../../customHooks/UseFetchDetails";
import { adjustLengthVi } from "../../../../../customHooks/adjustLength";
import CapsyncLoader from "../../../../../commonComponent/CapsyncLoader";
import CapsyncIcon from "../../../../../commonComponent/CapsyncIcon";
import MethodIdEleven from "../AllSubMethods/IndividualSubMethods/MethodIdEleven";
import MethodIdTwelve from "../AllSubMethods/EntitySubMethods/MethodIdTwelve";
import MethodIdThirtyFive from "../AllSubMethods/IndividualSubMethods/MethodIdThiryFive";
import { toast } from "react-toastify";
import { number } from "yup";
import AttachedDocument from "../Modals/AttachedDocument";
import SkeletonViSubMethods from "./SkeletonViSubMethods";
import ConfirmationModel from "../Modals/ConfirmationModel";
import BillingCardDetailModal from "../../../../../commonComponent/paymentSubscription/payment/BillingCardDetailModal";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentProgressBar from "../../../../../commonComponent/paymentSubscription/payment/PaymentProgressBar";
import { remove_link_from_verify_table } from "../../../../../slices/requests";
import { socket } from "../../../../../config/Socket";
import CapsyncToolTip from "../../../../../commonComponent/capsyncTooltip/CapsyncToolTip";

const ViSubMethods = (props) => {
  const CapsyncDeleteMessage = props.CapsyncDeleteMessage;
  const { fetchUserData } = UseFetchDetails();
  const [notesValue, setNotesValue] = useState("");
  const [legalName, setLegalName] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [mediaAlert, setMediaAlert] = useState("");
  const [showPfsSecondModal, setShowPfsSecondModal] = useState(false);
  const [payModal, setPayModal] = useState(false);
  const [payment_method, set_payment_method] = useState([]);
  const [formValue, setFormValue] = useState("");
  const [crdNumber, setCrdNumber] = useState("");
  const [firmName, setFirmName] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [nameAlert, setNameAlert] = useState("");
  const [showFile, setShowFile] = useState(false);
  const [showFile2, setShowFile2] = useState(false);
  const [showFile3, setShowFile3] = useState(false);
  const { fetchUserPermission } = UseFetchPermission();
  const userData = fetchUser_byUniqueCode();
  const userName =
    userData && userData.storeResponse && userData.storeResponse.legal_name;
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let store = location.pathname.split("/");
  const methodId = store[store.length - 1];
  const unique_row_id = store[store.length - 2];
  const verificationType = store[store.length - 3];
  const [data, setData] = useState("");
  const [fileList, setFileList] = useState([]);
  const [fileList2, setFileList2] = useState([]);
  const [fileList3, setFileList3] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const [openModal2, setOpenModal2] = useState(false);
  const [openModal3, setOpenModal3] = useState(false);
  const [showTrustUploadBlock, setShowTrustUploadBlock] = useState(false);
  const [is_deleted, setIs_deleted] = useState([]);
  const [showPfsLink, setShowPfsLink] = useState(false);
  const [showPfsLink2, setShowPfsLink2] = useState(false);
  const [showPfsLink3, setShowPfsLink3] = useState(false);
  const [files, setFiles] = useState([]);
  const [files2, setFiles2] = useState([]);
  const [files3, setFiles3] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [checkInvestor, setCheckInvestor] = useState(false);
  const [checkLiblity, setCheckLiblity] = useState(false);
  const [selectInverstorType, setSelectInverstorType] =
    useState("Select option");
  const [showAttachedDocument, setShowAttachedDocument] = useState(false);
  const [selectedAttachments, setSelectedAttachments] = useState([]);
  const [attachmentsDocuments, setAttachmentsDocuments] = useState([]);
  const [investorType, setInvestorType] = useState("");
  const [accreditationType, setAccreditationType] = useState("");

  const [selectInvestorError, setSelectInvestorError] = useState(false);
  const [isCheckedError, setIsCheckedError] = useState(false);
  const [checkInvestorError, setCheckInvestorError] = useState(false);
  const [checkLiblityError, setCheckLiblityError] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [uploadFileOneError, setUploadFileOneError] = useState(false);
  const [uploadFileTwoError, setUploadFileTwoError] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [accreditationSign, setAccreditationSign] = useState(false);
  const [signPfsLink, setSignPfsLink] = useState([]);
  const [showError, setShowError] = useState("");

  const [billingDetailModal, setBillingDetailModal] = useState(false);
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [showPaymentSuccessfull, setShowPaymentSuccessfull] = useState(false);
  const [showPaymentFailed, setShowPaymentFailed] = useState(false);
  const [failureReason, setFailureReason] = useState("");
  const [checkPfsLink, setCheckPfsLink] = useState(false);
  const [checkPfsStatus, setCheckPfsStatus] = useState(false);
  const [fetchedMedia, setFetchedMedia] = useState([]);
  const [submitUploadFile, setSubmitUploadFile] = useState(false);
  const [updateAttachment, setUpdateAttachment] = useState(false);
  const [userSubscriptionId, setUserSubscriptionId] = useState("");
  const [dropboxId, setDropboxId] = useState(1);
  const [showDuplicateError, setShowDuplicateError] = useState(false);
  const [showMaxfileSizeError, setShowMaxfileSizeError] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showDeleteSpinner, setShowDeleteSpinner] = useState("");
  const [fetchedPfs, setFetchedPfs] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [fileError, setFileError] = useState("");
  const [requestSent, setRequestSent] = useState("");
  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else if (user && user.user.id) {
    id = user.user.id;
  } else if (props.individualUserData && props.individualUserData.id) {
    id = props.individualUserData.id;
  }
  let role = user && user.user_role.role;
  useEffect(() => {
    if (localStorage.getItem("pfs_link") || signPfsLink) {
      setCheckPfsLink(true);
    } else {
      setCheckPfsLink(false);
    }
  }, [signPfsLink]);

  const getNumberValue = (number) => {
    setFormValue(number);
    setCheckInvestorError(false);
  };
  const getCrdNumber = (number) => {
    setCrdNumber(number);
    setCheckInvestorError(false);
  };
  const getFirmName = (name) => {
    setFirmName(name);
  };

  const getAttachment = () => {
    dispatch(
      vi_fetch_media({ unique_code: unique_row_id, vi_method_id: methodId })
    )
      .unwrap()
      .then(async (response) => {
        if (response.code === 200) {
          if (response.media_list && response.media_list.length > 0) {
            setFetchedMedia(response.media_list);
            // setFiles(response.media_list)
            // setShowFile(true)
            let upload_drop_box_id = [];
            let upload_drop_box_id2 = [];
            let upload_drop_box_id3 = [];
            response.media_list.forEach((file) => {
              if (
                file.file_name &&
                file.file_name.includes("hellosign_VI_pdf")
              ) {
                return;
              }
              if (file.upload_drop_box_id === 1) {
                upload_drop_box_id.push(file);
              } else if (file.upload_drop_box_id === 2) {
                upload_drop_box_id2.push(file);
              } else if (file.upload_drop_box_id === 3) {
                upload_drop_box_id3.push(file);
              }
            });
            const filterPfs = response.media_list.filter((file) =>
              file.original_file_name.includes("hellosign_VI_pdf")
            );
            if (upload_drop_box_id.length > 0) {
              setFiles(upload_drop_box_id);
              setShowFile(true);
            } else if (upload_drop_box_id.length === 0) {
              setFiles([]);
              setShowFile(false);
            }
            if (upload_drop_box_id2.length > 0) {
              setFiles2(upload_drop_box_id2);
              setShowFile2(true);
            } else if (upload_drop_box_id2.length === 0) {
              setFiles2([]);
              setShowFile2(false);
            }
            if (upload_drop_box_id3.length > 0) {
              setFiles3(upload_drop_box_id3);
              setShowFile3(true);
            } else if (upload_drop_box_id3.length === 0) {
              setFiles3([]);
              setShowFile3(false);
            }

            if (filterPfs.length > 0) {
              setFetchedPfs(filterPfs);
              filterPfs.forEach((file) => {
                // Check the value of upload_drop_box_id and set boolean values accordingly
                if (file.upload_drop_box_id === 1) {
                  setShowPfsLink(true);
                } else if (file.upload_drop_box_id === 2) {
                  setShowPfsLink2(true);
                } else if (file.upload_drop_box_id === 3) {
                  setShowPfsLink3(true);
                }
              });
            }
          } else if (
            response.media_list === undefined ||
            response.media_list.length === 0
          ) {
            setFiles([]);
            setFiles2([]);
            setFiles3([]);
            setShowFile(false);
            setShowFile2(false);
            setShowFile3(false);
          }
          setOpenModal(false);
          setOpenModal2(false);
          setOpenModal3(false);
          setUpdateAttachment(false);
          setShowSpinner(false);
          setTimeout(() => {
            setSubmitUploadFile(false);
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCheckbox = () => {
    setIsChecked(!isChecked);
    if (!isChecked) {
      setIsCheckedError(false);
    }
  };

  const handleCheckInvester = () => {
    setCheckInvestor(!checkInvestor);
    if (!checkInvestor) {
      setCheckInvestorError(false);
    }
  };

  const handleCheckLiblity = () => {
    setCheckLiblity(!checkLiblity);
    if (!checkLiblity) {
      setCheckLiblityError(false);
    }
  };

  const handleInvestorType = (item) => {
    setSelectInverstorType(item);
    setSelectInvestorError(false);
  };
  const handleShowAttachedDocument = () => {
    setShowAttachedDocument(true);
    setFileError("");
  };
  const handleAttachmentSubmit = () => {
    // const allfetchedAttachments = [...fileList, ...fileList2, ...fileList3, ...fetchedMedia]
    if (validateDuplicateFileName(selectedAttachments, fetchedMedia)) {
      setAttachmentsDocuments(selectedAttachments);
      setCheckPfsStatus(false);
      setShowAttachedDocument(false);
      setFileError("");
      setShowDuplicateError(false);
    } else {
      setShowAttachedDocument(false);
      setSelectedAttachments([]);
      setSelectedAttachments(attachmentsDocuments);
    }
  };
  const handleClick = () => {
    // alert("how are you?");
    // console.log("signPfsLink:- ", signPfsLink);
    // console.log('localStorage.getItem:- ',localStorage.getItem("pfs_link"));
    
    setAccreditationSign(true);
    setShowPfsSecondModal(true);

    if (!(localStorage.getItem("pfs_link") || signPfsLink)) {
      setShowPfsLink(false);
      setShowPfsLink2(false);
      setShowPfsLink3(false);
    } else if (
      showPfsLink === true &&
      (localStorage.getItem("pfs_link") || signPfsLink)
    ) {
      setShowPfsLink2(false);
      setShowPfsLink3(false);
    } else if (
      showPfsLink2 === true &&
      (localStorage.getItem("pfs_link") || signPfsLink)
    ) {
      setShowPfsLink(false);
      setShowPfsLink3(false);
    } else if (
      showPfsLink3 === true &&
      (localStorage.getItem("pfs_link") || signPfsLink)
    ) {
      setShowPfsLink(false);
      setShowPfsLink2(false);
    }
  };

  useEffect(() => {
    let values = {
      user_id:
        role === 1
          ? window.localStorage.getItem("pfs_access_id") !== null
            ? window.localStorage.getItem("pfs_access_id")
            : id
          : 0,
      wm_user_id:
        role === 2 || role === 3
          ? window.localStorage.getItem("pfs_access_id") !== null
            ? window.localStorage.getItem("pfs_access_id")
            : id
          : 0,
      filter_by: "",
    };
    dispatch(get_billing_and_subscription_by_user(values))
      .unwrap()
      .then((response) => {
        if (response.code == 200) {
          // setPaymentData(response.data.user_payment_deatil_data)
          if (response.data.transactions) {
            // setTransactionData(response.data.transactions);
            // set_subscription_plan_details(
            //     response.data.subscription_plan_details
            // );
            // set_payment_method(response.data.user_payment_deatil_data);
            // setIsPaymentByWmUser(response.data.is_payment_by_wm_user);
            // setPaymentByWmUserName(response.data.payment_by);
            // setCheckCard(!checkCard)
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const getDashboardData = () => {
    // let values = {
    //   user_id:
    //     id,
    //   wm_user_id: 0,
    //   filter_by: "", //(optional)advisor_billing, client_billing
    // };
    // dispatch(get_billing_and_subscription_by_user(values))
    //   .unwrap()
    //   .then((response) => {
    //     if (response.code === 200) {
    //       if (response.data.transactions) {
    //         set_payment_method(response.data.user_payment_deatil_data);
    //       }
    //     }
    //   })
    //   .catch((err) => {
    //     props.toastMessage(err.message, "error");
    //   });
  };
  const vifindUserbyUniqueIDCalled = useRef(false);

  const vi_findUser_by_Unique = () => {
    dispatch(vi_findUser_by_UniqueID({ unique_rowID: unique_row_id }))
      .unwrap()
      .then((response) => {
        setInvestorType(response.allData.pending[0].investor_type);
        setSignPfsLink(response.allData.single.sign_pfs_link);
        setAccreditationType(response.allData.single.verification_type);
        setUserSubscriptionId(response.allData.single.user_subscription_id);
        setRequestSent(response.allData.single.is_request_sent);
        setFileError("");

        if (!vifindUserbyUniqueIDCalled.current) {
          vifindUserbyUniqueIDCalled.current = true;
          if (
            response.allData.single.user_subscription_id !== 0 &&
            response.allData.single.is_request_sent
          ) {
            navigate("/individual/verifyinvestor/pending");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    vi_findUser_by_Unique();
  }, [unique_row_id, checkPfsLink]);

  useEffect(() => {
    getDashboardData();
    getAttachment();
  }, [methodId, !!updateAttachment]);

  const onDrop = useMemo(() => {
    const allfileList = [...fileList, ...fetchedMedia];
    const allfileList2 = [...fileList2, ...fetchedMedia];
    const allfileList3 = [...fileList3, ...fetchedMedia];
    return openModal
      ? (acceptedFiles) => {
          const dropzoneFiles = acceptedFiles.map((file) => file.name);
          if (validateDuplicateFileName(acceptedFiles, allfileList)) {
            setShowDuplicateError(false);
            setShowMaxfileSizeError(false);
            setFileError("");
            setIs_deleted(dropzoneFiles);
            setCheckPfsStatus(false);
            setFileList([...acceptedFiles, ...fileList]);
            setTimeout(() => {
              setIs_deleted([]);
            }, 1000);
            if (acceptedFiles.length > 0 && files.length === 0) {
              setShowFile(false);
            }
          }
        }
      : openModal2
      ? (acceptedFiles) => {
          const dropzoneFiles = acceptedFiles.map((file) => file.name);
          if (validateDuplicateFileName(acceptedFiles, allfileList2)) {
            setShowDuplicateError(false);
            setShowMaxfileSizeError(false);
            setFileError("");
            setFileList2([...acceptedFiles, ...fileList2]);
            setIs_deleted(dropzoneFiles);
            setCheckPfsStatus(false);
            setTimeout(() => {
              setIs_deleted([]);
            }, 1000);
            if (acceptedFiles.length > 0 && files2.length === 0) {
              setShowFile2(false);
            }
          }
        }
      : openModal3
      ? (acceptedFiles) => {
          const dropzoneFiles = acceptedFiles.map((file) => file.name);
          if (validateDuplicateFileName(acceptedFiles, allfileList3)) {
            setShowDuplicateError(false);
            setShowMaxfileSizeError(false);
            setFileError("");
            setFileList3([...acceptedFiles, ...fileList3]);
            setIs_deleted(dropzoneFiles);
            setCheckPfsStatus(false);
            setTimeout(() => {
              setIs_deleted([]);
            }, 1000);
            if (acceptedFiles.length > 0 && files3.length === 0) {
              setShowFile3(false);
            }
          }
        }
      : null;
  }, [
    openModal,
    openModal2,
    openModal3,
    fileList,
    fileList2,
    fileList3,
    is_deleted,
    showFile,
    showFile2,
    showFile3,
    attachmentsDocuments,
  ]);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    disabled: submitUploadFile || showDeleteSpinner,
  });
  const validateDuplicateFileName = (newFiles, existingFiles) => {
    const maxFileSize = 10 * 1024 * 1024; // 10MB in bytes
    const supportedExtensions = [".pdf", ".png", ".jpg", ".jpeg"];
    for (const newFile of newFiles) {
      for (const existingFile of existingFiles) {
        const existingFileNameParts = existingFile.file_name
          ? existingFile.file_name.split("/")
          : existingFile.name.split("/");
        const otherAttachmentText = existingFileNameParts[1];
        if (
          (newFile.name && newFile.name === existingFile.name) ||
          (newFile.attachment &&
            newFile.attachment === existingFile.file_name) ||
          (otherAttachmentText === "other_attachments" &&
            newFile.name === existingFile.original_file_name)
        ) {
          setShowDuplicateError(true);
          setShowMaxfileSizeError(false);
          setCheckPfsStatus(false);
          setSubmitUploadFile(false);
          setFileError("A file with this name already exists");
          return false;
        }
      }

      //check files extension
      const fileExtension = newFile.name
        ? newFile.name.slice(((newFile.name.lastIndexOf(".") - 1) >>> 0) + 2)
        : newFile.attachment
        ? newFile.attachment.slice(newFile.attachment.lastIndexOf(".") + 1)
        : newFile.lastIndexOf(".") !== -1
        ? newFile.slice(newFile.lastIndexOf(".") + 1)
        : undefined;
      const notAllowedExtensionHandling =
        (fileExtension && fileExtension === "") ||
        fileExtension === null ||
        fileExtension === undefined;
      if (
        notAllowedExtensionHandling ||
        !supportedExtensions.includes(`.${fileExtension.toLowerCase()}`)
      ) {
        setShowMaxfileSizeError(false);
        setShowDuplicateError(false);
        setCheckPfsStatus(false);
        setSubmitUploadFile(false);
        setFileError("Format not supported");
        return false;
      }
      // Check for maximum file size
      if (newFile.size && newFile.size > maxFileSize) {
        setShowMaxfileSizeError(true);
        setShowDuplicateError(false);
        setCheckPfsStatus(false);
        setSubmitUploadFile(false);
        setFileError("This file exceeds maximum file size limit (10MB)");
        return false;
      }
    }
    setFileError("");
    return true;
  };

  const fileSize = (iter) => {
    const fileInKB = (iter / 1024).toFixed(2);
    return fileInKB;
  };

  function deleteAttatchments(fileName, pfsDelete) {
    dispatch(
      vi_remove_attachment({ file_name: fileName, unique_rowId: unique_row_id })
    )
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          getAttachment();
          // setUpdateAttachment(true)
          !pfsDelete && toast.success(response.message);
          setShowDeleteSpinner("");
        } else {
          toast.error(response.message);
          setShowDeleteSpinner("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const removeItem = (index) => {
    if (typeof index === "string" && index.includes("hellosign_VI_pdf")) {
      deletePfsLink();
      // deleteAttatchments(index)
      setShowDeleteSpinner(index);
    } else if (typeof index === "string") {
      deleteAttatchments(index);
      setShowDeleteSpinner(index);
    } else {
      const fileListArr = [...fileList];
      fileListArr.splice(index, 1);
      setFileList(fileListArr);
      setShowDuplicateError(false);
      setShowMaxfileSizeError(false);
      setFileError("");
    }
  };
  const removeItem2 = (index) => {
    if (typeof index === "string" && index.includes("hellosign_VI_pdf")) {
      deletePfsLink();
      // deleteAttatchments(index)
      setShowDeleteSpinner(index);
    } else if (typeof index === "string") {
      deleteAttatchments(index);
      setShowDeleteSpinner(index);
    } else {
      const fileListArr = [...fileList2];
      fileListArr.splice(index, 1);
      setFileList2(fileListArr);
      setShowDuplicateError(false);
      setShowMaxfileSizeError(false);
      setFileError("");
    }
  };
  const removeItem3 = (index) => {
    if (typeof index === "string" && index.includes("hellosign_VI_pdf")) {
      deletePfsLink();
      // deleteAttatchments(index)
      setShowDeleteSpinner(index);
    } else if (typeof index === "string") {
      deleteAttatchments(index);
      setShowDeleteSpinner(index);
    } else {
      const fileListArr = [...fileList3];
      fileListArr.splice(index, 1);
      setFileList3(fileListArr);
      setShowDuplicateError(false);
      setShowMaxfileSizeError(false);
      setFileError("");
    }
  };

  const changeLegalName = (name) => {
    setLegalName(name);
    if (legalName.trim().length !== 0) {
      setNameAlert("");
    }
  };
  useEffect(() => {
    changeLegalName(userName);
  }, [userName]);

  const handleSubmitVI = () => {
    setDisableSubmit(true);
    // set method id on submit start
    dispatch(
      add_update_verification_methodId({
        unique_rowID: unique_row_id,
        verification_method_id: methodId,
      })
    );
    // set method id on submit end
    dispatch(
      find_legal_name({
        legal_name: legalName.trim(),
        unique_code: unique_row_id,
      })
    )
      .unwrap()
      .then((response) => {
        if (response.message === "User already exists with the same data") {
          toast.error(response.message);
          setDisableSubmit(false);
        } else {
          openPaymentModal(true);
        }
        // setLegalName(name);
      })
      .catch((err) => {
        console.log(err);
        setDisableSubmit(false);
      });
  };
  function openPaymentModal(params) {
    if (methodId === "37" || methodId === "42") {
      if (
        crdNumber.length === 7 &&
        legalName.trim().length !== 0 &&
        (attachmentsDocuments.length !== 0 ||
          (showFile && files.length > 0) ||
          (showFile2 && files2.length > 0) ||
          (showFile3 && files3.length > 0))
      ) {
        !!params && sendData();
      } else {
        if (crdNumber.length === 0) {
          setCheckInvestorError(true);
          toast.error("Enter CRD number");
        } else if (legalName.trim().length === 0) {
          setNameAlert("Enter legal name");
          // toast.error("Please enter legal name")
        } else if (
          attachmentsDocuments.length === 0 &&
          !(
            (showFile && files.length > 0) ||
            (showFile2 && files2.length > 0) ||
            (showFile3 && files3.length > 0)
          )
        ) {
          // toast.error("Please Upload the required files or Select the attached the documents")
          setUploadError(true);
        }
        setDisableSubmit(false);
      }
    } else if (
      methodId === "2" ||
      methodId === "38" ||
      methodId === "41" ||
      methodId === "18"
    ) {
      if (
        formValue.trim().length !== 0 &&
        legalName.trim().length !== 0 &&
        showFile &&
        files.length > 0 &&
        (attachmentsDocuments.length !== 0 ||
          (showFile && files.length > 0) ||
          (showFile2 && files2.length > 0) ||
          (showFile3 && files3.length > 0))
      ) {
        !!params && sendData();
      } else {
        if (legalName.trim().length === 0) {
          setNameAlert("Enter legal name");
          // toast.error("Please enter legal name")
        } else if (
          formValue.trim().length === 0 &&
          (methodId === "38" || methodId === "41")
        ) {
          setCheckInvestorError(true);
          toast.error(
            "Enter the investment name or deal name of the company of which you are a knowledgeable employee."
          );
        } else if (!(showFile && files.length > 0)) {
          setUploadFileOneError(true);
          toast.error("Upload evidence of position claimed.");
          setShowError("Upload evidence of position claimed.");
        } else if (
          formValue.trim().length === 0 &&
          (methodId === "2" || methodId === "18")
        ) {
          setCheckInvestorError(true);
          toast.error(
            "Enter the investment name or deal name of the company of which you are an officer."
          );
        } else if (
          attachmentsDocuments.length === 0 &&
          !(
            (showFile && files.length > 0) ||
            (showFile2 && files2.length > 0) ||
            (showFile3 && files3.length > 0)
          )
        ) {
          // toast.error("Please Upload the required files or Select the attached the documents")
          setUploadError(true);
        }
        setDisableSubmit(false);
      }
    } else if (methodId === "3" || methodId === "19") {
      if (
        (isChecked === true || (showFile && files.length > 0)) &&
        legalName.trim().length !== 0 &&
        props.dataAsset.grandTotal - props.dataLiability.grandTotal >=
          1000000 &&
        (attachmentsDocuments.length !== 0 ||
          (showFile && files.length > 0) ||
          (showFile2 && files2.length > 0) ||
          (showFile3 && files3.length > 0))
      ) {
        !!params && sendData();
      } else {
        if (legalName.trim().length === 0) {
          setNameAlert("Enter legal name");
          // toast.error("Please enter legal name")
        } else if (!(isChecked || (showFile && files.length > 0))) {
          setIsCheckedError(true);
          // toast.error("Upload a recent credit report, or certify that no credit report is available.")
        } else if (
          !(
            props.dataAsset.grandTotal - props.dataLiability.grandTotal >=
            1000000
          )
        ) {
          setUploadFileOneError(true);
          toast.error(
            "Net worth for this verification purpose should be more than $1,000,000 USD."
          );
          setShowError(
            "Net worth for this verification purpose should be more than $1,000,000 USD."
          );
        } else if (
          attachmentsDocuments.length === 0 &&
          !(
            (showFile && files.length > 0) ||
            (showFile2 && files2.length > 0) ||
            (showFile3 && files3.length > 0)
          )
        ) {
          // toast.error("Please Upload the required files or Select the attached the documents")
          setUploadError(true);
        }
        setDisableSubmit(false);
      }
    } else if (methodId === "1" || methodId === "17") {
      if (
        isChecked === true &&
        legalName.trim().length !== 0 &&
        ((showFile && files.length > 0) ||
          (showFile2 && files2.length > 0) ||
          (showFile3 && files3.length > 0)) &&
        (attachmentsDocuments.length !== 0 ||
          (showFile && files.length > 0) ||
          (showFile2 && files2.length > 0) ||
          (showFile3 && files3.length > 0))
      ) {
        !!params && sendData();
      } else {
        if (legalName.trim().length === 0) {
          setNameAlert("Enter legal name");
          // toast.error("Please enter legal name")
        } else if (!isChecked) {
          setIsCheckedError(true);
          toast.error(
            "The law governing this type of verification requires the investor to have a reasonable expectation of the same income level in the current year."
          );
        } else if (
          !(showFile && files.length > 0) ||
          !(showFile2 && files2.length > 0) ||
          !(showFile3 && files3.length > 0)
        ) {
          toast.error(
            "Add recent W-2 forms, K-1 forms, 1099 forms, or alternative evidence."
          );
          setUploadFileOneError(true);
          setShowError(
            "Add recent W-2 forms, K-1 forms, 1099 forms, or alternative evidence."
          );
        } else if (
          attachmentsDocuments.length === 0 &&
          !(
            (showFile && files.length > 0) ||
            (showFile2 && files2.length > 0) ||
            (showFile3 && files3.length > 0)
          )
        ) {
          setUploadError(true);
          // toast.error("Please Upload the required files or Select the attached the documents")
          setShowError(
            "Select the attached document or upload the required files"
          );
        }
        setDisableSubmit(false);
      }
    } else if (methodId === "9" || methodId === "24") {
      if (
        showFile &&
        files.length > 0 &&
        showFile2 &&
        files2.length > 0 &&
        legalName.trim().length !== 0 &&
        (attachmentsDocuments.length !== 0 ||
          (showFile && files.length > 0) ||
          (showFile2 && files2.length > 0) ||
          (showFile3 && files3.length > 0))
      ) {
        !!params && sendData();
      } else {
        if (legalName.trim().length === 0) {
          setNameAlert("Enter legal namee");
          // toast.error("Please enter legal name")
        } else if (
          attachmentsDocuments.length === 0 &&
          !(
            (showFile && files.length > 0) ||
            (showFile2 && files2.length > 0) ||
            (showFile3 && files3.length > 0)
          )
        ) {
          // toast.error("Please Upload the required files or Select the attached the documents")
          setShowError(
            "Select the attached document or upload the required files"
          );
          setUploadError(true);
        } else if (!(showFile && files.length > 0)) {
          setUploadFileOneError(true);
          toast.error("Upload officer's certificate.");
          setShowError("Upload officer's certificate.");
        } else if (!(showFile2 && files2.length > 0)) {
          setUploadFileTwoError(true);
          toast.error("Upload verification letters for each entity owner.");
          setShowError("Upload verification letters for each entity owner.");
        }
        setDisableSubmit(false);
      }
    } else if (methodId === "32" || methodId === "30" || methodId === "31") {
      if (
        showFile &&
        files.length > 0 &&
        legalName.trim().length !== 0 &&
        (attachmentsDocuments.length !== 0 ||
          (showFile && files.length > 0) ||
          (showFile2 && files2.length > 0) ||
          (showFile3 && files3.length > 0))
      ) {
        !!params && sendData();
      } else {
        if (legalName.trim().length === 0) {
          setNameAlert("Enter legal name");
          // toast.error("Please enter legal name")
        } else if (!(showFile && files.length > 0)) {
          if (methodId === "32") {
            setUploadError(true);
            toast.error("Upload proof of fund ownership");
            setShowError("Upload proof of fund ownership");
          } else if (methodId === "30") {
            setUploadError(true);
            toast.error(
              "Upload a plan statement, plan fiduciary, or officer's certificate."
            );
            setShowError(
              "Upload a plan statement, plan fiduciary, or officer's certificate."
            );
          } else if (methodId === "31") {
            setUploadError(true);
            toast.error("Upload evidence of position claimed.");
            setShowError("Upload evidence of position claimed.");
          }
        } else if (
          attachmentsDocuments.length === 0 &&
          !(
            (showFile && files.length > 0) ||
            (showFile2 && files2.length > 0) ||
            (showFile3 && files3.length > 0)
          )
        ) {
          // toast.error("Please Upload the required files or Select the attached the documents")
        }
        setDisableSubmit(false);
      }
    } else if (methodId === "33" || methodId === "34") {
      if (
        showFile &&
        files.length > 0 &&
        legalName.trim().length !== 0 &&
        isChecked &&
        (attachmentsDocuments.length !== 0 ||
          (showFile && files.length > 0) ||
          (showFile2 && files2.length > 0) ||
          (showFile3 && files3.length > 0))
      ) {
        !!params && sendData();
      } else {
        if (legalName.trim().length === 0) {
          setNameAlert("Enter legal name");
          // toast.error("Please enter legal name")
        } else if (!isChecked) {
          setIsCheckedError(true);
          toast.error(
            " Please check the box below to make the representation that the investor meets the requirements indicated."
          );
        } else if (!(showFile && files.length > 0)) {
          if (methodId === "33") {
            setUploadError(true);
            toast.error("Upload proof of business ownership.");
            setShowError("Upload proof of business ownership.");
          } else if (methodId === "34") {
            setUploadError(true);
            setShowError("Upload a qualified purchaser certificate.");
            toast.error("Upload a qualified purchaser certificate.");
          }
        } else if (
          attachmentsDocuments.length === 0 &&
          !(
            (showFile && files.length > 0) ||
            (showFile2 && files2.length > 0) ||
            (showFile3 && files3.length > 0)
          )
        ) {
          // toast.error("Please Upload the required files or Select the attached the documents")
        }
        setDisableSubmit(false);
      }
    } else if (methodId === "35" || methodId === "27" || methodId === "29") {
      if (
        showFile &&
        files.length > 0 &&
        legalName.trim().length !== 0 &&
        selectInverstorType === "Individual" &&
        (attachmentsDocuments.length !== 0 ||
          (showFile && files.length > 0) ||
          (showFile2 && files2.length > 0) ||
          (showFile3 && files3.length > 0))
      ) {
        !!params && sendData();
      } else if (
        showFile &&
        files.length > 0 &&
        legalName.trim().length !== 0 &&
        selectInverstorType === "Company" &&
        isChecked &&
        (attachmentsDocuments.length !== 0 ||
          (showFile && files.length > 0) ||
          (showFile2 && files2.length > 0) ||
          (showFile3 && files3.length > 0))
      ) {
        !!params && sendData();
      } else {
        if (legalName.trim().length === 0) {
          setNameAlert("Enter legal name");
          // toast.error("Please enter legal name")
        } else if (selectInverstorType === "Select option") {
          setSelectInvestorError(true);
          toast.error(
            "Select an option whether you are investing as an individual or a company"
          );
        } else if (selectInverstorType === "Company" && !isChecked) {
          setIsCheckedError(true);
          toast.error(
            " Please check the box below to make the representation that the investor meets the requirements indicated."
          );
        } else if (methodId === "35" && !(showFile && files.length > 0)) {
          setUploadError(true);
          toast.error("Upload proof of investments.");
          setShowError("Upload proof of investments.");
        } else if (methodId === "27" && !(showFile && files.length > 0)) {
          setUploadError(true);
          toast.error("Upload proof of fund ownership");
          setShowError("Upload proof of fund ownership");
        } else if (methodId === "29" && !(showFile && files.length > 0)) {
          setUploadError(true);
          toast.error("Upload a qualified purchaser certificate.");
          setShowError("Upload a qualified purchaser certificate.");
        } else if (
          attachmentsDocuments.length === 0 &&
          !(
            (showFile && files.length > 0) ||
            (showFile2 && files2.length > 0) ||
            (showFile3 && files3.length > 0)
          )
        ) {
          setUploadError(true);
          // toast.error("Please Upload the required files or Select the attached the documents")
          setShowError(
            "Select the attached document or upload the required files"
          );
        }
        setDisableSubmit(false);
      }
    } else if (methodId === "36") {
      if (
        showFile &&
        files.length > 0 &&
        showFile2 &&
        files2.length > 0 &&
        legalName.trim().length !== 0 &&
        (attachmentsDocuments.length !== 0 ||
          (showFile && files.length > 0) ||
          (showFile2 && files2.length > 0) ||
          (showFile3 && files3.length > 0))
      ) {
        !!params && sendData();
      } else {
        if (legalName.trim().length === 0) {
          setNameAlert("Enter legal name");
          // toast.error("Please enter legal name")
        } else if (!(showFile && files.length > 0)) {
          setUploadFileOneError(true);
          toast.error(
            "Upload evidence of who all the beneficial owners of the entity are"
          );
          setShowError(
            "Upload evidence of who all the beneficial owners of the entity are"
          );
        } else if (!(showFile2 && files2.length > 0)) {
          setUploadFileTwoError(true);
          toast.error(
            "Upload a qualified purchaser certificate for each entity owner."
          );
          setShowError(
            "Upload a qualified purchaser certificate for each entity owner."
          );
        } else if (
          attachmentsDocuments.length === 0 &&
          !(
            (showFile && files.length > 0) ||
            (showFile2 && files2.length > 0) ||
            (showFile3 && files3.length > 0)
          )
        ) {
          // toast.error("Please Upload the required files or Select the attached the documents")
        }
        setDisableSubmit(false);
      }
    } else if (methodId === "28") {
      if (
        legalName.trim().length !== 0 &&
        props.dataAsset.grandTotal - props.dataLiability.grandTotal >=
          2100000 &&
        selectInverstorType === "Individual" &&
        ((showFile && files.length > 0) || isChecked) &&
        (attachmentsDocuments.length !== 0 ||
          (showFile && files.length > 0) ||
          (showFile2 && files2.length > 0) ||
          (showFile3 && files3.length > 0))
      ) {
        !!params && sendData();
      } else if (
        legalName.trim().length !== 0 &&
        props.dataAsset.grandTotal - props.dataLiability.grandTotal >=
          2100000 &&
        selectInverstorType === "Company" &&
        checkInvestor &&
        checkLiblity &&
        (attachmentsDocuments.length !== 0 ||
          (showFile && files.length > 0) ||
          (showFile2 && files2.length > 0) ||
          (showFile3 && files3.length > 0))
      ) {
        !!params && sendData();
      } else {
        if (legalName.trim().length === 0) {
          setNameAlert("Enter legal name");
          // toast.error("Please enter legal name")
        } else if (
          !(
            props.dataAsset.grandTotal - props.dataLiability.grandTotal >=
            2100000
          )
        ) {
          setUploadFileOneError(true);
          setShowError(
            "Your net worth for this verification purposes should exceed $2,100,000 USD, which is the required amount to meet the qualified client threshold.  "
          );
          toast.error(
            "Your net worth for this verification purposes should exceed $2,100,000 USD, which is the required amount to meet the qualified client threshold.  "
          );
        } else if (selectInverstorType === "Select option") {
          setSelectInvestorError(true);
          toast.error(
            "Select an option whether you are investing as an individual or a company"
          );
        } else if (
          selectInverstorType === "Individual" &&
          !((showFile && files.length > 0) || isChecked)
        ) {
          setIsCheckedError(true);
          // toast.error("Upload a recent credit report, or certify that no credit report is available.")
        } else if (selectInverstorType === "Company" && !checkInvestor) {
          setCheckInvestorError(true);
          toast.error(
            "Please check the box below to make the representation that the investor meets the requirements indicated."
          );
        } else if (selectInverstorType === "Company" && !checkLiblity) {
          setCheckLiblityError(true);
          // toast.error("Check the box to indicate that you have disclosed all outsanding liabilities of the entity.")
        } else if (
          attachmentsDocuments.length === 0 &&
          !(
            (showFile && files.length > 0) ||
            (showFile2 && files2.length > 0) ||
            (showFile3 && files3.length > 0)
          )
        ) {
          setUploadError(true);
          // toast.error("Please Upload the required files or Select the attached the documents")
        }
        setDisableSubmit(false);
      }
    }

    //No special validation except legal name and files
    else if (
      legalName.trim().length !== 0 &&
      ((showPfsLink && (localStorage.getItem("pfs_link") || signPfsLink)) ||
        (showPfsLink2 && (localStorage.getItem("pfs_link") || signPfsLink)) ||
        (showPfsLink3 && (localStorage.getItem("pfs_link") || signPfsLink))) &&
      (attachmentsDocuments.length !== 0 ||
        (showFile && files.length > 0) ||
        (showFile2 && files2.length > 0) ||
        (showFile3 && files3.length > 0))
    ) {
      !!params && sendData();
    } else {
      if (legalName.trim().length === 0) {
        setNameAlert("Enter legal name");
        // toast.error("Please enter legal name")
      } else if (
        attachmentsDocuments.length === 0 &&
        !(
          (showFile && files.length > 0) ||
          (showFile2 && files2.length > 0) ||
          (showFile3 && files3.length > 0)
        )
      ) {
        // toast.error("Please Upload the required files or Select the attached the documents")
        setUploadError(true);
      }
      setDisableSubmit(false);
    }
  }
  const fileUpload = [...fileList, ...attachmentsDocuments];
  const fileUpload2 = [...fileList2, ...attachmentsDocuments];
  const fileUpload3 = [...fileList3, ...attachmentsDocuments];
  const checkMedia = () => {
    setDropboxId(1);
    if (
      (localStorage.getItem("pfs_link") || signPfsLink) &&
      (fileList.length !== 0 || attachmentsDocuments.length !== 0)
    ) {
      console.log("if condition");
      if (validateDuplicateFileName(fileUpload, fetchedMedia)) {
        setShowFile(true);
        setSubmitUploadFile(true);
        setShowSpinner(true);
        setMediaAlert("");
        setUploadError(false);
        setUploadFileOneError(false);
        setFileError("");
      }
    } else if (
      (localStorage.getItem("pfs_link") || signPfsLink) &&
      attachmentsDocuments.length !== 0
    ) {
      console.log("first else if condition");
      setUploadError(false);
      setMediaAlert("");
      setSubmitUploadFile(true);
    } else if (!(fileList.length !== 0 && attachmentsDocuments.length !== 0)) {
      if (files.length > 0) {
        if (signPfsLink) {
          setShowPfsLink(true);
        }
        setOpenModal(false);
      }
      else {
        setCheckPfsStatus(true);
        setShowMaxfileSizeError(false);
      }


      // toast.error("Please either Upload the required files or Select the attached documents")
    } else if (fileList.length === 0 && files.length === 0) {
      console.log("last else if condition");
      setShowFile(false);
    }
  };

  const checkMedia2 = () => {
    setDropboxId(2);
    if (
      signPfsLink &&
      (fileList2.length !== 0 || attachmentsDocuments.length !== 0)
    ) {
      if (validateDuplicateFileName(fileUpload2, fetchedMedia)) {
        setShowFile2(true);
        setMediaAlert("");
        setCheckPfsStatus(false);
        setSubmitUploadFile(true);
        setShowSpinner(true);
        setFileError("");
      }
    }

    if (fileList2.length !== 0) {
      setUploadError(false);
      setUploadFileTwoError(false);
    }

    if (fileList2.length === 0 && attachmentsDocuments.length === 0) {
      setCheckPfsStatus(true);
      setShowMaxfileSizeError(false);
      setFileError("");
    }
  };
  const checkMedia3 = () => {
    setDropboxId(3);
    if (
      signPfsLink &&
      (fileList3.length !== 0 || attachmentsDocuments.length !== 0)
    ) {
      if (validateDuplicateFileName(fileUpload3, fetchedMedia)) {
        setShowFile3(true);
        setMediaAlert("");
        setCheckPfsStatus(false);
        setSubmitUploadFile(true);
        setShowSpinner(true);
        setFileError("");
      }
    }
    if (fileList3.length !== 0) {
      setUploadError(false);
    }
    if (fileList3.length === 0 && attachmentsDocuments.length === 0) {
      setCheckPfsStatus(true);
      setShowMaxfileSizeError(false);
      setFileError("");
    }
  };

  useEffect(() => {
    if (
      !(showDuplicateError || checkPfsStatus) &&
      (fileList.length > 0 ||
        fileList2.length > 0 ||
        fileList3.length > 0 ||
        attachmentsDocuments.length > 0) &&
      fileError === ""
    ) {
      submitAttachment();
    } else {
      setShowSpinner(false);
    }
  }, [submitUploadFile]);

  const sendData = () => {
    if (
      acceptedFiles.length === 0 &&
      !(localStorage.getItem("pfs_link") || signPfsLink)
    ) {
      setMediaAlert("Please select attachment or link your PFS to procced");
    } else {
      setPaymentLoader(true);
      return submitMedia();
    }
  };
  const submitAttachment = async () => {
    const formdata = new FormData();
    formdata.append("user_id", id);
    let all_details = {
      unique_code: store[5],
      // notesValue: notesValTxt,
      // legalName: legalName,
      pfsLink: fetchedPfs.length > 0 ? "" : signPfsLink,
      user_id: id,
    };

    if (dropboxId === 1) {
      if (fileList.length >= 1) {
        for (let i = 0; i < fileList.length; i++) {
          formdata.append("attachments", fileList[i]);
        }
      }
    }

    if (dropboxId === 2) {
      if (fileList2.length >= 1) {
        for (let i = 0; i < fileList2.length; i++) {
          formdata.append("attachments", fileList2[i]);
        }
      }
    }
    if (dropboxId === 3) {
      if (fileList3.length >= 1) {
        for (let i = 0; i < fileList3.length; i++) {
          formdata.append("attachments", fileList3[i]);
        }
      }
    }

    if (attachmentsDocuments.length >= 1) {
      formdata.append(
        "attached_documents",
        JSON.stringify(attachmentsDocuments)
      );
    }

    formdata.append("all_details", JSON.stringify(all_details));
    formdata.append("drop_box_id", dropboxId);
    formdata.append("vi_method_id", methodId);
    const responseData = await dispatch(
      upload_media({ formdata, drop_box_id: dropboxId })
    );
    if (responseData.payload !== undefined) {
      if (
        responseData.payload.code == 200 &&
        responseData.payload.status == "Success"
      ) {
        setFileList([]);
        setFileList2([]);
        setFileList3([]);
        setAttachmentsDocuments([]);
        setSelectedAttachments([]);
        getAttachment();
        toast.success(responseData.payload.message);
        setShowSpinner(false);
        setShowDuplicateError(false);
        setShowMaxfileSizeError(false);
        setUploadFileOneError(false);
        setUploadFileTwoError(false);
        setIsCheckedError(false);
        setFileError("");

        return responseData;
      } else if (responseData.payload.code === 500) {
        setFileError(
          responseData.payload.message.includes(
            "Duplicate original_file_names found"
          )
            ? "A file with this name already exists"
            : responseData.payload.message
        );
        setShowSpinner(false);
        setSubmitUploadFile(false);
        setSelectedAttachments([]);
        setAttachmentsDocuments([]);
      } else {
        setShowDuplicateError(true);
        setSubmitUploadFile(false);
      }
    } else {
      // setSubmitUploadFile(false)
    }
  };

  const submitMedia = async () => {
    let notesValTxt = "";
    notesValTxt = notesValue ? "Note:" + notesValue + ", " : "";
    notesValTxt += `legalName: ${legalName}, `;
    if (store[6] === "1") {
      if (formValue) {
        notesValTxt += `The amount of income I(we) received for the previous year is: $${formValue},  `;
      }
    }
    if (store[6] === "2") {
      if (formValue) {
        notesValTxt += `The investment name or deal name of the company where I am an officer is: ${formValue},  `;
      }
    }
    if (store[6] === "38") {
      if (formValue) {
        notesValTxt += `The investment name or deal name of the company where I am knowledgeable employee is: ${formValue},  `;
      }
    }
    if (store[6] === "37" || store[6] === "42") {
      if (formValue) {
        notesValTxt += `Name : ${formValue},  `;
      }
      if (crdNumber) {
        notesValTxt += `CRD Number : ${crdNumber},  `;
      }
      if (firmName) {
        notesValTxt += `Firm name : ${firmName},  `;
      }
    }
    if (investorType.length !== 0) {
      notesValTxt += `Investor Type :  ${investorType},  `;
    }
    if (
      store[6] === "27" ||
      store[6] === "28" ||
      store[6] === "29" ||
      store[6] === "35"
    ) {
      if (selectInverstorType !== "Select option") {
        notesValTxt += `The investor is investing as :  ${selectInverstorType},  `;
      }
      if (isChecked && (store[6] === "27" || store[6] === "29")) {
        notesValTxt += `Declaration: Investor Accepts that it is not an investment company and is not an entity that would be an investment company but for the exemption offered under Section 3(c)(1) of the Investment Company Act.  `;
      }
      if (checkInvestor && store[6] === "28") {
        notesValTxt += `Declaration: Investor Accepts that it is not an investment company and is not an entity that would be an investment company but for the exemption offered under Section 3(c)(1) of the Investment Company Act.  `;
      }
      if (isChecked && (store[6] === "35" || store[6] === "28")) {
        notesValTxt += `Declaration: ${legalName} certifies that the investor meets the requirements indicated. `;
      }
      if (checkLiblity) {
        notesValTxt += `Declaration: ${legalName} declares that all outstanding liabilities of the entity have been disclosed. `;
      }
    }
    if (store[6] === "3" || store[6] === "19") {
      if (isChecked) {
        notesValTxt += `Declaration: ${legalName} certify that no credit report is available. `;
      }
    }
    if (store[6] === "33" || store[6] === "34") {
      if (isChecked) {
        notesValTxt += `Declaration:  ${legalName} certifies that the investor meets the requirements indicated. `;
      }
    }
    await dispatch(
      store_notes_value({
        notes_value: notesValTxt,
        unique_rowId: unique_row_id,
      })
    )
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          setDisableSubmit(false);
          if (userSubscriptionId !== 0) {
            setPaymentLoader(false);
            setPaymentLoader(true);
            submit_investors();
          } else {
            setBillingDetailModal(true);
            setPaymentLoader(false);
            setNameAlert("");
          }
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        console.log("error:", error);
      });
  };

  const submit_investors = () => {
    dispatch(submit_investors_api({ unique_rowId: unique_row_id, user_id: id }))
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          setPaymentLoader(false);
          toast.success(response.message);
          navigate("/individual/verifyinvestor/pending");
        }
      })
      .catch((err) => {
        console.log(err);
        setPaymentLoader(false);
      });
  };

  let latestPaymentStatus = null;
  useEffect(() => {
    socket.on("accreditation_payment_status", (payment) => {
      setTimeout(() => {
        if (payment.status === true) {
          if (payment.unique_rowId === unique_row_id) {
            latestPaymentStatus = payment.vi_status;
            toast.success(latestPaymentStatus);
            setShowPaymentSuccessfull(true);
            setBillingDetailModal(false);
            setPaymentLoader(false);
            setFormValue("");
            setNotesValue("");
            setCrdNumber("");
            setFirmName("");
            setSignPfsLink([]);
          }
        } else {
          setPaymentLoader(false);
          setShowPaymentFailed(true);
          latestPaymentStatus = payment.vi_status;
          setFailureReason(latestPaymentStatus);
        }
      }, 1000);
    });
    return () => {
      socket.off("accreditation_payment_status");
    };
  }, []);

  function chooseComponent() {
    if (methodId === "13") {
      return (
        <MethodIdEleven
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          setNotesValue={setNotesValue}
          getNumberValue={getNumberValue}
          formValue={formValue}
          nameAlert={nameAlert}
          unique_code={unique_row_id}
          verificationType={verificationType}
          is_deleted={is_deleted}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          showFile={showFile}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showPfsLink2={showPfsLink2}
          showPfsLink3={showPfsLink3}
          uploadError={uploadError}
          is_loader={props.is_loader}
          fetchedMedia={fetchedMedia}
          showDeleteSpinner={showDeleteSpinner}
        />
      );
    } else if (methodId === "1") {
      return (
        <MethodIdOne
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          files2={files2}
          deleteFile2={removeItem2}
          setOpenModal2={setOpenModal2}
          files3={files3}
          deleteFile3={removeItem3}
          setOpenModal3={setOpenModal3}
          setNotesValue={setNotesValue}
          getNumberValue={getNumberValue}
          formValue={formValue}
          nameAlert={nameAlert}
          unique_code={unique_row_id}
          verificationType={verificationType}
          is_deleted={is_deleted}
          showFile={showFile}
          showFile2={showFile2}
          showFile3={showFile3}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showPfsLink2={showPfsLink2}
          showPfsLink3={showPfsLink3}
          isChecked={isChecked}
          handleCheckbox={handleCheckbox}
          isCheckedError={isCheckedError}
          uploadError={uploadError}
          is_loader={props.is_loader}
          showError={showError}
          uploadFileOneError={uploadFileOneError}
          fetchedMedia={fetchedMedia}
          showDeleteSpinner={showDeleteSpinner}
        />
      );
    } else if (methodId === "17") {
      return (
        <MethodIdOne
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          files2={files2}
          deleteFile2={removeItem2}
          setOpenModal2={setOpenModal2}
          setShowTrustUploadBlock={setShowTrustUploadBlock}
          files3={files3}
          deleteFile3={removeItem3}
          setOpenModal3={setOpenModal3}
          setNotesValue={setNotesValue}
          getNumberValue={getNumberValue}
          formValue={formValue}
          nameAlert={nameAlert}
          unique_code={unique_row_id}
          verificationType={verificationType}
          is_deleted={is_deleted}
          showFile={showFile}
          showFile2={showFile2}
          showFile3={showFile3}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showPfsLink2={showPfsLink2}
          showPfsLink3={showPfsLink3}
          isChecked={isChecked}
          handleCheckbox={handleCheckbox}
          isCheckedError={isCheckedError}
          uploadError={uploadError}
          is_loader={props.is_loader}
          showError={showError}
          uploadFileOneError={uploadFileOneError}
          fetchedMedia={fetchedMedia}
          showDeleteSpinner={showDeleteSpinner}
        />
      );
    } else if (methodId === "2") {
      return (
        <MethodIdTwo
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          setNotesValue={setNotesValue}
          getNumberValue={getNumberValue}
          formValue={formValue}
          nameAlert={nameAlert}
          unique_code={unique_row_id}
          verificationType={verificationType}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showFile={showFile}
          checkInvestorError={checkInvestorError}
          uploadError={uploadError}
          uploadFileOneError={uploadFileOneError}
          is_loader={props.is_loader}
          showDeleteSpinner={showDeleteSpinner}
          showError={showError}
        />
      );
    } else if (methodId === "18") {
      return (
        <MethodIdTwo
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          files2={files2}
          deleteFile2={removeItem2}
          setOpenModal2={setOpenModal2}
          setShowTrustUploadBlock={setShowTrustUploadBlock}
          setNotesValue={setNotesValue}
          getNumberValue={getNumberValue}
          formValue={formValue}
          nameAlert={nameAlert}
          unique_code={unique_row_id}
          verificationType={verificationType}
          showFile={showFile}
          showFile2={showFile2}
          showFile3={showFile3}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showPfsLink2={showPfsLink2}
          checkInvestorError={checkInvestorError}
          uploadError={uploadError}
          uploadFileOneError={uploadFileOneError}
          is_loader={props.is_loader}
          showDeleteSpinner={showDeleteSpinner}
          showError={showError}
        />
      );
    } else if (methodId === "3") {
      return (
        <MethodIdThree
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          setNotesValue={setNotesValue}
          nameAlert={nameAlert}
          dataAssetTotal={props.dataAsset.grandTotal}
          dataLiabilityTotal={props.dataLiability.grandTotal}
          unique_code={unique_row_id}
          verificationType={verificationType}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showFile={showFile}
          isChecked={isChecked}
          handleCheckbox={handleCheckbox}
          isCheckedError={isCheckedError}
          uploadError={uploadError}
          is_loader={props.is_loader}
          uploadFileOneError={uploadFileOneError}
          showError={showError}
          showDeleteSpinner={showDeleteSpinner}
        />
      );
    } else if (methodId === "19") {
      return (
        <MethodIdThree
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          files2={files2}
          deleteFile2={removeItem2}
          setOpenModal2={setOpenModal2}
          setShowTrustUploadBlock={setShowTrustUploadBlock}
          setNotesValue={setNotesValue}
          nameAlert={nameAlert}
          dataAssetTotal={props.dataAsset.grandTotal}
          dataLiabilityTotal={props.dataLiability.grandTotal}
          unique_code={unique_row_id}
          verificationType={verificationType}
          showFile={showFile}
          showFile2={showFile2}
          showFile3={showFile3}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showPfsLink2={showPfsLink2}
          showPfsLink3={showPfsLink3}
          isChecked={isChecked}
          handleCheckbox={handleCheckbox}
          isCheckedError={isCheckedError}
          uploadError={uploadError}
          is_loader={props.is_loader}
          showDeleteSpinner={showDeleteSpinner}
          uploadFileOneError={uploadFileOneError}
          showError={showError}
        />
      );
    } else if (methodId === "37") {
      return (
        <MethodIdThirtyFive
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          setNotesValue={setNotesValue}
          getNumberValue={getNumberValue}
          formValue={formValue}
          getCrdNumber={getCrdNumber}
          getFirmName={getFirmName}
          crdNumber={crdNumber}
          firmName={firmName}
          nameAlert={nameAlert}
          unique_code={unique_row_id}
          verificationType={verificationType}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showFile={showFile}
          checkInvestorError={checkInvestorError}
          uploadError={uploadError}
          is_loader={props.is_loader}
          showDeleteSpinner={showDeleteSpinner}
        />
      );
    } else if (methodId === "42") {
      return (
        <MethodIdThirtyFive
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          files2={files2}
          deleteFile2={removeItem2}
          setOpenModal2={setOpenModal2}
          setShowTrustUploadBlock={setShowTrustUploadBlock}
          setNotesValue={setNotesValue}
          getNumberValue={getNumberValue}
          formValue={formValue}
          getCrdNumber={getCrdNumber}
          getFirmName={getFirmName}
          crdNumber={crdNumber}
          firmName={firmName}
          nameAlert={nameAlert}
          unique_code={unique_row_id}
          verificationType={verificationType}
          showFile={showFile}
          showFile2={showFile2}
          showFile3={showFile3}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showPfsLink2={showPfsLink2}
          showPfsLink3={showPfsLink3}
          checkInvestorError={checkInvestorError}
          uploadError={uploadError}
          is_loader={props.is_loader}
          showDeleteSpinner={showDeleteSpinner}
        />
      );
    } else if (methodId === "38") {
      return (
        <MethodIdThirtySix
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          setNotesValue={setNotesValue}
          getNumberValue={getNumberValue}
          formValue={formValue}
          nameAlert={nameAlert}
          unique_code={unique_row_id}
          verificationType={verificationType}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showFile={showFile}
          checkInvestorError={checkInvestorError}
          uploadError={uploadError}
          uploadFileOneError={uploadFileOneError}
          is_loader={props.is_loader}
          showDeleteSpinner={showDeleteSpinner}
          showError={showError}
        />
      );
    } else if (methodId === "41") {
      return (
        <MethodIdThirtySix
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          files2={files2}
          deleteFile2={removeItem2}
          setOpenModal2={setOpenModal2}
          setShowTrustUploadBlock={setShowTrustUploadBlock}
          setNotesValue={setNotesValue}
          getNumberValue={getNumberValue}
          formValue={formValue}
          nameAlert={nameAlert}
          unique_code={unique_row_id}
          verificationType={verificationType}
          showFile={showFile}
          showFile2={showFile2}
          showFile3={showFile3}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showPfsLink2={showPfsLink2}
          showPfsLink3={showPfsLink3}
          uploadError={uploadError}
          checkInvestorError={checkInvestorError}
          uploadFileOneError={uploadFileOneError}
          is_loader={props.is_loader}
          showDeleteSpinner={showDeleteSpinner}
          showError={showError}
        />
      );
    } else if (methodId === "14") {
      return (
        <MethodIdTwelve
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          setNotesValue={setNotesValue}
          getNumberValue={getNumberValue}
          formValue={formValue}
          nameAlert={nameAlert}
          unique_code={unique_row_id}
          verificationType={verificationType}
          is_deleted={is_deleted}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          showFile={showFile}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showPfsLink2={showPfsLink2}
          showPfsLink3={showPfsLink3}
          uploadError={uploadError}
          is_loader={props.is_loader}
          showDeleteSpinner={showDeleteSpinner}
        />
      );
    } else if (methodId === "22") {
      return (
        <MethodIdTwelve
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          setNotesValue={setNotesValue}
          nameAlert={nameAlert}
          unique_code={unique_row_id}
          verificationType={verificationType}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showFile={showFile}
          uploadError={uploadError}
          is_loader={props.is_loader}
          showDeleteSpinner={showDeleteSpinner}
        />
      );
    } else if (methodId === "20") {
      return (
        <MethodIdEleven
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          setNotesValue={setNotesValue}
          nameAlert={nameAlert}
          unique_code={unique_row_id}
          verificationType={verificationType}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showFile={showFile}
          uploadError={uploadError}
          is_loader={props.is_loader}
          showDeleteSpinner={showDeleteSpinner}
        />
      );
    } else if (methodId === "15") {
      return (
        <MethodIdThirteen
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          setNotesValue={setNotesValue}
          nameAlert={nameAlert}
          unique_code={unique_row_id}
          verificationType={verificationType}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showFile={showFile}
          uploadError={uploadError}
          is_loader={props.is_loader}
          showDeleteSpinner={showDeleteSpinner}
        />
      );
    } else if (methodId === "4") {
      return (
        <MethodIdFour
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          setNotesValue={setNotesValue}
          nameAlert={nameAlert}
          unique_code={unique_row_id}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showFile={showFile}
          uploadError={uploadError}
          is_loader={props.is_loader}
          showDeleteSpinner={showDeleteSpinner}
        />
      );
    } else if (methodId === "5") {
      return (
        <MethodIdFive
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          files2={files2}
          deleteFile2={removeItem2}
          setOpenModal2={setOpenModal2}
          setNotesValue={setNotesValue}
          nameAlert={nameAlert}
          unique_code={unique_row_id}
          showFile={showFile}
          showFile2={showFile2}
          showFile3={showFile3}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showPfsLink2={showPfsLink2}
          showPfsLink3={showPfsLink3}
          uploadError={uploadError}
          is_loader={props.is_loader}
          showDeleteSpinner={showDeleteSpinner}
        />
      );
    } else if (methodId === "6") {
      return (
        <MethodIdSix
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          setNotesValue={setNotesValue}
          nameAlert={nameAlert}
          unique_code={unique_row_id}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showFile={showFile}
          uploadError={uploadError}
          is_loader={props.is_loader}
          showDeleteSpinner={showDeleteSpinner}
        />
      );
    } else if (methodId === "7") {
      return (
        <MethodIdSeven
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          files2={files2}
          deleteFile2={removeItem2}
          setOpenModal2={setOpenModal2}
          setNotesValue={setNotesValue}
          nameAlert={nameAlert}
          unique_code={unique_row_id}
          showFile={showFile}
          showFile2={showFile2}
          showFile3={showFile3}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showPfsLink2={showPfsLink2}
          showPfsLink3={showPfsLink3}
          uploadError={uploadError}
          is_loader={props.is_loader}
          showDeleteSpinner={showDeleteSpinner}
        />
      );
    } else if (methodId === "8") {
      return (
        <MethodIdEight
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          files2={files2}
          deleteFile2={removeItem2}
          setOpenModal2={setOpenModal2}
          setNotesValue={setNotesValue}
          nameAlert={nameAlert}
          unique_code={unique_row_id}
          showFile={showFile}
          showFile2={showFile2}
          showFile3={showFile3}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showPfsLink2={showPfsLink2}
          showPfsLink3={showPfsLink3}
          uploadError={uploadError}
          is_loader={props.is_loader}
          showDeleteSpinner={showDeleteSpinner}
        />
      );
    } else if (methodId === "9") {
      return (
        <MethodIdNine
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          files2={files2}
          deleteFile2={removeItem2}
          setOpenModal2={setOpenModal2}
          files3={files3}
          deleteFile3={removeItem3}
          setOpenModal3={setOpenModal3}
          setNotesValue={setNotesValue}
          nameAlert={nameAlert}
          unique_code={unique_row_id}
          showFile={showFile}
          showFile2={showFile2}
          showFile3={showFile3}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showPfsLink2={showPfsLink2}
          showPfsLink3={showPfsLink3}
          uploadError={uploadError}
          uploadFileOneError={uploadFileOneError}
          uploadFileTwoError={uploadFileTwoError}
          is_loader={props.is_loader}
          showError={showError}
          showDeleteSpinner={showDeleteSpinner}
        />
      );
    } else if (methodId === "10") {
      return (
        <MethodIdTen
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          files2={files2}
          deleteFile2={removeItem2}
          setOpenModal2={setOpenModal2}
          setNotesValue={setNotesValue}
          nameAlert={nameAlert}
          unique_code={unique_row_id}
          showFile={showFile}
          showFile2={showFile2}
          showFile3={showFile3}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showPfsLink2={showPfsLink2}
          showPfsLink3={showPfsLink3}
          uploadError={uploadError}
          is_loader={props.is_loader}
          showDeleteSpinner={showDeleteSpinner}
        />
      );
    } else if (methodId === "39") {
      return (
        <MethodIdThirtySeven
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          files2={files2}
          deleteFile2={removeItem2}
          setOpenModal2={setOpenModal2}
          setNotesValue={setNotesValue}
          nameAlert={nameAlert}
          unique_code={unique_row_id}
          showFile={showFile}
          showFile2={showFile2}
          showFile3={showFile3}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showPfsLink2={showPfsLink2}
          showPfsLink3={showPfsLink3}
          uploadError={uploadError}
          is_loader={props.is_loader}
          showDeleteSpinner={showDeleteSpinner}
        />
      );
    } else if (methodId === "40") {
      return (
        <MethodIdThirtyEight
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          setNotesValue={setNotesValue}
          nameAlert={nameAlert}
          unique_code={unique_row_id}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showFile={showFile}
          uploadError={uploadError}
          is_loader={props.is_loader}
          showDeleteSpinner={showDeleteSpinner}
        />
      );
    } else if (methodId === "16") {
      return (
        <MethodIdFourteen
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          setNotesValue={setNotesValue}
          nameAlert={nameAlert}
          unique_code={unique_row_id}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showFile={showFile}
          uploadError={uploadError}
          is_loader={props.is_loader}
          showDeleteSpinner={showDeleteSpinner}
        />
      );
    } else if (methodId === "23") {
      return (
        <MethodIdTwentyThree
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          files2={files2}
          deleteFile2={removeItem2}
          setOpenModal2={setOpenModal2}
          setNotesValue={setNotesValue}
          nameAlert={nameAlert}
          unique_code={unique_row_id}
          verificationType={verificationType}
          showFile={showFile}
          showFile2={showFile2}
          showFile3={showFile3}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showPfsLink2={showPfsLink2}
          showPfsLink3={showPfsLink3}
          uploadError={uploadError}
          is_loader={props.is_loader}
          showDeleteSpinner={showDeleteSpinner}
        />
      );
    } else if (methodId === "24") {
      return (
        <MethodIdTwenty
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          files2={files2}
          deleteFile2={removeItem2}
          setOpenModal2={setOpenModal2}
          files3={files3}
          deleteFile3={removeItem3}
          setOpenModal3={setOpenModal3}
          setNotesValue={setNotesValue}
          nameAlert={nameAlert}
          unique_code={unique_row_id}
          verificationType={verificationType}
          showFile={showFile}
          showFile2={showFile2}
          showFile3={showFile3}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showPfsLink2={showPfsLink2}
          showPfsLink3={showPfsLink3}
          uploadError={uploadError}
          uploadFileOneError={uploadFileOneError}
          uploadFileTwoError={uploadFileTwoError}
          is_loader={props.is_loader}
          showError={showError}
          showDeleteSpinner={showDeleteSpinner}
        />
      );
    } else if (methodId === "25") {
      return (
        <MethodIdTwentyTwo
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          setNotesValue={setNotesValue}
          nameAlert={nameAlert}
          unique_code={unique_row_id}
          verificationType={verificationType}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showFile={showFile}
          uploadError={uploadError}
          is_loader={props.is_loader}
          showDeleteSpinner={showDeleteSpinner}
        />
      );
    } else if (methodId === "21") {
      return (
        <MethodIdTwentyTwo
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          setNotesValue={setNotesValue}
          nameAlert={nameAlert}
          unique_code={unique_row_id}
          verificationType={verificationType}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showFile={showFile}
          uploadError={uploadError}
          is_loader={props.is_loader}
          showDeleteSpinner={showDeleteSpinner}
        />
      );
    } else if (methodId === "27") {
      return (
        <MethodIdTwentyFive
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          files2={files2}
          deleteFile2={removeItem2}
          setOpenModal2={setOpenModal2}
          setNotesValue={setNotesValue}
          nameAlert={nameAlert}
          unique_code={unique_row_id}
          showFile={showFile}
          showFile2={showFile2}
          showFile3={showFile3}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showPfsLink2={showPfsLink2}
          showPfsLink3={showPfsLink3}
          isChecked={isChecked}
          handleCheckbox={handleCheckbox}
          selectInverstorType={selectInverstorType}
          handleInvestorType={handleInvestorType}
          selectInvestorError={selectInvestorError}
          isCheckedError={isCheckedError}
          uploadError={uploadError}
          uploadFileOneError={uploadFileOneError}
          uploadFileTwoError={uploadFileTwoError}
          is_loader={props.is_loader}
          showError={showError}
          showDeleteSpinner={showDeleteSpinner}
        />
      );
    } else if (methodId === "28") {
      return (
        <MethodIdTwentySix
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          dataAssetTotal={props.dataAsset.grandTotal}
          dataLiabilityTotal={props.dataLiability.grandTotal}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          setNotesValue={setNotesValue}
          nameAlert={nameAlert}
          unique_code={unique_row_id}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showFile={showFile}
          isChecked={isChecked}
          handleCheckbox={handleCheckbox}
          selectInverstorType={selectInverstorType}
          handleInvestorType={handleInvestorType}
          handleCheckInvester={handleCheckInvester}
          checkInvestor={checkInvestor}
          handleCheckLiblity={handleCheckLiblity}
          checkLiblity={checkLiblity}
          isCheckedError={isCheckedError}
          checkInvestorError={checkInvestorError}
          checkLiblityError={checkLiblityError}
          selectInvestorError={selectInvestorError}
          uploadError={uploadError}
          uploadFileOneError={uploadFileOneError}
          uploadFileTwoError={uploadFileTwoError}
          is_loader={props.is_loader}
          showError={showError}
          showDeleteSpinner={showDeleteSpinner}
        />
      );
    } else if (methodId === "29") {
      return (
        <MethodIdTwentySeven
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          files2={files2}
          deleteFile2={removeItem2}
          setOpenModal2={setOpenModal2}
          setNotesValue={setNotesValue}
          nameAlert={nameAlert}
          unique_code={unique_row_id}
          showFile={showFile}
          showFile2={showFile2}
          showFile3={showFile3}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showPfsLink2={showPfsLink2}
          showPfsLink3={showPfsLink3}
          isChecked={isChecked}
          handleCheckbox={handleCheckbox}
          selectInverstorType={selectInverstorType}
          handleInvestorType={handleInvestorType}
          selectInvestorError={selectInvestorError}
          isCheckedError={isCheckedError}
          uploadError={uploadError}
          uploadFileOneError={uploadFileOneError}
          uploadFileTwoError={uploadFileTwoError}
          is_loader={props.is_loader}
          showError={showError}
          showDeleteSpinner={showDeleteSpinner}
        />
      );
    } else if (methodId === "30") {
      return (
        <MethodIdTwentyEight
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          files2={files2}
          deleteFile2={removeItem2}
          setOpenModal2={setOpenModal2}
          setNotesValue={setNotesValue}
          nameAlert={nameAlert}
          unique_code={unique_row_id}
          showFile={showFile}
          showFile2={showFile2}
          showFile3={showFile3}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showPfsLink2={showPfsLink2}
          showPfsLink3={showPfsLink3}
          uploadError={uploadError}
          is_loader={props.is_loader}
          showError={showError}
          showDeleteSpinner={showDeleteSpinner}
        />
      );
    } else if (methodId === "31") {
      return (
        <MethodIdTwentyNine
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          files2={files2}
          deleteFile2={removeItem2}
          setOpenModal2={setOpenModal2}
          setNotesValue={setNotesValue}
          nameAlert={nameAlert}
          unique_code={unique_row_id}
          showFile={showFile}
          showFile2={showFile2}
          showFile3={showFile3}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showPfsLink2={showPfsLink2}
          showPfsLink3={showPfsLink3}
          uploadError={uploadError}
          is_loader={props.is_loader}
          showError={showError}
          showDeleteSpinner={showDeleteSpinner}
        />
      );
    } else if (methodId === "32") {
      return (
        <MethodIdThirty
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          files2={files2}
          deleteFile2={removeItem2}
          setOpenModal2={setOpenModal2}
          setNotesValue={setNotesValue}
          nameAlert={nameAlert}
          unique_code={unique_row_id}
          showFile={showFile}
          showFile2={showFile2}
          showFile3={showFile3}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showPfsLink2={showPfsLink2}
          showPfsLink3={showPfsLink3}
          uploadError={uploadError}
          is_loader={props.is_loader}
          showError={showError}
          showDeleteSpinner={showDeleteSpinner}
        />
      );
    } else if (methodId === "33") {
      return (
        <MethodIdThirtyOne
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          files2={files2}
          deleteFile2={removeItem2}
          setOpenModal2={setOpenModal2}
          setNotesValue={setNotesValue}
          nameAlert={nameAlert}
          unique_code={unique_row_id}
          showFile={showFile}
          showFile2={showFile2}
          showFile3={showFile3}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showPfsLink2={showPfsLink2}
          showPfsLink3={showPfsLink3}
          isChecked={isChecked}
          handleCheckbox={handleCheckbox}
          isCheckedError={isCheckedError}
          uploadError={uploadError}
          is_loader={props.is_loader}
          showError={showError}
          showDeleteSpinner={showDeleteSpinner}
        />
      );
    } else if (methodId === "34") {
      return (
        <MethodIdThirtyTwo
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          files2={files2}
          deleteFile2={removeItem2}
          setOpenModal2={setOpenModal2}
          setNotesValue={setNotesValue}
          nameAlert={nameAlert}
          unique_code={unique_row_id}
          showFile={showFile}
          showFile2={showFile2}
          showFile3={showFile3}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showPfsLink2={showPfsLink2}
          showPfsLink3={showPfsLink3}
          isChecked={isChecked}
          handleCheckbox={handleCheckbox}
          isCheckedError={isCheckedError}
          uploadError={uploadError}
          is_loader={props.is_loader}
          showError={showError}
          showDeleteSpinner={showDeleteSpinner}
        />
      );
    } else if (methodId === "35") {
      return (
        <MethodIdThirtyThree
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          files2={files2}
          deleteFile2={removeItem2}
          setOpenModal2={setOpenModal2}
          files3={files3}
          deleteFile3={removeItem3}
          setOpenModal3={setOpenModal3}
          setNotesValue={setNotesValue}
          nameAlert={nameAlert}
          unique_code={unique_row_id}
          showFile={showFile}
          showFile2={showFile2}
          showFile3={showFile3}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showPfsLink2={showPfsLink2}
          showPfsLink3={showPfsLink3}
          isChecked={isChecked}
          handleCheckbox={handleCheckbox}
          selectInverstorType={selectInverstorType}
          handleInvestorType={handleInvestorType}
          selectInvestorError={selectInvestorError}
          isCheckedError={isCheckedError}
          uploadError={uploadError}
          uploadFileOneError={uploadFileOneError}
          uploadFileTwoError={uploadFileTwoError}
          is_loader={props.is_loader}
          showError={showError}
          showDeleteSpinner={showDeleteSpinner}
        />
      );
    } else if (methodId === "36") {
      return (
        <MethodIdThirtyFour
          mediaAlert={mediaAlert}
          legalName={legalName}
          changeLegalName={changeLegalName}
          files={files}
          deleteFile={removeItem}
          setOpenModal={setOpenModal}
          files2={files2}
          deleteFile2={removeItem2}
          setOpenModal2={setOpenModal2}
          files3={files3}
          deleteFile3={removeItem3}
          setOpenModal3={setOpenModal3}
          setNotesValue={setNotesValue}
          nameAlert={nameAlert}
          unique_code={unique_row_id}
          showFile={showFile}
          showFile2={showFile2}
          showFile3={showFile3}
          pfsLink={localStorage.getItem("pfs_link") || signPfsLink}
          pfsName={fetchUserData.displayName}
          pfsDelete={deletePfsLink}
          showPfsLink={showPfsLink}
          showPfsLink2={showPfsLink2}
          showPfsLink3={showPfsLink3}
          uploadError={uploadError}
          uploadFileOneError={uploadFileOneError}
          uploadFileTwoError={uploadFileTwoError}
          is_loader={props.is_loader}
          showError={showError}
          showDeleteSpinner={showDeleteSpinner}
        />
      );
    }
  }

  const backToPending = () => {
    navigate("/individual/verifyinvestor/pending");
    if (localStorage.getItem("pfs_link")) {
      localStorage.removeItem("pfs_link");
    }
    setNameAlert("");
    setNotesValue("");
    setMediaAlert("");
  };

  const removePaymentMethod = (id) => {
    CapsyncDeleteMessage(true, "Delete", "", () => deleteFunc(id));
  };

  function deleteFunc(id) {
    dispatch(remove_user_subscription_method(id))
      .unwrap()
      .then((response) => {
        if (response.status === "Error") {
          props.toastMessage(response.message, "error");
        } else {
          props.toastMessage(
            "Your record has been deleted successfully. ",
            "success"
          );
          getDashboardData();
        }
      });
  }

  const closeMediaModal = () => {
    setOpenModal(false);
    setOpenModal2(false);
    setOpenModal3(false);
    setShowDuplicateError(false);
    setShowMaxfileSizeError(false);
    setCheckPfsStatus(false);
    setShowSpinner(false);
    setFileError("");
    // if (localStorage.getItem("pfs_link")) {
    // setPfsLink(localStorage.removeItem("pfs_link"))
    //   localStorage.removeItem("pfs_link");
    //   setShowPfs(false)
    // }
  };
  const deletePfsLink = () => {
    if (localStorage.getItem("pfs_link")) {
      localStorage.removeItem("pfs_link");
      setCheckPfsLink(false);
      toast.success(
        "Your Personal Financial Statement has been removed successfully."
      );
    }
    if (signPfsLink) {
      dispatch(remove_link_from_verify_table({ unique_rowId: unique_row_id }))
        .unwrap()
        .then((response) => {
          if (response.code === 200) {
            setCheckPfsLink(false);
            toast.success(
              "Your Personal Financial Statement has been removed successfully."
            );
            setSignPfsLink([]);
            setShowPfsLink(false);
            setShowPfsLink2(false);
            setShowPfsLink3(false);
            setFileError("");
          }
        })
        .catch((err) => {
          console.log(err);
        });
      deleteFetchedPfs();
    }
    getDashboardData();
  };

  const deleteFetchedPfs = () => {
    if (fetchedPfs.length > 0) {
      fetchedPfs.forEach((item) => {
        if (item.original_file_name.includes("hellosign_VI_pdf")) {
          const pfsFileName = item.file_name;
          deleteAttatchments(pfsFileName, true);
          setFetchedPfs([]);
        }
      });
    }
  };
  const handleConfirmationModal = () => {
    setShowConfirmation(true);
  };

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );

  const allContinueDisable =
    !signPfsLink ||
    !checkPfsLink ||
    showSpinner ||
    showDuplicateError ||
    showMaxfileSizeError ||
    checkPfsStatus ||
    is_deleted.length !== 0 ||
    fileError.length !== 0;

  return (
    <>
      {showLoader ? (
        <CapsyncLoader />
      ) : (
        <>
          <CommmonFileForModals
            accreditationSign={accreditationSign}
            setAccreditationSign={setAccreditationSign}
            unique_row_id={unique_row_id}
            showPfsSecondModal={showPfsSecondModal}
            setShowPfsSecondModal={setShowPfsSecondModal}
            text={"verify_investor"}
            setOpenModal={setOpenModal}
            setOpenModal2={setOpenModal2}
            setOpenModal3={setOpenModal3}
            openModal={openModal}
            openModal2={openModal2}
            openModal3={openModal3}
            setData={setData}
            data={data}
            toastMessage={props.toastMessage}
            individualUserData={props.individualUserData}
            setCheckPfsLink={setCheckPfsLink}
            deleteFetchedPfs={deleteFetchedPfs}
            {...props}
          />

          {/* <MainDefault
            payModal={payModal}
            setShowLoader={setShowLoader}
            openPaymentModal={openPaymentModal}
            payment_method={payment_method}
            removePaymentMethod={removePaymentMethod}
            is_verify_investor={true}
            unique_row_id={unique_row_id}
            getDashboardData={getDashboardData}
            sendData={sendData}
            continueToDashboard={continueToDashboard}
            individualUserData={props.individualUserData}

          /> */}
          {/* {clientSecret && ( */}
          <Elements stripe={stripePromise}>
            <BillingCardDetailModal
              billingDetailModal={billingDetailModal}
              setBillingDetailModal={setBillingDetailModal}
              intentType={2}
              accreditationType={accreditationType}
              unique_row_id={unique_row_id}
              sendData={sendData}
              setPaymentLoader={setPaymentLoader}
              showPaymentSuccessfull={showPaymentSuccessfull}
              showPaymentFailed={showPaymentFailed}
              setShowPaymentFailed={setShowPaymentFailed}
              failureReason={failureReason}
            />
          </Elements>
          {paymentLoader && <PaymentProgressBar />}

          <AttachedDocument
            showAttachedDocument={showAttachedDocument}
            setShowAttachedDocument={setShowAttachedDocument}
            setSelectedAttachments={setSelectedAttachments}
            selectedAttachments={selectedAttachments}
            setAttachmentsDocuments={setAttachmentsDocuments}
            attachmentsDocuments={attachmentsDocuments}
            handleAttachmentSubmit={handleAttachmentSubmit}
            setShowDuplicateError={setShowDuplicateError}
            setShowMaxfileSizeError={setShowMaxfileSizeError}
            setCheckPfsStatus={setCheckPfsStatus}
            fetchedMedia={fetchedMedia}
          />
          <ConfirmationModel
            setShowConfirmation={setShowConfirmation}
            showConfirmation={showConfirmation}
          />

          {chooseComponent()}
          {props.is_loader.assets === true ||
          props.is_loader.liabilities === true ? (
            <SkeletonViSubMethods />
          ) : (
            // requestSent && userSubscriptionId !== 0 ? navigate("/individual/verifyinvestor/pending")
            //   :
            <>
              <div className="accreditation-footer">
                <Container fluid>
                  <Row>
                    <Col lg={6}>
                      <p className="default-medium-sub-heading-m  cs-neutral-80">
                        If you are not{" "}
                        {store[4] === "qualifiedpurchaser"
                          ? "a qualified purchaser"
                          : store[4] === "qualifiedclient"
                          ? "a qualified client"
                          : "an accredited investor"}{" "}
                        or wish to cancel this verification request, &nbsp;
                        <Link
                          className="cs-primary"
                          onClick={handleConfirmationModal}
                        >
                          click here
                        </Link>
                      </p>
                    </Col>
                    <Col lg={6}>
                      <div className="accreditation-btn-group">
                        <Button
                          className="cs-btn-secondary default-regular-h5"
                          onClick={() => {
                            navigate(
                              `/individual/verifyinvestor/accreditedInvestor/${verificationType}/${unique_row_id}`
                            );
                          }}
                        >
                          Change Verification Method
                        </Button>
                        <Button
                          className="lg-btn cs-btn-primary default-regular-h5"
                          // onClick={() => openPaymentModal(true)}
                          onClick={handleSubmitVI}
                          disabled={
                            !(
                              (showPfsLink &&
                                (localStorage.getItem("pfs_link") ||
                                  signPfsLink)) ||
                              (showPfsLink2 &&
                                (localStorage.getItem("pfs_link") ||
                                  signPfsLink)) ||
                              (showPfsLink3 &&
                                (localStorage.getItem("pfs_link") ||
                                  signPfsLink)) ||
                              fetchedPfs.length > 0
                            ) || disableSubmit
                          }
                        >
                          Submit for Review
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </>
            )}
          <Modal
            show={openModal}
            onHide={!openModal}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="cs-md-modal manage-files"
            backdrop="static"
          >
            <Modal.Header>
              <Modal.Title
                id="contained-modal-title-vcenter"
                className="default-semi-bold-h3"
              >
                Select Files aniket
              </Modal.Title>
              {/* <CloseButton onClick={closeMediaModal} /> */}
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  {!checkPfsLink && (
                    <div className="select-pfs-file-row">
                      <div className="select-pfs-box cs-neutral-70 default-regular-h5">
                        <span>
                          <CapsyncIcon title="file-icon-outlined" size="22" />
                        </span>
                        {localStorage.getItem("pfs_link")
                          ? `${fetchUserData.displayName}'s PFS fjkdfjkdjfkdjf `
                          : "Attach PFS*"}
                      </div>

                      <Button
                        className="cs-btn-primary sm-btn default-regular-h5"
                        onClick={() => handleClick()}
                        disabled={localStorage.getItem("pfs_link")}
                      >
                        Link PFS
                      </Button>
                    </div>
                  )}
                  <div className="cs-files">
                    <div className="cs-scroller">
                      {checkPfsLink && !showPfsLink2 && !showPfsLink3 && (
                        <div className="cs-selected-files">
                          <span className="cs-neutral-60 cs-fileicon">
                            <CapsyncIcon title="file-icon-outlined" size="22" />
                          </span>
                          <div className="cs-files-names">
                            <p className="default-regular-sub-heading-xs">
                              <p> {fetchUserData.displayName}'s PFS </p>
                            </p>
                            {/* <p className="default-light-body-text-s cs-neutral-80">
                                    {fileSize(iter.size)} KB
                                  </p> */}
                          </div>
                          {/* {is_deleted === true ? (
                            <span className="cs-common-spinner cs-primary">
                              <CapsyncIcon title="loading-outlined" size="22" />
                            </span>
                          ) : ( */}
                          <div className="cs-file-icon icons">
                            <span
                              className="cs-icon-badge-neutral-20 cs-file-icon cs-icons"
                              onClick={() => handleClick()}
                            >
                              <CapsyncToolTip
                                Child={"edit-outlined"}
                                placement={"top"}
                                size="18"
                                message={"Edit"}
                                type="icon"
                              />
                            </span>
                            <span
                              className="cs-icon-badge-danger cs-danger cs-file-icon cs-icons"
                              onClick={(e) => deletePfsLink(e)}
                            >
                              <CapsyncToolTip
                                Child={"delete-outlined"}
                                placement={"top"}
                                size="18"
                                message={"Delete"}
                                type="icon"
                              />
                            </span>
                          </div>
                          {/* )} */}
                        </div>
                      )}
                    </div>
                  </div>

                  {(showPfsLink2 &&
                    (localStorage.getItem("pfs_link") || signPfsLink)) ||
                  (showPfsLink3 &&
                    (localStorage.getItem("pfs_link") || signPfsLink)) ? (
                    ""
                  ) : (
                    <div className="cs-divider cs-select-divider">
                      <span className="default-regular-body-text-s cs-neutral-50">
                        AND
                      </span>
                    </div>
                  )}
                </Col>
              </Row>

              <Row>
                <Col>
                  <div
                    onClick={
                      !submitUploadFile &&
                      showDeleteSpinner === "" &&
                      handleShowAttachedDocument
                    }
                    className={
                      submitUploadFile
                        ? "cs-attached-doc cs-disabled"
                        : "cs-attached-doc"
                    }
                  >
                    <span className="cs-neutral-60">
                      <CapsyncIcon title="file-icon-outlined" size="22" />
                    </span>
                    <h5
                      className={`default-regular-sub-heading-xs cs-neutral-100 cs-attached-title ${
                        submitUploadFile && "cs-disabled"
                      }`}
                    >
                      Select Attached documents
                      <span className="cs-neutral-60">
                        <CapsyncIcon title="chevron-right-outlined" size="22" />
                      </span>
                    </h5>
                  </div>
                  {attachmentsDocuments.length > 0 && (
                    <p className="default-regular-body-text-s cs-primary">
                      {" "}
                      {attachmentsDocuments.length} Attachments are selected
                    </p>
                  )}
                  <div className="cs-divider cs-select-divider">
                    <span className="default-regular-body-text-s cs-neutral-50">
                      OR
                    </span>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormLabel
                    className={`default-regular-sub-heading-xs cs-neutral-100 ${
                      submitUploadFile && "cs-disabled"
                    }`}
                  >
                    Upload Files
                  </FormLabel>
                  <div className="manage-profile-upload select-files-box">
                    <Row>
                      <Col>
                        <div className="cs-upload-box">
                          <div
                            {...getRootProps({
                              className: "dropzone cursor_pointer",
                            })}
                            className={`cs-upload-files cursor-pointer ${
                              submitUploadFile && "cs-neutral-60 disabled"
                            }`}
                          >
                            <input {...getInputProps()} name="attachments" />
                            <span className="cs-primary">
                              <CapsyncIcon
                                title="upload-files-filled"
                                size="28"
                              />
                            </span>
                            <p className="default-regular-sub-heading-s">
                              <span
                                className={submitUploadFile && "cs-disabled"}
                              >
                                Browse
                              </span>
                              &nbsp;or drag file here
                            </p>
                            <p className="default-regular-sub-heading-xxs">
                              Supports PDF, PNG, JPG and JPEG format
                            </p>
                          </div>
                          {checkPfsStatus && (
                            <span className="default-regular-sub-heading-xxs cs-danger form-error-msg">
                              Select the attached document or upload the
                              required files
                            </span>
                          )}
                          {/* {showDuplicateError && <span className="default-regular-sub-heading-xxs cs-danger form-error-msg">This file name already exists!</span>
                          }
                          {showMaxfileSizeError && <span className="default-regular-sub-heading-xxs cs-danger form-error-msg">This file exceeds maximum file size limit(10 MB).</span>
                          } */}
                          {!checkPfsStatus && (
                            <span className="form-error-msg default-light-body-text-s cs-danger">
                              {fileError}
                            </span>
                          )}
                          <div className="cs-files">
                            <div className="cs-scroller">
                              {fileList.map((iter, id) => {
                                return (
                                  <div key={id} className="cs-selected-files">
                                    <span className="cs-neutral-60 cs-fileicon">
                                      <CapsyncIcon
                                        title="file-icon-outlined"
                                        size="22"
                                      />
                                    </span>
                                    <div className="cs-files-names">
                                      <p className="default-regular-sub-heading-xs">
                                        <span key={id}>
                                          {adjustLengthVi(
                                            iter.path
                                              ? iter.path
                                              : iter.original_file_name
                                          )}
                                        </span>
                                      </p>
                                      <p className="default-light-body-text-s cs-neutral-80">
                                        {fileSize(iter.size)} KB
                                      </p>
                                    </div>
                                    {is_deleted.includes(iter.path) ? (
                                      <span className="cs-common-spinner cs-primary">
                                        <CapsyncIcon
                                          title="loading-outlined"
                                          size="22"
                                        />
                                      </span>
                                    ) : (
                                      <div className="cs-file-icon icons">
                                        <span
                                          className="cs-icon-badge-danger cs-danger cs-file-icon cs-icons"
                                          onClick={() => removeItem(id)}
                                        >
                                          <CapsyncIcon
                                            title="delete-outlined"
                                            size="18"
                                          />
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                              {files.map((iter, id) => {
                                return (
                                  <div key={id} className="cs-selected-files">
                                    <span className="cs-neutral-60 cs-fileicon">
                                      <CapsyncIcon
                                        title="file-icon-outlined"
                                        size="22"
                                      />
                                    </span>
                                    <div className="cs-files-names">
                                      <p className="default-regular-sub-heading-xs">
                                        <span key={id}>
                                          {adjustLengthVi(
                                            iter.original_file_name
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                    {showDeleteSpinner === iter.file_name ? (
                                      <span className="cs-common-spinner cs-primary">
                                        <CapsyncIcon
                                          title="loading-outlined"
                                          size="22"
                                        />
                                      </span>
                                    ) : (
                                      <div className="cs-file-icon icons">
                                        <span
                                          className="cs-icon-badge-danger cs-danger cs-file-icon cs-icons"
                                          // onClick={() => removeItem(id)}
                                          onClick={() =>
                                            removeItem(iter.file_name)
                                          }
                                        >
                                          <CapsyncIcon
                                            title="delete-outlined"
                                            size="18"
                                          />
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              {/* <div className="">
                {localStorage.getItem("pfs_access_id") ? (
                  ""
                ) : ( */}

              {/* )}
              </div> */}
            </Modal.Body>
            <Modal.Footer className="cs-modal-btn">
              <Button
                className="cs-btn-secondary md-btn default-regular-h5"
                onClick={closeMediaModal}
              >
                Cancel
              </Button>
              <Button
                className="cs-btn-primary md-btn default-regular-h5"
                disabled={allContinueDisable}
                onClick={() => checkMedia()}
              >
                {showSpinner && (
                  <span className="cs-common-spinner cs-neutral-40">
                    <CapsyncIcon title="loading-outlined" size="18" />
                  </span>
                )}
                Continue
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={openModal2}
            onHide={!openModal2}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="cs-md-modal manage-files"
            backdrop="static"
          >
            <Modal.Header>
              <Modal.Title
                id="contained-modal-title-vcenter"
                className="default-semi-bold-h3"
              >
                Select Files
              </Modal.Title>
              {/* <CloseButton onClick={closeMediaModal} /> */}
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  {!checkPfsLink && (
                    <div className="select-pfs-file-row">
                      <div className="select-pfs-box cs-neutral-60 default-regular-h5">
                        <span>
                          <CapsyncIcon title="file-icon-outlined" size="18" />
                        </span>
                        {localStorage.getItem("pfs_link") || signPfsLink
                          ? `${fetchUserData.displayName}'s PFS `
                          : "Attach PFs"}
                      </div>

                      <Button
                        className="cs-btn-primary sm-btn default-regular-h5"
                        onClick={() => handleClick()}
                        disabled={localStorage.getItem("pfs_link")}
                      >
                        Link PFS
                      </Button>
                    </div>
                  )}

                  <div className="cs-files">
                    <div className="cs-scroller">
                      {checkPfsLink && !showPfsLink && !showPfsLink3 && (
                        <div className="cs-selected-files">
                          <span className="cs-neutral-60 cs-fileicon">
                            <CapsyncIcon title="file-icon-outlined" size="22" />
                          </span>
                          <div className="cs-files-names">
                            <p className="default-regular-sub-heading-xs">
                              <p> {fetchUserData.displayName}'s PFS </p>
                            </p>
                            {/* <p className="default-light-body-text-s cs-neutral-80">
                                    {fileSize(iter.size)} KB
                                  </p> */}
                          </div>
                          {/* {is_deleted === true ? (
                            <span className="cs-common-spinner cs-primary">
                              <CapsyncIcon title="loading-outlined" size="22" />
                            </span>
                          ) : ( */}
                          <div className="cs-file-icon icons">
                            <span
                              className="cs-icon-badge-neutral-20 cs-file-icon cs-icons"
                              onClick={() => handleClick()}
                            >
                              <CapsyncToolTip
                                Child={"edit-outlined"}
                                placement={"top"}
                                size="18"
                                message={"Edit"}
                                type="icon"
                              />
                            </span>
                            <span
                              className="cs-icon-badge-danger cs-danger cs-file-icon cs-icons"
                              onClick={(e) => deletePfsLink(e)}
                            >
                              <CapsyncToolTip
                                Child={"delete-outlined"}
                                placement={"top"}
                                size="18"
                                message={"Delete"}
                                type="icon"
                              />
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {(showPfsLink &&
                    (localStorage.getItem("pfs_link") || signPfsLink)) ||
                  (showPfsLink3 &&
                    (localStorage.getItem("pfs_link") || signPfsLink)) ? (
                    ""
                  ) : (
                    <div className="cs-divider cs-select-divider">
                      <span className="default-regular-body-text-s cs-neutral-50">
                        AND
                      </span>
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <div
                    onClick={
                      !submitUploadFile &&
                      showDeleteSpinner === "" &&
                      handleShowAttachedDocument
                    }
                    className={
                      submitUploadFile
                        ? "cs-attached-doc cs-disabled"
                        : "cs-attached-doc"
                    }
                  >
                    <span className="cs-neutral-60">
                      <CapsyncIcon title="file-icon-outlined" size="22" />
                    </span>
                    <h5
                      className={`default-regular-sub-heading-xs cs-neutral-100 cs-attached-title ${
                        submitUploadFile && "cs-disabled"
                      }`}
                    >
                      Select Attached documents
                      <span className="cs-neutral-60">
                        <CapsyncIcon title="chevron-right-outlined" size="22" />
                      </span>
                    </h5>
                  </div>
                  {attachmentsDocuments.length > 0 && (
                    <p className="default-regular-body-text-s cs-primary">
                      {" "}
                      {attachmentsDocuments.length} Attachments are selected
                    </p>
                  )}
                  <div className="cs-divider cs-select-divider">
                    <span className="default-regular-body-text-s cs-neutral-50">
                      OR
                    </span>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormLabel
                    className={`default-regular-sub-heading-xs cs-neutral-100 ${
                      submitUploadFile && "cs-disabled"
                    }`}
                  >
                    Upload Files
                  </FormLabel>
                </Col>
              </Row>
              <div className="manage-profile-upload select-files-box">
                <Row>
                  <Col>
                    <div className="cs-upload-box">
                      <div
                        {...getRootProps({
                          className: "dropzone cursor_pointer",
                        })}
                        className={`cs-upload-files cursor-pointer ${
                          submitUploadFile && "cs-neutral-60 disabled"
                        }`}
                      >
                        <input {...getInputProps()} name="attachments" />
                        <span className="cs-primary">
                          <CapsyncIcon title="upload-files-filled" size="28" />
                        </span>
                        <p className="default-regular-sub-heading-s">
                          <span className={submitUploadFile && "cs-disabled"}>
                            Browse
                          </span>
                          &nbsp;or drag file here
                        </p>
                        <p className="default-regular-sub-heading-xxs">
                          Supports PDF, PNG, JPG and JPEG format
                        </p>
                      </div>
                      {checkPfsStatus && (
                        <span className="default-regular-sub-heading-xxs cs-danger form-error-msg">
                          Select the attached document or upload the required
                          files
                        </span>
                      )}
                      {/* {showDuplicateError && <span className="default-regular-sub-heading-xxs cs-danger form-error-msg">This file name already exists!</span>
                      }
                      {showMaxfileSizeError && <span className="default-regular-sub-heading-xxs cs-danger form-error-msg">This file exceeds maximum file size limit(10 MB).</span>
                      } */}
                      {!checkPfsStatus && (
                        <span className="form-error-msg default-light-body-text-s cs-danger">
                          {fileError}
                        </span>
                      )}
                      <div className="cs-files">
                        <div className="cs-scroller">
                          {fileList2.map((iter, id) => {
                            return (
                              <div key={id} className="cs-selected-files">
                                <span className="cs-neutral-60 cs-fileicon">
                                  <CapsyncIcon
                                    title="file-icon-outlined"
                                    size="22"
                                  />
                                </span>
                                <div className="cs-files-names">
                                  <p className="default-regular-sub-heading-xs">
                                    <p key={id}>
                                      {adjustLengthVi(
                                        iter.path
                                          ? iter.path
                                          : iter.original_file_name
                                      )}
                                    </p>
                                  </p>
                                  <p className="default-light-body-text-s cs-neutral-80">
                                    {fileSize(iter.size)} KB
                                  </p>
                                </div>
                                {is_deleted.includes(iter.path) ? (
                                  <span className="cs-common-spinner cs-primary">
                                    <CapsyncIcon
                                      title="loading-outlined"
                                      size="22"
                                    />
                                  </span>
                                ) : (
                                  <div className="cs-file-icon icons">
                                    <span
                                      className="cs-icon-badge-danger cs-danger cs-file-icon cs-icons"
                                      onClick={() => removeItem2(id)}
                                    >
                                      <CapsyncIcon
                                        title="delete-outlined"
                                        size="18"
                                      />
                                    </span>
                                  </div>
                                )}
                              </div>
                              // <div>
                              // </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    {/* {!localStorage.getItem("pfs_link") && <div className="cs-divider"><span className="default-regular-body-text-s cs-neutral-50">OR</span></div>} */}
                  </Col>
                </Row>
              </div>
              {/* <div>
                {localStorage.getItem("pfs_access_id") ? (
                  ""
                ) : (
                  <>

                  </>
                )}
              </div> */}
            </Modal.Body>
            <Modal.Footer className="cs-modal-btn">
              <Button
                className="cs-btn-secondary md-btn default-regular-h5"
                onClick={closeMediaModal}
              >
                Cancel
              </Button>
              <Button
                className="cs-btn-primary md-btn default-regular-h5"
                disabled={allContinueDisable}
                onClick={() => checkMedia2()}
              >
                {showSpinner && (
                  <span className="cs-common-spinner cs-neutral-40">
                    <CapsyncIcon title="loading-outlined" size="18" />
                  </span>
                )}
                Continue
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={openModal3}
            onHide={!openModal3}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="cs-md-modal manage-files"
            backdrop="static"
          >
            <Modal.Header>
              <Modal.Title
                id="contained-modal-title-vcenter"
                className="default-semi-bold-h3"
              >
                Select Files
              </Modal.Title>
              {/* <CloseButton onClick={closeMediaModal} /> */}
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  {!checkPfsLink && (
                    <div className="select-pfs-file-row">
                      <div className="select-pfs-box cs-neutral-60 default-regular-h5">
                        <span>
                          <CapsyncIcon title="file-icon-outlined" size="18" />
                        </span>
                        {localStorage.getItem("pfs_link") || signPfsLink
                          ? `${fetchUserData.displayName}'s PFS `
                          : "Attach PFs"}
                      </div>

                      <Button
                        className="cs-btn-primary sm-btn default-regular-h5"
                        onClick={() => handleClick()}
                        disabled={localStorage.getItem("pfs_link")}
                      >
                        Link PFS
                      </Button>
                    </div>
                  )}

                  <div className="cs-files">
                    <div className="cs-scroller">
                      {checkPfsLink && !showPfsLink && !showPfsLink2 && (
                        <div className="cs-selected-files">
                          <span className="cs-neutral-60 cs-fileicon">
                            <CapsyncIcon title="file-icon-outlined" size="22" />
                          </span>
                          <div className="cs-files-names">
                            <p className="default-regular-sub-heading-xs">
                              <p> {fetchUserData.displayName}'s PFS </p>
                            </p>
                            {/* <p className="default-light-body-text-s cs-neutral-80">
                                    {fileSize(iter.size)} KB
                                  </p> */}
                          </div>
                          {/* {is_deleted === true ? (
                            <span className="cs-common-spinner cs-primary">
                              <CapsyncIcon title="loading-outlined" size="22" />
                            </span>
                          ) : ( */}
                          <div className="cs-file-icon icons">
                            <span
                              className="cs-icon-badge-neutral-20 cs-file-icon cs-icons"
                              onClick={() => handleClick()}
                            >
                              <CapsyncToolTip
                                Child={"edit-outlined"}
                                placement={"top"}
                                size="18"
                                message={"Edit"}
                                type="icon"
                              />
                            </span>
                            <span
                              className="cs-icon-badge-danger cs-danger cs-file-icon cs-icons"
                              onClick={(e) => deletePfsLink(e)}
                            >
                              <CapsyncToolTip
                                Child={"delete-outlined"}
                                placement={"top"}
                                size="18"
                                message={"Delete"}
                                type="icon"
                              />
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {(showPfsLink &&
                    (localStorage.getItem("pfs_link") || signPfsLink)) ||
                  (showPfsLink2 &&
                    (localStorage.getItem("pfs_link") || signPfsLink)) ? (
                    ""
                  ) : (
                    <div className="cs-divider cs-select-divider">
                      <span className="default-regular-body-text-s cs-neutral-50">
                        AND
                      </span>
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <div
                    onClick={
                      !submitUploadFile &&
                      showDeleteSpinner === "" &&
                      handleShowAttachedDocument
                    }
                    className={
                      submitUploadFile
                        ? "cs-attached-doc cs-disabled"
                        : "cs-attached-doc"
                    }
                  >
                    <span className="cs-neutral-60">
                      <CapsyncIcon title="file-icon-outlined" size="22" />
                    </span>
                    <h5
                      className={`default-regular-sub-heading-xs cs-neutral-100 cs-attached-title ${
                        submitUploadFile && "cs-disabled"
                      }`}
                    >
                      Select Attached documents
                      <span className="cs-neutral-60">
                        <CapsyncIcon title="chevron-right-outlined" size="22" />
                      </span>
                    </h5>
                  </div>
                  {attachmentsDocuments.length > 0 && (
                    <p className="default-regular-body-text-s cs-primary">
                      {" "}
                      {attachmentsDocuments.length} Attachments are selected
                    </p>
                  )}
                  <div className="cs-divider cs-select-divider">
                    <span className="default-regular-body-text-s cs-neutral-50">
                      OR
                    </span>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormLabel
                    className={`default-regular-sub-heading-xs cs-neutral-100 ${
                      submitUploadFile && "cs-disabled"
                    }`}
                  >
                    Upload Files
                  </FormLabel>
                </Col>
              </Row>
              <div className="manage-profile-upload select-files-box">
                <Row>
                  <Col>
                    <div className="cs-upload-box">
                      <div
                        {...getRootProps({
                          className: "dropzone cursor_pointer",
                        })}
                        className={`cs-upload-files cursor-pointer ${
                          submitUploadFile && "cs-neutral-60 disabled"
                        }`}
                      >
                        <input {...getInputProps()} name="attachments" />
                        <span className="cs-primary">
                          <CapsyncIcon title="upload-files-filled" size="28" />
                        </span>
                        <p className="default-regular-sub-heading-s">
                          <span className={submitUploadFile && "cs-disabled"}>
                            Browse
                          </span>
                          &nbsp;or drag file here
                        </p>
                        <p className="default-regular-sub-heading-xxs">
                          Supports PDF, PNG, JPG and JPEG format
                        </p>
                      </div>
                      {checkPfsStatus && (
                        <span className="default-regular-sub-heading-xxs cs-danger form-error-msg">
                          Select the attached document or upload the required
                          files
                        </span>
                      )}
                      {/* {showDuplicateError && <span className="default-regular-sub-heading-xxs cs-danger form-error-msg">This file name already exists!</span>
                      }
                      {showMaxfileSizeError && <span className="default-regular-sub-heading-xxs cs-danger form-error-msg">This file exceeds maximum file size limit(10 MB).</span>
                      } */}
                      {!checkPfsStatus && (
                        <span className="form-error-msg default-light-body-text-s cs-danger">
                          {fileError}
                        </span>
                      )}
                      <div className="cs-files">
                        <div className="cs-scroller">
                          {fileList3.map((iter, id) => {
                            return (
                              <div key={id} className="cs-selected-files">
                                <span className="cs-neutral-60 cs-fileicon">
                                  <CapsyncIcon
                                    title="file-icon-outlined"
                                    size="22"
                                  />
                                </span>
                                <div className="cs-files-names">
                                  <p className="default-regular-sub-heading-xs">
                                    <p key={id}>
                                      {adjustLengthVi(
                                        iter.path
                                          ? iter.path
                                          : iter.original_file_name
                                      )}
                                    </p>
                                  </p>
                                  <p className="default-light-body-text-s cs-neutral-80">
                                    {fileSize(iter.size)} KB
                                  </p>
                                </div>
                                {is_deleted.includes(iter.path) ? (
                                  <span className="cs-common-spinner cs-primary">
                                    <CapsyncIcon
                                      title="loading-outlined"
                                      size="22"
                                    />
                                  </span>
                                ) : (
                                  <div className="cs-file-icon icons">
                                    <span
                                      className="cs-icon-badge-danger cs-danger cs-file-icon cs-icons"
                                      onClick={() => removeItem3(id)}
                                    >
                                      <CapsyncIcon
                                        title="delete-outlined"
                                        size="18"
                                      />
                                    </span>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Modal.Body>
            <Modal.Footer className="cs-modal-btn">
              <Button
                className="cs-btn-secondary md-btn default-regular-h5"
                onClick={closeMediaModal}
              >
                Cancel
              </Button>
              <Button
                className="cs-btn-primary md-btn default-regular-h5"
                disabled={allContinueDisable}
                onClick={checkMedia3}
              >
                {showSpinner && (
                  <span className="cs-common-spinner cs-neutral-40">
                    <CapsyncIcon title="loading-outlined" size="18" />
                  </span>
                )}
                Continue
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
};

export default ViSubMethods;
