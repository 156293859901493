/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useStripe } from "@stripe/react-stripe-js";

import {
  change_active_payment_method,
  remove_user_subscription_method,
  create_payment_intent,
  add_payment_billing,
  add_subscription,
} from "../../../slices/user_subscription";
import "../DefaultPayment.css";
import CapsyncIcon from "../../CapsyncIcon";
import CardPaymentDetails from "./CardPaymentDetails";
import StripeCheckOut from "./StripeCheckOut";
import DeleteModal from "../../modalPopup/deleteModal/DeleteModal";
import { fetch_all_payment_plan } from "../../../slices/common_payment_plans";
import PaymentSuccessfull from "./PaymentSuccessfull";
import PaymentFailed from "./PaymentFailed";

const BillingCardDetailModal = (props) => {
  const {
    intentType,
    setBillingDetailModal,
    billingDetailModal,
    unique_row_id,
    accreditationType,
    sendData,
    setPaymentLoader,
    showPaymentSuccessfull,
    showPaymentFailed,
    setShowPaymentFailed,
    failureReason,
    selectedPlan,
  } = props;

  const { user } = useSelector((state) => state.auth);
  const { userBillingDetail } = useSelector((state) => state.userSubscription);
  const dispatch = useDispatch();

  const [disabled, setDisabled] = useState(false);
  const [showStripeForm, setShowStripeForm] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState({
    show: false,
    message: "",
    title: "",
    custom: "",
  });
  const [currentActive, setCurrentActive] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [stripeErrorMessage, setStripeErrorMessage] = useState("");
  const [amount, setAmount] = useState();
  const [fetchVIPaymentPlan, setFetchVIPaymentPlan] = useState([]);
  const [checkFirstWaiting, setCheckFirstWaiting] = useState(null);
  const [billingSpinner, setBillingSpinner] = useState(false);

  let user_email = user && user.user.email;
  let user_role = user && user.user_role.role;
  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else if (user && user.user.id) {
    id = user.user.id;
  }

  // REMOVE/DELETE PAYMENT METHOD
  function CapsyncDeleteMessage(showDeleteModal, msg, title, func) {
    if (!showDeleteModal) {
      setDeleteStatus({
        ...deleteStatus,
        show: false,
      });
    } else {
      setDeleteStatus({
        show: showDeleteModal,
        message: msg,
        title: title,
        custom: func,
      });
    }
  }
  const removePaymentMethod = (id) => {
    CapsyncDeleteMessage(true, "Delete", "", () => deleteFunc(id));
  };
  function deleteFunc(id) {
    dispatch(remove_user_subscription_method(id))
      .unwrap()
      .then((response) => {
        if (response.status == "Error") {
          toast.error("you cannot remove your default card :" + Error);
        } else {
          toast.success(
            "Your payment & billing information have been updated succesfully."
          );
        }
      });
  }

  let stripe;
  if (intentType === 2 || intentType === 1) {
    stripe = useStripe();
  }

  // SET ACTIVE PAYMENT METHOD
  let selectedPaymentMethod;
  useEffect(() => {
    selectedPaymentMethod = userBillingDetail.find(
      (item) => item.is_active === true
    );
    selectedPaymentMethod && setPaymentMethod(selectedPaymentMethod);
  }, []);
  useEffect(() => {
    if (!currentActive) {
      selectedPaymentMethod = userBillingDetail.find(
        (item) => item.is_active === true
      );
    } else {
      selectedPaymentMethod = userBillingDetail.find(
        (item) => currentActive === item.user_payment_detail_id
      );
    }
    selectedPaymentMethod && setPaymentMethod(selectedPaymentMethod);
  }, [currentActive, userBillingDetail]);

  // ACCREDITATION - FETCH VI PAYMENT PLANS
  useEffect(() => {
    if (intentType === 2) {
      dispatch(fetch_all_payment_plan({ module_name: "Verify Investor" }))
        .unwrap()
        .then((response) => {
          if (response.code === 200) {
            setFetchVIPaymentPlan(response.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);
  // ACCREDITATION - SET AMOUNT FOR SPECIFIC VERIFICATION METHOD OF ACCREDITATION
  useEffect(() => {
    let currentVerification =
      fetchVIPaymentPlan &&
      fetchVIPaymentPlan.find((item) => item.title === accreditationType);
    currentVerification && setAmount(currentVerification.amount);
  }, [accreditationType, fetchVIPaymentPlan]);

  // HANDLED MODAL OPEN CLOSE FUNCTIONALITY
  useEffect(() => {
    if (
      !!billingDetailModal &&
      (intentType === 2 || intentType === 1) &&
      window.localStorage.getItem("pfs_access_id") !== null
    ) {
      setShowStripeForm(true);
      setBillingDetailModal(false);
    }
  }, [billingDetailModal]);

  // CHECK DEFAULT ACTIVE PAYMENT METHOD AND HANDLED SUBMIT BUTTON DISABLE STATE
  let checkActiveMethod;
  useEffect(() => {
    checkActiveMethod =
      !!userBillingDetail &&
      userBillingDetail.find((item) => item.is_active === true);
    (intentType === 2 || intentType === 1) && !checkActiveMethod
      ? setDisabled(true)
      : intentType === 3
      ? setDisabled(true)
      : setDisabled(false);
  }, [billingDetailModal]);
  useEffect(() => {
    setCurrentActive(null);
  }, [billingDetailModal, deleteStatus]);

  // CHECK FIRST NON VERIFY PAYMENT METHOD
  useEffect(() => {
    const first = userBillingDetail.find((item) => !item.is_verify);
    setCheckFirstWaiting(first);
  }, [userBillingDetail]);

  const handleCancel = () => {
    setBillingDetailModal(false);
    setCurrentActive(null);
  };

  const handleSave = () => {
    setDisabled(true);
    setBillingSpinner(true);
    let customerId, subscriptionDetail, clientSecret;
    if (intentType === 3) {
      changeDefaultPaymentMethod(currentActive);
      setBillingDetailModal(false);
      setCurrentActive(null);
    }
    if (
      ((amount && intentType === 2) || intentType === 1) &&
      paymentMethod &&
      window.localStorage.getItem("pfs_access_id") == null &&
      !!billingDetailModal
    ) {
      dispatch(
        create_payment_intent({
          email: user_email,
          intent_type: intentType,
          amount: intentType === 2 ? amount * 100 : "",
          price_id: !!selectedPlan ? selectedPlan.price.id : null,
          payment_method_id: JSON.parse(paymentMethod.stripe_payment_method).id,
          unique_row_id: !!unique_row_id ? unique_row_id : "",
        })
      )
        .unwrap()
        .then((response) => {
          if (response.code === 200) {
            customerId = response.data.payment_intent.customer;
            subscriptionDetail = response.data.subscription;
            clientSecret = response.data.payment_intent.client_secret;

            setPaymentLoader(true);
            setBillingDetailModal(false);
            setShowStripeForm(false);

            try {
              stripe
                .confirmPayment({
                  clientSecret: clientSecret,
                  redirect: "if_required",
                  confirmParams: {
                    payment_method: JSON.parse(
                      paymentMethod.stripe_payment_method
                    ).id,
                    // Return URL where the customer should be redirected after the PaymentIntent is confirmed.
                    return_url: "https://example.com",
                  },
                })
                .then((result) => {
                  if (result.error) {
                    console.log("confirmPayment result error:-");
                    setStripeErrorMessage("Something wrong");
                  } else {
                    if (intentType === 2) {
                      dispatch(
                        add_payment_billing({
                          response: result,
                          subscription_type: !!JSON.parse(
                            paymentMethod.stripe_payment_method
                          ).card
                            ? 1
                            : 2,
                          unique_row_id: unique_row_id,
                          email: user_email,
                          id: id,
                          role: user_role,
                          stripe_customer_id: customerId,
                        })
                      )
                        .unwrap()
                        .then((response) => {
                          if (response.code != 200) {
                            toast.error(response.message);
                          }
                        })
                        .catch((err) => {
                          console.log("add_payment_billing error:-", err);
                          setStripeErrorMessage("Something wrong");
                        });
                    }
                    if (intentType === 1) {
                      dispatch(
                        add_subscription({
                          stripe_customer_id: customerId,
                          price_id: selectedPlan.price.id,
                          subscription_type: !!JSON.parse(
                            paymentMethod.stripe_payment_method
                          ).card
                            ? 1
                            : 2, //card/bank
                          is_auto_subscription: true,
                          currency: "usd",
                          id: id,
                          role: user_role,
                          response: result,
                          intent_type: "paymentIntent",
                          subscription_detail: subscriptionDetail,
                        })
                      )
                        .unwrap()
                        .then((response) => {
                          if (response.code !== 200) {
                            toast.error(response.message);
                          }
                        })
                        .catch((err) => {
                          console.log("err stripe checkout", err);
                          setStripeErrorMessage("Something wrong");
                        });
                    }
                  }
                })
                .catch((error) => {
                  console.log("confirmPayment error:-", error);
                  setStripeErrorMessage(error);
                });
            } catch (err) {
              console.log("Error:-", err);
            }
          }
        })
        .catch((err) => {
          console.log("err stripe checkout", err);
        });
    }
  };

  const changeDefaultPaymentMethod = (params) => {
    dispatch(
      change_active_payment_method({
        id: id,
        role: user_role,
        user_payment_detail_id: params,
      })
    )
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          toast.success(
            "Your payment & billing information have been updated succesfully."
          );
        } else {
          console.log("some technical issue");
        }
      })
      .catch((err) => {
        console.log("error:- ", err);
        toast.error(err.message);
      });
  };

  return (
    <>
      <Modal
        show={billingDetailModal}
        onHide={() => !billingDetailModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="cs-md-modal"
        className="payment-method-modal cs-common-modal-overlay"
      >
        <Modal.Header>
          <Modal.Title className="default-semi-bold-h3">
            Add or Change method
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="cs-scroller">
          {userBillingDetail &&
            userBillingDetail.map((item, index) => {
              let isVerifyTitle =
                checkFirstWaiting &&
                checkFirstWaiting.user_payment_detail_id ===
                  item.user_payment_detail_id;
              return (
                <React.Fragment key={index}>
                  <CardPaymentDetails
                    item={item}
                    index={index}
                    currentActive={currentActive}
                    setCurrentActive={setCurrentActive}
                    removePaymentMethod={removePaymentMethod}
                    showOnPage={false}
                    setDisabled={setDisabled}
                    verifyTitle={isVerifyTitle}
                  />
                </React.Fragment>
              );
            })}
        </Modal.Body>
        <Modal.Footer>
          <div className="add-payment-method-button">
            {window.localStorage.getItem("pfs_access_id") == null && (
              <Button
                className="default-regular-h5 cs-btn-icon-tertiary"
                onClick={() => {
                  setShowStripeForm(true);
                  setBillingDetailModal(false);
                }}
              >
                <span className="cs-primary">
                  <CapsyncIcon title="add-filled" size="18" />
                </span>
                Add another method
              </Button>
            )}
            <div className="cs-modal-btn">
              <Button
                onClick={handleCancel}
                className="cs-btn-secondary md-btn default-regular-h5"
              >
                Cancel
              </Button>
              <Button
                onClick={handleSave}
                disabled={disabled}
                className="cs-btn-primary md-btn default-regular-h5"
              >
                {intentType === 3 ? "Save" : "Continue"}
                {(intentType === 2 || (intentType === 1 && billingSpinner)) &&
                  disabled && (
                    <span className="cs-common-spinner cs-neutral-40">
                      <CapsyncIcon title="loading-outlined" size="18" />
                    </span>
                  )}
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      {showStripeForm && (
        <StripeCheckOut
          setPaymentLoader={setPaymentLoader}
          showStripeForm={showStripeForm}
          setShowStripeForm={setShowStripeForm}
          intentType={intentType}
          setCurrentActive={setCurrentActive}
          amount={amount}
          sendData={sendData}
          setBillingDetailModal={setBillingDetailModal}
          unique_row_id={unique_row_id}
          selectedPlan={selectedPlan}
        />
      )}
      <DeleteModal
        CapsyncDeleteMessage={CapsyncDeleteMessage}
        deleteStatus={deleteStatus}
      />
      {intentType === 2 && (
        <PaymentSuccessfull showPaymentSuccessfull={showPaymentSuccessfull} />
      )}
      {(intentType === 2 || intentType === 1) && (
        <PaymentFailed
          showPaymentFailed={showPaymentFailed}
          failureReason={failureReason}
          setShowPaymentFailed={setShowPaymentFailed}
        />
      )}
    </>
  );
};

export default BillingCardDetailModal;
