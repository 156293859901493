import React, { useEffect, useRef, useState } from "react";
import {
  Row, Col, Image,
  Form, Button, Modal,
  Dropdown, FormControl,
  FormLabel, ModalFooter,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";
import { wealth_managers_update } from "../../../../slices/wealthManager.slice";
import PhoneInput from "react-phone-number-input";
import AddressSearch from "../../../../commonComponent/AddressSearch";
// import FormError

const EditProfie = (props) => {
  const {
    values,
    record,
    showEditProfile,
    setShowEditProfile,
    handleChange,
    saveDisable,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    resetForm,
    msg,
    phoneHandleChange,
    setMsg,
    confirmPhoneValidation,
    updateDetails,
    setUpdateDetails,
    setIsAddressChange,
    isManualAddress,
    onAddAddressManual,
    setFieldValue
  } = props;
  const { user } = useSelector((state) => state.auth);
  const role = user.user_role.role
  const email = user && user.user.email;
  const phone = user && user.user.phoneNo;
  const [licenseValidation, setlicenseValidation] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyLicenceId, setCompanyLicenceId] = useState("");
  const [rejMsg, setRejMsg] = useState("");
  const [isEmailChanged, setIsEmailChanged] = useState(false);
  const [isPhoneChanged, setIsPhoneChanged] = useState(false);
  // const [saveDisable, setSaveDisable] = useState(true);
  // const [autocompletePredictions, setAutocompletePredictions] = useState([]);

  const { message } = useSelector((state) => state.message);

  const [license_loader, setLicense_loader] = useState(false);

  const refSubmit = useRef(null);

  const handleClick = () => {
    // refSubmit.current.click();
  };

  const handler = () => {
    // getLicenceData();
  };
  const handleCancel = () => {
    setShowEditProfile(false);
    resetForm()
  }

  useEffect(() => {
    if (showEditProfile === false) {
      setIsEmailChanged(false)
      setIsPhoneChanged(false)
      setUpdateDetails("")
      setIsAddressChange(false)
    }
  }, [showEditProfile])


  return (
    <Modal
      show={showEditProfile}
      onHide={() => setShowEditProfile(false)}
      backdrop="static"
      // dialogClassName={"modal-dialog-border"}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="cs-lg-modal">
      <Modal.Header>
        <Modal.Title className="default-semi-bold-h3 cs-neutral-100">
          Edit Profile
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit} className="personal-inform-form">
          <Row>
            <Col className="col" lg={6} md={6} sm={6}>
              <Form.Group
                className={`cs-form-group ${role == 2 ? "cs-disabled" : ""} `}>
                <FormLabel>First name</FormLabel>
                <Form.Control
                  type="text"
                  autoComplete="off"
                  name="firstName"
                  onKeyDown={(e) => {
                    if (e.key === " " && e.target.selectionStart === 0) {
                      e.preventDefault();
                    }
                  }}
                  placeholder="First Name"
                  defaultValue={values.firstName}
                  className={values.firstName && "cs-input-field-active"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyPress={(e) =>
                    !/[a-zA-z]/.test(e.key) && e.preventDefault()
                  }
                  disabled={role == 2 ? true : false}
                  maxLength={40}
                />
                {errors.firstName && touched.firstName ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.firstName}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
            <Col className="col" lg={6} md={6} sm={6}>
              <Form.Group
                className={`cs-form-group ${role == 2 ? "cs-disabled" : ""} `}>
                <FormLabel>Last name</FormLabel>

                <Form.Control
                  type="text"
                  autoComplete="off"
                  name="lastName"
                  onKeyDown={(e) => {
                    if (e.key === " " && e.target.selectionStart === 0) {
                      e.preventDefault();
                    }
                  }}
                  placeholder="Last Name"
                  defaultValue={values.lastName}
                  className={values.lastName && "cs-input-field-active"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyPress={(e) =>
                    !/[a-zA-z]/.test(e.key) && e.preventDefault()
                  }
                  disabled={role == 2 ? true : false}
                  maxLength={40}
                />
                {errors.lastName && touched.lastName ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.lastName}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} sm={6}>
              <Form.Group className="cs-form-group cs-disabled">
                <FormLabel>Email</FormLabel>

                <Form.Control
                  // disabled
                  type="email"
                  autoComplete="off"
                  name="email"
                  placeholder="Email Address"
                  value={values.email}
                  onKeyDown={(e) => e.code === "Space" && e.preventDefault()}
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onChange={(e) => {
                    handleChange(e);
                    setIsEmailChanged(true);
                  }}
                  // onBlur={handleBlur}
                  className={values.email && "cs-input-field-active"}
                  disabled={true}
                />
                {errors.email && touched.email ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.email}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
            <Col lg={6} md={6} sm={6}>
              <Form.Group
                className="cs-form-group"
              // onClick={() => resetMfaType(1)}
              >
                <FormLabel>Phone number</FormLabel>
                <PhoneInput
                  limitMaxLength={15}
                  international
                  countryCallingCodeEditable={false}
                  addInternationalOption={false}
                  placeholder="Phone Number"
                  autoComplete="off"
                  name="phoneNo"
                  withCountryCallingCode={false}
                  value={values.phoneNo}
                  className="form-control"
                  onChange={(e) => {
                    phoneHandleChange(e, "phoneNo");
                    if (e === phone) {
                      setIsPhoneChanged(false);
                      phoneHandleChange("", "confirmPhoneNo");
                    } else {
                      // setIsPhoneChanged(true)
                    }
                  }}
                  defaultCountry="US"
                  countries={["US"]}
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                />
                {(errors.phoneNo && touched.phoneNo) || msg ? (
                  <div>
                    {errors.phoneNo && touched.phoneNo && (
                      <p className="form-error-msg default-light-body-text-s cs-danger">
                        {errors.phoneNo}
                      </p>
                    )}
                    {msg && (
                      <span className="form-error-msg default-light-body-text-s cs-danger">
                        {msg}
                      </span>
                    )}
                  </div>
                ) : null}

                {/* <FormLabel>Phone number</FormLabel>

                <Form.Control
                  type="text"
                  // disabled
                  autoComplete="off"
                  name="phoneNo"
                  placeholder="Phone Number"
                  defaultValue={values.phoneNo}
                  className={values.phoneNo && "cs-input-field-active"}
                />
                {errors.phoneNo && touched.phoneNo ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.phoneNo}
                  </span>
                ) : null} */}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col className="col" lg={12}>
              <Form.Group
                className={`cs-form-group ${role == 2 ? "cs-disabled" : ""} `}>
                <FormLabel>Name of Company</FormLabel>
                <Form.Control
                  type="text"
                  autoComplete="off"
                  name="businessName"
                  onKeyDown={(e) => {
                    if (e.key === " " && e.target.selectionStart === 0) {
                      e.preventDefault();
                    }
                  }}
                  placeholder="Company Name"
                  defaultValue={values.businessName}
                  className={values.businessName && "cs-input-field-active"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={role == 2 ? true : false}
                  maxLength={40}
                />
                {errors.businessName && touched.businessName ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.businessName}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} sm={6}>
              {isEmailChanged && (
                <Form.Group className="cs-form-group">
                  <FormLabel>Confirm Email</FormLabel>

                  <Form.Control
                    // disabled
                    type="email"
                    autoComplete="off"
                    name="confirmEmail"
                    placeholder="Confirm Email Address"
                    onKeyDown={(e) => e.code === "Space" && e.preventDefault()}
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    // onBlur={handleBlur}
                    value={values.confirmEmail}
                    onChange={handleChange}
                    required={isEmailChanged}
                    className={values.confirmEmail && "cs-input-field-active"}
                  />
                  {errors.confirmEmail && touched.confirmEmail ? (
                    <span className="form-error-msg default-light-body-text-s cs-danger">
                      {errors.confirmEmail}
                    </span>
                  ) : null}
                </Form.Group>
              )}
            </Col>
            {/* <Col lg={6} md={6} sm={6}>
              {isPhoneChanged && <Form.Group
                className="cs-form-group"
              >
                <FormLabel>Confirm Phone number</FormLabel>
                <PhoneInput
                  limitMaxLength={15}
                  international
                  countryCallingCodeEditable={false}
                  placeholder="Phone Number"
                  autoComplete="off"
                  name="confirmPhoneNo"
                  withCountryCallingCode={false}
                  className="form-control"
                  required={true}
                  onChange={(e) => { phoneHandleChange(e, "confirmPhoneNo") }}
                  defaultCountry="US"
                  countries={["IN", "GB", "US"]}
                  onPaste={(e) => {
                    e.preventDefault()
                    return false;
                  }} onCopy={(e) => {
                    e.preventDefault()
                    return false;
                  }}
                />
                {errors.confirmPhoneNo ? (
                  <p className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.confirmPhoneNo}
                  </p>
                ) : confirmPhoneValidation ? <p className="form-error-msg default-light-body-text-s cs-danger">
                  {errors.confirmPhoneNo}
                </p> : null}

              </Form.Group>}
            </Col> */}
          </Row>
          {role == 2 && (
            <>
              <Row>
                <Col lg={6} md={6} sm={6}>
                  <Form.Group className="cs-form-group account-form-field cs-disabled">
                    <FormLabel>License number</FormLabel>
                    <Form.Control
                      type="text"
                      placeholder="License number"
                      autoComplete="off"
                      name="licenceId"
                      defaultValue={values.licenceId}
                      className={values.licenceId && "cs-input-field-active"}
                      onChange={handleChange}
                      onBlur={handler}
                      maxLength={7}
                      disabled={true}
                      onKeyPress={(e) =>
                        !/[0-9]/.test(e.key) && e.preventDefault()
                      }
                      onKeyDown={(e) => {
                        if (
                          !/\d/.test(e.key) &&
                          e.key !== "Backspace" &&
                          e.key !== "Delete"
                        ) {
                          //  e.preventDefault(); // Prevent entering non-numeric characters
                        }
                      }}
                    />
                    {errors.licenceId && touched.licenceId ? (
                      <span className="form-error-msg default-light-body-text-s cs-danger">
                        {errors.licenceId}
                      </span>
                    ) : null}
                    {licenseValidation ? (
                      <>
                        <span className="form-error-msg default-light-body-text-s cs-danger">
                          {licenseValidation}
                        </span>
                      </>
                    ) : (
                      <></>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={6} md={6} sm={6}>
                  <Form.Group className="cs-form-group account-form-field cs-disabled">
                    <FormLabel>Company name</FormLabel>
                    <Form.Control
                      type="text"
                      placeholder="Company name"
                      autoComplete="off"
                      name="companyName"
                      defaultValue={
                        !companyName
                          ? values.companyName
                          : (values.companyName = companyName)
                      }
                      className={values.companyName && "cs-input-field-active"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={true}
                    />
                    {errors.companyName && touched.companyName ? (
                      <p className="default-light-body-text-s cs-danger ">
                        {errors.companyName}
                      </p>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6} sm={6}>
                  <Form.Group className="cs-form-group">
                    <FormLabel>Company license number</FormLabel>

                    <Form.Control
                      type="text"
                      placeholder="Company license number"
                      autoComplete="off"
                      name="companyLicenceId"
                      defaultValue={
                        !companyLicenceId
                          ? values.companyLicenceId
                          : (values.companyLicenceId = companyLicenceId)
                      }
                      className={
                        values.companyLicenceId && "cs-input-field-active"
                      }
                      onChange={handleChange}
                      // onBlur={handleBlur}
                      onKeyDown={(e) => {
                        if (
                          !/\d/.test(e.key) &&
                          e.key !== "Backspace" &&
                          e.key !== "Delete"
                        ) {
                          e.preventDefault(); // Prevent entering non-numeric characters
                        }
                      }}
                    />
                    {errors.companyLicenceId && touched.companyLicenceId ? (
                      <span className="form-error-msg default-light-body-text-s cs-danger">
                        {errors.companyLicenceId}
                      </span>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>
            </>
          )}

          <AddressSearch
            setUpdateDetails={setUpdateDetails}
            {...props}
            record={record.address}
          />

          {rejMsg && (
            <div className="cs-msg default-regular-body-text-s cs-danger">
              <span className="icon">
                <CapsyncIcon title="info-filled" />
              </span>
              <span className="txt"> {message}</span>
            </div>
          )}
          <div className="cs-form-group mt-2">
            {updateDetails && (
              <div className="cs-msg default-regular-body-text-s cs-danger">
                <span className="icon">
                  <CapsyncIcon title="info-filled" size="16" />
                </span>
                <span className="txt"> {updateDetails}</span>
              </div>
            )}
          </div>
          <div className="cs-modal-btn">
            <Button
              className="cs-btn-secondary md-btn default-regular-h6"
              type="button"
              onClick={() => {
                handleCancel();
                setMsg("");
              }}>
              Cancel
            </Button>
            <Button
              type="submit"
              className="cs-btn-primary md-btn default-regular-h6"
              disabled={
                saveDisable ||
                isPhoneChanged ||
                (isEmailChanged && !values.confirmEmail)
              }
            //uncommenet 3 lines for confirm Phone Number 1.onchange Phone number Field 2.Confirm Phone number input field 3.submit button
            // disabled={saveDisable || (isPhoneChanged && !values.confirmPhoneNo) || (isEmailChanged && !values.confirmEmail)}
            >
              Update
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditProfie;
