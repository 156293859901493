/* eslint-disable */
import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import ReactDOM from "react-dom";
import { Button, Container } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { usePlaidLink } from "react-plaid-link";
import secureLocalStorage from "react-secure-storage";

import SidebarSection from "./utilities/SidebarSection";
import NavbarSection from "./utilities/NavbarSection";
import { backAppURl } from "../../applicationMode";
import DeleteModal from "../../commonComponent/modalPopup/deleteModal/DeleteModal";
import LogoutModal from "../../commonComponent/component/LogoutConfirmationModal";
import UseFetchDetails from "../../customHooks/UseFetchDetails";
import {
  add_advisors,
  change_edit_permission,
  get_all_details,
  requestFetchAll,
  requestsNotifications,
  user_access_permission,
} from "../../slices/requests";
import UseMasterTable from "../../customHooks/UseMasterTable";
import { userFindRecord } from "../../slices/user";
import { socket } from "../../config/Socket";
import { categoryTotal, categoryTotalNew } from "../../slices/category.slice";
import SkeletonNavbarSection from "./utilities/SkeletonNavbarSection";
import SkeletonSidebarSection from "./utilities/SkeletonSidebarSection";
import { access_invitation_Pfs } from "../../slices/pfs_access";
import SubscribeModal from "./dashboard/SubscribeModal";
import CompleteProfile from "./dashboard/InitialSetup/CompleteProfile";
import InitialCommonModel from "./dashboard/InitialSetup/InitialCommonModel";
import { userRequestList } from "../../slices/requests";
import { Elements } from "@stripe/react-stripe-js";
import {
  authUserUpdated,
  authUserPFSData,
  userLastSyncDate,
} from "../../slices/auth";
import {
  creditManagerPermissionAccess,
  filemanagementPermissionAccess,
  identityProtectPermissionAccess,
  sidebarMenuPermissionAccess,
  standardPermissionUrl,
  verifyInvestorPermissionAccess,
  viewOnlyAccess,
} from "../../config/authUser";
import MicroDepositeVerificationModal from "../../commonComponent/paymentSubscription/subscription/MicroDepositeVerificationModal";
import CapsyncLoader from "../../commonComponent/CapsyncLoader";
import { setActiveTabState } from "../../slices/dashboard";
import { roleName } from "../../config/commonRoleName";
import UploadStatus from "./fileManagement/UploadStatus";
import {
  get_user_storage,
  setProgressUploadDetails,
  showUploadProgress,
  useUploadProgress,
} from "../../slices/fileManagement.slice";
import UpgradeStorageModal from "./fileManagement/UpgradeStorageModal";
import { loadStripe } from "@stripe/stripe-js";
import DownloadStatus from "./fileManagement/DownloadStatus";

const Layout = ({ page: Page }) => {
  UseMasterTable();
  const navigation = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  const plaidButton = useRef(null);
  const pfsAccessId = window.localStorage.getItem("pfs_access_id");
  const localStorageUser = JSON.parse(localStorage.getItem("user"));
  const pfs_access_obj = JSON.parse(localStorage.getItem("pfs_access_obj"));
  const uploadProgress = useUploadProgress();
  useEffect(() => {
    let reactClass = window.location.pathname.substring(1).replaceAll("/", "-");
    document.body.classList.add(reactClass);
    return () => {
      document.body.classList.remove(reactClass);
    };
  }, []);

  const { user, authUser, subscribePermission } = useSelector(
    (state) => state.auth
  );
  const { emptyCategory } = useSelector((state) => state.category);
  const userId = useRef("");
  const userEmail = useRef("");
  const storeAllNotifications = useSelector(
    (state) => state.notification.notification
  );
  const [showModalTrue, setShowModalTrue] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [storageData, setStorageData] = useState({
    maxSpace: 0,
    usedSpace: 0,
  });
  const [getuserLoader, setGetUserLoader] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 991);
  let id;
  let current_user_email;
  let { fetchUserData } = UseFetchDetails();
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
    current_user_email = fetchUserData.email;
  } else {
    id = user && user.user.id;
    current_user_email = user && user.user.email;
  }
  const sidebarMenuPermission = subscribePermission && subscribePermission;
  const current_user_id = user && user.user.id;
  const user_role = user && user.user_role.role;
  const loginStatus = localStorage.getItem("loginStatus");

  const dashactive = useRef(false);
  const [profileState, setProfileState] = useState(false);
  const verifyinvestoractive = useRef(false);
  const assetactive = useRef(false);
  const collaborationactive = useRef(false);
  const liabiliactive = useRef(false);
  const requestactive = useRef(false);

  const location = useLocation();
  const pathname = location.pathname;

  const navigateBack = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);

  const [dataLiability_filter_wise, setDataLiability_filter_wise] = useState(
    {}
  );
  const [dataLiability, setDataLiability] = useState({});
  const [paymentInstallAuto, setPaymentInstallAuto] = useState({});
  const [balanceDueAuto, setBalanceDueAuto] = useState({});
  const [dataAsset_filter_wise, setDataAsset_filter_wise] = useState({});
  const [dataAsset, setDataAsset] = useState({});
  const [requestButton, setRequestButton] = useState(false);
  const [syncLoader, setSyncLoader] = useState(false);
  const [categoryAccountAdded, setCategoryAccountAdded] = useState(false);

  const [is_loader, setIs_laoder] = useState({
    assets: true,
    liabilities: true,
  });
  const [isSharePfs, setIsSharePfs] = useState(null);

  const [profilePicture, setProfilePicture] = useState("");
  const [modal, setModal] = useState(false);
  const [thumbnailMsg, setThumbnailMsg] = useState("");
  const [isMsgModalOpen, setMsgModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [modalType, setModalType] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [fetchAllIndividuals, setFetchAllIndividuals] = useState([]);
  const [isMobileAccessUser, setisMobileAccessUser] = useState(
    window.innerWidth < 768
  );
  const [mainFilterStatus, setMainFilterStatus] = useState(true);
  const [customImgSrc, setCustomImgSrc] = useState("");
  const [individualUserData, setIndividualUserData] = useState({});
  const [isSubscribeModel, setIsSubscribeModel] = useState(true);
  const [editAccessUser, setEditAccessUser] = useState({});
  const [standardUsers, setStandardUsers] = useState([]);

  const [plaidErrorAccounts, setPlaidErrorAccounts] = useState({
    error_accounts: [],
    error_free_accounts: [],
  });
  const [isPlaidDataSynced, setIsPlaidDataSynced] = useState(false);
  const [plaidSync, setPlaidSync] = useState(true);
  const navigate = useNavigate();
  const [deleteStatus, setDeleteStatus] = useState({
    show: false,
    message: "",
    title: "",
    custom: "",
  });
  const [openSide, setOpenSide] = useState(!isMobile ? true : false);
  let sideBarStyle = null;
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setIsMobile(window.innerWidth <= 991);
    setisMobileAccessUser(window.innerWidth <= 767);
  };

  // list of all sharedPfs
  const [sharedPfs, setSharedPfs] = useState([]);
  const [addAccountModal, setAddAccountModal] = useState(false);

  const [showCompleteProfile, setShowCompleteProfile] = useState(true);
  const [standardAccessingUsers, setStandardAccessingUsers] = useState([]);

  const [isFilesUpdated, setIsFileUpdated] = useState(false);

  const handleCompleteProfileUpdate = (user) => {
    setShowCompleteProfile(false);
    dispatch(authUserUpdated({ user }));
    setIsSubscribeModel(true);
  };
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );

  function changeRequestButtonStatus(params) {
    setRequestButton(params);
  }

  function changeAccountModal(params, closeModal = false) {
    setAddAccountModal(params);
    if (
      dataAsset &&
      dataAsset.grandTotal == 0 &&
      dataLiability &&
      dataLiability.grandTotal == 0 &&
      closeModal == false
    ) {
      setAddAccountModal(true);
      CapsyncLogoutModal(!params);
    }
  }

  const fetchSignatureFromDetails = () => {
    dispatch(
      userRequestList({
        id: userId.current,
        user_email: userEmail.current,
        flag: roleName.individual,
      })
    )
      .unwrap()
      .then((response) => {})
      .catch((err) => {
        console.log("errrrrrrrrrr", err);
      });
  };
  const getUserStorageFunction = async () => {
    const response = await dispatch(get_user_storage({ id })).unwrap();
    response && setStorageData(response.data.storage || {});
  };
  useEffect(() => {
    getUserStorageFunction();
  }, []);

  useEffect(() => {
    if (uploadProgress.data.length > 0) {
      const unloadCallback = (event) => {
        const confirmationMessage = "Are you sure you want to leave this page?";
        event.returnValue = confirmationMessage; // Standard-compliant browsers
        return confirmationMessage; // Required for legacy browsers
      };

      window.addEventListener("beforeunload", unloadCallback);
      return () => window.removeEventListener("beforeunload", unloadCallback);
    }
  }, [uploadProgress.data.length]);

  useEffect(() => {
    socket.on("get_updated_list", (msg) => {
      // alert("zdfz");
    });
  });

  //   socket.on("user_downgraded_plan", (data) => {
  //     window.location.reload();
  //   });
  // }, []);
  useEffect(() => {
    const handleUpdatedList = (msg) => {
      fetchUser();
    };
    const handleDowngradedPlan = (data) => {
      window.location.reload();
    };
    // Subscribe to socket events
    socket.on("get_updated_list", handleUpdatedList);
    socket.on("user_downgraded_plan", handleDowngradedPlan);
    socket.on("quickbooks_redirect", (msg) => {
      if (window.localStorage.getItem("quickbooks_redirect")) {
        navigation(
          `/individual/assets/business/reports/${window.localStorage.getItem(
            "quickbooks_redirect"
          )}`
        );
      }
    });

    // Unsubscribe from socket events when component unmounts
    return () => {
      socket.off("get_updated_list", handleUpdatedList);
      socket.off("user_downgraded_plan", handleDowngradedPlan);
    };
  }, []);

  useEffect(() => {
    if (!loginStatus) {
      navigateBack("/");
    }
  }, []);

  useEffect(() => {
    socket.emit("fetch_user", {
      user_id: current_user_id,
      user_role:
        user_role === 1
          ? roleName.individual
          : user_role === 2
          ? roleName.financial_advisor
          : roleName.tax_professional,
      user_type: user_role,
    });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    if (dataAsset.grandTotal == "0.00" && dataLiability.grandTotal == "0.00") {
      changeAccountModal(true);
    }
  }, [dataAsset, dataLiability]);

  // useEffect(() => {
  //   socket.on("get_updated_list", (msg) => {
  //     if (window.localStorage.getItem('pfs_access_obj')) {
  //       // get user back for multi user flow
  //       socket.emit("give_access_to_advisor", {
  //         user_id: JSON.parse(window.localStorage.getItem('pfs_access_id')),
  //         advisor_user_id: window.localStorage.getItem('id'),
  //         flag: JSON.parse(window.localStorage.getItem('loginStatus')).user_role === 1 ? roleName.individual : JSON.parse(window.localStorage.getItem('loginStatus')).user_role == 2 ? roleName.financial_advisor : roleName.tax_professional
  //       })
  //     }
  //     fetch_access_invitation_Pfs();
  //     checkStandardView()
  //   });
  //   socket.on("newNotification", (msg) => {
  //     getNotificationRequests()
  //   })
  // }, []);
  useEffect(() => {
    const handleGetUpdatedList = (msg) => {
      if (window.localStorage.getItem("pfs_access_obj")) {
        // get user back for multi user flow
        socket.emit("give_access_to_advisor", {
          user_id: JSON.parse(window.localStorage.getItem("pfs_access_id")),
          advisor_user_id: window.localStorage.getItem("id"),
          flag:
            JSON.parse(window.localStorage.getItem("loginStatus")).user_role ===
            1
              ? roleName.individual
              : JSON.parse(window.localStorage.getItem("loginStatus"))
                  .user_role == 2
              ? roleName.financial_advisor
              : roleName.tax_professional,
        });
      }
      fetch_access_invitation_Pfs();
      checkStandardView();
    };

    const handleNewNotification = (msg) => {
      getNotificationRequests();
    };

    socket.on("get_updated_list", handleGetUpdatedList);
    socket.on("newNotification", handleNewNotification);

    // Clean up by turning off socket events when component unmounts
    return () => {
      socket.off("get_updated_list", handleGetUpdatedList);
      socket.off("newNotification", handleNewNotification);
    };
  }, []);

  useEffect(() => {
    const regexPattern =
      /^\/individual\/verifyinvestor\/accreditedInvestor\/\w+\/\w{6,}(\/\d+)?$/;

    //Redirect to dashboard when view only - update on user access permisssion
    if (authUser && authUser.pfs_access) {
      const checkPfsAccessUser =
        authUser &&
        authUser.pfs_access.find((access) => access.id === Number(pfsAccessId));
      if (checkPfsAccessUser && checkPfsAccessUser.to_access_permission === 1) {
        if (viewOnlyAccess.includes(location.pathname)) {
          navigation("/individual");
        } else if (regexPattern.test(location.pathname)) {
          navigation("/individual");
        }
      } else if (
        checkPfsAccessUser &&
        checkPfsAccessUser.to_access_permission === 2
      ) {
        if (standardPermissionUrl.includes(location.pathname)) {
          navigation("/individual");
        }
      }
    }
  }, [location, authUser]);
  const fetchUser = () => {
    setGetUserLoader(true);
    if (id) {
      dispatch(userFindRecord({ id }))
        .unwrap()
        .then((response) => {
          setGetUserLoader(false);
          setIndividualUserData(response);
        })
        .catch((err) => {
          setGetUserLoader(false);
          console.log(err);
        });
    }
  };

  useEffect(() => {
    fetchUser();
  }, [profileState]);

  useEffect(() => {
    const regexPattern =
      /^\/individual\/verifyinvestor\/accreditedInvestor\/\w+\/\w{6,}(\/\d+)?$/;
    if (sidebarMenuPermission && getuserLoader === false) {
      if (
        sidebarMenuPermission &&
        sidebarMenuPermission.is_allow_creditmanager !== "1" &&
        creditManagerPermissionAccess.includes(location.pathname)
      ) {
        navigation("/individual");
      } else if (
        regexPattern.test(location.pathname) &&
        sidebarMenuPermission.is_allow_accreditation !== "1"
      ) {
        navigation("/individual");
      } else if (
        sidebarMenuPermission.is_allow_accreditation !== "1" &&
        verifyInvestorPermissionAccess.includes(location.pathname)
      ) {
        navigation("/individual");
      } else if (
        sidebarMenuPermission.is_allow_identityprotect !== "1" &&
        identityProtectPermissionAccess.includes(location.pathname)
      ) {
        navigation("/individual");
      } else if (
        sidebarMenuPermission.is_allow_filemanagement !== "1" &&
        filemanagementPermissionAccess.includes(location.pathname)
      ) {
        navigation("/individual");
      }
    }
  }, [user, getuserLoader]);

  useEffect(() => {
    const regexPattern =
      /^\/individual\/verifyinvestor\/accreditedInvestor\/\w+\/\w{6,}(\/\d+)?$/;

    if (sidebarMenuPermission && getuserLoader === false) {
      const isPermissionEmpty = Object.keys(sidebarMenuPermission).length === 0;
      const matchesRestrictedPath =
        filemanagementPermissionAccess.includes(location.pathname) ||
        /^\/individual\/file-management(\/.*)?$/.test(location.pathname) ||
        regexPattern.test(location.pathname);

      if (isPermissionEmpty && matchesRestrictedPath) {
        navigation("/individual");
      }
    }
  }, [
    location.pathname,
    sidebarMenuPermissionAccess,
    navigation,
    getuserLoader,
  ]);

  const fetchAllStandardAccessingUsers = async () => {
    try {
      const response = await dispatch(
        get_all_details({
          user_id: id,
        })
      ).unwrap();
      if (window.localStorage.getItem("pfs_access_obj") === null) {
        let welUsers = response.data.wealth_manager.map((ele) => ({
          ...ele,
          roleName: "wealthmanager",
        }));
        let indUsers = response.data.individual.map((ele) => ({
          ...ele,
          roleName: "user",
        }));
        // welUsers.forEach((ele) => ele.role = "wealth_manager")
        setStandardAccessingUsers([...welUsers, ...indUsers]);
      } else {
        let individual = [],
          wealth_manager = [];
        if (
          JSON.parse(window.localStorage.getItem("loginStatus")).user_role === 1
        ) {
          individual = response.data.individual
            .filter((x) => x.id !== Number(window.localStorage.getItem("id")))
            .map((item, index) => ({ ...item, roleName: "user" }));
          wealth_manager = response.data.wealth_manager.map((val) => ({
            ...val,
            roleName: "wealthmanager",
          }));
        } else {
          individual = response.data.individual.map((item) => ({
            ...item,
            roleName: "user",
          }));
          wealth_manager = response.data.wealth_manager
            .filter((x) => x.id !== Number(window.localStorage.getItem("id")))
            .map((val) => ({ ...val, roleName: "wealthmanager" }));
        }
        setStandardAccessingUsers([...wealth_manager, ...individual]);
      }
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  const logUserRole =
    authUser && authUser.user_role ? authUser.user_role : authUser;

  function fetch_access_invitation_Pfs() {
    dispatch(
      access_invitation_Pfs({
        email: authUser && (authUser.email || authUser.user.email),
      })
    )
      .unwrap()
      .then((response) => {
        if (window.localStorage.getItem("pfs_access_id")) {
          const isAccess = response.find(
            (userData) =>
              userData.id == window.localStorage.getItem("pfs_access_id")
          );

          if (!isAccess) {
            toastMessage("You don't have access to use this Account!", "error");
            window.localStorage.removeItem("pfs_access_obj");
            window.localStorage.removeItem("pfs_access_id");

            setTimeout(() => {
              navigation(0);
            }, 2000);
          }
        }

        const approvedUsers = response.filter((userdata) =>
          logUserRole.role == 2 || logUserRole.role == 3
            ? userdata.to_status === 1 && userdata.is_subscribe
            : userdata.to_status === 1
        );
        setSharedPfs(approvedUsers);
        dispatch(authUserPFSData(approvedUsers));
        setIsSharePfs(true);
      })
      .catch(() => {});
  }

  useEffect(() => {
    if (!!authUser && !isSharePfs) {
      fetch_access_invitation_Pfs();
    }
  }, [authUser]);

  function toastMessage(params, type) {
    toast[type](params);
  }

  const openAddAccountModal = (s) => {
    setShowModalTrue(s);
  };

  function toggleSidebar(params) {
    setOpenSide(params);
  }

  function changeSrcStatus(params) {
    setCustomImgSrc(params);
  }

  function changeFilterStatus(params) {
    setMainFilterStatus(params);
  }

  function CapsyncDeleteMessage(showDeleteModal, msg, title, func) {
    if (!showDeleteModal) {
      setDeleteStatus({
        ...deleteStatus,
        show: false,
      });
    } else {
      setDeleteStatus({
        show: showDeleteModal,
        message: msg,
        title: title,
        custom: func,
      });
    }
  }

  const [logoutStatus, setlogoutStatus] = useState({
    show: false,
  });

  function CapsyncLogoutModal(s) {
    setlogoutStatus({
      show: s,
    });
  }

  const CapsyncMessage = (title, message, type) => {
    setMsgModalOpen(true);
    setModalTitle(title);
    setModalMessage(message);
    setModalType(type);
  };

  useEffect(() => {
    setTimeout(() => {
      setMsgModalOpen(false);
    }, 4000);
  }, [CapsyncMessage]);

  const [categoryData, setCategoryData] = useState({
    liability_installment_auto: false,
    liability_unpaid_taxes: false,
    liability_realestate_mortgages: false,
    liability_other_liabilities: false,
    liability_notes_payable: false,
    liability_installment_account: false,
    liability_accounts_payable: false,
    liability_automobiles: false,
    liability_life_insurance: false,

    asset_real_estate: false,
    asset_personal_property: false,
    asset_automobiles: false,
    asset_business: false,
    asset_stock_bonds: false,
    asset_life_insurance: false,
    asset_account_receivable: false,
    asset_retirement_accounts: false,
    asset_saving_accounts: false,
    asset_cash_on_hands: false,
    asset_other_assets: false,
  });

  function changeProfileState(params) {
    setProfileState(params);
  }

  function storeCategoryFunc(params) {
    setCategoryData({ ...categoryData, [params]: true });
  }

  function changeCategoryData(params) {
    storeCategoryFunc(params);
  }

  const handleclick = () => {
    if (isMobile) {
      toggle();
      setOpenSide(false);
    }
  };

  useEffect(() => {
    updateWidth();
    window.addEventListener("resize", updateWidth.bind(this));
    return () => {
      window.removeEventListener("resize", updateWidth.bind(this));
    };
  }, []);

  const updateWidth = useCallback(() => {
    const width = window.innerWidth;
    const widthLimit = 1900;

    if (width <= widthLimit) {
      if (width < 1900) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    } else {
      setIsOpen(true);
    }
  }, [isOpen]);

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  });

  const getProfilePicture = () => {
    let storeImg = `${backAppURl}/api/user/profile/${id}`;
    setProfilePicture(storeImg);
  };

  useEffect(() => {
    getProfilePicture();
  }, [profilePicture]);

  const openInitialModalState = () => {
    setModal(true);
  };

  const changeToggle = () => {
    setIsOpen(true);
  };

  const getNotificationRequests = () => {
    dispatch(
      requestsNotifications({
        user_id: id,
        module_name: roleName.individual,
      })
    );
  };

  const handleResponseFromIndividualDecision = () => {
    fetchAllStandardAccessingUsers();
  };

  useEffect(() => {
    socket.on("uploadProgress", (progress) => {
      if (
        progress.code === 200 &&
        progress.message === "success" &&
        progress.isDelete === true
      ) {
        getUserStorageFunction();
        if (progress.duplicate_file && progress.duplicate_file.length === 0) {
          setIsFileUpdated((oldData) => !oldData);
          toast.success("Your file has been uploaded successfully.");
        } else if (
          progress.duplicate_file &&
          progress.duplicate_file.length > 0
        ) {
          setIsFileUpdated((oldData) => !oldData);
          toast.error("Files with names that already exist can't be fetched.");
        }
      } else if (
        progress.code === 500 &&
        progress.message == "Please increase the Storage"
      ) {
        setShowUpgradeModal(true);
      } else if (progress.code === 500) {
        toast.error(progress.message);
      }
      dispatch(showUploadProgress(progress));
      dispatch(
        setProgressUploadDetails({
          id: progress.id,
          size: progress.totalSize,
          isDelete: progress.isDelete,
        })
      );
    });

    socket.on("thumbnailResponse", (message) => {
      setThumbnailMsg(message);
      setIsFileUpdated((oldData) => !oldData);
    });

    return () => {
      socket.off("uploadProgress");
      socket.off("thumbnailResponse");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Define socket event handlers
    const handleQuickbooksReport = (msg) => {
      navigation("/individual/assets/business/reports");
    };

    const handleSignatureRequestStatusUpdate = () => {
      fetchSignatureFromDetails();
    };
    socket.on("quickbooks_report", handleQuickbooksReport);
    socket.on(
      "response_from_individual_decision_to_main_user",
      handleResponseFromIndividualDecision
    );
    socket.on(
      "response_from_individual_decision_to_other_wealth_managers",
      handleResponseFromIndividualDecision
    );
    socket.on(
      "response_from_individual_decision_to_other_individuals",
      handleResponseFromIndividualDecision
    );
    socket.on(
      "signature_request_status_update",
      handleSignatureRequestStatusUpdate
    );
    socket.on(
      "signature_request_status_update_individual",
      handleSignatureRequestStatusUpdate
    );
    socket.on(
      "signature_request_status_update_spouse",
      handleSignatureRequestStatusUpdate
    );
    // return () => {
    //   socket.off('quickbooks_report', handleQuickbooksReport);
    //   socket.off('response_from_individual_decision_to_main_user', handleResponseFromIndividualDecision);
    //   socket.off('response_from_individual_decision_to_other_wealth_managers', handleResponseFromIndividualDecision);
    //   socket.off('response_from_individual_decision_to_other_individuals', handleResponseFromIndividualDecision);
    //   socket.off('signature_request_status_update', handleSignatureRequestStatusUpdate);
    //   socket.off('signature_request_status_update_individual', handleSignatureRequestStatusUpdate);
    //   socket.off('signature_request_status_update_spouse', handleSignatureRequestStatusUpdate);
    // };
  }, []);

  useEffect(() => {
    getNotificationRequests();
    errorAccounts();
    fetchAllStandardAccessingUsers();
  }, []);

  useEffect(() => {
    fetchAllNotifications();
  }, [current_user_email]);

  const fetchAllNotifications = useCallback(() => {
    if (current_user_email !== undefined) {
      dispatch(
        requestFetchAll({
          id: id,
          user_email: current_user_email,
        })
      )
        .unwrap()
        .then((response) => {
          const count = response.individual.filter((x) => x.check_status === 3);
          setFetchAllIndividuals(response.individual);
          setNotifications(count);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id, fetchAllIndividuals, notifications, current_user_email]);

  // const getCategoryTotalLib = useCallback(() => {
  //   dispatch(categoryTotal({ id: id, main_category: "liabilities" }))
  //     .unwrap()
  //     .then((response) => {
  //       console.log("setDataLiability_filter_wise", response);
  //       setDataLiability_filter_wise(response.filter_wise_total);
  //       // setDataLiability(response);
  //       // setIs_laoder((prev) => ({ ...prev, liabilities: false }));
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, [dataLiability, dataLiability_filter_wise, id, is_loader]);

  // const getCategoryTotal = useCallback(() => {
  //   dispatch(categoryTotal({ id: id, main_category: "assets" }))
  //     .unwrap()
  //     .then((response) => {
  //       console.log("resssssssssssssssssssss", response)
  //       setBalanceDueAuto(
  //         response.assets_category_total.automobileBalanceDueTotal
  //       );
  //       setDataAsset_filter_wise(response.filter_wise_total);
  //       // setDataAsset(response);
  //       setPaymentInstallAuto(
  //         response.assets_category_total.automobilePaymentTotal
  //       );
  //       // setIs_laoder((prev) => ({ ...prev, assets: false }));
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, [
  //   balanceDueAuto, dataAsset_filter_wise,
  //   dataAsset, paymentInstallAuto, is_loader,
  //   id,
  // ]);

  useEffect(() => {
    dispatch(
      userLastSyncDate({
        userId: id,
      })
    );
    socket.on("all_accounts_synced", (msg) => {
      dispatch(
        userLastSyncDate({
          userId: id,
        })
      );
      setIsPlaidDataSynced(true);
    });

    return () => {
      socket.off("all_accounts_synced");
    };
  }, []);

  useEffect(() => {
    if (
      (dataAsset.grandTotal !== "0.00" ||
        dataLiability.grandTotal !== "0.00") &&
      (Object.keys(dataAsset).length !== 0 ||
        Object.keys(dataLiability).length !== 0) &&
      isPlaidDataSynced === true
    ) {
      setPlaidSync(false);
      // getCategoryTotal();
      // getCategoryTotalLib();
      getNewCategoryTotal();
      setTimeout(() => {
        setPlaidSync(true);
        errorAccounts();
      }, 2000);
      setIsPlaidDataSynced(false);
    }
  }, [dataAsset, dataLiability, isPlaidDataSynced]);

  function storeIndPfs(spouse_id, pfs_access_role, email) {
    if (email) {
      window.localStorage.setItem("pfs_access_email", email.data.email);
    }
    let pfs_access_obj = {
      pfs_access_id: spouse_id,
      pfs_access_role: pfs_access_role,
    };
    window.localStorage.setItem(
      "pfs_access_obj",
      JSON.stringify(pfs_access_obj)
    );
    window.localStorage.setItem("pfs_access_id", spouse_id);
    window.location.href = "/individual";
  }

  function errorAccounts() {
    axios
      .post(`${backAppURl}/api/plaid/error_accounts`, { user_id: id })
      .then((response) => {
        setPlaidErrorAccounts({
          error_accounts: response.data.error_accounts,
          error_free_accounts: response.data.error_free_accounts,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function fixPlaidError(params) {
    axios
      .post(`${backAppURl}/api/plaid/update_mode`, {
        institution_id: params.institution_id,
        user_id: id,
      })
      .then((response) => {
        setSyncLoader(true);
        secureLocalStorage.setItem(
          "update_mode_link_token",
          response.data.link_token
        );
        secureLocalStorage.setItem(
          "error_institution_id",
          params.institution_id
        );
        setTimeout(() => {
          plaidButton.current.click();
          setSyncLoader(false);
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const config = {
    token: secureLocalStorage.getItem("update_mode_link_token"),
    onSuccess: (public_token, metadata) => {
      axios
        .post(`${backAppURl}/api/plaid/public_exchange_token`, {
          token: public_token,
        })
        .then((response) => {
          socket.emit("error_plaid_user_id", {
            user_id: id,
            error_institution_id: secureLocalStorage.getItem(
              "error_institution_id"
            ),
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onExit: (err, metadata) => {},
    onEvent: (eventName, metadata) => {},
  };

  const { open, ready } = usePlaidLink(config);

  const changeModal = () => {
    if (ready) {
      open();
    }
  };

  const handleToggleSideBar = () => {
    setOpenSide(!openSide);
  };

  if (width <= 991) {
    sideBarStyle = { paddingLeft: "0px", paddingRight: "0px" };
  } else {
    sideBarStyle = openSide
      ? { paddingLeft: "272px", paddingRight: "0px", transition: "all 0.3s" }
      : { paddingLeft: "72px", paddingRight: "0px", transition: "all 0.3s" };
  }
  useEffect(() => {
    const broadcastChannel = new BroadcastChannel("switch_account_channel");
    // broadcastChannel.onmessage = (event) => {
    //   if (event.data === "switch_account") {
    //     setTimeout(() => {
    //       window.location.reload();
    //     }, 500);
    //   }
    // };
    return () => {
      broadcastChannel.close();
    };
  }, []);
  const handleSwitchAccount = () => {
    const broadcastChannel = new BroadcastChannel("switch_account_channel");
    broadcastChannel.postMessage("switch_account");
  };

  const switchAccount = (params) => {
    if (window.localStorage.getItem("pfs_access_obj") === null) {
      dispatch(
        user_access_permission({
          user_id: Number(params),
          advisor_id: Number(window.localStorage.getItem("id")),
          user_role:
            JSON.parse(window.localStorage.getItem("loginStatus")).user_role ===
            1
              ? roleName.individual
              : JSON.parse(window.localStorage.getItem("loginStatus"))
                  .user_role == 2
              ? roleName.financial_advisor
              : roleName.tax_professional,
        })
      )
        .unwrap()
        .then((response) => {
          window.localStorage.setItem("pfs_access_email", response.data.email);
          if (response.access_status === 2) {
            dispatch(
              add_advisors({
                advisor_user_id: Number(window.localStorage.getItem("id")),
                user_id: Number(params),
                flag:
                  JSON.parse(window.localStorage.getItem("loginStatus"))
                    .user_role === 1
                    ? roleName.individual
                    : JSON.parse(window.localStorage.getItem("loginStatus"))
                        .user_role === 2
                    ? roleName.financial_advisor
                    : roleName.tax_professional,
              })
            )
              .unwrap()
              .then((response) => {
                socket.emit("broadcast_message", { user_id: Number(params) });
                storeIndPfs(params, 1);
              })
              .catch((err) => {
                console.log("err:- ", err);
              });
          } else {
            storeIndPfs(params, 1);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      dispatch(
        change_edit_permission({
          advisor_user_id: Number(window.localStorage.getItem("id")),
          user_id: Number(
            JSON.parse(window.localStorage.getItem("pfs_access_obj"))
              .pfs_access_id
          ),
          flag:
            JSON.parse(window.localStorage.getItem("loginStatus")).user_role ===
            1
              ? roleName.individual
              : JSON.parse(window.localStorage.getItem("loginStatus"))
                  .user_role === 2
              ? roleName.financial_advisor
              : roleName.tax_professional,
        })
      )
        .unwrap()
        .then((res) => {
          socket.emit("broadcast_message", {
            user_id: Number(
              JSON.parse(window.localStorage.getItem("pfs_access_obj"))
                .pfs_access_id
            ),
          });
          dispatch(
            user_access_permission({
              user_id: Number(params),
              advisor_id: Number(window.localStorage.getItem("id")),
              user_role:
                JSON.parse(window.localStorage.getItem("loginStatus"))
                  .user_role === 1
                  ? roleName.individual
                  : JSON.parse(window.localStorage.getItem("loginStatus"))
                      .user_role === 2
                  ? roleName.financial_advisor
                  : roleName.tax_professional,
            })
          )
            .unwrap()
            .then((access_permission_response) => {
              window.localStorage.setItem(
                "pfs_access_email",
                access_permission_response.data.email
              );

              if (access_permission_response.access_status === 2) {
                dispatch(
                  add_advisors({
                    advisor_user_id: Number(window.localStorage.getItem("id")),
                    user_id: Number(params),
                    flag:
                      JSON.parse(window.localStorage.getItem("loginStatus"))
                        .user_role === 1
                        ? roleName.individual
                        : JSON.parse(window.localStorage.getItem("loginStatus"))
                            .user_role === 2
                        ? roleName.financial_advisor
                        : roleName.tax_professional,
                  })
                )
                  .unwrap()
                  .then(() => {
                    socket.emit("broadcast_message", {
                      user_id: Number(params),
                    });
                    storeIndPfs(params, 1, access_permission_response);
                  })
                  .catch((err) => {
                    console.log("err:- ", err);
                  });
              } else {
                storeIndPfs(params, 1, access_permission_response);
              }
            })
            .catch((err) => {
              console.log("err:- ", err);
            });
        })
        .catch((err) => {
          console.log("err:- ", err);
        });
    }
    window.localStorage.setItem("last_pfs_access_id", params);
  };

  function checkStandardView() {
    if (window.localStorage.getItem("pfs_access_obj") !== null) {
      dispatch(
        user_access_permission({
          user_id: JSON.parse(window.localStorage.getItem("pfs_access_obj"))
            .pfs_access_id,
          advisor_id: Number(window.localStorage.getItem("id")),
          user_role:
            JSON.parse(window.localStorage.getItem("loginStatus")).user_role ===
            1
              ? roleName.individual
              : JSON.parse(window.localStorage.getItem("loginStatus"))
                  .user_role === 2
              ? roleName.financial_advisor
              : roleName.tax_professional,
        })
      )
        .unwrap()
        .then((response) => {
          if (response.access_status === 2) {
            setEditAccessUser(true);
          } else {
            setEditAccessUser(false);
          }
        })
        .catch(() => {});
    }
  }

  useEffect(() => {
    checkStandardView();
  }, []);

  useEffect(() => {
    userId.current = user.user.id;
    userEmail.current = user.user.email;
  }, [user.user]);

  const logUser = authUser && authUser.user ? authUser.user : authUser;

  useEffect(() => {
    if (
      logUser &&
      logUser.is_subscribe === false &&
      sharedPfs.length != 0 &&
      logUser.address
    ) {
      let pfsId =
        sharedPfs && sharedPfs.find((item) => item.is_subscribe === true);
      if (
        logUser.account_id === null &&
        window.localStorage.getItem("last_pfs_access_id") === null
      ) {
        !!pfsId ? switchAccount(pfsId.id) : switchAccount(sharedPfs[0].id);
      } else {
        !!pfsId
          ? window.localStorage.setItem("last_pfs_access_id", pfsId.id)
          : window.localStorage.setItem("last_pfs_access_id", sharedPfs[0].id);
      }
    }
  }, [sharedPfs, user.user.address]);

  useEffect(() => {
    // getCategoryTotal();
    // getCategoryTotalLib();
    getNewCategoryTotal();
  }, [emptyCategory]);

  const changeCapsyncUser = () => {
    socket.emit("broadcast_message", {
      user_id: Number(
        window.localStorage.getItem("pfs_access_obj") &&
          JSON.parse(window.localStorage.getItem("pfs_access_obj"))
            .pfs_access_id
      ),
    });
    dispatch(
      change_edit_permission({
        advisor_user_id: Number(window.localStorage.getItem("id")),
        user_id: Number(
          window.localStorage.getItem("pfs_access_obj") &&
            JSON.parse(window.localStorage.getItem("pfs_access_obj"))
              .pfs_access_id
        ),
        flag:
          JSON.parse(window.localStorage.getItem("loginStatus")).user_role === 1
            ? roleName.individual
            : JSON.parse(window.localStorage.getItem("loginStatus"))
                .user_role === 2
            ? roleName.financial_advisor
            : roleName.tax_professional,
      })
    )
      .unwrap()
      .then((response) => {
        // let lastID = window.localStorage.getItem('pfs_access_id') != null ? window.localStorage.getItem('pfs_access_id') : "";
        [
          "pfs_access_obj",
          "pfs_access_id",
          "pfs_access_comp",
          "pfs_comp_id",
          "pfs_comp_name",
          "pfs_access_comp_first",
        ].forEach((itr) => {
          window.localStorage.removeItem(itr);
        });
        if (
          JSON.parse(window.localStorage.getItem("loginStatus")).user_role === 1
        ) {
          // ((authUser && authUser.user && authUser.user.is_subscribe === false) || (authUser && authUser.is_subscribe === false)) && window.localStorage.setItem('last_pfs_access_id', lastID)
          window.location.href = "/individual";
        } else if (
          JSON.parse(window.localStorage.getItem("loginStatus")).user_role === 2
        ) {
          window.location.href = "/financial-advisor";
        } else {
          window.location.href = "/professional";
        }
      })
      .catch((err) => {
        console.log("err:- ", err);
      });
  };

  useEffect(() => {
    const activeTab = pathname.split("/")[2] || "dashboard";
    dispatch(setActiveTabState(activeTab));
  }, [pathname]);

  useEffect(() => {
    window.addEventListener("storage", (event) => {
      if (event.key === null) {
        navigation("/");
      }
    });
  }, []);

  useEffect(() => {
    dispatch(categoryTotalNew({ id: id }))
      .unwrap()
      .then((response) => {
        setIs_laoder((prev) => ({
          ...prev,
          assets: false,
          liabilities: false,
        }));
        setDataAsset(response.data.assets);
        setDataLiability(response.data.liabilities);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getNewCategoryTotal = () => {
    dispatch(categoryTotalNew({ id: id }))
      .unwrap()
      .then((response) => {
        setIs_laoder((prev) => ({
          ...prev,
          assets: false,
          liabilities: false,
        }));
        setDataAsset(response.data.assets);
        setDataLiability(response.data.liabilities);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  if (
    localStorageUser &&
    localStorageUser.user_role &&
    (localStorageUser.user_role.role == 2 ||
      localStorageUser.user_role.role == 3) &&
    pfs_access_obj == null
  ) {
    if (localStorageUser.user_role.role == 2) {
      window.location.href = "/financial-advisor";
    } else {
      window.location.href = "/professional";
    }
    return false;
  } else {
    return (
      <div
        className={`cs-main-layout ${
          openSide ? "cs-nav-toggled" : "menuClosed"
        }`}
      >
        {syncLoader && (
          <div className="fixed-capsync-loader">
            <CapsyncLoader />
          </div>
        )}
        {(dataAsset.grandTotal !== 0 || dataLiability.grandTotal !== 0) &&
        (Object.keys(dataAsset).length !== 0 ||
          Object.keys(dataLiability).length !== 0) &&
        is_loader.assets === false ? (
          <SidebarSection
            openAddAccountModal={openAddAccountModal}
            showModalTrue={showModalTrue}
            setShowModalTrue={setShowModalTrue}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            toggle={toggle}
            isOpen={isOpen}
            changeToggle={changeToggle}
            handleclick={handleclick}
            initialModalState={modal}
            // getCategoryTotal={getCategoryTotal}
            // getCategoryTotalLib={getCategoryTotalLib}
            getCategoryTotal={getNewCategoryTotal}
            profilePicture={profilePicture}
            getProfilePicture={getProfilePicture}
            changeCategoryData={changeCategoryData}
            toastMessage={toastMessage}
            CapsyncLogoutModal={CapsyncLogoutModal}
            requestNotifications={notifications.length}
            fetchAllNotifications={fetchAllNotifications}
            changeFilterStatus={changeFilterStatus}
            mainFilterStatus={mainFilterStatus}
            profileState={profileState}
            customImgSrc={customImgSrc}
            openSide={openSide}
            dataAsset={dataAsset}
            dataLiability={dataLiability}
            plaidErrorAccounts={plaidErrorAccounts}
            fixPlaidError={fixPlaidError}
            plaidButton={plaidButton}
            changeModal={changeModal}
            changeProfileState={changeProfileState}
            toggleSideBar={handleToggleSideBar}
            individualUserData={individualUserData}
            isMobile={isMobile}
            setOpenSide={setOpenSide}
            errorAccounts={errorAccounts}
            editAccessUser={editAccessUser}
            setCategoryAccountAdded={setCategoryAccountAdded}
          />
        ) : (
          !isMobile && <SkeletonSidebarSection />
        )}
        {/* <SkeletonSidebarSection /> */}
        <div className="cs-main-area">
          <Container fluid style={sideBarStyle}>
            {(dataAsset.grandTotal !== 0 || dataLiability.grandTotal !== 0) &&
            (Object.keys(dataAsset).length !== 0 ||
              Object.keys(dataLiability).length !== 0) &&
            is_loader.assets === false ? (
              <NavbarSection
                isMobile={isMobile}
                isMobileAccessUser={isMobileAccessUser}
                openAddAccountModal={openAddAccountModal}
                handleResponseFromIndividualDecision={
                  handleResponseFromIndividualDecision
                }
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
                toggle={toggle}
                dashactive={dashactive}
                profilePicture={profilePicture}
                getProfilePicture={getProfilePicture}
                CapsyncMessage={CapsyncMessage}
                toastMessage={toastMessage}
                CapsyncDeleteMessage={CapsyncDeleteMessage}
                CapsyncLogoutModal={CapsyncLogoutModal}
                requestNotifications={notifications}
                fetchAllNotifications={fetchAllNotifications}
                storeAllNotifications={storeAllNotifications}
                getNotificationRequests={getNotificationRequests}
                profileState={profileState}
                customImgSrc={customImgSrc}
                toggleSidebar={toggleSidebar}
                openSide={openSide}
                individualUserData={individualUserData}
                verifyinvestoractive={verifyinvestoractive}
                assetactive={assetactive}
                liabiliactive={liabiliactive}
                requestactive={requestactive}
                collaborationactive={collaborationactive}
                dataLiability={dataLiability}
                paymentInstallAuto={paymentInstallAuto}
                dataAsset={dataAsset}
                // getCategoryTotalLib={getCategoryTotalLib}
                // getCategoryTotal={getCategoryTotal}
                getCategoryTotal={getNewCategoryTotal}
                user={user}
                editAccessUser={editAccessUser}
                standardUsers={standardUsers}
                sharedPfs={sharedPfs}
                switchAccount={switchAccount}
                changeRequestButtonStatus={changeRequestButtonStatus}
                requestButton={requestButton}
                standardAccessingUsers={standardAccessingUsers}
                handleSwitchAccount={handleSwitchAccount}
              />
            ) : (
              <SkeletonNavbarSection />
            )}

            {/* <div className="cs-context"> */}
            <div
              className={
                (dataAsset.grandTotal !== 0 ||
                  dataLiability.grandTotal !== 0) &&
                (Object.keys(dataAsset).length !== 0 ||
                  Object.keys(dataLiability).length !== 0) &&
                is_loader.assets === false
                  ? "cs-context"
                  : "cs-context skeleton-for-mob"
              }
            >
              {" "}
              <Page
                handleclick={handleclick}
                modal={openInitialModalState}
                dataLiability_filter_wise={dataLiability_filter_wise}
                dataLiability={dataLiability}
                paymentInstallAuto={paymentInstallAuto}
                balanceDueAuto={balanceDueAuto}
                dataAsset_filter_wise={dataAsset_filter_wise}
                dataAsset={dataAsset}
                // getCategoryTotalLib={getCategoryTotalLib}
                // getCategoryTotal={getCategoryTotal}
                getCategoryTotal={getNewCategoryTotal}
                is_loader={is_loader}
                categoryData={categoryData}
                changeCategoryData={changeCategoryData}
                CapsyncMessage={CapsyncMessage}
                toastMessage={toastMessage}
                CapsyncDeleteMessage={CapsyncDeleteMessage}
                fetchAllIndividuals={fetchAllIndividuals}
                fetchAllNotifications={fetchAllNotifications}
                changeProfileState={changeProfileState}
                profileState={profileState}
                mainFilterStatus={mainFilterStatus}
                changeFilterStatus={changeFilterStatus}
                customImgSrc={customImgSrc}
                changeSrcStatus={changeSrcStatus}
                individualUserData={individualUserData}
                fetchUser={fetchUser}
                plaidSync={plaidSync}
                errorAccounts={errorAccounts}
                sharedPfs={sharedPfs}
                editAccessUser={editAccessUser}
                isFilesUpdated={isFilesUpdated}
                categoryAccountAdded={categoryAccountAdded}
                setCategoryAccountAdded={setCategoryAccountAdded}
                plaidErrorAccounts={plaidErrorAccounts}
              />
            </div>
          </Container>
        </div>
        {user &&
        user.payment_method &&
        user.payment_method.length > 0 &&
        user.user.is_subscribe == true ? (
          <MicroDepositeVerificationModal />
        ) : (
          user &&
          user.user.is_subscribe == false &&
          user.user.is_beta_user === false &&
          user.user.address &&
          isSharePfs != null && (
            <SubscribeModal
              sharedPfs={sharedPfs}
              isClose={sharedPfs.length != 0}
              onHandleClose={() => {
                !user.user.is_subscribe &&
                window.localStorage.getItem("pfs_access_id") != null
                  ? changeCapsyncUser()
                  : window.localStorage.getItem("last_pfs_access_id") != null &&
                    switchAccount(
                      window.localStorage.getItem("last_pfs_access_id")
                    );
              }}
              CapsyncLogoutModal={CapsyncLogoutModal}
            />
          )
        )}
        {user && !user.user.address && (
          <CompleteProfile
            toastMessage={toastMessage}
            changeSrcStatus={changeSrcStatus}
            changeProfileState={changeProfileState}
            customImgSrc={customImgSrc}
            individualUserData={individualUserData}
            completeProfileUpdated={handleCompleteProfileUpdate}
            showCompleteProfile={showCompleteProfile}
            CapsyncLogoutModal={CapsyncLogoutModal}
          />
        )}
        {user.user.address && user.user.is_subscribe == true && (
          <InitialCommonModel
            // getCategoryTotal={getCategoryTotal}
            // getCategoryTotalLib={getCategoryTotalLib}
            getCategoryTotal={getNewCategoryTotal}
            changeCategoryData={changeCategoryData}
            toastMessage={toastMessage}
            dataAsset={dataAsset}
            dataLiability={dataLiability}
            changeFilterStatus={changeFilterStatus}
            mainFilterStatus={mainFilterStatus}
            changeSrcStatus={changeSrcStatus}
            changeProfileState={changeProfileState}
            customImgSrc={customImgSrc}
            profileState={profileState}
            individualUserData={individualUserData}
            showModal={addAccountModal}
            changeModal={changeAccountModal}
            errorAccounts={errorAccounts}
            CapsyncLogoutModal={CapsyncLogoutModal}
            setCategoryAccountAdded={setCategoryAccountAdded}
          />
        )}

        <DeleteModal
          CapsyncDeleteMessage={CapsyncDeleteMessage}
          deleteStatus={deleteStatus}
        />

        <LogoutModal
          logoutStatus={logoutStatus}
          CapsyncLogoutModal={CapsyncLogoutModal}
        />
        {ReactDOM.createPortal(
          <UploadStatus />,
          document.getElementById("upload-progress")
        )}

        {ReactDOM.createPortal(
          <DownloadStatus />,
          document.getElementById("download-progress")
        )}

        <Elements stripe={stripePromise}>
          <UpgradeStorageModal
            setShowUpgradeModal={setShowUpgradeModal}
            showUpgradeModal={showUpgradeModal}
            showLimitModal={true}
            // onSuccess={fetchMainList}
            storage={storageData}
            noSocket={true}
          />
        </Elements>
      </div>
    );
  }
};

export default Layout;
