import React, { useState, useEffect, Fragment } from "react";
import { Nav, Button, Image, Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import classNames from "classnames";
import LogoImage from "../../../logoImage";
import { useDispatch, useSelector } from "react-redux";
import plusIcon from "../../../../../assets/images/other/plus.svg";
import UseFetchPermission from "../../../../customHooks/UseFetchPermission";
import {
  SignaturerequestFetchAll,
  userRequestList,
} from "../../../../slices/requests";
import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";
import "../../../../pages/individual/utilities/utilities.css";
import { socket } from "../../../../config/Socket";
import { roleName } from "../../../../config/commonRoleName";
import {
  INDIVIDUAL_MENU_ITEMS,
  EDIT_INDIVIDUAL_PERMISSIONS,
} from "./MenuItems";

function currencyFormat(num) {
  return Number(num)
    .toFixed(0)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

const DesktopMenu = ({ props, openAddAccountModal, isMobile }) => {
  const [signatureFrom, setSignatureFrom] = useState([]);
  const [signatureTo, setSignatureTo] = useState([]);
  const [countNotiFrom, setCountNotiFrom] = useState(0);
  const [countNotiTo, setCountNotiTo] = useState(0);
  const activeTabState = useSelector((state) => state.activeTab.activeTabState);
  const [menuItems, setMenuItems] = useState([]);
  const { fetchUserPermission } = UseFetchPermission();

  const [isFMExpanded, seIsFMExpanded] = useState(false);

  const [OpenBank, setOpenBank] = useState(false);
  const toggleBankingError = () => {
    setOpenBank(!OpenBank);
  };

  const handleClose = () => {
    props.toggle();
    props.setOpenSide(false);
  };
  const [OpenData, setOpenData] = useState(true);
  const toggleExpand = () => {
    setOpenData(!OpenData);
  };

  const [fetchSignPendingNotifications, setFetchSignPendingNotifications] =
    useState(0);

  const { user } = useSelector((state) => state.auth);
  const current_user_email = user && user.user.email;
  const pfsEmail = window.localStorage.getItem("pfs_access_email")
    ? window.localStorage.getItem("pfs_access_email")
    : user.user.email;
  const sidebarMenuDisplay = user && user.user && user.user.permission;
  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  const dispatch = useDispatch();
  const fetchSignDetails = () => {
    dispatch(
      SignaturerequestFetchAll({
        id: id,
        user_email: current_user_email,
      })
    )
      .unwrap()
      .then((response) => {
        let response1 = response.map((itr) => itr.email);
        if (response1.includes(current_user_email)) {
          const count = response.filter(
            (x) => x.check_status === 3 && x.is_deleted === false
          );

          setFetchSignPendingNotifications(count.length);
        } else {
          const count = response.filter(
            (x) => x.check_status === 2 && x.is_deleted === false
          );

          setFetchSignPendingNotifications(count.length);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (
      ["file-management", "archive", "trash"].some(
        (activeKey) => activeKey === activeTabState
      )
    ) {
      seIsFMExpanded(true);
    }
    fetchSignDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleNotification = (msg) => {
      fetchSignatureFromDetails();
    };

    socket.on("newNotification", handleNotification);
    socket.on("signature_request_status_update", handleNotification);
    socket.on("signature_request_status_update_individual", handleNotification);
    socket.on("signature_request_status_update_spouse", handleNotification);

    return () => {
      socket.off("newNotification", handleNotification);
      socket.off("signature_request_status_update", handleNotification);
      socket.off(
        "signature_request_status_update_individual",
        handleNotification
      );
      socket.off("signature_request_status_update_spouse", handleNotification);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // -------------------------------------
  const fetchSignatureFromDetails = () => {
    dispatch(
      userRequestList({
        id: id,
        user_email: pfsEmail,
        flag: roleName.individual,
      })
    )
      .unwrap()
      .then((response) => {
        setSignatureFrom(response.from);
        setSignatureTo(response.to);
        const countFrom = response.from.filter(
          (data) => data.is_completed === false
        );
        setCountNotiFrom(countFrom.length);
        const countTo = response.to.filter(
          (data) => data.is_completed === false
        );
        setCountNotiTo(countTo.length);
      })
      .catch((err) => {
        console.log("errrrrrrrrrr", err);
      });
  };
  useEffect(() => {
    if (user && user.user && user.user.email && user.user.id)
      fetchSignatureFromDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const checkPermission = (module_id) => {
    if (module_id === "activity-history") {
      return (
        props.editAccessUser &&
        user &&
        (user.user_role.role === 2 || user.user_role.role === 3) &&
        fetchUserPermission.to_access_permission === 2 &&
        window.localStorage.getItem("pfs_access_id") !== null
      );
    } else {
      return props.editAccessUser;
    }
  };
  const isModuleAllowed = (module_id) => {
    if (sidebarMenuDisplay) {
      if (module_id === "credit-manager") {
        return sidebarMenuDisplay.is_allow_creditmanager === "1";
      } else if (module_id === "verifyinvestor") {
        return sidebarMenuDisplay.is_allow_accreditation === "1";
      } else if (module_id === "identity-protect") {
        return sidebarMenuDisplay.is_allow_identityprotect === "1";
      } else if (module_id === "file-management") {
        return sidebarMenuDisplay.is_allow_filemanagement === "1";
      }
    }
    return true;
  };

  useEffect(() => {
    const menus = [...INDIVIDUAL_MENU_ITEMS].map((val) => {
      const isAllowed = isModuleAllowed(val.id);
      return {
        ...val,
        isShow: EDIT_INDIVIDUAL_PERMISSIONS.includes(val.id)
          ? checkPermission(val.id) && isAllowed
          : val.isShow && isAllowed,
      };
    });
    setMenuItems(menus);
  }, [props.editAccessUser, sidebarMenuDisplay, user]);

  // let total = props.requestNotifications + fetchSignPendingNotifications;
  let total = props.requestNotifications + countNotiFrom + countNotiTo;

  const isDropdownOpen = (item) => {
    return item.subItems.some(({ activeKey }) => activeKey === activeTabState);
  };

  return (
    <>
      <div
        className={classNames(
          "cs-main-sidebar",
          {
            "is-open": props.isOpen,
          },
          { isMobile } ? "is-open" : ""
        )}
      >
        <div className="sidebar-header">
          <LogoImage />

          <Button variant="link" onClick={handleClose} className="close-btn">
            <CapsyncIcon title="close-outlined" size="18" />
          </Button>
        </div>

        <div className="sidebar-nav">
          <Nav>
            {menuItems.map((val, idx) => {
              if (!val.isShow) {
                return;
              }
              if (val.subItems && val.subItems.length) {
                return (
                  <div
                    className={`cs-nav-dropdown cursor-pointer nav-item dropdown ${
                      isFMExpanded ? "navDropOpen" : ""
                    }`}
                    key={idx}
                  >
                    <span
                      key={idx}
                      // to={val.link}
                      onClick={() => {
                        seIsFMExpanded(!isFMExpanded);
                        props.handleclick();
                      }}
                      className={`cs-main-nav-item ${
                        isDropdownOpen(val) && "active"
                      }`}
                      id={val.id}
                    >
                      <div className="cs-menu-icon">
                        <CapsyncIcon
                          title={`${val.icon}-${
                            isDropdownOpen(val) ? "filled" : "outlined"
                          }`}
                          size="22"
                        />
                      </div>
                      {props.openSide && (
                        <Fragment>
                          <div
                            className={`default-regular-sub-heading-m ${
                              val.isNotifications ? "req-notification-num" : ""
                            }`}
                          >
                            <span> {val.title} </span>
                            <div className="cs-menu-icon chevron-down">
                              <CapsyncIcon
                                title="chevron-down-filled"
                                size="14"
                              />
                            </div>
                          </div>
                          {val.isNotifications &&
                            (props.requestNotifications > 0 ||
                              countNotiFrom > 0 ||
                              countNotiTo > 0) && (
                              <span className="request-pending-number default-regular-body-text-xxs">
                                {total}
                              </span>
                            )}
                        </Fragment>
                      )}
                    </span>
                    {isFMExpanded && (
                      <ul className="cs-dropdown-menu">
                        {val.subItems.map((subItem, index) => {
                          return (
                            <li className="cs-nav-item nav-item" key={index}>
                              <Link
                                key={index}
                                to={subItem.link}
                                onClick={() => props.handleclick()}
                                className={`cs-main-nav-item cs-nav-drop-item ${
                                  activeTabState === subItem.activeKey &&
                                  "active"
                                }`}
                                id={subItem.id}
                              >
                                <div className="cs-menu-icon">
                                  <CapsyncIcon
                                    title={`${subItem.icon}-${
                                      activeTabState === subItem.activeKey
                                        ? "filled"
                                        : "outlined"
                                    }`}
                                    size="22"
                                  />
                                </div>
                                {props.openSide && (
                                  <Fragment>
                                    <div
                                      className={`default-regular-sub-heading-m ${
                                        subItem.isNotifications
                                          ? "req-notification-num"
                                          : ""
                                      }`}
                                    >
                                      {subItem.title}
                                    </div>
                                    {subItem.isNotifications &&
                                      (props.requestNotifications > 0 ||
                                        countNotiFrom > 0 ||
                                        countNotiTo > 0) && (
                                        <span className="request-pending-number default-regular-body-text-xxs">
                                          {total}
                                        </span>
                                      )}
                                  </Fragment>
                                )}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                );
              }
              return (
                <Link
                  key={idx}
                  to={val.link}
                  onClick={() => props.handleclick()}
                  className={`cs-main-nav-item ${
                    activeTabState === val.activeKey && "active"
                  }`}
                  id={val.id}
                >
                  <div className="cs-menu-icon">
                    <CapsyncIcon
                      title={`${val.icon}-${
                        activeTabState === val.activeKey ? "filled" : "outlined"
                      }`}
                      size="22"
                    />
                  </div>
                  {props.openSide && (
                    <Fragment>
                      <div
                        className={`default-regular-sub-heading-m ${
                          val.isNotifications ? "req-notification-num" : ""
                        }`}
                      >
                        {val.title}
                      </div>
                      {val.isNotifications &&
                        (props.requestNotifications > 0 ||
                          countNotiFrom > 0 ||
                          countNotiTo > 0) && (
                          <span className="request-pending-number default-regular-body-text-xxs">
                            {total}
                          </span>
                        )}
                    </Fragment>
                  )}
                </Link>
              );
            })}
          </Nav>
        </div>

        {props.editAccessUser && (
          <>
            <div className="sidebar-add-account">
              {(props.plaidErrorAccounts.error_free_accounts.length > 0 ||
                props.plaidErrorAccounts.error_accounts.length > 0) && (
                <div
                  className={`${
                    props.plaidErrorAccounts.error_accounts.length > 0
                      ? "banking-validations"
                      : "banking-validations banking-sync-data"
                  }`}
                >
                  <div
                    className="banking-error"
                    onClick={toggleBankingError}
                    id="banking"
                  >
                    <div>
                      <div className="banking-icon">
                        {props.plaidErrorAccounts.error_accounts.length > 0 ? (
                          <span
                            className="cs-danger"
                            onClick={props.toggleSideBar}
                          >
                            <CapsyncIcon
                              title="error-validation-filled"
                              size="14"
                            />
                          </span>
                        ) : (
                          <span
                            className="cs-primary"
                            onClick={props.toggleSideBar}
                          >
                            <CapsyncIcon
                              title="verification-filled"
                              size="14"
                            />
                          </span>
                        )}
                      </div>

                      <h5 className="default-regular-h5 cs-neutral-90">
                        Accounts
                      </h5>
                      <span className="banking-underline"></span>
                    </div>
                    <span className="cs-neutral-90">
                      <CapsyncIcon
                        title={`chevron-${OpenBank ? "up" : "down"}-outlined`}
                        size="14"
                      />
                    </span>
                  </div>
                  {OpenBank && (
                    <div className="open-banking-messages">
                      <div className="warning-message">
                        <Button
                          ref={props.plaidButton}
                          onClick={props.changeModal}
                          style={{ display: "none" }}
                        />
                        <Accordion>
                          {props.plaidErrorAccounts.error_free_accounts.map(
                            (itr, i) => {
                              return (
                                <Accordion.Item
                                  eventKey={i}
                                  id={`banking-account`}
                                  key={i}
                                >
                                  <Accordion.Header>
                                    <div className="content-icon">
                                      <span className="cs-neutral-80">
                                        <CapsyncIcon
                                          title="chevron-down-filled"
                                          size="10"
                                        />
                                      </span>
                                      <p className="default-light-body-text-s cs-neutral-70">
                                        {itr.institution}
                                      </p>
                                    </div>
                                    <div className="cs-sync-btn">
                                      <Button className="cs-success">
                                        <CapsyncIcon
                                          title="check-outlined"
                                          size="14"
                                        />
                                      </Button>
                                    </div>
                                  </Accordion.Header>
                                  <div>
                                    {itr.accounts.map((new_itr) => {
                                      return (
                                        <>
                                          <Accordion.Body>
                                            <div className="user-data-value default-light-body-text-s cs-neutral-70">
                                              <p>{new_itr.account_name}</p>
                                              <div className="dollar-value-gap">
                                                <span>$&nbsp;</span>
                                                <span>
                                                  {currencyFormat(
                                                    new_itr.balance
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                          </Accordion.Body>
                                        </>
                                      );
                                    })}
                                  </div>
                                </Accordion.Item>
                              );
                            }
                          )}
                        </Accordion>
                        <Accordion>
                          {props.plaidErrorAccounts.error_accounts.map(
                            (itr, i) => {
                              return (
                                <Accordion.Item eventKey={i}>
                                  <Accordion.Header>
                                    <div className="content-icon">
                                      <span className="cs-neutral-80">
                                        <CapsyncIcon
                                          title="chevron-down-filled"
                                          size="10"
                                        />
                                      </span>
                                      <p className="default-light-body-text-s cs-neutral-70">
                                        {itr.institution}
                                      </p>
                                    </div>
                                    <div className="cs-sync-btn">
                                      <Button
                                        className="cs-primary"
                                        onClick={(e) => {
                                          props.fixPlaidError(itr);
                                          e.stopPropagation();
                                        }}
                                      >
                                        <CapsyncIcon
                                          title="sync-outlined"
                                          size="14"
                                        />
                                      </Button>
                                    </div>
                                  </Accordion.Header>
                                  <div>
                                    {itr.accounts.map((new_itr) => {
                                      return (
                                        <>
                                          <Accordion.Body>
                                            <div className="user-data-value default-light-body-text-s cs-neutral-70">
                                              <p>{new_itr.account_name}</p>
                                              <div className="dollar-value-gap">
                                                <span>$&nbsp;</span>
                                                <span>
                                                  {currencyFormat(
                                                    new_itr.balance
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                          </Accordion.Body>
                                        </>
                                      );
                                    })}
                                  </div>
                                </Accordion.Item>
                              );
                            }
                          )}
                        </Accordion>
                      </div>
                    </div>
                  )}
                  {!OpenBank &&
                    props.plaidErrorAccounts.error_accounts.length > 0 && (
                      <p className="default-light-body-text-s cs-neutral-70">
                        {props.plaidErrorAccounts.error_accounts.length > 0 &&
                          `There is an error with one of your account connections.`}
                      </p>
                    )}
                </div>
              )}
              <Button
                className="cs-btn-primary default-regular-h5"
                onClick={() => {
                  openAddAccountModal(true);
                }}
                id="add-account"
              >
                <Image src={plusIcon} className="add-account-plus-icon" />
                Add Account
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DesktopMenu;
