import React, { useState, useEffect } from "react";
import {
  Button,
  Offcanvas,
  Image,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import moment from "moment";
import Avatar from "../../../commonComponent/Avatar";
import { avatarTitles } from "../../../config/avatar";
import { backAppURl } from "../../../../app/applicationMode";
import { formatPhoneNumber } from "../../../util/common";
import CapsyncToolTip from "../../../commonComponent/capsyncTooltip/CapsyncToolTip";

const OffCanva = (props) => {
  const {
    show,
    setShow,
    mainTabName,
    OffcanvasState,
    dynamicURL,
    user_email,
    permissionRadio,
    checkPermissionValue,
    reSendInvitation,
    deleteRecord,
    isViewOnlyDisabled,
    isStandardDisabled,
  } = props;
  const {
    to_displayName,
    to_email,
    to_phoneNo,
    to_photo,
    to_user_role,
    to_user_id,
    to_licenceId,
    to_id,
  } = OffcanvasState;
  const userType =
    to_user_role == "1"
      ? "Individual"
      : to_user_role == "2"
      ? "Financial Advisor"
      : "Professional";
  const userTypeTitle =
    to_user_role == "1"
      ? "Individual"
      : to_user_role == "2"
      ? "Financial Advisor"
      : "Professional";
  // const canvasStatus =
  const [initials, setInitials] = useState("");
  const getInitials = (name) => {
    const nameArray = name.split(" ");
    const firstName = nameArray[0];
    const lastName = nameArray[nameArray.length - 1];
    const initials = firstName.charAt(0) + lastName.charAt(0);

    setInitials(initials.toUpperCase());
  };

  useEffect(() => {
    getInitials(to_displayName);
  });
  const imageUrl =
    userType == "Financial Advisor" || userType == "Professional"
      ? `${backAppURl}/api/wealthmanager/profile/${to_user_id}`
      : `${backAppURl}/api/user/profile/${to_user_id}`;
  const fallbackImageUrl = require("../../../../assets/images/other/gray.png");

  // Function to check if the image exists
  const imageExists = (url) => {
    const http = new XMLHttpRequest();
    http.open("HEAD", url, false);
    http.send();
    return http.status === 200;
  };

  return (
    <Offcanvas
      show={show}
      // onHide={handleClose}
      className="cs-offcanvas"
      onHide={() => setShow(false)}
      placement="end">
      <Offcanvas.Header>
        <>
          <div className="img-container">
            <div className="offcanvas-avatar">
              <>
                {avatarTitles.includes(to_photo) ? (
                  <>
                    <div
                      className={`${
                        to_photo === "avtar-6" ? "avatar-initials" : ""
                      }`}>
                      <Avatar title={to_photo} size={120} />
                      {to_photo === "avtar-6" && (
                        <div className="initials-overlay default-regular-h1 cs-neutral-80">
                          {initials}
                        </div>
                      )}
                    </div>
                  </>
                ) : to_photo === null ? (
                  <>
                    <Avatar title="avtar-6" size={120} />
                    <div className="initials-overlay default-regular-h1 cs-neutral-80">
                      {initials}
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <Image
                      className="profile-img"
                      src={to_user_id ? imageUrl : fallbackImageUrl}
                      alt="profile"
                      width={120}
                      height={120}
                    />
                    {!to_user_id && (
                      <div className="initials-overlay default-regular-h1 cs-neutral-80">
                        {initials}
                      </div>
                    )}
                  </>
                )}
              </>
              {/* {to_photo && to_user_role === "2" ? (
              <>
                <Image
                  src={`${dynamicURL}/api/wealthmanager/profile/${to_user_id}`}
                  width={120}
                  height={120}
                  alt="profile"
                />
              </>
            ) : to_photo && to_user_role === "1" ? (
              <>
                <Image
                  src={`${dynamicURL}/api/user/profile/${OffcanvasState.to_user_id}`}
                  width={120}
                  height={120}
                  alt="profile"
                />
              </>
            ) : (
              <>
                <Image
                  src={require("../../../../assets/images/other/face.png")}
                  width={96}
                  height={96}
                  alt="profile"
                />
              </>
            )} */}
            </div>
            <span onClick={() => setShow(false)}>
              <CapsyncIcon title="close-outlined" size="18" />
            </span>
          </div>
          <div className="header-information">
            <div className="name-information">
              <span className="default-semi-bold-h3">{to_displayName} </span>

              {userType == "Financial Advisor" && (
                <>
                  <span className="cs-neutral-100 offcanvas-divider"></span>
                  <span className="default-regular-sub-heading-m cs-neutral-60">
                    {"CRD #" + `${to_licenceId ? to_licenceId : "-"}`}
                  </span>
                </>
              )}
            </div>
            <div className="designation-information">
              <span className="default-regular-sub-heading-m cs-neutral-60">
                {userTypeTitle}
              </span>
            </div>
          </div>
        </>
      </Offcanvas.Header>
      <div className="mob-scroller">
        <Offcanvas.Body>
          <div className="top-information-container">
            {userType === "Financial Advisor" && (
              <div className="body-top-information">
                <p className="default-medium-sub-heading-s">Firm</p>
                <p className="default-regular-body-text-l d-flex align-items-center">
                  {`${
                    OffcanvasState.to_companyData
                      ? OffcanvasState.to_companyData
                      : "-"
                  }`}
                  <span className="cs-neutral-60">
                    <span className="cs-neutral-60 offcanvas-divider"></span>
                    {"CRD #" +
                      `${
                        OffcanvasState.companyLicenceId
                          ? OffcanvasState.companyLicenceId
                          : "-"
                      }`}
                  </span>
                </p>
              </div>
            )}
            {userType === "Professional" && (
              <div className="body-top-information">
                <p className="default-medium-sub-heading-s">Company Name</p>
                <p className="default-regular-body-text-l d-flex align-items-center">
                  {`${
                    OffcanvasState.to_companyData
                      ? OffcanvasState.to_companyData
                      : "-"
                  }`}
                </p>
              </div>
            )}
            <div className="body-top-information">
              <p className="default-medium-sub-heading-s">Email</p>
              {/* <p className="default-regular-body-text-l" title={to_email}> */}
              <p className="default-regular-body-text-l">
                {/* {user_email} */}
                {user_email ? (
                  user_email.length > 25 ? (
                    <CapsyncToolTip
                      Child={user_email.slice(0, 25) + "..."}
                      placement={"top"}
                      message={to_email}
                      type="text"
                    />
                  ) : (
                    user_email
                  )
                ) : (
                  "-"
                )}
              </p>
            </div>
            <div className="body-top-information">
              <p className="default-medium-sub-heading-s">Phone number</p>
              <p className="default-regular-body-text-l">
                <p className="default-regular-body-text-l">
                  {to_phoneNo === "0" ? "-" : formatPhoneNumber(to_phoneNo)}
                </p>
              </p>
            </div>
            <div className="body-top-information">
              <p className="default-medium-sub-heading-s">Date added</p>
              {OffcanvasState.to_status === 1 ? (
                <p className="default-regular-body-text-l">-</p>
              ) : (
                <p className="default-regular-body-text-l">
                  {moment(OffcanvasState.createdAt).format("MM/DD/YYYY")}
                </p>
              )}
            </div>
            {OffcanvasState.to_status === 1 ? (
              <>
                <div className="body-top-information ">
                  <p className="default-medium-sub-heading-s">Status</p>
                  {OffcanvasState.to_status === 2 ? (
                    <>
                      <span className="cs-badge cs-success default-regular-body-text-xs ">
                        Accepted
                      </span>
                    </>
                  ) : OffcanvasState.to_status === 3 ? (
                    <>
                      <span className="cs-badge cs-danger default-regular-body-text-xs">
                        Declined
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="cs-badge cs-warning default-regular-body-text-xs">
                        Pending
                      </span>
                    </>
                  )}
                </div>{" "}
              </>
            ) : (
              ""
            )}
            <div className="body-top-information">
              {OffcanvasState.to_status !== 3 && (
                <>
                  <p className="default-medium-sub-heading-s">
                    Change permission
                  </p>
                  <div className="cs-manage-user-radio">
                    <OverlayTrigger
                      key={"top"}
                      placement={"top"}
                      overlay={
                        <Tooltip id={`tooltip-top`} className="cs-radio-btn">
                          Viewing privilege only, No editing privileges.
                        </Tooltip>
                      }>
                      <label className="cs-radio-btn-label">
                        <input
                          className="radio-button"
                          type="radio"
                          label="1"
                          name="access_permission"
                          checked={permissionRadio === 1}
                          onClick={() => checkPermissionValue(1)}
                          disabled={isViewOnlyDisabled}
                        />
                        <span className="default-light-body-text-l cs-neutral-100">
                          View Only
                        </span>
                      </label>
                    </OverlayTrigger>

                    <OverlayTrigger
                      key={"top"}
                      placement={"top"}
                      overlay={
                        <Tooltip id={`tooltip-top`} className="cs-radio-btn">
                          Viewing and limited editing privileges.
                        </Tooltip>
                      }>
                      <label className="cs-radio-btn-label">
                        <input
                          className="radio-button"
                          type="radio"
                          name="access_permission"
                          label="2"
                          checked={permissionRadio === 2}
                          onClick={() => checkPermissionValue(2)}
                          disabled={isStandardDisabled}
                        />
                        <span className="default-light-body-text-l cs-neutral-100">
                          Standard
                        </span>
                      </label>
                    </OverlayTrigger>
                  </div>
                </>
              )}
            </div>
          </div>
        </Offcanvas.Body>

        {OffcanvasState.to_status !== 3 && (
          <div className="button-container">
            {OffcanvasState.to_status === 1 && (
              <Button
                className="cs-btn-primary btn btn-primary default-regular-body-text-m"
                onClick={() => reSendInvitation(OffcanvasState.to_id)}>
                Resend invite
              </Button>
            )}
            <Button
              className="cs-btn-danger btn btn-primary default-regular-body-text-m"
              onClick={() => deleteRecord(OffcanvasState.to_id)}>
              Delete user
            </Button>
          </div>
        )}
      </div>
    </Offcanvas>
  );
};

export default OffCanva;
