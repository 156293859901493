/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import OtpInput from "react-otp-input";
import { Link } from "react-router-dom";
import { clearMessage } from "../../../slices/message";
import { authUserUpdated, mfaPhoneAuth } from "../../../slices/auth";
import { mfaSetupPhoneAuthPin } from "../../../slices/user";
import { MfaContext } from "./Mfa_setup/MfaSetup";
import UseFetchDetails from "../../../customHooks/UseFetchDetails";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import { uniValidation } from "../liabilities/categoryForms/validationSchema";

const MfaPhonePinVerification = ({ CapsyncMessage, closeModal }) => {
  const fetchData = useContext(MfaContext);

  const [loading, setLoading] = useState(false);
  const [resendMsg, setResendMsg] = useState();
  const [otp, setOtp] = useState();
  const [validationMsg, setValidationMsg] = useState("");
  const [msg, setMsg] = useState("");
  const { user } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  const PhoneNumber = user.user.phoneNo;
  const email = user.user.email;

  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  const handleChange = (otp) => {
    setMsg("");
    setResendMsg(false);
    setOtp(otp);
  };

  useEffect(() => {
    dispatch(clearMessage());
    setResendMsg(false);
  }, [dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setResendMsg(false);
    setLoading(true);
    const MFAPhoneOtp = otp;
    if (MFAPhoneOtp) {
      dispatch(mfaSetupPhoneAuthPin({ id, email, MFAPhoneOtp }))
        .unwrap()
        .then((res) => {
          if (res.code === 200) {
            setLoading(false);
            fetchData.closeMfaModal();
            dispatch(authUserUpdated({ MFAPhone: true }))
            toast.success(res.message)

          } else {
            setMsg(res.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          CapsyncMessage("Error", "Error! Incorrect code. Please re-enter the code complete the verification process.", "err");
          setLoading(false);
        });
    } else {
      setLoading(false);
      setMsg(uniValidation.otp.require);
    }
  };

  const resendMFAPhoneOTP = () => {
    setOtp("");
    dispatch(clearMessage());
    dispatch(mfaPhoneAuth({ id, email }))
      .then((res) => {
        setResendMsg(res.payload.message);
      })
      .catch((err) => { });
  };

  function showStarNumber(params) {
    let newContent = "";
    newContent += params;
    let storeNewContent =
      newContent.substring(0, 3) +
      "*******" +
      newContent.substring(10, newContent.length);
    return storeNewContent;
  }

  return (
    <>
      {/* <Row className="mfVerificationPin">
        <Col lg={12}>
          <div className="auth-right res-mob-vi-auth-right settings-mfa-verify">
            <div className="formArea">
              <h2 className="mfa-popup-subtitle">Mobile verification</h2>
              <hr className="underline" />
              <div>
                <span className="QRauth-verify mfa-popup-subtitle-text">
                  We have sent code to{" "}
                  <span className="phoneShow">
                    {showStarNumber(PhoneNumber)}
                  </span>
                </span>
              </div>
              <div className="auth-form">
                <Form onSubmit={handleSubmit}>
                  <OtpInput
                    value={otp}
                    onChange={handleChange}
                    numInputs={6}
                    renderInput={(props) => <input {...props} />}
                    inputStyle="otp-txt"
                    containerStyle="otp-layout"
                    // isInputNum={true}
                    inputType="number"
                    shouldAutoFocus={true}
                  />
                  {msg ? (
                    <p className="formik-form-error text-center">{msg}</p>
                  ) : null}
                  <div>
                    <span className="mfaWithEmail-resed-Info">
                      Did not receive the code?{" "}
                      <Link onClick={resendMFAPhoneOTP}>Resend</Link>
                    </span>
                  </div>
                  <Button
                    type="submit"
                    className="cs-pri-sm-btn mfa-phn-verify"
                    disabled={loading}
                    onClick={handleSubmit}
                  >
                    <span className="gap-right-verify">Verify account</span>
                    {loading && (
                      <>
                        &nbsp;
                        <span className="spinner-border spinner-border-sm"></span>
                      </>
                    )}
                  </Button>
                </Form>
              </div>
            </div>

            {resendMsg && (
              <div className="cap-success text-center cap-messageArea">
                <BsFillInfoCircleFill className="iconAlert" />
                {resendMsg}
              </div>
            )}

            {validationMsg && (
              <div className="cs-msg default-regular-body-text-s cs-danger">
                <span className="icon">
                  <CapsyncIcon title="info-filled" />
                </span>
                <span className="formik-form-error">
                  {" "}
                  {message.validationMsg}
                </span>
              </div>
            )}
          </div>
        </Col>
      </Row> */}

      <div className="email-otp-verfication">
        {/* <h3 className="default-semi-bold-h3">Mobile verification </h3> */}
        <div className="text-boxes-content">
          <div className="otp-content">
            <h5 className="default-regular-h5 cs-neutral-80">
              We have sent a code to
              <span className="default-regular-h5 cs-primary">
                {" " + showStarNumber(PhoneNumber)}
              </span>
            </h5>
            <Form onSubmit={handleSubmit} className="otp-form-boxes">
              <OtpInput
                value={otp}
                onChange={handleChange}
                numInputs={6}
                renderInput={(props) => <input {...props} />}
                inputStyle="otp-txt"
                containerStyle="otp-layout"
                // isInputNum={true}
                inputType="number"
                shouldAutoFocus={true}
              />
              {msg ? (
                <span className="default-light-body-text-s cs-danger">
                  {msg}
                </span>
              ) : null}
            </Form>
            <p className="default-regular-h5 cs-neutral-90">
              Didn't receive the code?
              <span
                onClick={resendMFAPhoneOTP}
                className="cs-primary cursor-pointer"
              >
                &nbsp;Resend
              </span>
            </p>
          </div>
        </div>
        <div className="email-verification-buttons">
          <Button
            onClick={() => closeModal()}
            className="cs-btn-secondary md-btn default-regular-h5"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="cs-btn-primary md-btn default-regular-h5"
            disabled={loading}
            onClick={handleSubmit}
          >
            {/* <span onClick={fetchProp.closeModal}>Verify account</span> */}
            Verify account
          </Button>
        </div>

        {resendMsg && (
          <div className="cs-msg default-regular-body-text-s cs-success">
            <span className="icon cs-success">
              <CapsyncIcon title="verification-outlined" size="18" />
            </span>
            {resendMsg}
          </div>
        )}
        {validationMsg && (
          <div className="cs-msg default-regular-body-text-s cs-danger">
            <span className="icon">
              <CapsyncIcon title="info-filled" />
            </span>
            <span className="txt">{message.validationMsg}</span>
          </div>
        )}
      </div>
    </>
  );
};

export default MfaPhonePinVerification;
