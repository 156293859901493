/* eslint-disable */
import React, { useState, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import OtpInput from "react-otp-input";
import { mfaGoogleAuthPin } from "../../../slices/auth";
import { Themeprovider } from "./File_Encryption/FileEncryption";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import "../settings/File_Encryption/file_encryption.css";
import { toast } from "react-toastify";
import { uniValidation } from "../liabilities/categoryForms/validationSchema";

const commonMfGooglePin = (props) => {
  const fetchProp = useContext(Themeprovider);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState();
  const [msg, setMsg] = useState("");
  const [valid, setValid] = useState("");
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleChange = (otp) => {
    setMsg("");
    setOtp(otp);
  };

  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }
  const email = user && user.user.email;

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const MFAGoogleOtp = otp;
    if (MFAGoogleOtp) {
      dispatch(mfaGoogleAuthPin({ id, MFAGoogleOtp, email }))
        .unwrap()
        .then((res) => {
          setLoading(false);
          if (res.code === 200) {
            props.setModalShow && props.setModalShow(false);
            props.save && props.save();
            props.saveData && props.saveData();
            props.setshowpass && props.setshowpass(true);
            // if(props.forFileEncryption && props.forFileEncryption===true){
            //    toast.success('File encryption password can be viewed');
            // }
          } else {
            setLoading(false);
            setMsg("Enter a valid code");
          }
        })
        .catch((err) => {
          setValid("Enter a valid code");
          setLoading(false);
        });
    } else {
      setLoading(false);
      setMsg(uniValidation.otp.require);
    }
  };

  return (
    <>
      <div className="email-otp-verfication">
        {!props.notitle && (
          <h3 className="default-semi-bold-h3">
            Authenticator app verification
          </h3>
        )}
        <div className="otp-content">
          <h5 className="default-regular-h5 cs-neutral-80">
            Enter the 6-digit code from your authenticator app.
          </h5>
          <Form onSubmit={handleSubmit} className="otp-form-boxes">
            <OtpInput
              value={otp}
              onChange={handleChange}
              numInputs={6}
              renderInput={(props) => <input {...props} />}
              inputStyle="otp-txt"
              containerStyle="otp-layout"
              // isInputNum={true}
              inputType="number"
              shouldAutoFocus={true}
            />
            {msg ? (
              <span className="default-light-body-text-s cs-danger">{msg}</span>
            ) : null}
          </Form>
          {/* <h5 className="default-regular-sub-heading-xs cs-neutral-70">
            MFA code can be located in your Authenticator app installed on your
            smartphone.
          </h5> */}
        </div>
        <div className="email-verification-buttons">
          <Button
            type="submit"
            className="cs-btn-secondary md-btn default-regular-h5"
            onClick={() => props.setModalShow(false)}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="cs-btn-primary md-btn default-regular-h5"
            disabled={loading}
            onClick={handleSubmit}
          >
            {/* <span onClick={fetchProp.closeModal}>Verify account</span> */}
            Verify account
          </Button>
        </div>

        {valid && (
          <div className="cs-msg default-regular-body-text-s cs-danger">
            <span className="icon">
              <CapsyncIcon title="info-filled" />
            </span>
            <span className="txt"> {valid}</span>
          </div>
        )}
      </div>
    </>
  );
};

export default commonMfGooglePin;
