/* eslint-disable */
import React, { useEffect, useState } from "react";
import copypfs from "../../../../assets/icons/svg/outlined/copy-outlined.svg";
import userIcon from "../../../../assets/icons/svg/outlined/user-single-outlined.svg";
import emailIcon from "../../../../assets/icons/svg/outlined/email-outlined.svg";
import { useDispatch, useSelector } from "react-redux";
import { createPfsUrl, sendpfsemail, } from "../../../slices/user";
import { contingent_liabilities_getbalance } from "../../../slices/contingent_Liabilities";
import SharePfsFirstModal from "./SharePfsFirstModal";
import SharePfsSecondMainModal from "../sharePfsModals/SharePfsSecondModal/SharePfsSecondMainModal";
import SharePfsThirdModal from "./SharePfsThirdModal";
import { categoryTotal } from "../../../slices/category.slice";
import { fetch_all_external_attachments } from "../../../slices/requests";
import { emailSchema } from "../../../util/common";
import { uniValidation } from "../liabilities/categoryForms/validationSchema";

const CommmonFileForModals = (props) => {

  const [AllIndividuals, setAllIndividuals] = useState({
    request_send_by_email: "",
  });
  const [JointUserEmail, setJointUserEmail] = useState({
    JointUserEmail: "",
  });
  const [secondUserEmail, setsecondUserEmail] = useState({
    secondUserEmail: "",
  });

  const [JointuserName, setJointuserName] = useState({
    JointuserName: "",
  });
  const [SpouseValue, setSpouseValue] = useState({
    request_send_by_email: "",
  });
  const [IsChecked, setIsChecked] = useState({
    IsChecked: "",
  });
  const [IndividualSignature, setIndividualSignature] = useState({
    IndividualSignature: "",
  });
  const [PfSLnk, setPfSLnk] = useState({
    PfSLnk: "",
  });
  const [secondUserRequests, setsecondUserRequest] = useState({
    secondUserRequests: false,
  });

  const [SpouseEmail, setSpouseEmail] = useState({
    SpouseEmail: "",
  });

  const [isCheckedWealth, setIsCheckedWealth] = useState(true);
  const handleCheckboxChange = () => {
    if (isCheckedWealth === false) {
      setIsCheckedWealth(true);
    } else {
      setIsCheckedWealth(false);
    }
  };

  useEffect(() => {
    setIsCheckedWealth(true);
  }, [props.showModelFirst]);

  const [pfsLnk, setPfsLnk] = useState({ PfsLnk: "" });
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [showThirdModal, setShowThirdModal] = useState(false);
  const [ToUserEmail, setToUsermail] = useState({
    toUseremail: "",
  });
  const [ToUSerEmail, setToUSerEmail] = useState({
    ToUSerEmail: "",
  });
  // const [ToUserText, setToUserText] = useState({
  //   ToUserText: "",
  // });
  const [IndividualSign, setIndividualSign] = useState({
    IndividualSign: "",
  });
  const [recieverEmailWealth, setrecieverEmailWealth] = useState({
    recieverEmailWealth: "",
  });
  const [nameValidation, setNameValidation] = useState("");
  const [IsCheckedWealth, setisCheckedWealth] = useState({
    IsCheckedWealth: "",
  });
  const [copyPfsLink, setCopyPfsLink] = useState();
  const userDisplayName = props.individualUserData.displayName;
  const [dataAsset, setDataAsset] = useState({});
  const [dataLiability, setDataLiability] = useState({});
  const [dataAsset_filter_wise, setDataAsset_filter_wise] = useState({});
  const [paymentInstallAuto, setPaymentInstallAuto] = useState({});
  const [balanceDueAuto, setBalanceDueAuto] = useState({});
  const [dataLiability_filter_wise, setDataLiability_filter_wise] = useState(
    {}
  );
  const [getContigentValues, setGetContigentValues] = useState({});
  const [toUserEmail, setToUserEmail] = useState("");
  const [changeLastName, setChangeLastName] = useState("");
  const [changeName, setChangeName] = useState("");
  const [changeNameError, setChangeNameError] = useState('');
  const [changeLastNameError, setChangeLastNameError] = useState('');
  const [toUserText, setToUserText] = useState("");
  const [selectValue, setSelectValue] = useState();
  const [secondEmail, setSecondEmail] = useState("");
  const [checkJointName, setCheckJointName] = useState("");
  const [checkJointEmail, setCheckJointEmail] = useState("");
  const [secondUserName, setSecondUserName] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [userNameError, setuserNameError] = useState(null);
  const [firstDate, setFirstDate] = useState(new Date());
  const [ext_attachment, setExt_attachment] = useState([]);
  const [ext_Files, setExt_Files] = useState([]);

  const [spouseEmailValue, setSpouseEmailValue] = useState("")
  const [previousAttachFiles, setPreviousAttachFiles] = useState([])
  let total_attachments = [...previousAttachFiles, ...ext_Files]
  const [radioValue, setRadioValue] = useState({
    num: "1",
    name: "PFS Summary",
  });
  const dispatch = useDispatch();

  const validationMessages = {
    AVOID_WHITE_SPACES: "Avoid leading or trailing whitespace",
    AVOID_SPECIAL_CHARACTERS: "Please remove special characters",
    AVOID_NUMBERS_IN_COSIGNER: "Enter a valid name",
    AVOID_NUMBERS: "Enter a valid first name",
    AVOID_NUMBERS_IN_SECOND_NAME: "Enter a valid last name",
    AVOID_MORE_THAN_TWO_WHITESPACE: "Avoid having more than one space between names.",
    AVOID_BLANK_FIELD: "Enter name"
  }

  function changeAttachmentState(params) {
    setExt_attachment(params);
  }

  useEffect(() => {
    setisCheckedWealth({
      IsCheckedWealth: props.isCheckedWealth,
    });
    setIndividualSignature({
      IndividualSignature: props.IndividualSign,
    });
    setsecondUserRequest({
      secondUserRequests: props.secondUserRequests,
    });
    setPfSLnk({
      PfSLnk: props.PfSLnk,
    });
    setSpouseEmail({
      SpouseEmail: props.SpouseEmail,
    });
    setrecieverEmailWealth({
      recieverEmailWealth: props.recieverEmailWealth,
    });
    setAllIndividuals({
      request_send_by_email: props.new_AllIndividuals,
    });
    setJointUserEmail({
      JointUserEmail: props.JointUserEmail,
    });
    setsecondUserEmail({
      secondUserEmail: props.secondUserEmail,
    });
    setJointuserName({
      JointuserName: props.JointuserName,
    });
    setIsChecked({
      IsChecked: props.IsChecked,
    });
    setIndividualSign({
      IndividualSign: props.IndividualSignature,
    });
    setSpouseValue({
      SpouseValue: props.SpouseValue,
    });
    setToUsermail({
      toUseremail: props.toUseremail,
    });
    setToUSerEmail({
      ToUSerEmail: props.ToUSerEmail,
    });
    setPfsLnk({
      pfsLnk: props.pfsLnk,
    });
    setToUserText({
      toUserText: props.toUserText,
    });
  }, [props]);

  const { user } = useSelector((state) => state.auth);
  let user_display_name = "";
  let id;

  const emailTo = user && user.user.email;

  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  if (userDisplayName && userDisplayName.length > 10) {
    let arr = userDisplayName.split("");
    for (let z = 0; z < 10; z++) {
      const element = arr[z];
      user_display_name += element;
    }
    user_display_name += "...";
  } else {
    user_display_name = userDisplayName;
  }
  const user_id = user && user.user.id;

  const generateShareUrl = () => {
    if (id) {
      dispatch(createPfsUrl({ user_id: id }))
        .unwrap()
        .then((response) => {
          setCopyPfsLink(response.link);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    generateShareUrl();
  }, [id]);

  const copyUrl = (params) => {
    navigator.clipboard.writeText(params);
    alert("Copied to clipboard");
  };

  let exptractedUrl = copyPfsLink && copyPfsLink.slice(31, 53);
  let exptractedUrl2 = copyPfsLink && copyPfsLink.slice(31, 77);

  const assetGrandTotal = Number(
    dataAsset &&
    dataAsset.filter_wise_total &&
    dataAsset.filter_wise_total.filter_wise_total
  );
  const fromAssetsTotal =
    Number(dataAsset && dataAsset.LialifeInsuranceLoan) +
    (dataAsset &&
      dataAsset.assets_category_total &&
      dataAsset.assets_category_total.automobileBalanceDueTotal);

  const liabilitiyTotal = Number(
    dataLiability &&
    dataLiability.filter_wise_total &&
    dataLiability.filter_wise_total.filter_wise_total
  );

  const liabilitiyGrandTotal = liabilitiyTotal;

  //Assets categories total
  const getCategoryTotal = () => {
    dispatch(categoryTotal({ id: id, main_category: "assets" }))
      .unwrap()
      .then((response) => {
        setBalanceDueAuto(
          response.assets_category_total.automobileBalanceDueTotal
        );

        setDataAsset_filter_wise(response.filter_wise_total);
        setDataAsset(response);
        setPaymentInstallAuto(
          response.assets_category_total.automobilePaymentTotal
        );
      })
      .catch(() => { });
  };
  useEffect(() => {
    getCategoryTotal();
  }, []);

  //--------------- **** -----------------//

  //Liability categories total
  const getCategoryTotalLib = () => {
    dispatch(categoryTotal({ id: id, main_category: "liabilities" }))
      .unwrap()
      .then((response) => {
        setDataLiability_filter_wise(response.filter_wise_total);
        setDataLiability(response);
      })
      .catch(() => { });
  };

  useEffect(() => {
    getCategoryTotalLib();
  }, []);

  const getAllContingentLia = () => {
    dispatch(contingent_liabilities_getbalance({ id }))
      .unwrap()
      .then((response) => {
        if (response[0] === undefined) {
          setGetContigentValues(response);
        } else {
          setGetContigentValues(response[0]);
        }
      });
  };

  useEffect(() => {
    getAllContingentLia();
  }, []);

  const isValidEmail = async (email) => {
    let result = null;
    try {
      await emailSchema.validate(email);
    } catch (error) {
      result = error.message;
    }
    return result;
  };

  function isValidUserName(username) {
    return /^[a-zA-Z]{2,20}$/g.test(username);
  }

  const hasLeadingTrailingWhitespace = (value) => {
    return /^\s|\s$/.test(value);
  }
  const hasSpecialCharacter = (value) => {
    return /[!@#$%^&*()_+{}\[\]:;<>,.?~\\\-=/]/.test(value);
  }

  const hasNumber = (value) => {
    return /[0-9]/.test(value);
  }


  const setChangeNameValidation = (value) => {
    const changeNameValue = value ? value : changeName;
    // if (hasLeadingTrailingWhitespace(changeNameValue)) setChangeNameError(validationMessages.AVOID_WHITE_SPACES)
    // if (hasSpecialCharacter(changeNameValue)) setChangeNameError(validationMessages.AVOID_SPECIAL_CHARACTERS)
    if (hasNumber(changeNameValue)) setChangeNameError(validationMessages.AVOID_NUMBERS)
    // if (!hasSpecialCharacter(changeNameValue) && !hasLeadingTrailingWhitespace(changeNameValue) && !hasNumber(changeNameValue)) setChangeNameError("");
    if (!hasNumber(changeNameValue)) setChangeNameError("");
  }

  const setChangeLastNameValidation = (value) => {
    const changeLastNameValue = value ? value : changeLastName;
    // if (hasLeadingTrailingWhitespace(changeLastNameValue)) setChangeLastNameError(validationMessages.AVOID_WHITE_SPACES)
    // if (hasSpecialCharacter(changeLastNameValue)) setChangeLastNameError(validationMessages.AVOID_SPECIAL_CHARACTERS)
    if (hasNumber(changeLastNameValue)) setChangeLastNameError(validationMessages.AVOID_NUMBERS_IN_SECOND_NAME)
    // if (!hasSpecialCharacter(changeLastNameValue) && !hasLeadingTrailingWhitespace(changeLastNameValue) && !hasNumber(changeLastNameValue)) setChangeLastNameError("");
    if (!hasNumber(changeLastNameValue)) setChangeLastNameError("");
  }


  const checkFirstValidation = async () => {
    try {
      const emailValidationResult = await isValidEmail(toUserEmail);
      // if (emailValidationResult || hasLeadingTrailingWhitespace(changeName) || hasLeadingTrailingWhitespace(changeLastName) || hasSpecialCharacter(changeName) || hasSpecialCharacter(changeLastName) || hasNumber(changeName) || hasNumber(changeLastName)) {
      if (emailValidationResult || hasNumber(changeName) || hasNumber(changeLastName)) {
        setEmailError(emailValidationResult);
        setChangeNameValidation();
        setChangeLastNameValidation();
      } else {
        setEmailError("");
        setChangeNameError("");
        setChangeLastNameError("");
        setShowSecondModal(true);
        props.changeFirstModel(false);
      }
    } catch (error) {
    }
  };

  function subCancel() {
    setEmailError("");
    props.changeFirstModel(false);
  }

  const sendEmailFunction = () => {
    return dispatch(
      sendpfsemail({
        user_id: id,
        pfsLink: copyPfsLink,
        firstUserEmail: toUserEmail,
        secondUserEmail: secondEmail,
        secondUserName: secondUserName,
      })
    )
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          props.changeFirstModel(false);
          setShowSecondModal(false);
          setShowThirdModal(false);
          props.toastMessage(
            "You have sent the PFS sucessfully to the email address!",
            "success"
          );
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const sendPfsLinkEmail = async (email, userName) => {
    if (selectValue === 1) {
      sendEmailFunction();
    } else {
      let emailError = "";
      let userNameError = "";
      try {
        await emailSchema.validate(email);
      } catch (error) {
        emailError = error.message || uniValidation.email.validEmail;
      }
      if (!isValidUserName(userName)) {
        userNameError = "Enter name";
      }
      setEmailError(emailError);
      setuserNameError(userNameError);
      if (!emailError && !userNameError) {
        sendEmailFunction();
      }
    }
  };
  const handleEmailChange = (email) => {
    setEmailError("");
    setToUserEmail(email);
  };
  const [changeText, setChangeText] = useState("");
  const handleNotesChange = (paramNote) => {
    setChangeText(paramNote);
  };


  const handleNameChange = (paramNote) => {
    setChangeName(paramNote);
  };
  const handleLastNameChange = (paramNote) => {
    setChangeLastName(paramNote);
  };

  const checkNameValidations = (cosignerName) => {
    // const nameLetterArray = cosignerName.split("");
    if (!cosignerName) {
      setNameValidation(validationMessages.AVOID_BLANK_FIELD);
    }
    else if (hasNumber(cosignerName))
      setNameValidation(validationMessages.AVOID_NUMBERS_IN_COSIGNER);
    // else if (hasSpecialCharacter(cosignerName))
    //   setNameValidation(validationMessages.AVOID_SPECIAL_CHARACTERS);
    // else if (hasLeadingTrailingWhitespace(cosignerName))
    //   setNameValidation(validationMessages.AVOID_WHITE_SPACES);
    // else if (
    //   nameLetterArray.length > 1 &&
    //   (nameLetterArray[0] === " " ||
    //     nameLetterArray[nameLetterArray.length - 1] === " ")
    // ) {
    //   setNameValidation(validationMessages.AVOID_WHITE_SPACES);
    // }

    else if (
      !(
        // (nameLetterArray.length > 1 &&
        //   (nameLetterArray[0] === " " ||
        //     nameLetterArray[nameLetterArray.length - 1] === " ")) ||
        // hasSpecialCharacter(cosignerName) ||
        // hasLeadingTrailingWhitespace(cosignerName) ||
        hasNumber(cosignerName) ||
        !cosignerName
      )
    ) {
      setNameValidation("");
    }
    // for (let i = 0; i < nameLetterArray.length - 1; i++) {
    //   if (nameLetterArray[i] === " " && nameLetterArray[i + 1] === " ") {
    //     setNameValidation(validationMessages.AVOID_MORE_THAN_TWO_WHITESPACE);
    //   }
    // }
  };

  const HandleSpouseNameChange = (name) => {
    setCheckJointName(name)
    checkNameValidations(name);


    if (name) {
      // if (!isValidUserName(name)) {
      //   console.log("its error");
      //   setuserNameError("Enter user name");
      // } else {
      //   setuserNameError("");
      //   setSecondUserName(name);
      //   console.log("spouse name", name)
      // }
      const nameArray = name.split(" ");


      const firstTwoNames = nameArray.slice(0, 2).join(" ");
      setuserNameError("");
      setSecondUserName(firstTwoNames);

    } else {
      setSecondUserName("");
      setuserNameError("Enter name");
    }
  };
  const handleSpouseEmailChange = async (email) => {
    setSpouseEmailValue(email);
    setCheckJointEmail(email);
    try {
      const emailValidationResult = await isValidEmail(email);
      if (emailValidationResult) {
        setEmailError(emailValidationResult);
      } else {
        setEmailError("");
        setSecondEmail(email);
      }
    } catch (error) {
    }
  };
  const fetchStoredImages = () => {
    dispatch(
      fetch_all_external_attachments({
        id: user_id,
      })
    )

      .unwrap()
      .then((response) => {

        setPreviousAttachFiles(response.data)
      })
      .catch((err) => {
        console.log("errrrrrrrrrr", err);
      });
  };
  useEffect(() => {
    fetchStoredImages()
  }, [])
  return (
    <>
      <SharePfsFirstModal
        handleEmailChange={handleEmailChange}
        handleNotesChange={handleNotesChange}
        handleNameChange={handleNameChange}
        handleLastNameChange={handleLastNameChange}
        text={props.text}
        changeFirstModel={props.changeFirstModel}
        subCancel={subCancel}
        showModelFirst={props.showModelFirst}
        setToUserEmail={setToUserEmail}
        emailIcon={emailIcon}
        emailError={emailError}
        copypfs={copypfs}
        copyUrl={copyUrl}
        copyPfsLink={copyPfsLink}
        exptractedUrl2={exptractedUrl2}
        exptractedUrl={exptractedUrl}
        checkFirstValidation={checkFirstValidation}
        toUserEmail={toUserEmail}
        toUserText={toUserText}
        setToUserText={setToUserText}
        handleCheckboxChange={handleCheckboxChange}
        isCheckedWealth={isCheckedWealth}
        setIsCheckedWealth={setIsCheckedWealth}
        changeName={changeName}
        changeLastName={changeLastName}
        changeText={changeText}
        accreditationSign={props.accreditationSign}
        setAccreditationSign={props.setAccreditationSign}
        previousAttachFiles={previousAttachFiles}
        setPreviousAttachFiles={setPreviousAttachFiles}
        changeNameError={changeNameError}
        changeLastNameError={changeLastNameError}
        setChangeLastNameError={setChangeLastNameError}
        setChangeNameError={setChangeNameError}
        setChangeNameValidation={setChangeNameValidation}
        setChangeLastNameValidation={setChangeLastNameValidation}
      />

      {/* ---------------Preview Modal-------------- */}
      <SharePfsSecondMainModal
        individualUserData={props.individualUserData}
        changeAttachmentState={changeAttachmentState}
        changeTextNote={changeText}
        setData={props.setData}
        data={props.data}
        changeName={changeName}
        changeLastName={changeLastName}
        openModalFromVi={props.showPfsSecondModal}
        setShowPfsSecondModal={props.setShowPfsSecondModal}
        text={props.text}
        notesText={props.notesText}
        setOpenModal={props.setOpenModal}
        setOpenModal2={props.setOpenModal2}
        setOpenModal3={props.setOpenModal3}
        openModal={props.openModal}
        openModal2={props.openModal2}
        openModal3={props.openModal3}

        //---------------------------------------------//
        openSecondModal={props.showSecondModal}
        showString={props.showString}
        setShowSecondModalRequest={props.setShowSecondModal}
        AllIndividuals={props.AllIndividuals}
        SpouseValue={props.SpouseValue}
        pfsLnk={props.pfsLnk}
        //---------------------------------------------//
        SignatureReqid={props.SignatureReqid}
        SignatureId={props.SignatureId}
        showSecondModal={showSecondModal}
        setShowSecondModal={setShowSecondModal}
        getCategoryTotal={getCategoryTotal}
        getCategoryTotalLib={getCategoryTotalLib}
        dataAsset={dataAsset}
        dataAsset_filter_wise={dataAsset_filter_wise}
        assetGrandTotal={assetGrandTotal}
        liabilitiyGrandTotal={liabilitiyGrandTotal}
        paymentInstallAuto={paymentInstallAuto}
        balanceDueAuto={balanceDueAuto}
        dataLiability={dataLiability}
        dataLiability_filter_wise={dataLiability_filter_wise}
        getContigentValues={getContigentValues}
        setFirstDate={setFirstDate}
        firstDate={firstDate}
        setShowThirdModal={setShowThirdModal}
        toUserEmail={toUserEmail}
        toUserText={toUserText}
        IsChecked={IsChecked}
        IndividualSign={IndividualSign}
        showThirdModal={showThirdModal}
        spouseValue={SpouseValue}
        PfsLnk={pfsLnk}
        ToUserEmail={ToUserEmail}
        allIndividuals={AllIndividuals}
        isCheckedWealth={isCheckedWealth}
        setIsCheckedWealth={setIsCheckedWealth}
        IsCheckedWealth={IsCheckedWealth}
        recieverEmailWealth={recieverEmailWealth}
        IndividualSignature={IndividualSignature}
        setSelectValue={setSelectValue}
        selectValue={selectValue}
        secondEmail={secondEmail}
        secondUserName={secondUserName}
        setSecondEmail={setSecondEmail}
        setSecondUserName={setSecondUserName}
        JointUserEmail={JointUserEmail}
        JointuserName={JointuserName}
        secondUserRequests={secondUserRequests}
        secondUserEmail={secondUserEmail}
        emailIcon={emailIcon}
        userIcon={userIcon}
        SpouseEmail={SpouseEmail}
        ToUSerEmail={ToUSerEmail}
        SignedPfSLnk={PfSLnk}
        userId={props.userId}
        HandleSpouseNameChange={HandleSpouseNameChange}
        userNameError={userNameError}
        handleSpouseEmailChange={handleSpouseEmailChange}
        emailError={emailError}
        toastMessage={props.toastMessage}
        CapsyncMessage={props.CapsyncMessage}
        accreditationSign={props.accreditationSign}
        setAccreditationSign={props.setAccreditationSign}
        unique_row_id={props.unique_row_id}
        setExt_Files={setExt_Files}
        ext_Files={ext_Files}
        spouseEmailValue={spouseEmailValue}
        setToUserEmail={setToUserEmail}
        setCheckPfsLink={props.setCheckPfsLink}
        checkJointName={checkJointName}
        checkJointEmail={checkJointEmail}
        setCheckJointEmail={setCheckJointEmail}
        deleteFetchedPfs={props.deleteFetchedPfs}
        previousAttachFiles={previousAttachFiles}
        setPreviousAttachFiles={setPreviousAttachFiles}
        setChangeText={setChangeText}
        setChangeName={setChangeName}
        setChangeLastName={setChangeLastName}
        radioValue={radioValue}
        setRadioValue={setRadioValue}
        total_attachments={total_attachments}
        nameValidation={nameValidation}
      />

      {/* ---------------Third share indivisual & joint Modal-------------- */}
      <SharePfsThirdModal
        individualUserData={props.individualUserData}
        handleSpouseEmailChange={handleSpouseEmailChange}
        ext_attachment={ext_attachment}
        userId={props.userId}
        IsChecked={IsChecked}
        IndividualSign={IndividualSign}
        showThirdModal={showThirdModal}
        AllIndividuals={AllIndividuals}
        SpouseValue={SpouseValue}
        pfsLnk={pfsLnk}
        ToUserEmail={ToUserEmail}
        changeLastName={changeLastName}
        setShowThirdModal={setShowThirdModal}
        setSelectValue={setSelectValue}
        selectValue={selectValue}
        setSecondEmail={setSecondEmail}
        emailIcon={emailIcon}
        emailError={emailError}
        setSecondUserName={setSecondUserName}
        userIcon={userIcon}
        userNameError={userNameError}
        sendPfsLinkEmail={sendPfsLinkEmail}
        secondEmail={secondEmail}
        secondUserName={secondUserName}
        toUserEmail={toUserEmail}
        handleCheckboxChange={handleCheckboxChange}
        isCheckedWealth={isCheckedWealth}
        setIsCheckedWealth={setIsCheckedWealth}
        IsCheckedWealth={IsCheckedWealth}
        recieverEmailWealth={recieverEmailWealth}
        IndividualSignature={IndividualSignature}
        ToUSerEmail={ToUSerEmail}
        SignedPfSLnk={PfSLnk}
        SpouseEmail={SpouseEmail}
        JointUserEmail={JointUserEmail}
        JointuserName={JointuserName}
        secondUserRequests={secondUserRequests}
        secondUserEmail={secondUserEmail}
        HandleSpouseNameChange={HandleSpouseNameChange}
        toastMessage={props.toastMessage}
        checkJointName={checkJointName}
        checkJointEmail={checkJointEmail}
        changeName={changeName}
        changeTextNote={changeText}
        accreditationSign={props.accreditationSign}
        setAccreditationSign={props.setAccreditationSign}
        setExt_Files={setExt_Files}
        setToUserEmail={setToUserEmail}
        setCheckJointEmail={setCheckJointEmail}
        spouseEmailValue={spouseEmailValue}
        setChangeText={setChangeText}
        setChangeName={setChangeName}
        setChangeLastName={setChangeLastName}
        radioValue={radioValue}
        setRadioValue={setRadioValue}
        nameValidation={nameValidation}
        setNameValidation={setNameValidation}
        setCheckJointName={setCheckJointName}
      />
    </>
  );
};

export default CommmonFileForModals;
