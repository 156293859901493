import React, { useEffect } from "react";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

import "../assets/css/variables.css";
import "../assets/css/main.css";
import "../assets/css/responsive.css";
import "react-toastify/dist/ReactToastify.css";

import Routing from "./routes/Routing";
import DeviceDetector from "device-detector-js";
import PageNotFound from "./pages/404";
import UnderMaintenance from "./pages/underMaintenance";

var pjson = require("../../package.json");
console.log(pjson.version);
const App = () => {
  useEffect(() => {
    const deviceDetector = new DeviceDetector();
    const userAgent = window.navigator.userAgent;
    const device_info = deviceDetector.parse(userAgent);

    document.body.classList.add(
      `os-${device_info.os.name.replace(/ /g, "-").toLowerCase()}`
    );
    document.body.classList.add(
      `browser-${device_info.client.name.replace(/ /g, "-").toLowerCase()}`
    );
  }, []);

  if (process.env.REACT_APP_IS_UNDER_MAINTENANCE === "true") {
    return <UnderMaintenance />;
  }
  let output = window.localStorage.getItem("tempPass");
  if (output === null && process.env.REACT_APP_WEB_PASSWORD !== "app_login") {
    let webPass = process.env.REACT_APP_WEB_PASSWORD;
    let disPass = window.prompt("Enter password:");
    if (disPass === webPass) {
      window.localStorage.setItem("tempPass", webPass);
      window.location.href = "/";
    } else {
      return <PageNotFound />;
    }
  } else {
    return (
      <>
        <Routing />
        <div className="versionFooter" version={pjson.version}></div>
      </>
    );
  }
};

export default App;
