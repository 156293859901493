import React, { useEffect, useState } from "react";
import { Form, Button, Modal, Row, Col, ModalFooter } from "react-bootstrap";
import "../../../../app/pages/individual/sharePfsModals/SharePfsModal.css";
import { fetch_all_external_attachments, other_attachements_remove } from "../../../slices/requests";
import { useDispatch, useSelector } from "react-redux";



const SharePfsFirstModal = (props) => {
  const { user, authUser } = useSelector((state) => state.auth);
  const user_id = user && user.user.id;
  const { changeNameError, changeLastNameError, setChangeNameError, setChangeLastNameError, showModelFirst, setChangeNameValidation, setChangeLastNameValidation } = props;
  const [submitted, setSubmitted] = useState(false)
  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
  }
  const dispatch = useDispatch();

  const resetForm = () => {
    props.handleNameChange("");
    props.handleLastNameChange("");
    props.handleEmailChange("");
    props.handleNotesChange("");
    setChangeLastNameError("")
    setChangeNameError("")
    props.handleCheckboxChange(false);
  };
  const fetchStoredImages = () => {
    dispatch(
      fetch_all_external_attachments({
        id: user_id,
      })
    )
      .unwrap()
      .then((response) => {
        props.setPreviousAttachFiles(response.data)
      })
      .catch((err) => {
        console.log("errrrrrrrrrr", err);
      });
  };

  useEffect(() => {
    if (showModelFirst === false) {
      setSubmitted(false);
    }
    return () => {
      setSubmitted(false);
    };
  }, [showModelFirst]);


  const handleNameChange = (e) => {
    const value = e.target.value;
    props.handleNameChange(value)
    if (submitted) setChangeNameValidation(value);
  }

  const handleLastNameChange = (e) => {
    const value = e.target.value;
    props.handleLastNameChange(value)
    if (submitted) setChangeLastNameValidation(value);
  }

  return (
    <>
      <Modal
        show={props.showModelFirst}
        onHide={!props.showModelFirst}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="cs-md-modal"
      >
        <Modal.Header>
          <Modal.Title className="default-semi-bold-h3">Share PFS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Form.Group className="cs-form-group">
                <Form.Label className="">First name</Form.Label>
                <Form.Control
                  className={props.changeName && "cs-input-field-active"}
                  required
                  // type="text"
                  autoComplete="off"
                  // placeholder="Optional"
                  onKeyDown={(e) => {
                    if (e.key === " " && e.target.selectionStart === 0) {
                      e.preventDefault();
                    }
                  }}
                  onPaste={(e) => {
                    const pastedText = (e.clipboardData || window.clipboardData).getData('text');
                    if (pastedText.trim() === '') {
                      e.preventDefault();
                    }
                  }}
                  onChange={handleNameChange}
                  maxLength={40}
                // onBlur={(e) => props.handleNameBlur(e.target.value)}
                />
                {changeNameError ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {changeNameError}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="cs-form-group">
                <Form.Label>Last name</Form.Label>
                <Form.Control
                  className={props.changeLastName && "cs-input-field-active"}
                  // type="text"
                  autoComplete="off"
                  // placeholder="Optional"
                  // onChange={handleChange}
                  onKeyDown={(e) => {
                    if (e.key === " " && e.target.selectionStart === 0) {
                      e.preventDefault();
                    }
                  }}
                  onPaste={(e) => {
                    const pastedText = (e.clipboardData || window.clipboardData).getData('text');
                    if (pastedText.trim() === '') {
                      e.preventDefault();
                    }
                  }}
                  onChange={handleLastNameChange}
                  maxLength={40}
                />
                {changeLastNameError ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {changeLastNameError}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group className="cs-form-group">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  className={props.toUserEmail && "cs-input-field-active"}
                  type="email"
                  autoComplete="off"
                  onKeyDown={(e) => e.code === 'Space' && e.preventDefault()}
                  // onChange={(e) => props.setToUserEmail(e.target.value)}
                  onChange={(e) => props.handleEmailChange(e.target.value)}
                  value={props.toUserEmail}
                />

                {props.emailError ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {props.emailError}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Form.Group className="cs-form-group last-email-field">
                <Form.Label>Notes</Form.Label>
                <textarea
                  className={
                    props.changeText && "cs-textarea"
                      ? "cs-textarea cs-input-field-active"
                      : "cs-textarea"
                  }
                  type="text"
                  autoComplete="off"
                  placeholder="Optional"
                  onChange={(e) => props.handleNotesChange(e.target.value)}
                // className="cs-textarea"
                />
              </Form.Group>
            </Col>
          </Row>
          {id ? (
            <div className="signature-return-box">
              <Form.Check
                type="checkbox"
                checked={props.isCheckedWealth}
                onChange={props.handleCheckboxChange}
                className="cs-form-check-box"
                id="signaturereturnbox"
                label={
                  <span htmlFor="signaturereturnbox" className="default-regular-body-text-m cs-neutral-90" onClick={() => props.isCheckedWealth}>
                    Return to Me
                  </span>
                }
              />
            </div>
          ) : (
            ""
          )}
        </Modal.Body>
        <ModalFooter>
          <div className="cs-modal-btn">
            <Button
              // onClick={() => props.subCancel()}
              // onClick={() => props.changeFirstModel(false)}
              onClick={() => {
                resetForm();  // Reset form values
                props.changeFirstModel(false);  // Close the modal
              }}
              className="cs-btn-secondary md-btn default-regular-h5"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={() => {
                props.checkFirstValidation()
                fetchStoredImages()
                setSubmitted(true);
              }}
              className="cs-btn-primary md-btn default-regular-h5"
              disabled={props.toUserEmail === "" || props.changeName === "" || props.changeLastName === ""}
            >
              Review & Send
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default SharePfsFirstModal;
