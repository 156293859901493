import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Button, Card, Col, Image, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { get_subscription_product_list } from "../../../slices/subscription_plan";
import {
  cancel_user_subscription,
  get_billing_and_subscription_by_user,
  update_user_subscription_plan,
} from "../../../slices/user_subscription";
import {
  authUserUpdated,
  userInitialModalSetup,
  wealth_managers_findone,
} from "../../../slices/auth";
import commonNames from "../../../config/commonNames";
import StripeCheckOut from "../payment/StripeCheckOut";
import CancelPlanFeedback from "../../../pages/individual/settings/Billing_subscription/CancelPlanFeedback";
import UpdateSubsciptionConfirmationModal from "./UpdateSubsciptionConfirmationModal";
import BillingCardDetailModal from "../payment/BillingCardDetailModal";
import PaymentProgressBar from "../payment/PaymentProgressBar";
import { socket } from "../../../config/Socket";
import { userFindRecord } from "../../../slices/user";

const PlanCards = (props) => {
  const { intentType } = props;

  const { subscriptionPlan } = useSelector((state) => state.subscriptionPlan);
  const { userBillingDetail } = useSelector((state) => state.userSubscription);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [disabled, setDisabled] = useState(false);
  const [plans, setPlans] = useState([]);
  const [planArray, setPlanArray] = useState([]);
  const [checked, setChecked] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [showStripeForm, setShowStripeForm] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showchangeConfirmModal, setShowchangeConfirmModal] = useState(false);
  const [selectedPlanDetail, setSelectedPlanDetail] = useState({
    interval: "",
    name: "",
  });
  const [planType, setPlanType] = useState("");
  const [flag, setFlag] = useState(0);
  const [billingDetailModal, setBillingDetailModal] = useState(false);
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [showPaymentFailed, setShowPaymentFailed] = useState(false);
  const [failureReason, setFailureReason] = useState("");

  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else if (user && user.user.id) {
    id = user.user.id;
  }
  let role = user && user.user_role.role;
  let stripe_price_id = user && user.user.stripe_price_id;
  let old_price_id = user && user.user.stripe_price_id;
  let is_auto_subscription = user && user.user.is_auto_subscription;

  const checkActiveMethod =
    !!userBillingDetail &&
    userBillingDetail.find((item) => item.is_active === true);

  // GET SUBSCRIPTION PLAN LIST BASED ON ROLE
  useEffect(() => {
    dispatch(
      get_subscription_product_list({
        role:
          role === 1
            ? "Individual"
            : role === 2
            ? "Financial Advisor"
            : "Financial Advisor",
      })
    )
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          setPlanArray(response.data);
        }
      })
      .catch((err) => {});
  }, []);

  // SET SUBSCRIPTION PLANS IN STATE
  useEffect(() => {
    if (!!checked) {
      return setPlans(subscriptionPlan.year);
    } else {
      return setPlans(subscriptionPlan.month);
    }
  }, [checked, subscriptionPlan]);

  // SET SELECTED PLAN DETAIL IN STATE
  useEffect(() => {
    planArray &&
      planArray.map((plan) => {
        plan.price.find((price) => {
          if (stripe_price_id === price.id && is_auto_subscription) {
            setSelectedPlanDetail({
              interval: price.recurring.interval,
              name: plan.name,
            });
          }
        });
      });
  }, [
    planArray,
    checked,
    subscriptionPlan,
    showchangeConfirmModal,
    plans,
    is_auto_subscription,
  ]);

  function confirmCancelPlan() {
    dispatch(cancel_user_subscription({ id: id, role: role }))
      .unwrap()
      .then((response) => {
        // toast.success("Your plan has been Cancelled!");
        setShowConfirmModal(false);
        dispatch(authUserUpdated({ user: user, stripePriceId: null }));
        role === 1
          ? dispatch(userFindRecord({ id }))
          : dispatch(wealth_managers_findone({ wm_id: id }));
      })
      .catch((err) => {});
  }

  function confirmUpdatePlan() {
    setDisabled(true);
    dispatch(
      update_user_subscription_plan({
        price_id: selectedPlan.price.id,
        id: id,
        role: role,
        is_upgrade: planType === "Upgrade" ? true : false,
        old_price_id: old_price_id,
      })
    )
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          setShowchangeConfirmModal(false);
          toast.success(
            "Your subscription plan has been updated successfully."
          );
          dispatch(
            authUserUpdated({
              user: user,
              stripePriceId: selectedPlan.price.id,
            })
          );
          role === 1
            ? dispatch(userFindRecord({ id }))
            : dispatch(wealth_managers_findone({ wm_id: id }));
        } else {
          toast.error(response.message);
          setDisabled(false);
        }
      })
      .catch((err) => {
        setShowchangeConfirmModal(false);
        toast.error("Something went wrong");
      });
  }

  // HANDLED MONTHLY/ANNUALLY TOGGLE
  useEffect(() => {
    if (selectedPlanDetail.interval === "year" && flag == 0) {
      setChecked(true);
      setFlag(1);
    }
  }, [selectedPlanDetail]);

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );

  // CALL GET BILLING AND SUBSCRIPTION API
  let values = {
    user_id:
      role === 1
        ? window.localStorage.getItem("pfs_access_id") !== null
          ? window.localStorage.getItem("pfs_access_id")
          : id
        : 0,
    wm_user_id:
      role === 2 || role === 3
        ? window.localStorage.getItem("pfs_access_id") !== null
          ? window.localStorage.getItem("pfs_access_id")
          : id
        : 0,
    filter_by: "",
  };
  useEffect(() => {
    if (
      window.localStorage.getItem("pfs_access_id") == null &&
      !window.location.pathname.includes("/billing-subscription")
    ) {
      dispatch(get_billing_and_subscription_by_user(values));
    }
  }, []);

  let latestPaymentStatus = null;
  useEffect(() => {
    socket.on("capsync_subscription", (payment) => {
      setTimeout(() => {
        setBillingDetailModal(false);
        if (payment.status === 1) {
          latestPaymentStatus = payment.message;
          setPaymentLoader(false);
          if (intentType === 1) {
            dispatch(
              userInitialModalSetup({ user_id: user.user.id, user_role: role })
            );
            dispatch(
              authUserUpdated({
                user: user,
                isSubscribe: true,
                initialSetup: true,
                stripePriceId:
                  !!selectedPlan && selectedPlan.price
                    ? selectedPlan.price.id
                    : "",
              })
            );
            dispatch(get_billing_and_subscription_by_user(values));
          }
          role === 1
            ? dispatch(userFindRecord({ id }))
            : dispatch(wealth_managers_findone({ wm_id: id }));
        } else {
          latestPaymentStatus = payment.message;
          setFailureReason(latestPaymentStatus);
          setPaymentLoader(false);
          setShowPaymentFailed(true);
          role === 1
            ? dispatch(userFindRecord({ id }))
            : dispatch(wealth_managers_findone({ wm_id: id }));
        }
      }, 1000);
    });
    return () => {
      socket.off("capsync_subscription");
    };
  }, []);

  return (
    <>
      {userBillingDetail.length > 0 &&
      window.localStorage.getItem("pfs_access_id") == null ? (
        <Elements stripe={stripePromise}>
          <BillingCardDetailModal
            billingDetailModal={billingDetailModal}
            setBillingDetailModal={setBillingDetailModal}
            intentType={intentType}
            selectedPlan={selectedPlan}
            setPaymentLoader={setPaymentLoader}
            showPaymentFailed={showPaymentFailed}
            setShowPaymentFailed={setShowPaymentFailed}
            failureReason={failureReason}
          />
        </Elements>
      ) : (
        showStripeForm && (
          <StripeCheckOut
            selectedPlan={selectedPlan}
            setShowStripeForm={setShowStripeForm}
            intentType={intentType}
            setPaymentLoader={setPaymentLoader}
            setBillingDetailModal={setBillingDetailModal}
          />
        )
      )}
      <section className="cs-subscription-section">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <div className="plan-content-area">
                {intentType === 1 && (
                  <div className="sub-head">
                    <h1 className="default-semi-bold-h1 cs-neutral-100">
                      Choose a plan that's right for you
                    </h1>
                    {!user.user.is_used_free_trial && (
                      <p className="cs-neutral-90 default-regular-sub-heading-m">
                        7-day free trial, cancel any time.
                      </p>
                    )}
                  </div>
                )}
                <div className="plan-heading month-years-plan">
                  {intentType === 3 && (
                    <p className="default-regular-sub-heading-l cs-neutral-100">
                      Plans
                    </p>
                  )}
                  <div className="plan-toggle">
                    <label className="default-regular-sub-heading-l cs-neutral-80">
                      Bill Monthly
                    </label>
                    <input
                      checked={checked}
                      onChange={(e) => setChecked(e.target.checked)}
                      className="cs-switch-checkbox"
                      id={`switch` + intentType}
                      type="checkbox"
                    />
                    <label
                      className="cs-switch-label default-regular-body-text-l cs-neutral-80"
                      htmlFor={`switch` + intentType}
                    >
                      <span className={`switch-button`} />
                    </label>
                    <label className="default-regular-sub-heading-l cs-neutral-80">
                      Bill Annually
                    </label>
                    <div className="subscription-save-offer">
                      <label
                        className={
                          !!checked
                            ? "save-plan-offer"
                            : "disable-save-plan-offer"
                        }
                      >
                        <span className="default-semi-bold-h4">
                          {commonNames.offer_percentage}
                        </span>
                        {!!checked ? (
                          <Image
                            src={require("../../../../assets/images/auth/card-blue.png")}
                            width={93}
                            height={4}
                            alt="Save Offer"
                          />
                        ) : (
                          <Image
                            src={require("../../../../assets/images/auth/card.png")}
                            width={93}
                            height={4}
                            alt="Save Offer"
                          />
                        )}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="subscript-Planarea subscription-plan-cards">
                {plans &&
                  plans.map((items, index) => {
                    let type =
                      stripe_price_id && is_auto_subscription
                        ? selectedPlanDetail.name != items.name
                          ? items.metadata.plan_type === "Simple"
                            ? "Downgrade"
                            : "Upgrade"
                          : "Switch"
                        : "Choose";
                    return (
                      <Card
                        className={
                          items.metadata.most_popular
                            ? "card-popular-plan"
                            : "card-simple-plan "
                        }
                      >
                        <Card.Body
                          key={index}
                          className={
                            items.metadata.most_popular
                              ? "card-plan popular-plan"
                              : "card-plan"
                          }
                        >
                          <span
                            className={
                              items.metadata.most_popular
                                ? "popular-plan-tag default-semi-bold-h6"
                                : "popular-plantag-hide default-semi-bold-h6"
                            }
                          >
                            MOST POPULAR
                          </span>
                          <Card.Text>
                            <div className="card-plan-amount">
                              <h2 className="default-semi-bold-h1 cs-neutral-100">
                                $&nbsp;
                                {(items.price.unit_amount / 100).toFixed(2)}
                              </h2>
                              <span className="default-regular-h4 cs-neutral-60">
                                {"/" + items.price.recurring.interval}
                              </span>
                            </div>
                            <div className="card-plan-summary">
                              <h2 className="default-semi-bold-h1 cs-neutral-100">
                                {items.name}
                              </h2>
                              <p className="default-regular-sub-heading-m cs-neutral-80">
                                {items.description}
                              </p>
                            </div>
                            <hr className="cs-neutral-60" />
                            <div className="card-specification default-light-sub-heading-s">
                              <ul>
                                {items.features.map((features) => {
                                  return <li>{features.name}</li>;
                                })}
                              </ul>
                            </div>
                          </Card.Text>
                          {stripe_price_id === items.price.id &&
                          is_auto_subscription ? (
                            <Button
                              className="cs-btn-primary default-regular-h5"
                              onClick={() => {
                                setShowFeedback(true);
                              }}
                            >
                              Cancel plan
                            </Button>
                          ) : (
                            <Button
                              className="cs-btn-primary default-regular-h5"
                              disabled={
                                intentType === 3 ? !checkActiveMethod : false
                              }
                              onClick={() => {
                                setDisabled(false);
                                setPlanType(type);
                                if (intentType === 1) {
                                  setSelectedPlan(items);
                                  (userBillingDetail.length == 0 &&
                                    window.localStorage.getItem(
                                      "pfs_access_id"
                                    ) === null) ||
                                  window.localStorage.getItem(
                                    "pfs_access_id"
                                  ) !== null
                                    ? setShowStripeForm(true)
                                    : setBillingDetailModal(true);
                                } else if (intentType === 3) {
                                  setSelectedPlan(items);
                                  setShowchangeConfirmModal(true);
                                }
                              }}
                            >
                              {type + " plan"}
                            </Button>
                          )}
                        </Card.Body>
                      </Card>
                    );
                  })}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <UpdateSubsciptionConfirmationModal
        showchangeConfirmModal={showchangeConfirmModal}
        setShowchangeConfirmModal={setShowchangeConfirmModal}
        confirmUpdatePlan={confirmUpdatePlan}
        planType={planType}
        disabled={disabled}
      />
      <CancelPlanFeedback
        showFeedback={showFeedback}
        setShowFeedback={setShowFeedback}
        confirmCancelPlan={confirmCancelPlan}
        showConfirmModal={showConfirmModal}
        setShowConfirmModal={setShowConfirmModal}
      />
      {paymentLoader && <PaymentProgressBar />}
    </>
  );
};

export default PlanCards;
