/* eslint-disable */
import React, { useState, useEffect, Fragment } from "react";
import moment from "moment";
import { Row, Col, Button, Container, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  get_billing_and_subscription_by_user,
  trasfer_billing,
} from "../../../../slices/user_subscription";
import NumberFormat from "react-currency-format";
import UseFetchPermission from "../../../../customHooks/UseFetchPermission";
import DataTable from "react-data-table-component";
import CommonSettingHeading from "../CommonHeader/CommonSettingHeading";
import "../../settings/Billing_subscription/billing_subscription.css";
import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";
import CapsyncSectionHeader from "../../../../commonComponent/CapsyncSectionHeader";
import CommonSettingDropdown from "../CommonHeader/CommonSettingDropdown";
import SkeletonBillingSubscription from "./SkeletonBillingSubscription";
import Skeleton from "react-loading-skeleton";
import PlanCards from "../../../../commonComponent/paymentSubscription/subscription/PlanCards";
import BillingCardDetailModal from "../../../../commonComponent/paymentSubscription/payment/BillingCardDetailModal";
import { useNavigate } from "react-router-dom";
import DefaultPaymentMethod from "../../../../commonComponent/paymentSubscription/payment/DefaultPaymentMethod";
import SkeletonSettingsTable from "../SkeletonSettingsTable";
import { customStyles } from "../../../../util/common";

const BillingSubscription = (props) => {
  const { toastMessage, is_loader } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { fetchUserPermission } = UseFetchPermission();
  const { user } = useSelector((state) => state.auth);
  const { userBillingDetail } = useSelector((state) => state.userSubscription);
  const { userTransactionData } = useSelector(
    (state) => state.userSubscription
  );
  const [isPaymentByWmUser, setIsPaymentByWmUser] = useState(false);
  const [billingDetailModal, setBillingDetailModal] = useState(false);
  const [paymentByWmUserName, setPaymentByWmUserName] = useState("");
  const [billingLoader, setBillingLoader] = useState(false);
  const defaultCard =
    Array.isArray(userBillingDetail) &&
    userBillingDetail.find((items) => items.is_active == true);

  let id;
  let role;

  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
    role = 1;
  } else {
    id = user && user.user.id;
    role = user && user.user_role.role;
  }

  const getBillingSubscription = () => {
    let values = {
      user_id:
        role === 1
          ? window.localStorage.getItem("pfs_access_id") !== null
            ? window.localStorage.getItem("pfs_access_id")
            : id
          : 0,
      wm_user_id:
        role === 2 || role === 3
          ? window.localStorage.getItem("pfs_access_id") !== null
            ? window.localStorage.getItem("pfs_access_id")
            : id
          : 0,
      filter_by: "",
    };
    dispatch(get_billing_and_subscription_by_user(values))
      .unwrap()
      .then((response) => {
        if (response.code == 200) {
          if (response.data.transactions) {
            setIsPaymentByWmUser(response.data.is_payment_by_wm_user);
            setPaymentByWmUserName(response.data.payment_by);
          }
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (user.user.is_beta_user === true) navigate("/individual");
    getBillingSubscription();
  }, []);

  const changeBilling = () => {
    setBillingLoader(true);
    dispatch(
      trasfer_billing({
        wm_user_id: 0,
        user_id: id,
        is_transfer_to_wm_user: false,
      })
    )
      .unwrap()
      .then((response) => {
        setBillingLoader(false);
        if (response.status === "Error") {
          toastMessage(response.message, "error");
          setTimeout(() => setHistoryLoader(false), 1500);
        } else {
          toastMessage(
            "Your clients billing has been transferred to your firm successfully.",
            "success"
          );
          getBillingSubscription()();
        }
      })
      .catch((err) => {
        setBillingLoader(false);
      });
  };

  const columns = [
    {
      name: "Date",
      selector: (tr_itr) =>
        moment(new Date(tr_itr.createdAt)).format("YYYYMMDD"),
      cell: (tr_itr) => moment(new Date(tr_itr.createdAt)).format("MM/DD/YYYY"),
      sortable: true,
    },
    {
      name: "Details",
      selector: (tr_itr) => tr_itr.description,
      sortable: true,
    },
    {
      name: "Billing period",
      selector: (tr_itr) => tr_itr.billing_period,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (tr_itr) =>
        tr_itr.transaction_response
          ? JSON.parse(tr_itr.transaction_response).amount
          : JSON.parse(tr_itr.transaction_response).amount / 100,
      cell: (tr_itr) => (
        <>
          <div className="dollar-value-gap amount-dollar-width">
            <span> $&nbsp;</span>
            <NumberFormat
              value={
                tr_itr.transaction_response
                  ? JSON.parse(tr_itr.transaction_response).amount
                    ? "$" +
                      " " +
                      JSON.parse(tr_itr.transaction_response).amount / 100
                    : "$" + " " + 0
                  : ""
              }
              displayType={"text"}
              thousandSeparator={true}
            />
          </div>
        </>
      ),
      // sortable: true,
    },
    {
      name: "Invoices",
      selector: (tr_itr) => (
        <>
          <Button
            className="wm-setting-billing-exportbtn wm-set-bill-sub cs-btn-tertiary md-btn default-regular-sub-heading-m"
            href={tr_itr.receipt_url}
            target="_blank"
          >
            Export to PDF
          </Button>
        </>
      ),
    },
  ];
  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };
  return (
    <>
      <section className="settings-main-section">
        <Container fluid>
          {is_loader.assets === true || is_loader.liabilities === true ? (
            <div className="cs-section-topbar">
              <div className="skeleton-breadcrumb">
                <div className="skeleton-breadcrumb-1">
                  {" "}
                  <Skeleton width={50} />
                </div>
                <div className="skeleton-breadcrumb-2">
                  {" "}
                  <Skeleton width={20} />
                </div>
                <div className="skeleton-breadcrumb-3">
                  <Skeleton width={50} />
                </div>
              </div>
              <div className="cs-title">
                <h1 className="default-semi-bold-h1 cs-neutral-100">
                  <Skeleton width={200} />
                </h1>
              </div>
            </div>
          ) : (
            <div className="cs-section-topbar">
              <CapsyncSectionHeader
                mainTitle="Dashboard"
                mainUrl="/individual"
                pagetitle={"Settings"}
                subTitle="Settings"
              />
            </div>
          )}
          {props.is_loader.assets === true ||
          props.is_loader.liabilities === true ? (
            <div className="setting-mobile-dropdown for-mob">
              <Skeleton width="100" height={30} />
            </div>
          ) : (
            <CommonSettingDropdown />
          )}
          <Card>
            <div className="settings-body">
              {is_loader.assets === true || is_loader.liabilities === true ? (
                <div className="for-des">
                  {" "}
                  <div className="skeleton-account-tabs">
                    <p className="skeleton-account-tab">
                      {" "}
                      <Skeleton width={100} />
                    </p>
                    <p className="skeleton-account-tab">
                      {" "}
                      <Skeleton width={100} />
                    </p>
                    <p className="skeleton-account-tab">
                      {" "}
                      <Skeleton width={80} />
                    </p>
                    <p className="skeleton-account-tab">
                      {" "}
                      <Skeleton width={150} />
                    </p>
                    <p className="skeleton-account-tab">
                      {" "}
                      <Skeleton width={100} />
                    </p>
                  </div>
                </div>
              ) : (
                <CommonSettingHeading editAccessUser={props.editAccessUser} />
              )}
              {is_loader.assets === true || is_loader.liabilities === true ? (
                <SkeletonBillingSubscription />
              ) : (
                <>
                  <div className="billing-subscription-page">
                    <PlanCards intentType={3} />

                    {fetchUserPermission.to_access_permission === 1 &&
                    window.localStorage.getItem("pfs_access_id") !== null ? (
                      ""
                    ) : (
                      <>
                        <Container fluid>
                          <Row>
                            <Col lg={4}>
                              <div className="payment-cards">
                                <div className="payment-method">
                                  <span className="default-regular-sub-heading-l cs-neutral-100">
                                    Payment method
                                  </span>
                                  <div className="text-button cursor-pointer">
                                    <Button
                                      type="submit"
                                      className="default-regular-h5 cs-btn-icon-tertiary"
                                      onClick={() =>
                                        setBillingDetailModal(true)
                                      }
                                    >
                                      <span className="cs-primary">
                                        <CapsyncIcon
                                          title="add-filled"
                                          size="16"
                                        />
                                      </span>
                                      Add/change method
                                    </Button>
                                  </div>
                                </div>
                                <DefaultPaymentMethod
                                  defaultCard={defaultCard}
                                />
                                {billingLoader ? (
                                  <span className="cs-common-spinner cs-primary-main">
                                    <CapsyncIcon
                                      title="loading-outlined"
                                      size="60"
                                    />
                                  </span>
                                ) : isPaymentByWmUser === true &&
                                  window.localStorage.getItem(
                                    "pfs_access_id"
                                  ) === null ? (
                                  <>
                                    <p className="default-light-body-text-s cs-neutral-70">
                                      Note: Your subscription is paid by
                                      {" " + paymentByWmUserName}
                                    </p>
                                    <span
                                      onClick={changeBilling}
                                      className="default-regular-sub-heading-s cs-primary cursor-pointer"
                                    >
                                      Allow billing transfer to your account
                                    </span>
                                  </>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="billing-history">
                                <p className="default-regular-sub-heading-l cs-neutral-100">
                                  Billing history
                                </p>
                                <div className="cs-data-table">
                                  <DataTable
                                    columns={columns}
                                    data={userTransactionData}
                                    pagination
                                    paginationComponentOptions={
                                      paginationOptions
                                    }
                                    sortIcon={
                                      <>
                                        <span className="cs-neutral-100">
                                          <CapsyncIcon
                                            title="sort-outlined"
                                            size="22"
                                          />
                                        </span>
                                      </>
                                    }
                                    customStyles={customStyles}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </>
                    )}
                    <BillingCardDetailModal
                      billingDetailModal={billingDetailModal}
                      setBillingDetailModal={setBillingDetailModal}
                      intentType={3}
                    />
                  </div>
                </>
              )}
            </div>
          </Card>

          {is_loader.assets === true || is_loader.liabilities === true ? (
            <div className="mobile-billing-history for-mob">
              <p className="default-regular-sub-heading-l cs-neutral-100">
                <Skeleton width={150} />
              </p>
              <div className="cs-data-table">
                <SkeletonSettingsTable tableData={[1, 2, 3]} />
              </div>
            </div>
          ) : (
            <div className="mobile-billing-history">
              <p className="default-regular-sub-heading-l cs-neutral-100">
                Billing history
              </p>
              <div className="cs-data-table">
                <DataTable
                  columns={columns}
                  data={userTransactionData}
                  pagination
                  paginationComponentOptions={paginationOptions}
                  sortIcon={
                    <>
                      <span className="cs-neutral-100">
                        <CapsyncIcon title="sort-outlined" size="22" />
                      </span>
                    </>
                  }
                  customStyles={customStyles}
                />
              </div>
            </div>
          )}
        </Container>
      </section>
    </>
  );
};

export default BillingSubscription;
