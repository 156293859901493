import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Image } from "react-bootstrap";
import ErrorValidation from "../../../../assets/images/other/error-validation.svg";
import "../PaymentSubscription.css";
import { getPfsIndividualUsers } from "../../../slices/user";
import Avatar from "../../Avatar";
import { avatarTitles } from "../../../config/avatar";
import { backAppURl } from "../../../applicationMode";

const UpdateSubsciptionConfirmationModal = (props) => {
  const {
    showchangeConfirmModal,
    setShowchangeConfirmModal,
    confirmUpdatePlan,
    planType,
    disabled,
  } = props;

  const { user } = useSelector((state) => state.auth);
  const avatarState = useSelector((state) => state.avatar.avatarState);
  const dispatch = useDispatch();

  const [pfsIndividualUsers, setPfsIndividualUsers] = useState();

  let user_id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    user_id = window.localStorage.getItem("pfs_access_id");
  } else if (user && user.user.id) {
    user_id = user.user.id;
  }

  useEffect(() => {
    dispatch(getPfsIndividualUsers({ user_id: user_id }))
      .unwrap()
      .then((response) => {
        setPfsIndividualUsers(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Modal
        className="cs-common-modal-overlay"
        show={showchangeConfirmModal}
        onHide={() => !showchangeConfirmModal}
        style={{ zIndex: "99999999" }}
        centered
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="cs-md-modal"
      >
        <Modal.Header>
          <Modal.Title className="default-semi-bold-h3 card-modal-head">
            {planType === "Downgrade" && pfsIndividualUsers.length > 0
              ? "Are you sure?"
              : planType + " Subscription Plan"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {planType === "Downgrade" && pfsIndividualUsers.length > 0 ? (
            <div className="downgrade-plan-modal">
              {/* <span>
                                <Image
                                    src={ErrorValidation}
                                    alt="Error Validation"
                                    width={100}
                                    height={100}
                                />
                            </span> */}
              <div className="cs-profile-group">
                {pfsIndividualUsers &&
                  pfsIndividualUsers.map((user) => (
                    <>
                      {avatarTitles.includes(user.profileImageUrl) ||
                      avatarTitles.includes(avatarState) ? (
                        <div className="profile-overlap">
                          <div
                            className={`${
                              user.profileImageUrl === "avtar-6"
                                ? "avatar-initials"
                                : ""
                            }`}
                          >
                            <Avatar
                              title={avatarState || user.profileImageUrl}
                              size={96}
                            />
                            {(avatarState === "avtar-6" ||
                              user.profileImageUrl === "avtar-6") && (
                              <div className="initials-overlay default-regular-h1 cs-neutral-80">
                                {user.to_firstName.charAt(0) +
                                  user.to_lastName.charAt(0)}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : user.profileImageUrl === "-" ? (
                        <div className="profile-overlap">
                          <Avatar title="avtar-6" size={96} />
                          <div className="initials-overlay default-regular-h1 cs-neutral-80">
                            {user.to_firstName.charAt(0) +
                              user.to_lastName.charAt(0)}
                          </div>
                        </div>
                      ) : (
                        <div className="profile-overlap">
                          <Image
                            src={
                              user.profileImageUrl
                                ? `${backAppURl}/api/user/profile/${user.to_user_id}`
                                : require("../../../../assets/images/other/face.png")
                            }
                            alt="Profile"
                            width={96}
                            height={96}
                          />
                        </div>
                      )}
                    </>
                  ))}
              </div>
              <p className="default-medium-sub-heading-xs cs-neutral-90 downgrade-content">
                {/* You are about to downgrade your subscription plan, after that your account access from <b className='cs-neutral-100'>following</b> have been revoked. */}
                You are about to downgrade your subscription plan. Your added
                users will no longer be able to collaborate on your account.
              </p>
              {/* {pfsIndividualUsers && pfsIndividualUsers.map((user) => (
                                <div className='name-box'>
                                    <span>
                                        {(avatarTitles.includes(user.profileImageUrl) || avatarTitles.includes(avatarState)) ? (
                                            <>
                                                <div className={`${user.profileImageUrl === 'avtar-6' ? 'avatar-initials' : ''}`}>
                                                    <Avatar title={avatarState || user.profileImageUrl} size={38} />
                                                    {((avatarState === "avtar-6") || user.profileImageUrl === 'avtar-6') && (
                                                        <div className="initials-overlay default-regular-h5 cs-neutral-80">{user.to_firstName.charAt(0) + user.to_lastName.charAt(0)}</div>
                                                    )}
                                                </div>
                                            </>
                                        ) : user.profileImageUrl === '-' ?

                                            <>
                                                <Avatar title="avtar-6" size={38} />
                                                <div className="initials-overlay default-regular-h5 cs-neutral-80">{user.to_firstName.charAt(0) + user.to_lastName.charAt(0)}</div>
                                            </>
                                            : <Image
                                                src={user.profileImageUrl
                                                    ? `${backAppURl}/api/user/profile/${user.to_user_id}`
                                                    : require("../../../../assets/images/other/face.png")
                                                }
                                                alt="profile"
                                                width={32} height={32}
                                            />
                                        }
                                    </span>
                                    <div className='name-permission'>
                                        <h5 className='default-regular-h5 cs-neutral-90'>{user.to_firstName + " " + user.to_lastName}</h5>
                                        <h6 className='default-regular-h6 cs-neutral-70'>{user.to_access_permission === 1 ? "View Only" : "Standard"}</h6>
                                    </div>
                                </div>
                            ))} */}
            </div>
          ) : (
            <>
              <div className="cs-plans-change text-center">
                <span>
                  <Image
                    src={ErrorValidation}
                    alt="Error Validation"
                    width={100}
                    height={100}
                  />
                </span>
                <h4 class="default-regular-sub-heading-m cs-neutral-100">
                  You are about to {planType} your plan
                </h4>
                <p class="default-light-body-text-m cs-neutral-90">
                  Do you want to {planType} your plan?
                </p>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="cs-modal-btn cs-center-btn">
            <Button
              className="cs-btn-secondary md-btn default-regular-h5"
              onClick={() => setShowchangeConfirmModal(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                confirmUpdatePlan();
              }}
              disabled={disabled}
              className="cs-btn-primary md-btn default-regular-h5"
            >
              {planType === "Downgrade" && pfsIndividualUsers.length > 0
                ? "Continue"
                : planType}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdateSubsciptionConfirmationModal;
