/* eslint-disable eqeqeq */
import React, { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import "../pages/individual/verifyinvestor/DashboardStatus/Modals/Modal.css";

// auth routs path

import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import OtherUser from "../pages/auth/OtherUser";
import EmailVerify from "../pages/auth/EmailVerification";
import Success from "../pages/auth/Success";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ResetPassword from "../pages/auth/ResetPassword";
import SignInMFVerification from "../pages/auth/SignInMfVerification";
import SignInMFAuthSetup from "../pages/auth/SignInMfAuthSetup";
import SignInMFAuthEmailPin from "../pages/auth/SignInMfAuthEmailPin";
import SignInMFAuthPhoneSetup from "../pages/auth/SignInMfAuthPhoneSetup";
import SignInMFAuthPhonePin from "../pages/auth/SignInMfAuthPhonePin";
import SignInMfAuthGoogleSetup from "../pages/auth/SignInMfAuthGoogleSetup";
import SignInMfAuthGooglePin from "../pages/auth/SignInMfAuthGooglePin";
// import Subscriptions from "../pages/auth/Subscriptions";

import "../pages/auth/auth.css";
import "../pages/individual/dashboard/InitialSetup/initial-setup.css";
import ManageUserStatus from "../pages/auth/ManageUserStatus";

// Skeleton CSS
import "react-loading-skeleton/dist/skeleton.css";
import "../../app/pages/individual/manageUser/manageUser.css";
import "../../assets/css/main.css";
import "../pages/auth/subscription.css";

import Portfolio from "../commonComponent/stocksPortfolio/Portfolio";
//Individual main layout
import Layout from "../pages/individual/IndividualLayout";

//Indivisual assets
import Transactions from "../pages/individual/transactions/PlaidTransactions";
import AssetCategoryDashboard from "../pages/individual/assets/category/CategoryDashboard";
import LiabilityCategoryDashboard from "../pages/individual/liabilities/CategoryDashboard";
import Submission from "../pages/wealthmanager/Submission/Submission";
import Amortization from "../pages/individual/Amortization";
import RequestDashboard from "../pages/individual/request/RequestDashboard";

//Individual-Header
import manageUser from "../pages/individual/manageUser/ManageUser";
import AccountDetails from "../pages/individual/settings/Account_details/AccountDetails";
import FileEncryption from "../pages/individual/settings/File_Encryption/FileEncryption";
import BillingSubscription from "../pages/individual/settings/Billing_subscription/BillingSubscription";
import MfaSetup from "../pages/individual/settings/Mfa_setup/MfaSetup";
import AuditLogs from "../pages/individual/settings/Audit_logs/auditLogs";

//custom css for the mange user page
import VI_Pending from "../pages/individual/verifyinvestor/DashboardStatus/Pending";
import VI_Completed from "../pages/individual/verifyinvestor/DashboardStatus/Completed";
import VI_Expired from "../pages/individual/verifyinvestor/DashboardStatus/Expired";
import IndividualMethods from "../pages/individual/verifyinvestor/DashboardStatus/MethodsDashboard/IndividualMethods";
import QualifiedClientMethods from "../pages/individual/verifyinvestor/DashboardStatus/MethodsDashboard/QualifiedClientMethods";
import QualifiedPurchaserMethods from "../pages/individual/verifyinvestor/DashboardStatus/MethodsDashboard/QualifiedPurchaserMethods";
import EntityMethods from "../pages/individual/verifyinvestor/DashboardStatus/MethodsDashboard/EntityMethods";
import TrustsMethods from "../pages/individual/verifyinvestor/DashboardStatus/MethodsDashboard/TrustsMethods";

// VI sub methods
import ViSubMethods from "../pages/individual/verifyinvestor/DashboardStatus/MethodsDashboard/ViSubMethods";
import QuickBooksReports from "../commonComponent/quickBooks/QuickBooksReports";
import QuickBooksFetchData from "../commonComponent/quickBooks/QuickBooksFetchData";
import { useUser } from "../slices/auth";

// weathmanager routes

// import SignUp from "../pages/auth/SignUp";

//Wealth manager main layout
import WealthManagerLayout from "../pages/wealthmanager/WealthManagerLayout";
import Wmdashboard from "../pages/wealthmanager/dashboard/Dashboard";

//Wealth manager individual section
import Individual from "../pages/wealthmanager/Individual/Individual";
// import ManageUserStatus from "../pages/auth/ManageUserStatus";
// import RequestDashboardWel from "../pages/wealthmanager/Requests/RequestDashboardWel";
import Company from "../pages/wealthmanager/Company/Company";

import AccountDetailsWel from "../pages/wealthmanager/SettingsPage/AccountDetails/AccountDetails";
import BillingAndSubscription from "../pages/wealthmanager/SettingsPage/BillingAndSubscription/BillingAndSubscription";
import MFASetup from "../pages/wealthmanager/SettingsPage/MFASetup/MFASetup";
import WmAuditLogs from "../pages/wealthmanager/SettingsPage/WmAuditLogs/WmAuditLogs";

import "../../assets/css/main.css";
import FileEncryptionWel from "../pages/wealthmanager/SettingsPage/FileEncryption/FileEncryption";
import RequestDashboardWel from "../pages/wealthmanager/Requests/RequestDashboard";
import Archive from "../pages/individual/fileManagement/Archive";

const Menu = lazy(() =>
  import(
    "../pages/individual/dashboard/individual_dashboard/IndividualDashboard"
  )
);
const AssetDashBoard = lazy(() =>
  import("../pages/individual/assets/asset_dashboard/AssetDashboard")
);
const LiablitiesDashboard = lazy(() =>
  import(
    "../pages/individual/liabilities/liability_dashboard/LiablilitiesDashboard"
  )
);
const CreditManagerDetails = lazy(() =>
  import("../pages/individual/creditManager/CreditManagerDetails")
);
const IdentityProtect = lazy(() =>
  import("../pages/individual/identityProtect/IdentityProtect")
);
// import { useHistory } from 'react-router-dom';
const FileManagement = lazy(() =>
  import("../pages/individual/fileManagement/FileManagement")
);
const Trash = lazy(() => import("../pages/individual/fileManagement/Trash"));

const IndRoutes = ({ handleLogoutFunc, CapsyncMessage }) => {
  const user = useUser();
  const role = user && user.user_role && user.user_role.role;
  const token = user && user.token;

  return (
    <Suspense>
      <Routes>
        {/* Auth------------------------Routes */}
        <Route path="/">
          <Route
            path="user-verification"
            element={<OtherUser auth_sec_className="auth-signup" />}
          />
          <Route
            path="signup"
            element={<SignUp auth_sec_className="auth-signup" />}
          />
          {/* <Route
          path="subscriptions"
          element={<Subscriptions auth_sec_className="auth-signin" />}
        /> */}
          <Route
            path=""
            element={<SignIn handleLogoutFunc={handleLogoutFunc} />}
          />
          <Route path="verify-email/:id" element={<EmailVerify />} />
          <Route path="success/:emailVerificationCode" element={<Success />} />
          <Route path="decline" element={<ManageUserStatus />} />
          <Route path="manage/performed" element={<ManageUserStatus />} />
          <Route path="manage/:status" element={<SignUp />} />
          <Route
            path="forgot-password"
            element={
              <ForgotPassword auth_sec_className="auth-forgot-password" />
            }
          />
          {/*<Route
          path="expire-password"
          element={<ExpirePassword auth_sec_className="auth-forgot-password" />}
        />
        <Route
          path="expire-password-phone"
          element={
            <ExpirePasswordPhone auth_sec_className="auth-forgot-password" />
          }
        />
        <Route
          path="expire-password-phone-verification"
          element={
            <ExpirePhoneVerification auth_sec_className="auth-forgot-password" />
          }
        />
        <Route
          path="expire-password-update"
          element={
            <ExpireUpdatePassword auth_sec_className="auth-forgot-password" />
          }
        /> */}
          <Route
            path="reset-password/:resetPasswordToken"
            element={<ResetPassword CapsyncMessage={CapsyncMessage} />}
          />
          <Route path="signin-mf-auth-setup" element={<SignInMFAuthSetup />} />
          <Route path="mf-auth-emailpin" element={<SignInMFAuthEmailPin />} />
          <Route
            path="signin-mf-verification"
            element={<SignInMFVerification />}
          />
          <Route
            path="signup-mf-auth-phone-setup"
            element={<SignInMFAuthPhoneSetup />}
          />
          <Route path="mf-auth-phonepin" element={<SignInMFAuthPhonePin />} />
          <Route
            path="signup-mf-auth-google-setup"
            element={<SignInMfAuthGoogleSetup />}
          />
          <Route path="mf-auth-googlepin" element={<SignInMfAuthGooglePin />} />
        </Route>
        {token && (
          <>
            {/* individual-------------------------------Routes */}
            {
              <>
                <Route path="/individual">
                  <Route path="" element={<Layout page={Menu} />} />

                  <Route
                    path="verifyinvestor/pending"
                    element={<Layout page={VI_Pending} />}
                  />
                  <Route
                    path="verifyinvestor/completed"
                    element={<Layout page={VI_Completed} />}
                  />
                  <Route
                    path="verifyinvestor/expired"
                    element={<Layout page={VI_Expired} />}
                  />
                  <Route
                    path="verifyinvestor/accreditedInvestor/individuals/:rowId"
                    element={<Layout page={IndividualMethods} />}
                  />
                  <Route
                    path="verifyinvestor/accreditedInvestor/qualifiedclient/:rowId"
                    element={<Layout page={QualifiedClientMethods} />}
                  />
                  <Route
                    path="verifyinvestor/accreditedInvestor/qualifiedpurchaser/:rowId"
                    element={<Layout page={QualifiedPurchaserMethods} />}
                  />
                  <Route
                    path="verifyinvestor/accreditedInvestor/:type/:rowId/:methodId"
                    element={<Layout page={ViSubMethods} />}
                  />
                  <Route
                    path="verifyinvestor/accreditedInvestor/entities/:rowId"
                    element={<Layout page={EntityMethods} />}
                  />
                  <Route
                    path="verifyinvestor/accreditedInvestor/trusts/:rowId"
                    element={<Layout page={TrustsMethods} />}
                  />

                  <Route
                    path="assets"
                    element={<Layout page={AssetDashBoard} />}
                  />
                  {/*  -------------------Assets Category---------------------- */}
                  <Route
                    exact
                    path="assets/:category"
                    element={<Layout page={AssetCategoryDashboard} />}
                  />

                  <Route
                    exact
                    path="quickbooks/:accesstoken/:realmid/:refresh_token"
                    element={<QuickBooksFetchData />}
                  />

                  <Route
                    exact
                    path="assets/business/reports/:business_id"
                    element={<Layout page={QuickBooksReports} />}
                  />

                  <Route
                    path=":category/amortization/:categoryType/:row_id"
                    element={<Layout page={Amortization} />}
                  />

                  {/* Liability Category */}

                  <Route
                    exact
                    path="liabilities/:category"
                    element={<Layout page={LiabilityCategoryDashboard} />}
                  />

                  <Route
                    path=":main_category/:category/transaction/:mask_id/*"
                    element={<Layout page={Transactions} />}
                  />
                  <Route
                    path="liabilities"
                    element={<Layout page={LiablitiesDashboard} />}
                  />
                  <Route
                    path="request"
                    element={<Layout page={RequestDashboard} />}
                  />
                  <Route
                    path="credit-manager"
                    element={<Layout page={CreditManagerDetails} />}
                  />
                  <Route
                    path="identity-protect"
                    element={<Layout page={IdentityProtect} />}
                  />
                  {/* <Route path="*" element={<PageNotFound />} /> */}
                  {/* ------------------------------------Non-Retirement Brokerage Accounts------------------------------------------- */}
                  <Route
                    path="assets/:category/portfolio/:mask_id/*"
                    element={<Layout page={Portfolio} />}
                  />

                  {/* ----------------Indivisual - Header--------------- */}
                  <Route
                    path="collaboration"
                    element={<Layout page={manageUser} />}
                  />
                  <Route
                    path="account-details"
                    element={<Layout page={AccountDetails} />}
                  />
                  {/* <Route
                    path="file-encryption"
                    element={<Layout page={FileEncryption} />}
                  /> */}
                  <Route
                    path="mfa-setup"
                    element={<Layout page={MfaSetup} />}
                  />
                  <Route
                    path="billing-subscription"
                    element={<Layout page={BillingSubscription} />}
                  />
                  <Route
                    path="audit-logs"
                    element={<Layout page={AuditLogs} />}
                  />
                  {/* {["LOCAL", "DEV"].includes(process.env.REACT_APP_MODE) && ( */}
                  <Route
                    path="file-management"
                    element={<Layout page={FileManagement} />}
                  />
                  {/* )} */}
                  {/* {["LOCAL", "DEV"].includes(process.env.REACT_APP_MODE) && ( */}
                  <Route
                    path="file-management/:id"
                    element={<Layout page={FileManagement} />}
                  />
                  {/* )} */}
                  {/* {["LOCAL", "DEV"].includes(process.env.REACT_APP_MODE) && ( */}
                  <Route path="trash" element={<Layout page={Trash} />} />
                  {/* )} */}
                  {/* {["LOCAL", "DEV"].includes(process.env.REACT_APP_MODE) && ( */}
                  <Route path="archive" element={<Layout page={Archive} />} />
                  {/* )} */}
                  {/* -------------------------submission---------------------- */}

                  <Route
                    path="activity-history"
                    element={<Layout page={Submission} />}
                  />
                </Route>
              </>
            }
            {/* wealthmanager------------------------------Routes */}

            {role == 2 && (
              <>
                <Route path="/financial-advisor">
                  <Route
                    path=""
                    element={<WealthManagerLayout page={Wmdashboard} />}
                  />

                  <Route
                    path="individuals"
                    element={<WealthManagerLayout page={Individual} />}
                  />
                  <Route
                    path="companies"
                    element={<WealthManagerLayout page={Company} />}
                  />
                  <Route
                    path="requests"
                    element={<WealthManagerLayout page={RequestDashboardWel} />}
                  />

                  {/* ---------------------------------------------------------------- */}
                  <Route
                    path="account-details"
                    element={<WealthManagerLayout page={AccountDetailsWel} />}
                  />

                  <Route
                    path="mfa-setup"
                    element={<WealthManagerLayout page={MFASetup} />}
                  />

                  {/* <Route
                    path="file-encryption"
                    element={<WealthManagerLayout page={FileEncryptionWel} />}
                  /> */}
                  <Route
                    path="billing-subscription"
                    element={
                      <WealthManagerLayout page={BillingAndSubscription} />
                    }
                  />
                  <Route
                    path="audit-logs"
                    element={<WealthManagerLayout page={WmAuditLogs} />}
                  />

                  <Route
                    path="wmcompanydecline"
                    element={<ManageUserStatus />}
                  />

                  <Route path="wmindidecline" element={<ManageUserStatus />} />

                  {/* <Route path="wealthcompnanies/:status" element={<SignUp />} />

                  <Route path="wealthindividual/:status" element={<SignUp />} /> */}

                  {/* <Route
          path="/manageuser"
          element={<WealthManagerLayout page={WmManageUser} />}
        /> */}
                </Route>
              </>
            )}
            {role == 3 && (
              <>
                <Route path="/professional">
                  <Route
                    path=""
                    element={<WealthManagerLayout page={Wmdashboard} />}
                  />

                  <Route
                    path="individuals"
                    element={<WealthManagerLayout page={Individual} />}
                  />
                  <Route
                    path="companies"
                    element={<WealthManagerLayout page={Company} />}
                  />
                  <Route
                    path="requests"
                    element={<WealthManagerLayout page={RequestDashboardWel} />}
                  />

                  {/* ---------------------------------------------------------------- */}
                  <Route
                    path="account-details"
                    element={<WealthManagerLayout page={AccountDetailsWel} />}
                  />

                  <Route
                    path="mfa-setup"
                    element={<WealthManagerLayout page={MFASetup} />}
                  />

                  {/* <Route
                    path="file-encryption"
                    element={<WealthManagerLayout page={FileEncryptionWel} />}
                  /> */}
                  <Route
                    path="billing-subscription"
                    element={
                      <WealthManagerLayout page={BillingAndSubscription} />
                    }
                  />
                  <Route
                    path="audit-logs"
                    element={<WealthManagerLayout page={WmAuditLogs} />}
                  />

                  <Route
                    path="procompanydecline"
                    element={<ManageUserStatus />}
                  />

                  <Route path="wmindidecline" element={<ManageUserStatus />} />
                </Route>
              </>
            )}
          </>
        )}
        <Route
          path="/financial-advisor/wealthcompanies/:status"
          element={<SignUp />}
        />
        <Route
          path="/professional/company-sign-up/:status"
          element={<SignUp />}
        />
        <Route
          path="/professional/individual-sign-up/:status"
          element={<SignUp />}
        />
        <Route
          path="/financial-advisor/wealthindividual/:status"
          element={<SignUp />}
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
  );
};

export default IndRoutes;
