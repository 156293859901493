import API from "../util/api";
import { headerAddToken } from "../util/common";

const requests_add = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/add", values, header);
};
const signatureRequests_add = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/Signadd", values, header);
};
const signatureRequests_update = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/SignUpdate", values, header);
};
const signatureRequests_update_signed = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/SignUpdateSIgned", values, header);
};
const requests_list = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/list", values, header);
};

const signature_request_list = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/signature_request_list", values, header);
};
const signature_request_Indi_list = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/signature_request_Indi_list", values, header);
};
const request_list = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/request_list", values, header);
};

const requests_AcceptDeny = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/accept_deny", values, header);
};
const approveDecline_InModal = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/approveDecline_InModal", values, header);
};

const requests_user_details = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/fetch_user", values, header);
};

const requests_delete = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/delete", values, header);
};

const requests_user_notifications = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/notifications", values, header);
};

const requests_user_notifications_read = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/markread", values, header);
};

const requests_user_fetchId = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/fetchId", values, header);
};

const requests_fetch_all = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/allrequests", values, header);
};

const Signature_requests_fetch_all_indi = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/allSignatureRequestsIndi", values, header);
};
const requests_count_all = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/countrequests", values, header);
};

const requests_fetch_emailLink = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/fetchlinkemail", values, header);
};

const requests_decryptShareLink = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/decryptLink", values, header);
};

const requests_sendlink = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/sendlink", values, header);
};

const wm_to_ind_resend_email = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/wm_to_ind_resend_email", values, header);
};

const getAllDataFromRequest = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/allSignatureRequests", values, header);
};

const find_Signature_requests = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/FindSignatureRequests", values, header);
};
const cancel_signature_requests = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/cancel_signature_request", values, header);
};
const signatureupdateSingleSign = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/updateSingleSign", values, header);
};
const updateSingleSignSecond = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/updateSingleSignSecond", values, header);
};
const acceptSignatureReq_update = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/acceptSignatureSignature", values, header);
};
const updateSecondUserSign = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/secondUserSign", values, header);
};
const manage_user_wealth = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/manage_user_wealth", values, header);
};
const Second_signature_request_list = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/SecondSignaturerequestFetchAll", values, header);
};
const signature_Requests_add = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/sign_add", values, header);
};

const second_signatureRequests_update = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/SecondSignUpdate", values, header);
};
const notification_open = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/notification_open", values, header);
};

const get_access_from_indi = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/get_access_from_indi", values, header);
};

const get_permission_status = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/get_permission_status", values, header);
};

const get_all_standard_details = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/get_all_standard_details", values, header);
};

const edit_permitted_user = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/edit_permitted_user", values, header);
}
const read_single_notification = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/read_single_notification", values, header);
};

const request_bulk_add = async (values) => {
  let header = headerAddToken();
  return API.post('/requests/bulk_add', values, header)
}

const all_users_access_status = async (values) => {
  let header = headerAddToken();
  return API.post("/requests/all_users_access_status", values, header);
};

const cancelled_by_main_user = async (values) => {
  let header = headerAddToken();
  return API.post("/requests/cancelled_by_main_user", values, header);
};

const get_cancelled_users_list = async (values) => {
  let header = headerAddToken();
  return API.post("/requests/get_cancelled_users_list", values, header);
};

const decline_by_main_user = async (values) => {
  let header = headerAddToken();
  return API.post("/requests/decline_by_main_user", values, header);
};

const change_access_back_to_indi = async (values) => {
  let header = headerAddToken();
  return API.post("/requests/change_access_back_to_indi", values, header);
};

const user_access_permission = async (values) => {
  let header = headerAddToken();
  return API.post("/requests/user_access_permission", values, header);
};

const check_edit_permission = async (values) => {
  let header = headerAddToken();
  return API.post("/requests/check_edit_permission", values, header);
};

const give_edit_access_to_advisor = async (values) => {
  let header = headerAddToken();
  return API.post("/requests/give_edit_access_to_advisor", values, header);
};

const advisor_logging_out = async (values) => {
  let header = headerAddToken();
  return API.post("/requests/advisor_logging_out", values, header);
};

const add_advisors = async (values) => {
  let header = headerAddToken();
  return API.post("/requests/add_advisors", values, header);
};

const get_all_details = async (values) => {
  let header = headerAddToken();
  return API.post("/requests/get_all_details", values, header);
};

const change_edit_permission = async (values) => {
  let header = headerAddToken();
  return API.post("/requests/change_edit_permission", values, header);
};
const remove_link_from_verify_table = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/remove_link_from_verify_table", values, header);
};
const fetch_all_external_attachments = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/fetch_all_external_attachments", values, header);
};
const other_attachements_remove = async (values) => {
  let header = await headerAddToken();
  return API.post("/requests/other_attachements_remove", values, header);
};
const requests_services = {
  change_edit_permission,
  get_all_details,
  add_advisors,
  advisor_logging_out,
  give_edit_access_to_advisor,
  check_edit_permission,
  user_access_permission,
  change_access_back_to_indi,
  decline_by_main_user,
  edit_permitted_user,
  get_all_standard_details,
  get_permission_status,
  get_access_from_indi,
  signature_Requests_add,
  notification_open,
  Second_signature_request_list,
  updateSecondUserSign,
  acceptSignatureReq_update,
  updateSingleSignSecond,
  signatureupdateSingleSign,
  find_Signature_requests,
  getAllDataFromRequest,
  signature_request_Indi_list,
  requests_add,
  requests_list,
  signature_request_list,
  requests_AcceptDeny,
  requests_user_details,
  requests_user_notifications,
  requests_user_notifications_read,
  requests_delete,
  request_list,
  requests_user_fetchId,
  requests_fetch_all,
  requests_count_all,
  requests_fetch_emailLink,
  requests_decryptShareLink,
  requests_sendlink,
  approveDecline_InModal,
  wm_to_ind_resend_email,
  signatureRequests_add,
  signatureRequests_update,
  second_signatureRequests_update,
  Signature_requests_fetch_all_indi,
  signatureRequests_update_signed,
  manage_user_wealth,
  cancel_signature_requests,
  read_single_notification,
  request_bulk_add,
  all_users_access_status,
  cancelled_by_main_user,
  get_cancelled_users_list,
  remove_link_from_verify_table,
  fetch_all_external_attachments,
  other_attachements_remove
};

export default requests_services;
