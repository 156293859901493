import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, FormControl } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import MfaPhonePinVerification from "./MfaPhonePinVerification";
import { mfaPhoneAuthSetup } from "../../../slices/auth";
import { formatPhoneNumber } from "../../../util/common";
import { uniValidation } from "../liabilities/categoryForms/validationSchema";

function MfaPhoneVerification({ CapsyncMessage, closeModal }) {
  const [msg, setMsg] = useState("");
  const [mFAPhone, setmFAPhone] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const phoneNo = user && user.user.phoneNo;
  const id = user && user.user.id;
  const email = user && user.user.email;
  // const CapsyncMessage = props.CapsyncMessage;
  // const { message } = useSelector((state) => state.message);
  // let url = backAppURl;

  // const [record, setRecord] = useState({});
  // const [formData, setFormData] = useState(true);
  // const { user } = useSelector((state) => state.auth);
  // const email = user && user.user.email;
  // let id;
  // if (window.localStorage.getItem("pfs_access_id") !== null) {
  //   id = window.localStorage.getItem("pfs_access_id");
  // } else {
  //   id = user && user.user.id;
  // }
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (id) {
  //     dispatch(userFindRecord({ id }))
  //       .unwrap()
  //       .then((response) => {
  //         setRecord(response);
  //       })
  //       .catch(() => {});
  //   }
  // }, [id]);

  // const validationSchema = yup.object().shape({
  //   phoneNo: yup.string().required("Please enter phone number"),
  // });

  // const { values, errors, touched, handleBlur, handleSubmit, setFieldValue } =
  //   useFormik({
  //     initialValues: record,
  //     enableReinitialize: true,
  //     validationSchema: validationSchema,
  //     onSubmit: async (values) => {
  //       dispatch(mfaPhoneUpdateRecord(values))
  //         .unwrap()
  //         .then((response) => {
  //           if (response.code == 200) {
  //             setFormData(false);
  //             dispatch(mfaPhoneAuth({ id, email }))
  //               .then((res) => {
  //                 localStorage.setItem("newPhoneNo", res.payload.res_data);
  //               })
  //               .catch((err) => {});
  //           }
  //         })
  //         .catch(() => {});
  //     },
  //   });

  const handleSubmit = () => {
    if (phoneNo && msg === "") {
      dispatch(mfaPhoneAuthSetup({ id, email, phoneNo }))
        .then((res) => { })
        .catch((err) => { });
      setmFAPhone(true)
    } else {
      setMsg(uniValidation.phone_number.valid);
    }
  };

  return (
    <>
      {!mFAPhone && (
        <>
          <span className="default-regular-h5 cs-neutral-80 text-center mobile-verification-title">Enter your phone number below to complete the mobile verification process.</span>
          <Form.Group className="cs-form-group mobile-verification-box">
            <FormControl
              className=""
              placeholder="Phone Number"
              name="phoneNo"
              value={formatPhoneNumber(phoneNo)}
              disabled={true}
            />
            {msg ? <p className="formik-form-error">{msg}</p> : null}
          </Form.Group>
          <div className="cs-modal-btn">
            <Button
              type="button"
              className="cs-btn-secondary md-btn default-regular-h5"
              variant="secondary"
              onClick={() => closeModal()}
            >
              Cancel
            </Button>
            <Button
              type="button"
              onClick={handleSubmit}
              className="cs-btn-primary md-btn default-regular-h5"
              variant="primary"
            >
              Send SMS
            </Button>
          </div>
        </>
      )}

      {/* {formData ? (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col lg={12} sm={12}>
              <Form.Group
                className="mb-3 cap-form-group"
                controlId="validationFormik04"
              >
               <PhoneInput
                  limitMaxLength={15}
                  international
                  countryCallingCodeEditable={false}
                  autoComplete="off"
                  name="phoneNo"
                  defaultCountry="US"
                  value={values.phoneNo}
                  className="form-control"
                  onChange={(e) => setFieldValue("phoneNo", e)}
                  countries={["IN", "GB", "US"]}
                  onBlur={handleBlur}
                  readOnly
                />
                {errors.phoneNo && touched.phoneNo ? (
                  <p className="formik-form-error">{errors.phoneNo}</p>
                ) : null}
              </Form.Group>
            </Col>
          </Row>

          {message && (
            <div className="cap-error cap-messageArea">
              <BsFillInfoCircleFill className="iconAlert" />
              {message}
            </div>
          )}

          <div className="ind-setting-email-mfa">
            <div>
              <Button className="cs-pri-sm-btn" type="submit">
                Save
              </Button>
            </div>
          </div>
        </Form>
      ) : (
        <Row>
          <Col lg={12} sm={12}>
            <MfaPhonePinVerification />
          </Col>
        </Row>
      )} */}

      {!!mFAPhone && (
        <Row>
          <Col lg={12} sm={12}>
            <MfaPhonePinVerification CapsyncMessage={CapsyncMessage} closeModal={closeModal} />
          </Col>
        </Row>
      )}
    </>
  );
}

export default MfaPhoneVerification;
