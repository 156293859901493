/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Form, Image, Button, Modal, Dropdown } from "react-bootstrap";
import { backAppURl } from "../../../applicationMode";
import { useDispatch, useSelector } from "react-redux";
import { Signature_Requests_Add, userRequestList, } from "../../../slices/requests";
import axios from "axios";
import HelloSignProgress from "../../../commonComponent/component/HelloSignProgress";
import IndividualJoint from "../../../commonComponent/pfsComponent/IndividualJoint";
import JointSpouse from "../../../commonComponent/pfsComponent/JointSpouse";
import { socket } from "../../../config/Socket";
import { roleName } from "../../../config/commonRoleName";
import HelloSign from "hellosign-embedded";
import { uniValidation } from "../liabilities/categoryForms/validationSchema";
const SharePfsThirdModal = (props) => {
  let IsChecked = props.IsChecked.IsChecked;
  let SpouseValue = props.SpouseValue.SpouseValue;
  const { nameValidation, setNameValidation, checkJointName } = props;

  const { user } = useSelector((state) => state.auth);
  const current_user_token = user && user.token;

  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }
  let indi = window.localStorage.getItem("pfs_access_id");
  let userEmail = user && user.user.email;
  const dispatch = useDispatch();
  const user_id = user && user.user.id;
  const user_displayName = user && user.user.displayName;
  const user_email = user && user.user.email;
  let userRole = user && user.user_role.role;
  const [loader, setIsLoader] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [requestResponse, setRequestResponse] = useState([]);
  const [requestId, setRequestId] = useState("");

  // const [inputFields, setInpuFields] = useState([
  //   { statement: "", file: "", index_id: 0 },
  // ]);
  // const [ext_attachment, setExt_attachment] = useState([
  //   { files: "", index_id: 0 },
  // ]);



  const handleOpen = async () => {
    setValidation("");

    props.setShowThirdModal(false);
    props.setSelectValue(undefined);
    props.setToUserEmail("")
    // window.location.reload();
  };
  // const fetchDetailsCompany = () => {
  //   dispatch(userRequestList({ id: user_id }))
  //     .unwrap()
  //     .then((response) => {
  //       setRequestResponse(response);
  //       setRequestId(response.companyID);
  //     });
  // };
  // useEffect(() => {
  //   fetchDetailsCompany();
  // }, [id]);

  const [Responses, setResponse] = useState("");
  const downloadPdf = async (Flag, nameValue) => {
    await axios({
      url: `${backAppURl}/api/pdf/export`,
      method: "post",
      responseType: "blob",

      data: {
        user_id: id,
        flag: "true",
        sec: Flag,
        secondName: nameValue,
      },
      headers: {
        Authorization: `Bearer ${current_user_token}`,
      },
    })
      .then((response) => {
        setResponse(response);
      })
      .catch((err) => {
        console.log("err", err);
        return false;
      });
  };

  const [validationMOdal, setValidationModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = () => {
    if (isChecked == false) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  };

  let email = props.individualUserData.email;
  let firstName = props.individualUserData.firstName;
  let lastName = props.individualUserData.lastName;

  let SpouseEmail;
  if (!props.SpouseEmail.SpouseEmail) {
    SpouseEmail = props.AllIndividuals.request_send_by_email;
  } else {
    SpouseEmail = props.SpouseEmail.SpouseEmail;
  }
  let ValidateMsg = (
    <p className="msgColor">Please select individual or joint to continue</p>
  );

  let emailError = props.emailError ? (
    <p className="formik-form-error">{props.emailError}</p>
  ) : (
    <p className="formik-form-error">{uniValidation.email.validEmail}</p>
  );
  let NameError = props.userNameError ? (
    <p className="formik-form-error">{props.userNameError}</p>
  ) : (
    <p className="formik-form-error">Enter name </p>
  );
  const [Validation, setValidation] = useState("");

  const [validateMessage, setValidateMessage] = useState("");
  const handleValidation = () => {
    setValidateMessage(ValidateMsg);
  };

  useEffect(() => {
    props.setCheckJointEmail("")
    props.setCheckJointName("")

    return () => {
      props.setCheckJointEmail("")
      props.setCheckJointName("")
      setValidation("");
      setNameValidation("");
      setSubmitted(false);
    }
  }, [props.selectValue])

  const fetchSignatureFromDetails = () => {
    // console.log('in this')
    dispatch(
      userRequestList({
        id: user_id,
        user_email: user_email,
        flag: roleName.individual,
      })
    )
      .unwrap()
      .then((response) => {
        // setSignatureFrom(response.from);
        // setSignatureTo(response.to);
      })
      .catch((err) => {
        //
      });
  };

  const launchHelloSign = async (values, Namevalue) => {
    if (userEmail !== props.spouseEmailValue) {
      props.setShowThirdModal(false);
    }

    if (userRole == 2 || userRole == 3) {
      let requestValues;
      if (props.isCheckedWealth == true) {
        requestValues = {
          request_send_by: user_id,
          request_send_by_role: userRole == 2 ? roleName.financial_advisor : roleName.tax_professional,
          request_send_to_role: roleName.individual,
          access_permission: 1,
          module_name: "wealth manager individual",
          request_send_by_displayName: user_displayName,
          request_send_by_email: user_email,

          request_type: "Signature Request",

          request_for: 1,
          email: email,
          firstName: firstName,
          lastName: lastName,
          isCheckedWealth: true,
          recieverEmailWealth: props.toUserEmail,
        };
      } else {
        requestValues = {
          request_sent_by: user_id,
          request_sent_by_role: userRole == 2 ? roleName.financial_advisor : roleName.tax_professional,
          request_sent_to_role: roleName.individual,
          access_permission: 1,
          module_name: "wealth manager individual",
          request_sent_by_displayName: user_displayName,
          request_sent_by_email: user_email,

          request_type: "Signature Request",
          request_for: 1,
          request_sent_to_email: email,
          request_sent_to_firstName: firstName,
          request_sent_to_lastName: lastName,
        };
      }

      try {
        setIsLoader(false);
        const response = dispatch(Signature_Requests_Add(requestValues));

        if (response.payload.code === 200) {
          props.setShowThirdModal(false);
          props.setExt_Files([])
          props.toastMessage("Your Personal Financial Statement was sent successfully.", "success");
          fetchSignatureFromDetails()
          props.setSelectValue(undefined);
          props.setToUserEmail("")
          // setTimeout(() => window.location.reload(true), 2000)
        }
      } catch (err) {
        props.toastMessage("Your signature request has been sent successfully. ", "error");
        props.setSelectValue(undefined);
        props.setToUserEmail("")
        props.setShowThirdModal(false);
        window.location.reload(true)
      }
    } else if (userRole === 1 && indi) {

      let requestValues = {
        request_sent_by: user_id,
        request_sent_by_role: roleName.individual,
        request_sent_to_role: roleName.individual,
        access_permission: 1,
        module_name: roleName.individual,
        request_sent_by_displayName: user_displayName,
        request_sent_by_email: user_email,
        request_type: "Signature Request",
        request_for: 1,
        request_sent_to_email: email,
        request_sent_to_firstName: firstName,
        request_sent_to_lastName: lastName,
        receiver_email: props.toUserEmail,
        is_return_to_me: props.isCheckedWealth,
      };

      const response = await dispatch(Signature_Requests_Add(requestValues));

      setIsLoader(false);
      if (response.payload != undefined && response.payload.code === 200) {
        props.setShowThirdModal(false);

        let emailUrl = `${backAppURl}/api/hellosign/send_signature`;
        const response1 = await fetch(emailUrl, {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${current_user_token}`,
          },

          body: JSON.stringify({
            email: email,
            userEmail: userEmail,
            isSigned: true,
            pfsLnk: props.pfsLnk,
            user_id: id,
            firstName: firstName,
            seconduserName: requestValues.JointuserName || "",
          }),
        });
        props.toastMessage("Your Personal Financial Statement was sent successfully.", "success");
        fetchSignatureFromDetails()
        props.setSelectValue(undefined);
        props.setToUserEmail("")
        props.setSecondEmail("");
        props.setCheckJointEmail("");
        props.setSecondUserName("");
        props.setChangeName("");
        props.setChangeLastName("");
        props.setChangeText("");
        props.setRadioValue({ name: "PFS Summary", num: "1" });
        // setTimeout(() => window.location.reload(true), 2000)
        if (response1.ok) {
          window.location.reload();
          const webhookResponse = await response1.json();
        } else {
          props.toastMessage("Your signature request has been sent successfully. ", "error");
          window.location.reload();
          console.error("Error registering webhook:", response1.status);
          props.setSelectValue(undefined);
          props.setToUserEmail("")

        }
      } else if (response.payload.status === 400) {
        setIsLoader(false);

        props.toastMessage("Your signature request has been sent successfully. ", "error");
        props.setSelectValue(undefined);
        props.setToUserEmail("")
        props.setChangeName("");
        props.setChangeLastName("");
        props.setChangeText("");
        props.setRadioValue({ name: "PFS Summary", num: "1" });
        props.setShowThirdModal(false);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      } else {
        props.toastMessage("Your signature request has been sent successfully. ", "error");
        props.setShowThirdModal(false);
        props.setSelectValue(undefined);
        props.setToUserEmail("")
        props.setChangeName("");
        props.setChangeLastName("");
        props.setChangeText("");
        props.setRadioValue({ name: "PFS Summary", num: "1" });
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    } else {

      try {
        if (props.selectValue == 2) {

          if (userEmail === props.spouseEmailValue) {
            setValidation("Spouse email cannot be the same as that of the main user")

          }
          else {
            signatureReq();
            setValidation("")
          }


          async function signatureReq() {
            setIsLoader(false);
            let recieverEmail;
            if (!props.toUserEmail) {
              recieverEmail = props.ToUserEmail.toUseremail;
            } else {
              recieverEmail = props.toUserEmail;
            }
            if (!values) {
              setIsLoader(false);

              props.setShowThirdModal(true);
              setValidation(emailError);
              setNameValidation("");
            } else if (!Namevalue) {
              setIsLoader(false);
              props.setShowThirdModal(true);
              setValidation("");
              setNameValidation(NameError);
            } else {
              setIsLoader(true);
              var nameArray = Namevalue.split(" ");
              var firstSecondName = nameArray[0];
              var lastSecondName = nameArray.length > 1 ? nameArray[nameArray.length - 1] : "";
              let requestValues = {
                request_sent_by: user_id,
                request_sent_by_role: roleName.individual,
                request_sent_to_role: roleName.individual,
                module_name: roleName.individual,
                request_sent_by_displayName: user_displayName,
                request_sent_by_email: user_email,
                request_sent_to_email: user_email,
                request_sent_to_firstName: firstName,
                request_sent_to_lastName: lastName,
                is_return_to_me: props.isCheckedWealth,
                receiver_email: props.toUserEmail,
                receiver_notes: props.changeTextNote,//
                receiver_firstName: props.changeName,//
                request_type: "Signature Request",

                receiver_lastName: props.changeLastName,//
                joint_user_email: props.secondEmail,
                // JointuserName: props.secondUserName,
                JointuserName: firstSecondName || "",
                JointuserlastName: lastSecondName || "",

                // request_from: comp,//
                request_sent_to_phoneNo: "",
                // company_id: companyId || 0,////
                // firstReq: firstReq,///
              };
              const formData = new FormData();
              formData.append("signature_data", JSON.stringify(requestValues));
              formData.append("user_id", requestValues.request_sent_by);
              props.ext_attachment.forEach((itr) => {
                formData.append("attachments", itr.files);
              });

              const response = await dispatch(Signature_Requests_Add(formData));

              let signatureCommonId = response && response.payload ? response.payload.id : 0;
              let flag;
              if (user_email && values) {
                flag = "sign2";
              } else {
                flag = "sign";
              }
              downloadPdf(flag, Namevalue);
              setTimeout(() => {
                sendemailSigna();
              }, 4000);
              async function sendemailSigna() {
                let emailUrl = `${backAppURl}/api/hellosign/send_email_sign`;
                if (response.payload && response.payload.status === "Success") {
                  const response1 = await fetch(emailUrl, {
                    method: "post",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${current_user_token}`,
                    },

                    body: JSON.stringify({
                      email: user_email,
                      userEmail: props.toUserEmail,
                      isSigned: true,

                      pfsLnk: "",
                      user_id: user_id,
                      firstName: firstName,
                      seconduserName: requestValues.JointuserName || "",
                      JointUserEmail: values,
                      signatureCommonId: signatureCommonId,
                    }),
                  });
                  props.toastMessage("Request sent successfully", "success");
                  props.setSelectValue(undefined);
                  props.setSecondEmail("");
                  props.setSecondUserName("");
                  props.setCheckJointEmail("");
                  props.setToUserEmail("")
                  props.setChangeName("");
                  props.setChangeLastName("");
                  props.setChangeText("");
                  props.setRadioValue({ name: "PFS Summary", num: "1" });
                  fetchSignatureFromDetails()
                  props.setExt_Files([])
                  socket.emit("notification", "message")
                  // setTimeout(() => window.location.reload(true), 2000)
                }
                else {
                  props.toastMessage("Your signature request has been sent successfully. ", "error");
                  props.setSelectValue(undefined);
                  props.setToUserEmail("")
                  props.setChangeName("");
                  props.setChangeLastName("");
                  props.setChangeText("");
                  props.setRadioValue({ name: "PFS Summary", num: "1" });
                  // setTimeout(() => window.location.reload(true), 2000)
                }
                // if (response1.ok) {
                //   setIsLoader(false);
                //   props.toastMessage("Request sent successfully3", "success");
                //   // window.location.reload();
                // } else {
                //   props.toastMessage("Your signature request has been sent successfully. ", "error");
                // }
                setIsLoader(false);
                // props.toastMessage("Your Personal Financial Statement was sent successfully. 4", "success");
              }
            }
          }
        } else {


          let flag;
          if (user_email && values) {
            flag = "sign2";
          } else {
            flag = "sign";
          }
          setIsLoader(true);
          downloadPdf(flag);
          setTimeout(() => {
            sendemailSigna();
          }, 4000);

          let requestValues = {
            request_sent_by: user_id,
            request_sent_by_role: roleName.individual,
            request_sent_to_role: roleName.individual,
            access_permission: 1,
            module_name: roleName.individual,
            request_sent_by_displayName: user_displayName,
            request_sent_by_email: user_email,

            request_type: "Signature Request",

            request_for: 1,
            request_sent_to_email: user_email,
            request_sent_to_firstName: firstName,
            request_sent_to_lastName: lastName,
            receiver_email: props.toUserEmail,
            receiver_notes: props.changeTextNote,//
            receiver_firstName: props.changeName,//


            receiver_lastName: props.changeLastName,//

            is_return_to_me: props.isCheckedWealth,
          };

          async function sendemailSigna() {

            const formData = new FormData();
            formData.append("signature_data", JSON.stringify(requestValues));
            formData.append("user_id", requestValues.request_sent_by);
            props.ext_attachment.forEach((itr) => {
              formData.append("attachments", itr.files);
            });

            const response = await dispatch(Signature_Requests_Add(formData));

            const payloadId = response && response.payload && response.payload.id;
            let signatureCommonId = response && response.payload ? response.payload.id : 0;
            // let emailUrl = `${backAppURl}/api/hellosign/send_signature`;
            let claimUrl = `${backAppURl}/api/hellosign/send_user_signature`;
            if (response.payload && response.payload.status === "Success") {
              // const response1 = await fetch(emailUrl, {
              //   method: "post",
              //   headers: {
              //     Accept: "application/json",
              //     "Content-Type": "application/json",
              //     Authorization: `Bearer ${current_user_token}`,
              //   },

              //   body: JSON.stringify({
              //     email: user_email,
              //     userEmail: props.toUserEmail,
              //     isSigned: true,
              //     pfsLnk: "",
              //     user_id: user_id,
              //     firstName: firstName,
              //     signatureCommonId: signatureCommonId,
              //   }),
              // });
              // props.setExt_Files([])
              const response = await fetch(claimUrl, {
                method: "post",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${current_user_token}`,
                },

                body: JSON.stringify({
                  email: user_email,
                  userEmail: props.toUserEmail,
                  user_id: user_id,
                  firstName: firstName,
                  flag: "self_request",
                  id: payloadId
                }),
              });
              const responseData = await response.text();
              const signer2 = JSON.parse(responseData);

              const email1 = signer2.email1;

              const signer2Url = signer2.signing_url;
              const email2 = signer2.email2;

              const signatureRequestId = signer2.signature_request_id;

              const accessToken = process.env.REACT_APP_HELLO_SIGN_API_KEY;
              const client = new HelloSign();

              if (process.env.REACT_APP_MODE === "PROD") {
                client.open(signer2Url, {
                  clientId: process.env.REACT_APP_HELLO_SIGN_CLIENT_ID,
                });
              } else {
                client.open(signer2Url, {
                  clientId: process.env.REACT_APP_HELLO_SIGN_CLIENT_ID,
                  skipDomainVerification: true,
                });
              }



              client.on("sign", async (event) => {
                let downloadUrl = `${backAppURl}/api/hellosign/get_user_signature`;

                const response = await fetch(downloadUrl, {
                  method: "post",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${current_user_token}`,
                  },
                  body: JSON.stringify({
                    values: signatureRequestId,
                    flag: "self_request"
                  }),
                });
              })
              // props.toastMessage("Your Personal Financial Statement was sent successfully.", "success");
              fetchSignatureFromDetails()
              props.setSelectValue(undefined);
              props.setSecondEmail("");
              props.setSecondUserName("");
              props.setCheckJointEmail("");
              props.setToUserEmail("")
              props.setChangeName("");
              props.setChangeLastName("");
              props.setChangeText("");
              props.setRadioValue({ name: "PFS Summary", num: "1" });
              // setTimeout(() => window.location.reload(true), 2000)
              socket.emit("notification", "message")
            } else {
              // props.toastMessage("Your signature request has been sent successfully. ", "error");
              props.setSelectValue(undefined);
              props.setToUserEmail("")
              props.setChangeName("");
              props.setChangeLastName("");
              props.setChangeText("");
              props.setRadioValue({ name: "PFS Summary", num: "1" });
              // setTimeout(() => window.location.reload(true), 2000)
            }



            setIsLoader(false);
            // props.toastMessage("Your Personal Financial Statement was sent successfully.6", "success");
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      {loader === false ? (
        <>
          <Modal
            show={props.showThirdModal}
            onHide={() => handleOpen()}
            // dialogClassName="indi-sharepfs-modal"
            backdrop="static"
            keyboard={false}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="cs-md-modal"
            className="cs-common-modal-overlay"
          >
            <Modal.Header>
              <Modal.Title className="default-semi-bold-h3">
                Select PFS type
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {userRole == 1 && IsChecked != undefined ? (
                <>
                  <IndividualJoint
                    props={props}
                    userRole={userRole}
                    IsChecked={IsChecked}
                  />
                </>
              ) : (
                <>
                  <IndividualJoint props={props} userRole={userRole} />
                </>
              )}

              {props.selectValue == 2 && (
                <>
                  <JointSpouse
                    props={props}
                    handleCheckboxChange={handleCheckboxChange}
                    nameValidation={nameValidation}
                    Validation={Validation}
                    setValidation={setValidation}
                    isChecked={isChecked}
                    setNameValidation={setNameValidation}
                    submitted={submitted}
                    setSubmitted={setSubmitted}
                  />
                </>
              )}

              {/* for checking purpose  */}
              <div className="cs-modal-btn">
                <Button
                  variant="secondary"
                  className="cs-btn-secondary md-btn default-regular-h5  ms-md-3 individual-cancel-btnn"
                  onClick={
                    props.selectValue == undefined
                      ? () => {
                        props.setShowThirdModal(false);
                        props.setToUserEmail("")

                      }
                      : () => handleOpen()
                  }
                >
                  Cancel
                </Button>
                <Button
                  className="cs-btn-primary md-btn default-regular-h5 individual-review-btn"
                  variant="primary"
                  onClick={
                    props.selectValue == undefined
                      ? () => handleValidation()
                      : () => {
                        setSubmitted(true);
                        if (!nameValidation) {
                          launchHelloSign(
                            props.secondEmail,
                            props.secondUserName
                          )
                        }
                      }

                  }
                  disabled={Validation !== '' || props.selectValue === null || props.selectValue === undefined || props.nameValidation !== '' || ((props.checkJointEmail === "" || !checkJointName) && props.selectValue == 2) ? true : false}
                >
                  {`${props.selectValue === "1" && window.localStorage.getItem("pfs_access_id") === null && user.user_role.role === 1 ? "Sign" : "Send"}`}
                </Button>

              </div>
            </Modal.Body>
          </Modal>
        </>
      ) : (
        <>
          <HelloSignProgress />
        </>
      )}
    </>
  );
};

export default SharePfsThirdModal;