/* eslint-disable */
import React, { useState, useEffect } from "react";
import {
  Modal, Button,
  Row, Col, Form, FormLabel,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import { userInitialUpdateRecord } from "../../../../slices/user";
import ProfileImageComponent from "../../../profileImage/ProfileCard";
import { formatPhoneNumber } from "../../../../util/common";
import { authUserUpdated } from "../../../../slices/auth";
import { userFindRecord } from "../../../../slices/user";
import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";
import AddressSearch from "../../../../commonComponent/AddressSearch";
import { uniValidation } from "../../liabilities/categoryForms/validationSchema";

const CompleteProfile = (props) => {
  const { showCompleteProfile } = props;

  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  let id;

  const [addressObj, setAddressObj] = useState({ line1: '', line2: '', city: '', state: '', country: '', postal_code: '' });
  const [isLoading, setIsLoading] = useState(true);
  const [isAddressChange, setIsAddressChange] = useState(false);

  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  useEffect(() => {
    if (id) {
      dispatch(userFindRecord({ id }))
        .unwrap()
        .then((response) => {
          setRecord(response);
        })
        .catch(() => { });
    }
  }, [id]);

  const initialAddress = {
    street_name: '',
    city: '',
    state: '',
    zip_code: ''
  }

  const manualAddressValidationSchema = yup.object({
    street_name: yup
      .string()
      .required(uniValidation.street_name.require).test("is-not-only-spaces", uniValidation.street_name.require, (value) => {
        return value.trim() !== "";
      })
      .matches(/(?=.*?\d)/, uniValidation.street_name.valid)
      .matches(/[A-Za-z]{2}/, uniValidation.street_name.valid),
    city: yup
      .string()
      .required(uniValidation.city.require).test("is-not-only-spaces", uniValidation.city.require, (value) => {
        return value.trim() !== "";
      })
      .min(2, uniValidation.city.valid),
    state: yup
      .string()
      .matches(/^\S(?:.*\S)?$/, "No spaces allowed")
      .min(2, "Required at least 2 characters"),
    zip_code: yup
      .string()
      .required(uniValidation.zip_code.require).test("is-not-only-spaces", uniValidation.zip_code.require, (value) => {
        return value.trim() !== "";
      })
      .matches(/^[0-9]+$/, uniValidation.zip_code.valid)
      .min(5, uniValidation.zip_code.valid)
      .max(5, uniValidation.zip_code.valid),
  });

  const { values, errors, touched, status, handleSubmit, setFieldValue, setStatus, setFieldTouched } =
    useFormik({
      initialValues: initialAddress,
      enableReinitialize: true,
      validationSchema: manualAddressValidationSchema,
      onSubmit: async (values) => {
        if (!!isAddressChange && !!status && (!!status.street_name || !!status.city || !!status.state || !!status.zip_code)) {
          return
        }
        let user_details = {};
        user_details.id = id;
        user_details.address = addressObj;
        dispatch(userInitialUpdateRecord(user_details))
          .unwrap()
          .then((response) => {
            if (response.code === 200) {
              let getValue = localStorage.getItem("user");
              let user = JSON.parse(getValue);
              user.user.address = addressObj;
              dispatch(authUserUpdated({ user: user, addressobj: addressObj }));
              localStorage.setItem("user", JSON.stringify(user));
              props.completeProfileUpdated(user);
            }
          })
          .catch(() => { });
      },
    });

  const handleChange = ({ target }) => {
    setFieldValue(target.name, target.value);
  };

  const handleLogout = () => {
    props.CapsyncLogoutModal(true);
  }

  useEffect(() => {
    if (isAddressChange === true) {
      setIsLoading(false);
    }
  }, [isAddressChange])

  return (
    <>
      <Modal
        show={showCompleteProfile}
        onHide={() => handleLogout()}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="cap-dash-main-modal plaid-modal"
        id="connect-your-institutions"
        keyboard={false}
        dialogClassName={"cs-lg-modal"}
      >
        <div className="cs-complete-profile">
          <Modal.Header>
            <Modal.Title className="default-semi-bold-h3 cs-neutral-100">
              Complete Profile
            </Modal.Title>
            <span className="cs-neutral-70 cs-close-btn" onClick={() => handleLogout()}><CapsyncIcon title="close-outlined" size="20" /></span>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Row>
                <p className="default-medium-sub-heading-m cs-neutral-100">
                  Add your address to complete your profile.
                </p>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="personal-profile">
                    <div className="profile cs-avatar-lg">
                      <ProfileImageComponent
                        toastMessage={props.toastMessage}
                        role={user && user.user_role.role}
                        id={id}
                        profileImageUrl={user && user.user.profileImageUrl}
                        changeProfileState={props.changeProfileState}
                        profileState={props.profileState}
                        changeSrcStatus={props.changeSrcStatus}
                        customImgSrc={props.customImgSrc}
                        user={user}
                      />
                    </div>
                    <input
                      type="file"
                      name="file"
                      style={{ display: "none" }}
                      // onChange={ProfileImgPreview}
                      id="flexCheckDefault"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={6} sm={6}>
                  <Form.Group className="cs-form-group cs-disabled">
                    <FormLabel>First name</FormLabel>
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      placeholder="John"
                      disabled
                      name="firstName"
                      onKeyDown={(e) => {
                        if (e.key === " " && e.target.selectionStart === 0) {
                          e.preventDefault();
                        }
                      }}

                      defaultValue={user.user.firstName}
                      readOnly
                      maxLength={40}
                    />
                    {errors.firstName && touched.firstName ? (
                      <span className="default-light-body-text-s cs-danger">
                        {errors.firstName}
                      </span>
                    ) : null}
                  </Form.Group>
                </Col>
                <Col xs={6} md={6} sm={6}>
                  <Form.Group className="cs-form-group cs-disabled">
                    <FormLabel>Last name </FormLabel>
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      placeholder="Doe"
                      name="lastName"
                      onKeyDown={(e) => {
                        if (e.key === " " && e.target.selectionStart === 0) {
                          e.preventDefault();
                        }
                      }}

                      disabled={true}
                      defaultValue={user.user.lastName}
                      readOnly
                      maxLength={40}
                    />
                    {errors.lastName && touched.lastName ? (
                      <span className="default-light-body-text-s cs-danger">
                        {errors.lastName}
                      </span>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group className="cs-form-group cs-disabled">
                    <FormLabel>Email</FormLabel>
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      placeholder="John"
                      name="email"
                      onKeyDown={(e) => e.code === 'Space' && e.preventDefault()}
                      disabled={true}
                      defaultValue={user.user.email}
                      readOnly
                    />
                    {errors.email && touched.email ? (
                      <span className="default-light-body-text-s cs-danger">
                        {errors.email}
                      </span>
                    ) : null}
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group className="cs-form-group cs-disabled">
                    <FormLabel>Phone number</FormLabel>
                    <Form.Control
                      type="text"
                      name="phoneNo"
                      disabled={true}
                      defaultValue={formatPhoneNumber(user.user.phoneNo)}
                      value={formatPhoneNumber(user.user.phoneNo)}
                      readOnly
                    />
                    {/* <small>Update your phone number from setting page.</small> */}
                  </Form.Group>
                </Col>
              </Row>
              <AddressSearch
                errors={errors}
                touched={touched}
                values={values}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                setAddressObj={setAddressObj}
                setStatus={setStatus}
                status={status}
                addressObj={addressObj}
                setIsAddressChange={setIsAddressChange}
                setFieldTouched={setFieldTouched}
              />
              <div className="profile-footer cs-center-btn cs-modal-btn">
                <Button
                  className="cs-btn-primary md-btn default-regular-h5"
                  type="submit"
                  disabled={isLoading}
                >
                  Continue
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </div>
      </Modal>

    </>
  );
};

export default CompleteProfile;
