/* eslint-disable */
import React, { useCallback, useEffect, useState } from "react";
import {
  Nav, Navbar, NavDropdown,
  Container, Image,
  Dropdown, Form, Button,
  Row, Col
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import moment from "moment";
import img from "../../../../assets/icons/svg/outlined/search-outlined.svg";
import {
  SignaturerequestFetchAll,
  add_advisors,
  notificationOpen,
  requestsNotifications,
} from "../../../slices/requests";
import {
  access_invitation_Pfs,
  access_indiPFS_fromWealth,
  add_recently,
} from "../../../slices/pfs_access";
import { backAppURl } from "../../../applicationMode";
import UseWealthManagerDetails from "../../../customHooks/UseWealthManagerDetails";
import ExpirePopup from "../../../commonComponent/component/ExpirePopup";
// import { wealth_managers_findone } from "../../../slices/auth";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import TrialExpireComponent from "../../../commonComponent/TrialExpireComponent";
import Notifications from "../../../commonComponent/Notifications";
import "../../individual/utilities/utilities.css"
import { trialExpiryDays } from "../../../util/common";
import { avatarTitles } from "../../../config/avatar";
import Avatar from "../../../commonComponent/Avatar";
import { socket } from "../../../config/Socket";
import { roleName } from "../../../config/commonRoleName";
import CapsyncToolTip from "../../../commonComponent/capsyncTooltip/CapsyncToolTip";

const NavbarSection = (props) => {
  let url = backAppURl;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const role = user && user.user_role.role
  const avatarState = useSelector((state) => state.avatar.avatarState)

  const userDisplayName = user && user.user.displayName;
  const user_id = user && user.user.id;
  let wm_email = user && user.user.email;
  const [notifications, setNotifications] = useState([]);
  const [wm_individuals, setWm_individuals] = useState([]);
  // const [wmFetchData, setWmFetchData] = useState({});

  const [isExpanded, setIsExpanded] = useState(false);
  const [initials, setInitials] = useState("");

  let user_display_name = "";

  const { isMobile } = props;


  if (userDisplayName && userDisplayName.length > 10) {
    let arr = userDisplayName.split("");

    for (let z = 0; z < 10; z++) {
      const element = arr[z];
      user_display_name += element;
    }
    user_display_name += "...";
  } else {
    user_display_name = userDisplayName;
  }

  // useEffect(() => {
  //   dispatch(wealth_managers_findone({ wm_id: user_id }))
  //     .unwrap()
  //     .then((response) => {
  //       setWmFetchData(response);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, [props.profileState]);

  const fetchAllNotifications = useCallback(() => {
    if (role == 2 || role == 3) {
      dispatch(
        requestsNotifications({ user_id: user_id, module_name: role == 2 ? roleName.financial_advisor : roleName.tax_professional })
      )
        .unwrap()
        .then((response) => {
          setNotifications(response);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [user_id]);

  const checkDate = (date) => {
    let todayDate = moment(new Date().toISOString()).format("MM/DD/YYYY");
    if (date === todayDate) {
      return "Today";
    } else {
      return date;
    }
  };
  useEffect(() => {
    let use_timer = fetchAllNotifications; //setInterval(fetchAllNotifications, 1000);
    return () => clearInterval(use_timer);
  }, [fetchAllNotifications]);

  const checkNotifications = () => {
    setTimeout(() => {
      dispatch(notificationOpen({ user_id: user_id }));
    }, 2000);
  };
  const handleRefresh = () => {
    window.location.reload();
  };

  const [signRequest, setSignRequest] = useState("");
  const [signRequestCount, setSignRequestCount] = useState("");
  const [expireModal, setExpireModal] = useState(false);

  const { wmFetchUserData } = UseWealthManagerDetails();

  const fetchSignDetails = () => {
    dispatch(
      SignaturerequestFetchAll({
        user_email: wm_email,
      })
    )
      .unwrap()
      .then((response) => {
        const store = response;
        let response1 = response.filter(
          (itr) =>
            (itr.check_status === 4 ||
              itr.check_status === 5 ||
              itr.check_status === 1) &&
            itr.is_Open === false
        );

        setSignRequest(response1);
        setSignRequestCount(response1.length);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    // fetchSignDetails();
  }, []);

  const getIndividuals = async () => {
    try {
      const response = await dispatch(
        access_invitation_Pfs({
          email: user.user.email,
        })
      ).unwrap()
      if (response && response.length) {
        setWm_individuals(response);
      } else {
        setWm_individuals([]);
      }
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  useEffect(() => {
    getIndividuals();
  }, []);
  useEffect(() => {
    const broadcastChannel = new BroadcastChannel("switch_account_channel");
    // broadcastChannel.onmessage = (event) => {
    //   // if (event.data === "switch_account") {
    //   //   setTimeout(() => {
    //   //     const pfs_access_comp = localStorage.getItem("pfs_access_comp")
    //   //     if (pfs_access_comp === "Individual") {
    //   //       window.location.href = '/individual'
    //   //     } else {
    //   //       window.location.href = '/financial-advisor'
    //   //     }
    //   //   }, 500);
    //   // }
    // };
    return () => {
      broadcastChannel.close();
    };
  }, []);
  const handleSwitchAccount = () => {
    const broadcastChannel = new BroadcastChannel("switch_account_channel");
    broadcastChannel.postMessage("switch_account");
  };


  const accessIndividualPFS = (params, pfs_access_id, type) => {
    dispatch(
      add_recently({
        recent_ids: Number(params),
        wm_id: Number(window.localStorage.getItem('id')),
        module: roleName.individual,
      })
    ).unwrap().then((response) => {
      handleSwitchAccount()
    }).catch((err) => {
    })

    if (type === 1) {
      props.accessPFS(params, user_id)
    } else if (role == 2 || role == 3) {
      dispatch(add_advisors({
        advisor_user_id: Number(user_id),
        user_id: Number(params),
        flag: role == 2 ? roleName.financial_advisor : roleName.tax_professional
      })).unwrap().then(() => {
        socket.emit('broadcast_message', { user_id: Number(params) })
        props.accessPFS(params, user_id);
      }).catch((err) => {
        console.log(err);
      })
    }
  };

  const logoutFunc = () => {
    props.CapsyncLogoutModal(true);
  };

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const notification = props.storeAllNotifications.length
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    filterAccounts(query);
  };

  const filterAccounts = (query) => {
    const filtered = wm_individuals.filter((account) => {
      return account.to_status === 1 && account.displayName.toLowerCase().includes(query.toLowerCase());
    });
    setFilteredAccounts(filtered);
  };

  function changeExpireState(params) {
    setExpireModal(params);
  }

  const headerClassName = `${isExpanded ? "expanded-header" : "cs-header"}`;
  const handleHamburgerClick = () => {
    setIsExpanded(!isExpanded); // Toggle the expanded state
    props.toggleSidebar(!props.openSide);
    handleSwitchAccount()
  };

  const [isHovered, setIsHovered] = useState(false);
  const [isBulletHovered, setIsBulletHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleMouseEnterOnBullet = () => {
    setIsBulletHovered(true);
  };

  const handleMouseLeaveOnBullet = () => {
    setIsBulletHovered(false);
  };

  const { customImgSrc, wmDetails: {
    profileImageUrl
  } = {}, wmDetails } = props;

  // const profileImage = customImgSrc !== "" ? customImgSrc : profileImageUrl ? profileImageUrl : require('../../../../assets/images/other/face.png');
  const profileImage = customImgSrc !== "" ? customImgSrc : profileImageUrl ? `${backAppURl}/api/wealthmanager/profile/${user_id}` : require('../../../../assets/images/other/face.png');

  const daysLeft = trialExpiryDays(wmDetails.subscription_expire_date);
  const isUpgradeVisible = wmDetails !== undefined && wmDetails.is_auto_subscription === false && daysLeft < 6


  useEffect(() => {
    const name = user.user.displayName;
    if (name) {
      const nameArray = name.split(' ');
      const firstName = nameArray[0];
      const lastName = nameArray[nameArray.length - 1];
      const initials = firstName.charAt(0) + lastName.charAt(0);
      setInitials(initials.toUpperCase())
    }

  }, [user.user.displayName]);

  const handleAddClient = () => {
    props.setShowModal(true);
    props.setShowInitialModal(true)
  }

  const location = useLocation();

  useEffect(() => {
    const handleUpdatedList = (msg) => {
      getIndividuals();
    };
    socket.on("get_updated_list", handleUpdatedList);
    return () => {
      socket.off("get_updated_list", handleUpdatedList);
    };
  }, []);


  return (
    <>
      <ExpirePopup
        expireModal={expireModal}
        changeExpireState={changeExpireState}
        role={`${role ===2 ? "financial-advisor" : "/professional"} `}
      />
      <div className={headerClassName}>
        <Navbar collapseOnSelect expand="lg">
          {/* <Button
            variant="outline-primary"
            onClick={props.toggle}
            className="sidebar-resp-toggle"
            style={{ border: "none", color: "black" }}
          >
            test
            <FontAwesomeIcon icon={faBars} />
          </Button> */}
          <Container fluid>
            <Row>
              <Col>
                <div className="cs-nav-header">
                  <span
                    onClick={handleHamburgerClick}
                    className="cursor-pointer">
                    <CapsyncIcon title="hamburger-outlined" size="22" />
                  </span>

                  <Dropdown className="cs-navbar-dropdown-btn">
                    <Dropdown.Toggle className="default-light-body-text-s">
                      My Dashboard
                      <CapsyncIcon title="chevron-down-outlined" size="16" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="cs-pfs-menu cs-scroller">
                      <Dropdown.Item
                        className={
                          window.localStorage.getItem("pfs_access_id") == null
                            ? "cs-active-accounts pfs-item "
                            : "pfs-item "
                        }>
                        <Link
                          to={ role ===2 ? "/financial-advisor" : "/professional" } 
                          className="default-light-body-text-s cs-neutral-100">
                          My Dashboard
                        </Link>
                      </Dropdown.Item>
                      <span className="default-regular-sub-heading-xs cs-neutral-60">
                        SHARED ACCOUNTS
                      </span>

                      <div className="cs-search-bar">
                        <Form.Group className="cs-form-group">
                          <Form.Control
                            type="text"
                            placeholder="Search account"
                            value={searchQuery}
                            // onChange={(event) => setSearch(event.target.value)}
                            className={searchQuery && "cs-input-field-active"}
                            onChange={handleSearchChange}
                          />
                          <span className="input-field-icon">
                            <CapsyncIcon title="search-outlined" size="18" />
                          </span>
                        </Form.Group>
                      </div>

                      {/* <Dropdown.Item className="pfs-item-name"> */}
                      {searchQuery ? (
                        filteredAccounts.length >= 1 ? (
                          <>
                            {filteredAccounts.map((itr_shared) => {
                              return (
                                <Dropdown.Item
                                  className="pfs-item-name"
                                  key={itr_shared.id}
                                  onClick={() =>
                                    accessIndividualPFS(
                                      itr_shared.id,
                                      itr_shared.pfs_access_id,
                                      1
                                    )
                                  }>
                                  <div className="default-regular-h5">
                                    <div className="linked-account">
                                      <span className="linked-account-name">
                                        <h6 className="default-light-body-text-s cs-neutral-90">
                                          {/* {itr_shared.displayName} */}
                                          {itr_shared.displayName.length >
                                          20 ? (
                                            <CapsyncToolTip
                                              Child={
                                                itr_shared.displayName.slice(
                                                  0,
                                                  20
                                                ) + "..."
                                              }
                                              placement={"top"}
                                              message={itr_shared.displayName}
                                              type="text"
                                            />
                                          ) : (
                                            <>{itr_shared.displayName}</>
                                          )}
                                        </h6>
                                        {/* <CapsyncIcon title="verified-filled" size="18" /> */}
                                      </span>

                                      <span className="cs-neutral-70 default-regular-body-text-xs">
                                        {itr_shared.to_access_permission == 1
                                          ? "View Only"
                                          : "Standard"}
                                      </span>
                                    </div>
                                  </div>
                                </Dropdown.Item>
                              );
                            })}
                          </>
                        ) : (
                          <p className="default-regular-sub-heading-xs cs-neutral-60">
                            No shared accounts available
                          </p>
                        )
                      ) : wm_individuals.length >= 1 &&
                        wm_individuals.some(
                          (item) => item.to_status === 1 && item.is_subscribe
                        ) ? (
                        <>
                          {wm_individuals.map((itr_shared) => {
                            const {
                              to_access_permission,
                              displayName,
                              id,
                              pfs_access_id,
                            } = itr_shared;
                            return (
                              itr_shared.to_status === 1 &&
                              itr_shared.is_subscribe && (
                                <Dropdown.Item
                                  className="pfs-item-name"
                                  key={id}
                                  onClick={() =>
                                    accessIndividualPFS(
                                      id,
                                      pfs_access_id,
                                      to_access_permission
                                    )
                                  }>
                                  <div className="default-regular-h5">
                                    <div className="linked-account">
                                      <span className="linked-account-name">
                                        <h6 className="default-light-body-text-s cs-neutral-90">
                                          {/* {displayName} */}
                                          {displayName.length > 20 ? (
                                            <CapsyncToolTip
                                              Child={
                                                displayName.slice(0, 20) + "..."
                                              }
                                              placement={"top"}
                                              message={displayName}
                                              type="text"
                                            />
                                          ) : (
                                            <>{displayName}</>
                                          )}
                                        </h6>
                                        {/* <CapsyncIcon title="verified-filled" size="18" /> */}
                                      </span>

                                      <span className="cs-neutral-70 default-regular-body-text-xxs">
                                        {to_access_permission == 1
                                          ? "View Only"
                                          : "Standard"}
                                      </span>
                                    </div>
                                  </div>
                                </Dropdown.Item>
                              )
                            );
                          })}
                        </>
                      ) : (
                        <p className="default-regular-sub-heading-xs cs-neutral-60">
                          No shared accounts available
                        </p>
                      )}
                      {/* </Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>

                  <Nav className="ms-auto navbar-wrapper financial-notification">
                    <TrialExpireComponent
                      data={wmDetails}
                      daysLeft={daysLeft}
                      changeExpireState={changeExpireState}
                    />
                    {isUpgradeVisible && (
                      <div className="navbar-vertical-line nav-icons"></div>
                    )}
                    <Nav>
                      <Notifications
                        forIndividual={false}
                        props={props}
                        notification={notification}
                        address={user.user && user.user.address}
                        // profileImg={customImgSrc !== "" ? customImgSrc : profileImageUrl ? profileImageUrl : ""}
                        profileImg={
                          customImgSrc !== ""
                            ? customImgSrc
                            : profileImageUrl
                            ? `${backAppURl}/api/wealthmanager/profile/${user_id}`
                            : ""
                        }
                      />
                    </Nav>

                    {<div className="navbar-vertical-line nav-icons"></div>}
                    <div>
                      <NavDropdown
                        // title={<Image
                        //   className="profile-img"
                        //   src={profileImage}
                        //   alt="profile"
                        // />}

                        title={
                          !!user.user.profileImageUrl &&
                          user.user.profileImageUrl.includes("avtar") ? (
                            <div
                              className={` ${
                                user.user.profileImageUrl === "avtar-6"
                                  ? "avatar-initials"
                                  : ""
                              } `}>
                              <Avatar
                                title={user.user.profileImageUrl}
                                size={40}
                              />
                              {user.user.profileImageUrl === "avtar-6" && (
                                <div className="initials-overlay default-regular-h5 cs-neutral-80">
                                  {initials}{" "}
                                </div>
                              )}
                            </div>
                          ) : user.user.profileImageUrl === null ? (
                            <>
                              <Avatar title="avtar-6" size={40} />
                              <div className="initials-overlay default-regular-h5 cs-neutral-80">
                                {initials}
                              </div>
                            </>
                          ) : (
                            <Image
                              className="profile-img"
                              src={
                                props.customImgSrc !== ""
                                  ? props.customImgSrc
                                  : user.user.profileImageUrl
                                  ? `${backAppURl}/api/${
                                      user.user_role.role === 1
                                        ? "user"
                                        : "wealthmanager"
                                    }/profile/${user.user.id}`
                                  : require("../../../../assets/images/other/face.png")
                              }
                              alt="Profile"
                              width={40}
                              height={40}
                            />
                          )
                        }
                        className="cs-settings-dropdown">
                        <NavDropdown.Item
                          className={`profile-item ${
                            [
                              "/financial-advisor/account-details",
                              "/financial-advisor/mfa-setup",
                              "/financial-advisor/billing-subscription",
                              "/financial-advisor/audit-logs",
                              "/professional/account-details",
                              "/professional/mfa-setup",
                              "/professional/billing-subscription",
                              "/professional/audit-logs",
                            ].includes(window.location.pathname)
                              ? "selected-profile-item"
                              : ""
                          }`}
                          onClick={() => {
                            role === 2
                              ? navigate("/financial-advisor/account-details")
                              : navigate("/professional/account-details");
                          }}>
                          <Nav.Link className="profile-item-link">
                            {[
                              "/financial-advisor/account-details",
                              "/financial-advisor/mfa-setup",
                              "/financial-advisor/billing-subscription",
                              "/financial-advisor/audit-logs",
                              "/professional/account-details",
                              "/professional/mfa-setup",
                              "/professional/billing-subscription",
                              "/professional/audit-logs",
                            ].includes(window.location.pathname) ? (
                              <>
                                <span className="selected-nav-icon">
                                  <CapsyncIcon
                                    title="settings-filled"
                                    size="22"
                                  />
                                </span>
                                <span className="default-regular-h5 selected-nav-item">
                                  Settings
                                </span>
                              </>
                            ) : (
                              <>
                                <CapsyncIcon
                                  title="settings-outlined"
                                  size="22"
                                />

                                <span className="default-regular-h5">
                                  Settings
                                </span>
                              </>
                            )}
                          </Nav.Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onClick={logoutFunc}
                          className="profile-item">
                          <Nav.Link className="profile-item-link">
                            <CapsyncIcon title="logout-outlined" size="22" />
                            <span className="default-regular-h5">Log out</span>
                          </Nav.Link>
                        </NavDropdown.Item>
                      </NavDropdown>
                    </div>
                  </Nav>
                </div>
              </Col>
            </Row>
          </Container>
        </Navbar>
        <span
          onClick={() => handleAddClient()}
          className="cs-neutral-10 mobile-add-icon">
          <CapsyncIcon title="add-filled" size="22" />
        </span>
      </div>
    </>
  );
};

export default NavbarSection;
