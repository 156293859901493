import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const CommonSettingDropdown = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const role = user && user.user_role.role;
  const [tabArr] = useState([
    {
      pathName: "/professional/account-details",
      name: "Account Details",
    },
    { pathName: "/professional/mfa-setup", name: "MFA Setup" },
    // { pathName: "/professional/file-encryption", name: "File Encryption" },
    {
      pathName: "/professional/billing-subscription",
      name: "Billing & Subscription",
    },
    { pathName: "/professional/audit-logs", name: "Audit Logs" },
  ]);

  function handleDropdownSelect(eventKey) {
    navigate(eventKey);
  }

  return (
    <>
      <div className="setting-mobile-dropdown">
        <Dropdown onSelect={handleDropdownSelect}>
          <Dropdown.Toggle className="default-regular-body-text-l">
            {
              tabArr.filter((x) => x.pathName === window.location.pathname)[0]
                .name
            }
            <span className="cs-neutral-80">
              <CapsyncIcon title="chevron-down-outlined" size="16" />
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              eventKey="/professional/account-details"
              active={
                window.location.pathname === "/professional/account-details"
              }
              className="default-regular-body-text-l cs-neutral-90"
            >
              Account Details
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="/professional/mfa-setup"
              active={window.location.pathname === "/professional/mfa-setup"}
              className="default-regular-body-text-l cs-neutral-90"
            >
              MFA Setup
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="/professional/billing-subscription"
              active={
                window.location.pathname ===
                "/professional/billing-subscription"
              }
              className="default-regular-body-text-l cs-neutral-90"
            >
              Billing & Subscription
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="/professional/audit-logs"
              active={window.location.pathname === "/professional/audit-logs"}
              className="default-regular-body-text-l cs-neutral-90"
            >
              Audit Logs
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

export default CommonSettingDropdown;
