import React, { useState, useEffect } from "react";
import { Accordion, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import commonNames from "../../config/commonNames";
import moment from "moment";
import EditModal from './Company/EditModal'
import "./FinancialAdvisor.css";
import {
  company_add_recently,
} from "../../slices/pfs_access";
import {
  wealth_companies_partners,
  wealth_company_remove,
  comomon_partner_delete,
} from "../../slices/wealthManager.slice";
import CategoryTableData from "../../commonComponent/CategoryTableData";
import CapsyncIcon from "../../commonComponent/CapsyncIcon";
import DeletePartner from "./DeletePartner";
import DeleteModal from "../../commonComponent/modalPopup/deleteModal/DeleteModal";
import CapsyncToolTip from "../../commonComponent/capsyncTooltip/CapsyncToolTip";
import { socket } from "../../config/Socket";
import { add_advisors, user_access_permission } from "../../slices/requests";
import AccordionSkeleton from "./AccordionSkeleton";
import { formatPhoneNumber } from "../../util/common";
import { roleName } from "../../config/commonRoleName";
import { useSelector } from "react-redux";
const ActionComponent = (props) => {
  const { company, changeEditModalState, deleteCompanyPartner, fetchPartners, id, CapsyncDeleteMessage, } = props;
  const { user } = useSelector((state) => state.auth)
  const role = user.user_role.role

  return (
    <>
      <div className="action-box table-icons-container cs-file-icon">
        <span onClick={(e) => {

          changeEditModalState(true, false, company.companyName, company.id)
          e.stopPropagation()
          const result = fetchPartners(id)
        }
        }
          className="cs-icon-badge-neutral-20 cs-neutral-80"
        >
          <CapsyncToolTip
            Child={"edit-outlined"}
            placement={"top"}
            size="18"
            message={"Edit"}
            type="wealth_company_action"
            entry={company}
          />
        </span>
        <span
          onClick={(e) => {
            e.stopPropagation();
            CapsyncDeleteMessage(
              true,
              "",
              "You are going to delete this entry, once it is deleted you will be not able to recover this data.",
              () => deleteCompanyPartner(company.id)
            );
          }}
          className="cs-icon-badge-danger cs-danger"
        >
          <CapsyncToolTip
            Child={"delete-outlined"}
            placement={"top"}
            size="18"
            message={"Delete"}
            type="wealth_company_action"
            entry={company}
          />

        </span>
      </div>
    </>
  );
};

const CompanyTable = (props) => {
  const { user } = useSelector((state) => state.auth)
  const role = user.user_role.role
  const {

    toastMessage,
    forDashboard,
    data,
    fetchAllCompanies,
    changeAddEditModalState,
    filteredCompanies,
    editAddmodalState,
    partners,
    setPartners,
    rowStartIdx,
    rowsPerPage,
    setRowStartIdx,
    currentNumberOfRows,
  } = props;
  const dispatch = useDispatch();
  const [showDeletePartnerModal, setShowDeletePartnerModal] = useState(false);
  const [smLoader, setsmLoader] = useState(false);
  const [activeKey, setActiveKey] = useState(null);
  const [companyPartners, setCompanyPartners] = useState([]);
  const [commonPartnerDelete, setCommonPartnerDelete] = useState({
    comp_id: -1,
    request_id: -1,
    check_status: -1,
    wm_id: -1,
  });

  const [editModalState, setEditModalState] = useState({
    show: false,
    hide: true,
    companyName: "",
    companyId: "",
  });

  const [deleteStatus, setDeleteStatus] = useState({
    show: false,
    message: "",
    title: "",
    custom: "",
  });
  function CapsyncDeleteMessage(s, msg, title, func) {
    setDeleteStatus({
      show: s,
      message: msg,
      title: title,
      custom: func,
    });

  }

  useEffect(() => {
    setActiveKey(null);
  }, [data]);

  const changeCommonPartnerDeleteObject = (
    comp_id,
    request_id,
    check_status,
    wm_id,

  ) => {
    setCommonPartnerDelete({
      comp_id: comp_id,
      request_id: request_id,
      check_status: check_status,
      wm_id: wm_id,
    });
  };

  const changeEditModalState = (s, h, companyName, companyId) => {
    setEditModalState({
      show: s,
      hide: h,
      companyName: companyName,
      companyId: companyId,
    });
  };

  function deleteCompanyPartner(param) {
    dispatch(wealth_company_remove({ c_id: param }))
      .unwrap()
      .then(() => {
        props.toastMessage("This company has been deleted successfully.", "success");
        fetchAllCompanies();
        if (currentNumberOfRows(rowStartIdx, rowsPerPage, data.length) <= 1 && rowStartIdx > 0) {
          setRowStartIdx(rowStartIdx - rowsPerPage)
        }
        //doubt call list again
      })
      .catch((err) => {
        props.toastMessage(err.message, "error");
      });
  }

  function removePartnerCommon(delete_from) {
    dispatch(
      comomon_partner_delete({
        commonPartnerDelete: commonPartnerDelete,
        delete_from: delete_from,
      })
    )
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          props.toastMessage("Deleted Successfully", "success");
          fetchAllCompanies();
        } else {
          props.toastMessage("Rejected", "error");
        }
      })
      .catch((err) => {
        props.toastMessage(err.message, "error");
      })
  }

  const fetchPartners = (params) => {
    setsmLoader(true);
    dispatch(wealth_companies_partners({ comp_id: params }))
      .unwrap()
      .then((response) => {
        setCompanyPartners(response);

        setsmLoader(false);
      })
      .catch((err) => {
        setsmLoader(false);
        props.toastMessage(err.message, "error");
      });
  };

  useEffect(() => {
    const broadcastChannel = new BroadcastChannel("switch_account_channel");
    // broadcastChannel.onmessage = (event) => {
    //   // if (event.data === "switch_account") {
    //   //   setTimeout(() => {
    //   //     window.location.reload();
    //   //   }, 500);
    //   // }
    // };
    return () => {
      broadcastChannel.close();
    };
  }, []);
  const handleSwitchAccount = () => {

    const broadcastChannel = new BroadcastChannel("switch_account_channel");
    broadcastChannel.postMessage("switch_account");
  };

  const accessCompanyIndividualPFS = (input) => {
    const { comp_id, recent_ids, email } = input

    window.localStorage.setItem("pfs_comp_id", comp_id)


    const recentDataObj = {
      comp_id: comp_id,
      email: email,
      recent_ids: recent_ids,
      wm_id: Number(window.localStorage.getItem('id')),
      module: "company",
    }

    dispatch(
      company_add_recently(recentDataObj)
    ).unwrap().then((res) => {
    }).catch((err) => {
      console.log('err:- ', err);
    });

    dispatch(user_access_permission({
      user_id: Number(input.request_send_to),
      advisor_id: Number(window.localStorage.getItem('id')),
      user_role: JSON.parse(window.localStorage.getItem('loginStatus')).user_role === 1 ? roleName.individual : JSON.parse(window.localStorage.getItem('loginStatus')).user_role === 2 ? roleName.financial_advisor : roleName.tax_professional
    })).unwrap()
      .then((response) => {
        if (response.access_status === 2) {
          dispatch(add_advisors({
            advisor_user_id: Number(window.localStorage.getItem('id')),
            user_id: Number(input.request_send_to),
            flag: role == 2 ? roleName.financial_advisor : roleName.tax_professional
          })).unwrap().then(() => {
            socket.emit('broadcast_message', { user_id: Number(input.request_send_to) })
            props.accessPFS(input.request_send_to, Number(window.localStorage.getItem('id')))
          }).catch((err) => {
            console.log(err);
          });
        } else {
          props.accessPFS(input.request_send_to, Number(window.localStorage.getItem('id')))
        }


      }).catch((err) => {
        console.log('err:- ', err);
      })
    handleSwitchAccount()
  };

  const onPartnerClick = (partner, companyName) => {
    const {
      email,
      check_status,
      request_id,
      comp_id,
      request_send_to,
      is_subscribe
    } = partner;
    if (check_status === 1 && is_subscribe === true) {
      accessCompanyIndividualPFS(
        {
          request_id: request_id,
          comp_id: comp_id,
          recent_ids: request_id,
          email: email,
          companyName: companyName,
          request_send_to: request_send_to
        })
    }
  }

  const handlDeletePartner = (e, partner) => {
    const { comp_id, request_id, check_status, request_send_by } = partner;
    e.stopPropagation();
    setShowDeletePartnerModal(
      true
    );
    changeCommonPartnerDeleteObject(
      comp_id,
      request_id,
      check_status,
      request_send_by
    );
  }

  const handleCompanyClick = (rowStartIdx, index, id) => {
    if (activeKey === rowStartIdx + index === false) {
      fetchPartners(id)
    }
    if (activeKey === rowStartIdx + index) {
      setActiveKey(null)
    }
    else {
      setActiveKey(rowStartIdx + index)
    }
  }

  return (
    <>
      {forDashboard === false &&
        <DeletePartner
          setShowDeletePartnerModal={setShowDeletePartnerModal}
          showDeletePartnerModal={showDeletePartnerModal}
          changeCommonPartnerDeleteObject={changeCommonPartnerDeleteObject}
          removePartnerCommon={removePartnerCommon}
        />}
      {forDashboard === false && <EditModal
        editModalState={editModalState}
        changeEditModalState={changeEditModalState}
        companyPartners={companyPartners}
        changeAddEditModalState={changeAddEditModalState}
        filteredCompanies={filteredCompanies}
        toastMessage={toastMessage}
        fetchAllCompanies={fetchAllCompanies}
        editAddmodalState={editAddmodalState}
        setPartners={setPartners}
        partners={partners}

      />}
      {forDashboard === false && <DeleteModal
        CapsyncDeleteMessage={CapsyncDeleteMessage}
        deleteStatus={deleteStatus}
      />}

      {data.length == 0 && (
        <span className="cs-common-no-record">
          There are no records to display
        </span>
      )}
      {data.length > 0 && (
        <>
          <div className="table amortization-main-listing signatur-from companies-table">
            <Table className="cs-table company-first-table">
              <thead className="cs-thead">
                <tr>
                  <th
                    className="default-medium-sub-heading-m cs-neutral-100"
                    width="600">
                    Company Name
                  </th>
                  <th
                    className="default-medium-sub-heading-m cs-neutral-100"
                    width="400">
                    Members
                  </th>
                  <th
                    className="default-medium-sub-heading-m cs-neutral-100"
                    width="300">
                    {forDashboard ? "PFS Submitted" : commonNames.Actions}
                  </th>
                </tr>
              </thead>
              <tbody className="cs-tbody request-dashboard">
                <tr>
                  <td
                    className="accordion-table-collapse accordion-after"
                    colSpan={3}>
                    <Accordion defaultActiveKey="0" activeKey={activeKey}>
                      {data.slice(rowStartIdx, rowStartIdx + rowsPerPage).map((company, index) => {
                        const { id, companyName, companyMembers, completed_sign_request_count, sign_request_count } = company;
                        return (
                          <Accordion.Item eventKey={rowStartIdx + index}>
                            <Accordion.Header onClick={() => handleCompanyClick(rowStartIdx, index, id)}>
                              <Table className="cs-table">
                                <tbody className="cs-tbody">
                                  <tr>
                                    <td
                                      className="default-regular-sub-heading-m cs-neutral-90"
                                      width="600">
                                      {/* {companyName} */}
                                      {companyName ? (
                                        companyName.length > 50 ? (
                                          <CapsyncToolTip
                                            Child={companyName.slice(0, 50) + "..."}
                                            placement={"top"}
                                            message={companyName}
                                            type="text"
                                          />
                                        ) : (
                                          companyName
                                        )
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                    <td
                                      className="default-regular-sub-heading-m cs-neutral-90"
                                      width="400">
                                      {companyMembers}
                                    </td>

                                    <td
                                      className="default-regular-sub-heading-m"
                                      width="300"
                                      onClick={() => fetchPartners(id)}>
                                      {forDashboard ? (
                                        completed_sign_request_count === 0 && sign_request_count === 0 ? '-' :
                                          completed_sign_request_count + "/" + sign_request_count
                                      ) : (
                                        <ActionComponent
                                          changeEditModalState={
                                            changeEditModalState
                                          }
                                          CapsyncDeleteMessage={CapsyncDeleteMessage}
                                          deleteStatus={deleteStatus}
                                          setDeleteStatus={setDeleteStatus}
                                          company={company}
                                          deleteCompanyPartner={
                                            deleteCompanyPartner
                                          }
                                          id={id}
                                          fetchPartners={fetchPartners}
                                          companyPartners={companyPartners}
                                        />
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Accordion.Header>
                            <Accordion.Body>
                              {smLoader && activeKey === rowStartIdx + index === true ? (
                                <AccordionSkeleton noOfRows={companyMembers} noOfCols={6} />
                              ) : (
                                <>
                                  {companyPartners.length > 0 && (
                                    <Table className="cs-table accordion-table">
                                      <thead className="cs-thead">
                                        <tr>
                                          <th className="default-semi-bold-h6 cs-neutral-100">
                                            First name
                                          </th>
                                          <th className="default-semi-bold-h6 cs-neutral-100">
                                            Last name
                                          </th>
                                          <th className="default-semi-bold-h6 cs-neutral-100">
                                            Email
                                          </th>
                                          <th className="default-semi-bold-h6 cs-neutral-100">
                                            Phone Number
                                          </th>
                                          {forDashboard === false ? <th className="default-semi-bold-h6 cs-neutral-100">
                                            Date Added
                                          </th> :
                                            <th className="default-semi-bold-h6 cs-neutral-100">
                                              Status
                                            </th>}
                                          {/* Update the actions based on props  */}
                                          {forDashboard == false ? (
                                            <>
                                              <th className="default-semi-bold-h6 cs-neutral-100">
                                                {commonNames.Actions}
                                              </th>
                                            </>
                                          ) : (
                                            <th className="default-semi-bold-h6 cs-neutral-100"></th>
                                          )}
                                        </tr>
                                      </thead>

                                      <tbody className="cs-tbody">
                                        {companyPartners.map((partner, index) => {
                                          const {
                                            firstName,
                                            lastName,
                                            email,
                                            fromPhoneNo,
                                            check_status,
                                            updatedAt,
                                            sign_request_status,
                                            is_subscribe
                                          } = partner;
                                          return (
                                            <tr key={index} onClick={() => onPartnerClick(partner, companyName)} className={check_status === 1 && is_subscribe === true && "cursor-pointer"}>
                                              <td className="default-regular-body-text-m cs-neutral-90">
                                                {firstName}
                                              </td>
                                              <td className="default-regular-body-text-m cs-neutral-90">
                                                {lastName}
                                              </td>
                                              <td className="default-regular-body-text-m cs-neutral-90">
                                                {/* {email} */}
                                                {email ? (
                                                  email.length > 25 ? (
                                                    <CapsyncToolTip
                                                      Child={email.slice(0, 25) + "..."}
                                                      placement={"top"}
                                                      message={email}
                                                      type="text"
                                                    />
                                                  ) : (
                                                    email
                                                  )
                                                ) : (
                                                  "-"
                                                )}
                                              </td>

                                              <td className="default-regular-body-text-m cs-neutral-90">
                                                {fromPhoneNo
                                                  ? formatPhoneNumber(fromPhoneNo)
                                                  : "-"}
                                              </td>
                                              {forDashboard === false ? (check_status == 2 ||
                                                check_status == 3 ? (
                                                <td className="default-regular-body-text-m cs-neutral-90">
                                                  <span className="cs-badge cs-warning default-regular-body-text-xs">
                                                    Pending
                                                  </span>
                                                </td>
                                              ) : (
                                                <td className="default-regular-body-text-m cs-neutral-90">
                                                  {moment(updatedAt).format(
                                                    "MM/DD/YYYY"
                                                  )}
                                                </td>
                                              ))
                                                : (forDashboard && (
                                                  <>
                                                    {/* <h1>{sign_request_status}</h1> */}
                                                    {sign_request_status === 1 ? (

                                                      <td className="default-regular-body-text-m cs-neutral-90">
                                                        <span className="cs-badge default-regular-body-text-xs cs-success">
                                                          Completed
                                                        </span>
                                                      </td>)
                                                      : sign_request_status === 0 ? (
                                                        <td><span>
                                                          -
                                                        </span></td>
                                                      ) : (
                                                        < td className="default-regular-body-text-m cs-neutral-90">
                                                          <span className="cs-badge cs-warning default-regular-body-text-xs">
                                                            Pending
                                                          </span>
                                                        </td>
                                                      )}
                                                  </>
                                                ))}

                                              {forDashboard == false ? (
                                                <td className="default-regular-body-text-m">
                                                  <div className="wm-individual-action table-icons-container cs-file-icon">
                                                    <div className={`cs-icon-badge-danger cs-danger`}>
                                                      <CategoryTableData
                                                        type="wealth_company_action"
                                                        clickFunc={(e) => handlDeletePartner(e, partner)}
                                                        entry={company}
                                                        icon="delete-outlined"
                                                      />
                                                    </div>
                                                    <span
                                                      className={check_status === 1 ? "cs-neutral-80" : "cs-neutral-80 cs-disabled"}>
                                                      {is_subscribe === false && check_status === 1
                                                        ? <span className="cs-neutral-80 cs-disabled-icon">
                                                          <CapsyncToolTip
                                                            Child={"chevron-right-outlined"}
                                                            placement={"top"}
                                                            size="18"
                                                            message={"There’s an issue with this users account"}
                                                            type="icon"
                                                          />
                                                        </span>
                                                        :
                                                        <CapsyncIcon title="chevron-right-outlined" size="18" />

                                                      }
                                                    </span>
                                                  </div>
                                                </td >
                                              ) : (
                                                <td className="default-regular-body-text-m cs-neutral-90">
                                                  <div className="wm-individual-action company-dashboard">
                                                    <span
                                                      className={check_status === 1 ? "cs-neutral-80" : "cs-neutral-80 cs-disabled-icon"}>
                                                      {is_subscribe === false && check_status === 1
                                                        ? <span className="cs-neutral-80 cs-disabled-icon">
                                                          <CapsyncToolTip
                                                            Child={"chevron-right-outlined"}
                                                            placement={"top"}
                                                            size="18"
                                                            message={"There’s an issue with this users account"}
                                                            type="icon"
                                                          />
                                                        </span>
                                                        :
                                                        <CapsyncIcon title="chevron-right-outlined" size="18" />

                                                      }
                                                    </span>
                                                  </div>
                                                </td >
                                              )
                                              }
                                            </tr >
                                          );
                                        })}
                                      </tbody>
                                    </Table>
                                  )}
                                  {companyPartners.length == 0 && (
                                    <span className="cs-common-no-record">
                                      There are no records to display
                                    </span>
                                  )}
                                </>
                              )}
                            </Accordion.Body >
                          </Accordion.Item >
                        );
                      })}
                    </Accordion >
                  </td >
                </tr >
              </tbody >
            </Table >
          </div>
          <>
            {forDashboard === true && (
              <span className="wm-view-all">
                {
                  role ===2 ? <Link to={"/financial-advisor/companies"}>View all</Link> : <Link to={"/professional/companies"}>View all</Link>
                }
              </span>
            )}
          </>
        </>
      )
      }
    </>
  );
};

export default CompanyTable;
