import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import LeftPart from "./LeftPart";
import { useFormik } from "formik";
import * as yup from "yup";
import { signin } from "../../slices/auth";
import { clearMessage } from "../../slices/message";
import PageNotFound from "../404";
import Cookies from "universal-cookie";
import CapsyncLoader from "../../commonComponent/CapsyncLoader";
import { checkDomainAccept, loginStatusStore } from "../../util/common";
import CapsyncIcon from "../../commonComponent/CapsyncIcon";
import AuthHead from "./AuthHead";
import { socket } from "../../config/Socket";
import { sendVerificationEmail } from "../../slices/auth";
import DeviceDetector from "device-detector-js";
import { roleName } from "../../config/commonRoleName";
import { syncPlaidAccounts } from "../../config/plaidFunctions";
import { uniValidation } from "../individual/liabilities/categoryForms/validationSchema";


const SignIn = ({ handleLogoutFunc }) => {
  const [showPwd, setShowPwd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [authValidMsg, setAuthValidMsg] = useState("");
  const [verifyCode, setVerifyCode] = useState("");
  const [sendEmail, setSendEmail] = useState();
  const { message } = useSelector((state) => state.message);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cookies = new Cookies();

  const deviceDetector = new DeviceDetector();
  const userAgent = window.navigator.userAgent;
  const device_info = deviceDetector.parse(userAgent);

  const statusCls =
    message && message.status === "Error"
      ? "cs-msg default-regular-body-text-s cs-danger"
      : "cs-msg default-regular-body-text-s cs-success";



  useEffect(() => {
    dispatch(clearMessage());
    window.localStorage.removeItem('_signup_verify');
  }, [dispatch]);

  useEffect(() => {
    handleLogoutFunc(false);
  }, [window.localStorage.getItem("user")]);

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      // .matches(/^\S*$/, uniValidation.email.sapces)
      .required(uniValidation.email.required).test("is-not-only-spaces", uniValidation.email.required, (value) => {
        return value.trim() !== "";
      })
      .email(uniValidation.email.validEmail)
      .test('no-more-than-30-before-at', uniValidation.email.validEmail, value =>
        value ? value.split('@')[0].length <= 30 : true
      )
      .test('at-least-1-character-before-at', 'Enter at least 1 character before @', value =>
        value ? value.split('@')[0].length > 0 : true
      )
      .test('custom-email-format', uniValidation.email.validEmail, value => {
        return value ? uniValidation.email.allowedFormat.test(value) : true
      })
      .test('domain-acceptance', uniValidation.email.validEmail, value => {
        return checkDomainAccept(value);
      }),
    password: yup
      .string()
      .required(uniValidation.password.require).test("is-not-only-spaces", uniValidation.password.require, (value) => {
        return value.trim() !== "";
      })
      .matches(/^\S*$/, uniValidation.password.comman)
      .matches(/[0-9]/, uniValidation.password.comman)
      .matches(/[a-z]/, uniValidation.password.comman)
      .matches(/[A-Z]/, uniValidation.password.comman)
      .matches(/[^A-Za-z0-9]/, uniValidation.password.comman)
      .min(8, uniValidation.password.comman)
      .max(20, uniValidation.password.comman)
  });


  const resendEmail = (emailVerificationCode) => {
    setLoading(true);
    dispatch(sendVerificationEmail({ emailVerificationCode }))
      .unwrap()
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    setSendEmail(true);
  };
  useEffect(() => {
    const broadcastChannel = new BroadcastChannel("signin_channel");
    broadcastChannel.onmessage = (event) => {
      if (event.data === "signin") {
        setTimeout(() => {
          // window.location.reload();
        }, 500);
      }
    };
    return () => {
      broadcastChannel.close();
    };
  }, []);

  const handleSignIn = () => {
    const broadcastChannel = new BroadcastChannel("signin_channel");
    broadcastChannel.postMessage("signin");
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: validationSchema,
      onSubmit: async (formValue) => {
        const { email, password } = formValue;
        setLoading(true);
        setSendEmail(false)
        dispatch(signin({ email, password, device_info }))
          .unwrap()
          .then((data) => {
            if (data.code === 500) {
              setAuthValidMsg(data.message);
              if (data.verifyCode) {
                setVerifyCode(data.verifyCode);
              }
              setLoading(false);
            } else {
              // if (data.user.user.is_expire_password) {
              //   return navigate("/expire-password");
              // }
              if (data.user.user_role.role === 2 || data.user.user_role.role === 3) {
                const deviceCookie = cookies.get(
                  "deviceAuth24Hr-" + data.user.user.id
                );
                if (deviceCookie) {
                  handleSignIn()
                  loginStatusStore(data.user.user_role.role, data.user.user.id);
                  socket.emit("fetch_user", {
                    user_id: data.user.user.id,
                    user_type: data.user.user_role.role,
                    user_role: data.user.user_role.role === 2 ? roleName.financial_advisor : roleName.tax_professional,
                    logged_out: false,
                  });
                  socket.emit("user_logging_out", true);
                  if (data.user.user_role.role === 2) {
                    navigate("/financial-advisor");
                  } else {
                    navigate("/professional");
                  }
                } else {
                  if (data.user.user.firstSignInSteps === 1) {
                    navigate("/signin-mf-verification");
                  } else {
                    navigate("/signin-mf-auth-setup");
                  }
                }
              } else {
                const deviceCookie = cookies.get(
                  "deviceAuth24Hr-" + data.user.user.id
                );
                if (deviceCookie) {
                  handleSignIn()
                  loginStatusStore(data.user.user_role.role, data.user.user.id);
                  syncPlaidAccounts(data.user.user.id)
                  socket.emit("fetch_user", {
                    user_id: data.user.user.id,
                    user_type: data.user.user_role.role,
                    user_role: roleName.individual,
                    logged_out: false,
                  });
                  socket.emit("user_logging_out", true);
                  navigate("/individual");
                } else {
                  if (data.user.user.firstSignInSteps === 1) {
                    navigate("/signin-mf-verification");
                  } else {
                    navigate("/signin-mf-auth-setup");
                  }
                }
              }
            }
          })
          .catch(() => {
            setLoading(false);
          });
      },
    });

  return (
    <section className="auth-section">
      <Container fluid>
        <Row className="align-items-center">
          <Col lg={6}>
            <LeftPart
              page="signin"
              img="auth-image.svg"
              title="Your New Immersive Digital Finance Hub."
              text="Sync your real-time digital Personal Financial Statement with CapSync."
            />
          </Col>
          <Col lg={6}>
            <div className="auth-right-section">
              <AuthHead title="Sign in" />
              <div className="cs-auth-form">
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg={12}>
                      <Form.Group className="cs-form-group">
                        <Form.Control
                          className={
                            values.email && "cs-input-field-active"
                          }
                          type="email"
                          placeholder="Email address"
                          autoComplete="off"
                          name="email"
                          onKeyDown={(e) => e.code === 'Space' && e.preventDefault()}
                          value={values.email}
                          onChange={handleChange}
                        // onBlur={handleBlur}
                        />
                        {errors.email && touched.email ? (
                          <span className="form-error-msg default-light-body-text-s cs-danger">
                            {errors.email}
                          </span>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <Form.Group className="cs-form-group">
                        <Form.Control
                          className={
                            values.password && "cs-input-field-active"
                          }
                          type={showPwd ? "text" : "password"}
                          placeholder="Password"
                          autoComplete="off"
                          name="password"
                          onKeyDown={(e) => {
                            if (e.key === " " && e.target.selectionStart === 0) {
                              e.preventDefault();
                            }
                          }}
                          maxLength={20}
                          value={values.password}
                          onChange={handleChange}
                        // onBlur={handleBlur}
                        />
                        <span
                          onClick={() => setShowPwd(!showPwd)}
                          className="input-field-icon cursor-pointer cs-neutral-80"
                        >
                          <CapsyncIcon
                            title={`${showPwd
                              ? "show-password-outlined"
                              : "hide-password-outlined"
                              }`}
                            size="18"
                          />
                        </span>
                        {errors.password && touched.password ? (
                          <span className="form-error-msg default-light-body-text-s cs-danger">
                            {errors.password}
                          </span>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <div className="auth-forgot-Link-text">
                        <Link
                          to="/forgot-password"
                          className="cs-primary default-regular-sub-heading-m"
                        >
                          Forgot password?
                        </Link>
                      </div>
                    </Col>
                  </Row>
                  {authValidMsg !== "" && !sendEmail && (
                    <>
                      <div className="error-msg default-light-body-text-m cs-danger">
                        <span className="icon">
                          <CapsyncIcon
                            title="declined-outlined"
                            size="18"
                          />
                        </span>
                        <span className="formik-form-error ">
                          {authValidMsg}
                        </span>
                      </div>
                      {verifyCode && (
                        <Link
                          onClick={() => resendEmail(verifyCode)}
                          className="cs-primary default-regular-sub-heading-m"
                        >
                          Resend email
                        </Link>
                      )}
                    </>
                  )}

                  {sendEmail && message.message && (
                    <div className={statusCls}>
                      <span className="icon">
                        {/* <CapsyncIcon title="declined-outlined" /> */}
                        <CapsyncIcon title={`${message.status === "Error" ? "declined-outlined" : "verification-outlined"}`} />


                      </span>
                      <span>{message.message}</span>
                    </div>
                  )}
                  <Row>
                    <Col lg={12}>
                      <div className="auth-signin-btn cs-form-group">

                        <Button
                          type="submit"
                          className="cs-btn-primary lg-btn default-regular-h5"
                          disabled={loading}
                        >
                          Sign in
                        </Button>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={12}>
                      <Form.Group className="">
                        <div className="cs-msg default-regular-body-text-s cs-danger">
                          <span htmlFor="termsCheckbox" className="default-light-body-text-m cs-neutral-90">
                            By selecting Sign in, you agree to our&nbsp;
                            <Link
                              to="https://capsync.com/terms-of-service/"
                              className="cs-primary default-regular-body-text-s cs-link"
                              target="_Blank"
                            >
                              Terms of Service
                              {/* &nbsp;Terms of ytytyty Service&nbsp; */}
                            </Link>
                            &nbsp;and acknowledge our&nbsp;
                            <Link
                              to="https://capsync.com/privacy-policy/"
                              className="cs-primary default-regular-body-text-s cs-link"
                              target="_Blank"
                            >
                              Privacy Policy.
                            </Link>
                          </span>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>

                </Form>
                <span className="auth-main-link default-regular-body-text-m">
                  New to CapSync?{" "}
                  <Link to="/signup" className="cs-primary">
                    Sign up
                  </Link>
                </span>
              </div>
            </div>
          </Col>
        </Row>
        {loading && <CapsyncLoader />}
      </Container>
    </section>
  );
}

export default SignIn;
